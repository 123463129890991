import { pick } from "lodash";
import { createSelector } from "reselect";
import { AppState } from "..";
import { EducationSection, Profile, ProfileState } from "src/interfaces";

import {
  PERSONAL_INFO_KEYS,
  EDUCATION_KEYS,
  CAREER_KEYS,
  OTHER_HC_KEYS,
  DEBTS_KEYS,
} from "./selectorKeys";
import { getIsCurrentStudent } from "../system/selector";

const now = new Date();
const nowYear = now.getFullYear();
const nowMonth = now.getMonth() + 1;

export const getProfileBuildState = (state: AppState) => state.profileBuild;

export const getProfileLoading = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.loading
);
export const getProfileLoaded = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.loaded
);
export const getProfileError = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.error
);

export const getCurrentStep = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.currentStep
);
export const getProfileProgress = createSelector(
  getProfileBuildState,
  (state) => state.progress
);

export const getProfile = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.profile
);
export const getSpouseProfile = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.spouseProfile
);
export const getHousehold = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.household
);

export const getPersonalInfo = createSelector(
  [getProfile],
  (profile: Profile) => pick(profile, PERSONAL_INFO_KEYS)
);
export const getSpousePersonalInfo = createSelector(
  [getSpouseProfile],
  (profile: Profile) => pick(profile, PERSONAL_INFO_KEYS)
);
export const getEducation = createSelector([getProfile], (profile: Profile) =>
  pick(profile, EDUCATION_KEYS)
);
export const getCareer = createSelector([getProfile], (profile: Profile) =>
  pick(profile, CAREER_KEYS)
);
export const getOtherHC = createSelector([getProfile], (profile: Profile) =>
  pick(profile, OTHER_HC_KEYS)
);
export const getSpouseEducation = createSelector(
  [getSpouseProfile],
  (profile: Profile) => pick(profile, EDUCATION_KEYS)
);
export const getSpouseCareer = createSelector(
  [getSpouseProfile],
  (profile: Profile) => pick(profile, CAREER_KEYS)
);
export const getSpouseOtherHC = createSelector(
  [getSpouseProfile],
  (profile: Profile) => pick(profile, OTHER_HC_KEYS)
);
export const getDebts = createSelector([getProfile], (profile: Profile) =>
  pick(profile, DEBTS_KEYS)
);
export const getSpouseDebts = createSelector(
  [getSpouseProfile],
  (profile: Profile) => pick(profile, DEBTS_KEYS)
);
export const getProfileRepayment = createSelector(
  [getProfile],
  (profile) => profile.fed_repayment_plan
);
export const getSpouseProfileRepayment = createSelector(
  [getSpouseProfile],
  (profile) => profile.fed_repayment_plan
);

export const getSchools = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.schools
);
export const getLeveledSchools = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.leveledSchools
);
export const getSpecialties = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.specialties
);
export const getFields = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.fields
);
export const getProfessions = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.professions
);
export const getEmployments = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.employments
);
export const getFellowships = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.fellowships
);
export const getResidencies = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.residencies
);
export const getDesignations = createSelector(
  getProfileBuildState,
  (state: ProfileState) => state.designations
);

const getProfileStudentStatus = (education: EducationSection) => {
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const gradMonth =
    education.phd_grad_month ||
    education.date_last_school_month ||
    education.undergrad_grad_month ||
    education.aa_grad_month ||
    1;
  const gradYear =
    education.phd_grad_year ||
    education.date_last_school_year ||
    education.undergrad_grad_year ||
    education.aa_grad_year ||
    0;
  if (!gradYear) {
    return 12;
  }
  if (gradYear > currentYear) {
    return -1;
  }
  let monthDiff = -1;
  if (gradYear === currentYear) {
    monthDiff = currentMonth - gradMonth;
  } else {
    monthDiff = 12 * (currentYear - gradYear) + currentMonth + 12 - gradMonth;
  }
  return monthDiff;
};

const graduationYearPlusSixMonths = (education: EducationSection) => {
  const year =
    education.phd_grad_year ||
    education.date_last_school_year ||
    education.undergrad_grad_year ||
    education.aa_grad_year ||
    0;
  if (!year) {
    return 0;
  }
  const month =
    education.phd_grad_month ||
    education.date_last_school_month ||
    education.undergrad_grad_month ||
    education.aa_grad_month ||
    1;
  if (month + 6 > 12) {
    return year + 1;
  }
  return year;
};

const graduationMonthPlusSix = (education: EducationSection) => {
  const month =
    education.phd_grad_month ||
    education.date_last_school_month ||
    education.undergrad_grad_month ||
    education.aa_grad_month ||
    1;
  return (month + 6) % 12;
};

export const getGraduationYear = createSelector(
  getEducation,
  graduationYearPlusSixMonths
);
export const getSpouseGraduationYear = createSelector(
  getSpouseEducation,
  graduationYearPlusSixMonths
);

export const getGraduationMonth = createSelector(
  getEducation,
  graduationMonthPlusSix
);
export const getSpouseGraduationMonth = createSelector(
  getSpouseEducation,
  graduationMonthPlusSix
);

export const getLastGraduationYearMonth = createSelector(
  [
    getGraduationYear,
    getSpouseGraduationYear,
    getGraduationMonth,
    getSpouseGraduationMonth,
  ],
  (myYear, spouseYear, myMonth, spouseMonth) => {
    const output = [myYear, myMonth];
    if (spouseYear >= myYear) {
      output[0] = spouseYear;
      if (spouseYear > myYear) {
        output[1] = spouseMonth;
      } else {
        output[1] = Math.max(myMonth, spouseMonth);
      }
    }
    return output;
  }
);

export const getIsUndergrad = createSelector(getEducation, (education) => {
  const higherGradYear =
    education.phd_grad_month || education.date_last_school_year || 0;
  return !higherGradYear;
});
export const getUserInSchool = createSelector(
  [getGraduationYear, getGraduationMonth],
  (year, month) => {
    return year > nowYear || (year === nowYear && month >= nowMonth);
  }
);
export const getSpouseInSchool = createSelector(
  [getSpouseGraduationYear, getSpouseGraduationMonth],
  (year, month) => {
    return year > nowYear || (year === nowYear && month >= nowMonth);
  }
);

export const getHasGraduated = createSelector(
  [
    getIsCurrentStudent,
    getGraduationYear,
    getGraduationMonth,
    getSpouseGraduationYear,
    getSpouseGraduationMonth,
  ],
  (isCurrentStudent, userYear, userMonth, spouseYear, spouseMonth) => {
    if (!isCurrentStudent) {
      return false;
    }
    const userGraduated =
      !userYear ||
      userYear < nowYear ||
      (userYear === nowYear && userMonth - 6 <= nowMonth);
    const spouseGraduated =
      !spouseYear ||
      spouseYear < nowYear ||
      (spouseYear === nowYear && spouseMonth - 6 <= nowMonth);
    return userGraduated && spouseGraduated;
  }
);

export const getStudentStatus = createSelector(
  getEducation,
  getProfileStudentStatus
);
export const getSpouseStudentStatus = createSelector(
  getSpouseEducation,
  getProfileStudentStatus
);

export const getRetirementDate = createSelector(getPersonalInfo, (profile) => {
  const dobYear = profile.dob_year;
  const targetYear = (dobYear || 1999) + 65;
  return `${targetYear}-06-30`;
});
