import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import IncomeAndExpenses from "./MainForm";
import GetStarted from "./GetStarted";

const incomeAndExpensesView = {
  [PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_1]: GetStarted,
  [PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_2]: GetStarted,
  [PROFILE_BUILD_STEPS.INCOME_EXPENSES]: IncomeAndExpenses,
};

export default incomeAndExpensesView;
