import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  AppBar,
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import Icon from "../../Icon";

import { headerStyles } from "src/theme";
import { AdapterLink } from "src/utils";
import { AccountProvider } from "src/interfaces";
// import TutorialMenu from "../TutorialMenu";
import UserMenu from "../UserMenu";
import { getCalendlyLinkApi } from "src/apiService";
import { Modal } from "src/components/Dialogs";
import StatusIndicator from "src/components/StatusIndicator";
import { getAccountProviders } from "src/store/account/selector";
import { getAlerts, getScore } from "src/store/dashboard/selector";
import { isLoggedInSelector } from "src/store/system/selector";
import { getRouterState } from "src/store/router/selector";

interface DashboardHeaderProps {
  onDrawerToggle: VoidFunction;
  title: string;
}

const useStyles = makeStyles(headerStyles);

const DashboardHeader = ({ onDrawerToggle, title }: DashboardHeaderProps) => {
  const classes = useStyles();
  const score = useSelector(getScore);
  const loggedIn = useSelector(isLoggedInSelector);
  const providers = useSelector(getAccountProviders);
  const alerts = useSelector(getAlerts);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogUrl, setDialogUrl] = useState("");
  const router = useSelector(getRouterState);

  useEffect(() => {
    if (router?.location?.hash === "#schedule") {
      openCalendlyDialog();
    }
  }, []);

  const openCalendlyDialog = () => {
    if (dialogUrl) {
      setDialogOpen(true);
    }
    getCalendlyLinkApi()
      .then((result) => {
        let calendlyUrl = result.url + "?";
        if (result.prefill) {
          calendlyUrl += `name=${result.prefill.name}&email=${result.prefill.email}`;
          if (result.prefill.customAnswers) {
            calendlyUrl += `&a1=${result.prefill.customAnswers.a1}`;
          }
        }
        setDialogUrl(calendlyUrl);
        setDialogOpen(true);
      })
      .catch(console.error);
  };

  const closeDialog = () => setDialogOpen(false);
  const renderUserBar = () => {
    const newProviders = (providers || []).filter(
      (item: AccountProvider) => item.new || item.error
    );
    if (loggedIn) {
      return (
        <>
          <Grid item>
            <Tooltip title="Your FitBUX Score">
              <div className="flex items-center">
                <IconButton
                  color="inherit"
                  className="p-1"
                  component={AdapterLink}
                  to="/scorefactors"
                >
                  <Icon iconName="fb-meter" />
                  <Typography component="h4" className={`${classes.text} ml-2`}>
                    {score}
                  </Typography>
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" flexItem className="mx-1 h-5" />
          </Grid>
          <Grid item>
            <Tooltip title="Alerts">
              <IconButton
                color="inherit"
                className="p-1"
                component={AdapterLink}
                to="/alerts"
              >
                <Icon iconName="fb-notification" />
                {((alerts && !!alerts.length) ||
                  (newProviders && !!newProviders.length)) && (
                  <Box style={{ position: "absolute", right: -5, bottom: 3 }}>
                    <StatusIndicator fill="#B81414" />
                  </Box>
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Schedule a Call">
              <IconButton
                color="inherit"
                className="p-1"
                onClick={openCalendlyDialog}
              >
                <Icon iconName="fb-phone" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Message Your Coach">
              <IconButton
                color="inherit"
                className="p-1"
                component={AdapterLink}
                to="/coach"
              >
                <Icon iconName="fb-email" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Settings">
              <IconButton
                color="inherit"
                className="p-1"
                component={AdapterLink}
                to="/settings"
              >
                <Icon iconName="fb-settings" />
              </IconButton>
            </Tooltip>
          </Grid>
          {/*<Grid item>
            <TutorialMenu />
          </Grid>*/}
          <Grid item>
            <UserMenu />
          </Grid>
        </>
      );
    } else
      return (
        <>
          <Grid item>
            <Tooltip title="Log In">
              <IconButton
                color="inherit"
                component={AdapterLink}
                to="/login"
                href="#"
                className="p-1"
              >
                <InputIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      );
  };

  return (
    <>
      <AppBar color="default" position="relative" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                  href="#"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Typography component="h3" className={classes.title}>
              {title}
            </Typography>
            <Grid item xs></Grid>
            {renderUserBar()}
          </Grid>
        </Toolbar>
      </AppBar>
      <Modal title=" " size="lg" isOpen={dialogOpen} onClose={closeDialog}>
        <Box style={{ height: 850, width: "100%" }}>
          <iframe
            style={{
              border: "none",
              height: "88%",
              width: "100%",
            }}
            src={dialogUrl}
            title="Calendly scheduler"
          ></iframe>
        </Box>
      </Modal>
    </>
  );
};

export default DashboardHeader;
