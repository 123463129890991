import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
} from "@material-ui/core";

import Radio from "src/components/Radio";
import HomeMortgageForm from "./HomeMortgageForm";

import { FormComponentType } from "./interfaces";
import { DollarTextField } from "src/utils/inputMask";

const HomeValueForm: FormComponentType = ({ formState, updateField }) => (
  <>
    <Grid item xs={12} className="mt-5">
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className="mb-3 h-4">
          What is the value of the home?
        </FormLabel>
        <DollarTextField
          name="home_value"
          style={{ maxWidth: 350, width: "100%" }}
          onChange={updateField}
          variant="outlined"
          value={formState.home_value || 0}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} className="mt-5">
      <FormControl component="fieldset">
        <FormLabel component="legend" className="mb-3">
          Do you have a loan associated with this home?
        </FormLabel>
        <RadioGroup
          aria-label="has_home_mtg"
          name="has_home_mtg"
          onChange={updateField}
          style={{ maxWidth: 350, width: "100%" }}
          value={formState.has_home_mtg || 0}
          className="flex flex-row"
        >
          <FormControlLabel value="n" control={<Radio />} label="No" />
          <FormControlLabel value="y" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    </Grid>
    {formState.has_home_mtg === "y" && (
      <HomeMortgageForm
        formState={formState}
        updateField={updateField}
        hideValue={true}
      />
    )}
  </>
);

export default HomeValueForm;
