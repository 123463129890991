import { createSelector } from "reselect";
import { cloneDeep, forOwn } from "lodash";

import { AppState } from "..";
import { ASSET_TYPES, CashflowSummary, TypeAmount } from "src/interfaces";
import { getAccounts } from "../account/selector";
import {
  getLivePlanStartMonth,
  getLivePlanStartYear,
  getLiveShortTermSavings,
} from "../planBuild/selector";
import { getHasPlan } from "../system/selector";
import { getUnconfirmedIncomeAndExpenses } from "../transaction/selector";

const now = new Date();
const nowMonth = now.getMonth() + 1;
const nowYear = now.getFullYear();

export const getDashboardState = (state: AppState) => state.dashboard;
export const getPlanBuild = (state: AppState) => state.planBuild;

export const getActivePage = createSelector(
  getDashboardState,
  (state) => state.activePage
);

export const getScore = createSelector(
  getDashboardState,
  (state) => state.score
);
export const getScoreRanks = createSelector(
  getDashboardState,
  (state) => state.scoreRanks
);
export const getHcValue = createSelector(
  getDashboardState,
  (state) => state.hcScore
);
export const getMessages = createSelector(
  getDashboardState,
  (state) => state.messages
);
export const getHelpItem = createSelector(
  getDashboardState,
  (state) => state.help
);
export const getTutorial = createSelector(
  getDashboardState,
  (state) => state.tutorial
);
export const getVideo = createSelector(
  getDashboardState,
  (state) => state.video
);
export const getPopup = createSelector(
  getDashboardState,
  (state) => state.popup
);
export const getPopupActive = createSelector(
  getDashboardState,
  (state) => state.popupActive
);
export const getPopupPlan = createSelector(
  getDashboardState,
  (state) => state.popupPlan
);
export const getIsSidebarEnabled = createSelector(
  getDashboardState,
  (state) => state.isSidebarEnabled
);
export const getSidebarContent = createSelector(
  getDashboardState,
  (state) => state.sidebarContent
);
export const getHeaderClicked = createSelector(
  getDashboardState,
  (state) => state.headerClicked
);
export const getLoadedSummaries = createSelector(
  getDashboardState,
  (state) => state.loadedSummaries
);
export const getCalls = createSelector(
  getDashboardState,
  (state) => state.calls
);
export const getIdealPrivateLoanRate = createSelector(
  getDashboardState,
  (state) => state.idealPrivateLoanRate
);
export const getEmergencyFundTarget = createSelector(
  getDashboardState,
  (state) => state.emergencyFundTarget
);
export const getLifeInsuranceLiabilities = createSelector(
  getDashboardState,
  (state) => state.lifeInsuranceLiabilities
);
export const getMarketingDeals = createSelector(
  getDashboardState,
  (state) => state.marketingDeals
);
export const getRefreshNeeded = createSelector(
  getDashboardState,
  (state) => state.refreshNeeded
);
export const getIdrData = createSelector(getDashboardState, (state) => ({
  myIdrAccounts: state.myIdrAccounts,
  myIdrAssumptions: state.myIdrAssumptions,
  myIdrOverview: state.myIdrOverview,
  spouseIdrAccounts: state.spouseIdrAccounts,
  spouseIdrAssumptions: state.spouseIdrAssumptions,
  spouseIdrOverview: state.spouseIdrOverview,
}));
export const getMiscState = createSelector(
  getDashboardState,
  (state) => state.miscState
);
export const getPlanNotifications = createSelector(
  getDashboardState,
  (state) => state.planNotifications
);

const summaryReduceFn = (
  result: any,
  item: { type: string; amount: number; [field: string]: any }
) => {
  let whoValue = item.who || item.whose || "";
  if (whoValue === "mine") {
    whoValue = "applicant";
  }
  const typeValue = whoValue ? `${item.type}__${whoValue}` : item.type;
  return { ...result, [typeValue]: (result[typeValue] || 0) + item.amount };
};

export const getMonthsSincePlanInception = createSelector(
  [getLivePlanStartYear, getLivePlanStartMonth],
  (startYear, startMonth) =>
    Math.min(12, (nowYear - startYear) * 12 + nowMonth - startMonth)
);

const formatSummary = (summary: CashflowSummary) => ({
  annual: summary.annual.reduce(summaryReduceFn, {}),
  monthly: summary.monthly.reduce(summaryReduceFn, {}),
  current: summary.current.reduce(summaryReduceFn, {}),
});
export const getRawIncomeSummary = createSelector(
  getDashboardState,
  (state) => state.incomeSummary
);
export const getRawExpenseSummary = createSelector(
  getDashboardState,
  (state) => state.expenseSummary
);
export const getRawRiskSummary = createSelector(
  getDashboardState,
  (state) => state.riskSummary
);
export const getIncomeSummary = createSelector(
  getRawIncomeSummary,
  formatSummary
);
export const getExpenseSummary = createSelector(
  getRawExpenseSummary,
  formatSummary
);
export const getRiskSummary = createSelector(getRawRiskSummary, formatSummary);

// export const getOpenAccountIndex = createSelector(getDashboardState, (state) => state.openAccount);
// export const getOpenAccount = createSelector<AppState, DashboardState, number, Account | null>
//   ([getDashboardState, getOpenAccountIndex], (state, index) =>
//   index < 0 ? null : state.accounts[index]);

export const getExecutionSummary = createSelector(
  [getDashboardState, getPlanBuild, getMonthsSincePlanInception],
  (state, planState, monthsSinceInception) => {
    const planPercents = planState.livePlan.allocations[0];
    // const studentLoans = ["priv_loans", "fed_loan", "perkins_loan"];
    const emptyValues = {
      current: { actual: 0, plan: 0 },
      month: { actual: 0, plan: 0 },
      month_avg: { actual: 0, plan: 0 },
      annual: { actual: 0, plan: 0 },
    };
    const asset = {
      total: cloneDeep(emptyValues),
    };
    const debt = {
      total: cloneDeep(emptyValues),
    };
    const income = {
      total: cloneDeep(emptyValues),
    };
    const assetKeys = new Set<string>([]);
    const debtKeys = new Set<string>([]);
    const result = { asset, debt, assetKeys, debtKeys, income };
    if (!state.wholePlanSummary || !state.transactionSummary) {
      return result;
    }
    forOwn(
      state.transactionSummary.actual,
      (subObject2, currentMonthAnnual) => {
        forOwn(subObject2, (allocationList: any, assetDebt) => {
          if (assetDebt === "asset" || assetDebt === "debt") {
            (allocationList as TypeAmount[]).forEach(
              ({ type, amount }: TypeAmount) => {
                const category: any = result[assetDebt];
                if (assetDebt === "asset") {
                  assetKeys.add(type);
                } else {
                  debtKeys.add(type);
                }
                if (amount > 0) {
                  category.total[currentMonthAnnual].actual += amount;
                }
                if (!category[type]) {
                  category[type] = cloneDeep(emptyValues);
                }
                category[type][currentMonthAnnual].actual += amount;
              }
            );
          }
          if (assetDebt === "income") {
            const category: any = result[assetDebt];
            category.total[currentMonthAnnual].actual += subObject2.income;
          }
        });
      }
    );
    forOwn(
      state.transactionSummary.plan,
      (allocationList: TypeAmount[], currentMonthAnnual) => {
        allocationList.forEach(({ type }: TypeAmount) => {
          const assetDebt = type in ASSET_TYPES ? "asset" : "debt";
          const category: any = result[assetDebt];
          let planAmount;
          if (type === "student_loan") {
            planAmount =
              (planPercents.fed_loan || 0) +
              (planPercents.perkins_loan || 0) +
              (planPercents.priv_loan || 0);
          } else {
            planAmount = planPercents[type as keyof typeof planPercents];
          }
          if (planAmount) {
            if (assetDebt === "asset") {
              assetKeys.add(type);
            } else {
              debtKeys.add(type);
            }
            category.total[currentMonthAnnual].plan += planAmount;
            if (!category[type]) {
              category[type] = cloneDeep(emptyValues);
            }
            category[type][currentMonthAnnual].plan = planAmount;
          }
        });
      }
    );
    [asset, debt, income].forEach((item: any) => {
      Object.keys(item).forEach((key: string) => {
        item[key].month_avg.plan = item[key].month.plan;
        item[key].month_avg.actual =
          item[key].annual.actual / monthsSinceInception;
      });
    });
    return result;
  }
);

export const getStudentCashflowSummaries = createSelector(
  getDashboardState,
  (state) => {
    const income: any = { total: 0 };
    const expenses: any = { total: 0 };
    const risks: any = { total: 0 };
    const incomeKeys = new Set<string>([]);
    const expenseKeys = new Set<string>([]);
    const riskKeys = new Set<string>([]);
    const result = {
      income,
      expenses,
      risks,
      incomeKeys,
      expenseKeys,
      riskKeys,
    };
    if (
      !state?.studentExpenseSummary ||
      !state?.studentIncomeSummary ||
      !state?.studentRiskSummary
    ) {
      return result;
    }
    if (state.studentExpenseSummary.length) {
      state.studentExpenseSummary[0].summary.forEach(({ type, amount }) => {
        expenses.total += amount;
        expenseKeys.add(type);
        if (!expenses[type]) {
          expenses[type] = 0;
        }
        expenses[type] += amount;
      });
    }
    if (state.studentIncomeSummary.length) {
      state.studentIncomeSummary[0].summary.forEach(({ type, amount }) => {
        income.total += amount;
        incomeKeys.add(type);
        if (!income[type]) {
          income[type] = 0;
        }
        income[type] += amount;
      });
    }
    if (state.studentRiskSummary.length) {
      state.studentRiskSummary[0].summary.forEach(({ type, amount }) => {
        risks.total += amount;
        riskKeys.add(type);
        if (!risks[type]) {
          risks[type] = 0;
        }
        risks[type] += amount;
      });
    }
    return result;
  }
);

export const getStudentPlanSummaries = createSelector(
  getDashboardState,
  (state) => {
    const education: any = { total: 0 };
    const asset: any = { total: 0 };
    const debt: any = { total: 0 };
    const assetKeys = new Set<string>([]);
    const debtKeys = new Set<string>([]);
    const educationKeys = new Set<string>([]);
    // const expenseKeys = new Set<string>([]);
    const result = {
      education,
      asset,
      debt,
      educationKeys,
      assetKeys,
      debtKeys,
    };
    if (!state?.studentPlanSummary || !state?.studentEducationSummary) {
      return result;
    }
    if (state?.studentPlanSummary?.actual?.length) {
      forOwn(
        state.studentPlanSummary.actual[0],
        (allocationList: any, assetDebt) => {
          if (assetDebt === "asset" || assetDebt === "debt") {
            (allocationList as TypeAmount[]).forEach(
              ({ type, amount }: TypeAmount) => {
                const category = result[assetDebt];
                if (assetDebt === "asset") {
                  assetKeys.add(type);
                } else {
                  debtKeys.add(type);
                }
                category.total += amount;
                if (!category[type]) {
                  category[type] = 0;
                }
                category[type] += amount;
              }
            );
          }
        }
      );
    }
    if (state?.studentEducationSummary?.[0]?.cost) {
      state.studentEducationSummary[0].cost.forEach(
        ({ type, amount }: TypeAmount) => {
          education.total += amount;
          educationKeys.add(type);
          if (!education[type]) {
            education[type] = 0;
          }
          education[type] += amount;
        }
      );
    }
    return result;
  }
);

export const getCashTotal = createSelector(
  [getAccounts, getHasPlan, getLiveShortTermSavings],
  (accounts, hasPlan, shortTermSavings) => {
    const total = accounts.reduce((result, account) => {
      if (account.variable === "cash_value") {
        return result + (account.balance || 0);
      }
      return result;
    }, 0);
    if (!hasPlan || !shortTermSavings) {
      return total;
    }
    return Math.max(0, total - shortTermSavings);
  }
);

export const getAlerts = createSelector(
  [getMessages, getUnconfirmedIncomeAndExpenses],
  (messages, { income, expenses }) => {
    const alerts: any[] = [];
    if (messages.find((message) => message.new)) {
      alerts.push({ type: "message" });
    }
    if (income.length || expenses.length) {
      alerts.push({ type: "transactions", n: income.length + expenses.length });
    }
    return alerts;
  }
);
