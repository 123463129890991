import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { VIDEOS } from "src/store/dashboard/helpTopics";
import { getSidebarContent } from "src/store/dashboard/selector";
import { colors } from "src/theme";
import Button from "src/components/Button";
import Messages from "src/pages/Dashboard/Coach/Messages";

const useStyle = makeStyles({
  container: {
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  info: {},
  body: {
    backgroundColor: colors.blueGray8,
    margin: "5px",
    padding: "5px",
    // textAlign: "justify"
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    marginBottom: "10px",
  },
  messagePlaceHolder: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: "10px",
  },
  divider: {
    backgroundColor: "black",
    marginBottom: "10px",
  },
});

export const SideContent = () => {
  const classes = useStyle();
  const sidebarContent = useSelector(getSidebarContent);
  const [showMessages, setShowMessages] = useState(false);

  const renderMessageComponent = () => {
    if (showMessages) {
      return (
        <Box className={classes.messageContainer}>
          <Divider style={{ backgroundColor: "black" }} />
          <Box
            onClick={() => {
              setShowMessages(false);
            }}
          >
            <CloseIcon />
          </Box>
          <Messages centerSendButton />
        </Box>
      );
    } else {
      return (
        <Box className={classes.messagePlaceHolder}>
          <Divider className={classes.divider} />

          <Typography variant="caption">
            Have a question? Shoot us a message
          </Typography>
          <Box>
            <Button
              variant="outlined"
              onClick={() => {
                setShowMessages(true);
              }}
            >
              Message Us
            </Button>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <Box style={{ height: "100%" }}>
        <Box className={classes.container}>
          <Box className={classes.info}>
            {sidebarContent &&
              sidebarContent.map((textSegment, index) => (
                <Box key={index}>
                  <Typography
                    variant="h1"
                    style={{ margin: "20px 0px 10px 5px", fontSize: "18px" }}
                  >
                    {textSegment.header}
                  </Typography>
                  {!!textSegment.body.length && (
                    <Box className={classes.body}>
                      {textSegment.body.map((bodySegment: any, i: number) => (
                        <Typography key={i} paragraph>
                          {bodySegment}
                        </Typography>
                      ))}
                    </Box>
                  )}

                  {!!textSegment.lists &&
                    textSegment.lists.map((list) => (
                      <Box key={list.title}>
                        <Typography>{list.title}</Typography>
                        <Box component="ul" key={list.title}>
                          {list.list.map((item) => (
                            <li>{item}</li>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  {!!textSegment.videoKey && (
                    <iframe
                      src={`https://player.vimeo.com/video/${
                        VIDEOS[textSegment.videoKey as keyof typeof VIDEOS]
                      }`}
                      width="265"
                      height="180"
                      frameBorder="0"
                      allow="fullscreen; picture-in-picture"
                      style={{ width: "100%" }}
                      title={textSegment.videoKey}
                    ></iframe>
                  )}
                  {!!textSegment.lists &&
                    textSegment.lists.map((list) => (
                      <Box key={list.title}>
                        <Typography>{list.title}</Typography>
                        <Box component="ul" key={list.title}>
                          {list.list.map((item) => (
                            <li>{item}</li>
                          ))}
                        </Box>
                      </Box>
                    ))}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      {renderMessageComponent()}
    </>
  );
};
