import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, makeStyles, Paper, Typography } from "@material-ui/core";

import { getCalendlyLinkApi } from "src/apiService";
import Button from "src/components/Button";
import { Modal } from "src/components/Dialogs";
import { getRouterState } from "src/store/router/selector";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import colors from "src/theme/colors";
import Messages from "./Messages";

const useStyles = makeStyles({
  paper: {
    height: "100%",
    padding: 0,
    marginLeft: 12,
    marginRight: 24,
    width: 510,
  },
  paperHeader: {
    backgroundColor: "transparent",
    background: colors.gradBoxCard,
    height: 240,
    padding: 36,
    width: "100%",
  },
  paperContent: {
    padding: "40px 36px",
    "&>p": {
      marginTop: 20,
    },
  },
  paperActions: {
    display: "flex",
    marginTop: 60,
  },
  image: {
    display: "block",
    height: 168,
    margin: "0 auto",
  },
  modalContent: {
    height: 850,
    width: "100%",
  },
  iframe: {
    border: "none",
    height: "88%",
    width: "100%",
  },
});

export const Content = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const [dialogUrl, setDialogUrl] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const router = useSelector(getRouterState);

  useEffect(() => {
    if (router.location.hash === "#messages") {
      setShowMessages(true);
    }
  }, [router.location.hash]);

  const openCalendlyDialog = () => {
    if (dialogUrl) {
      setDialogOpen(true);
    }
    getCalendlyLinkApi()
      .then((result) => {
        let calendlyUrl = result.url + "?";
        if (result.prefill) {
          calendlyUrl += `name=${result.prefill.name}&email=${result.prefill.email}`;
          if (result.prefill.customAnswers) {
            calendlyUrl += `&a1=${result.prefill.customAnswers.a1}`;
          }
        }
        setDialogUrl(calendlyUrl);
        setDialogOpen(true);
      })
      .catch(console.error);
  };

  const closeCalendlyDialog = () => setDialogOpen(false);

  if (showMessages) {
    return <Messages goBack={() => setShowMessages(false)} />;
  }

  return (
    <Box className="flex">
      <Paper square className={styles.paper}>
        <Box className={styles.paperHeader}>
          <img
            className={styles.image}
            src="/assets/images/png/clipboard.png"
          />
        </Box>
        <Box className={styles.paperContent}>
          <Typography variant="h2" component="h2">
            We're Here to Help!
          </Typography>
          <Typography variant="body1">
            Need help from a financial expert? You can contact us for free.
            Either message a coach below or click schedule a call to jump on our
            calendar. We look forward to helping you.
          </Typography>
          <Box className={styles.paperActions}>
            {!subscribed && (
              <Button fbColor="primary" onClick={() => setShowMessages(true)}>
                Message a Coach
              </Button>
            )}
            <Button
              className="ml-4"
              fbColor="secondary"
              onClick={openCalendlyDialog}
            >
              Schedule a Call
            </Button>
          </Box>
        </Box>
      </Paper>
      <Paper square className={styles.paper}>
        <Box className={styles.paperHeader}>
          <img
            className={styles.image}
            src="/assets/images/png/fb-bullhorn.png"
          />
        </Box>
        <Box className={styles.paperContent}>
          <Typography variant="h2" component="h2">
            Your Designated FitBUX Coach
          </Typography>
          <Typography variant="body1">
            One of the most liked features of our premium Membership is that you
            get to speak to a Coach any time you’d like and you get to speak to
            the same Coach every time.
          </Typography>
          <Typography variant="body1">
            That’s right, you don’t have to speak to someone at a random call
            center.
          </Typography>
          <Box className={styles.paperActions}>
            {!subscribed && (
              <Button
                fbColor="primary"
                onClick={() => dispatch(openUpgradeDialog())}
              >
                Upgrade Membership
              </Button>
            )}
            {subscribed && (
              <Button fbColor="primary" onClick={() => setShowMessages(true)}>
                Message Your Coach
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
      <Modal
        title=" "
        size="lg"
        isOpen={dialogOpen}
        onClose={closeCalendlyDialog}
      >
        <Box className={styles.modalContent}>
          <iframe
            className={styles.iframe}
            src={dialogUrl}
            title="Calendly scheduler"
          ></iframe>
        </Box>
      </Modal>
    </Box>
  );
};
