import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { headerStyles } from "src/theme";
import { Content } from "./Content";

const useStyles = makeStyles(headerStyles);

const RiskManagement = () => {
  const classes = useStyles();
  return (
    <main className={classes.mainContent}>
      <Content />
    </main>
  );
};

export default RiskManagement;
