export interface User {
  first_name: string;
  last_name: string;
  email: string;
}

export enum AUTH_STATUS {
  NONE = 0,
  LOADING = 1,
  SUCCEED = 2,
  FAILED = 3,
}

export interface Features {
  married: boolean;
  hasPlan: boolean;
  subscribed: boolean;
  subscription?: boolean;
  inschool: boolean;
  // Force enable Plan Builder functionality
  planBuilder: boolean;
}

export interface SystemState {
  features: Features;
  loggedIn: boolean;
  user?: User;
  spouse?: User;
  loading: boolean;
  error: any;
  authStatus: AUTH_STATUS;
  addingSpouse: boolean;
  spouseLinked: boolean;
  passwordIsReset: boolean;
  passwordResetFail: boolean;
  previousRoute: string;
  upgradeDialogOpen: boolean;
  moneySchoolDialogOpen: boolean;
  updatesSuspended: boolean;
}
