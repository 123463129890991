import * as React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Box, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import StatusIndicator from "src/components/StatusIndicator";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";

const useStyles = makeStyles({
  alert: {
    "&:hover": {
      background: colors.gradLightHorizontal,
      cursor: "pointer",
    },
  },
});

const MessageCard = ({ alert }: any) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  return (
    <Card
      className={styles.alert + " w-full mt-3"}
      onClick={() =>
        dispatch(
          push(alert.type === "message" ? "/coach#messages" : "/transactions")
        )
      }
    >
      <Box className="flex justify-start items-center">
        <Box className="flex w-8 mr-8">
          <StatusIndicator
            fill={alert.type === "message" ? colors.success : colors.warn}
          />
          <Icon
            iconName={
              alert.type === "message" ? "fb-face-satisfied" : "fb-money"
            }
          />
        </Box>
        <Box>
          {alert.type === "message"
            ? "You have a new message from your coach!"
            : `You have ${alert.n} new transactions to confirm.`}
        </Box>
        <Icon iconName="fb-chevron-right" className="ml-auto" />
        {/*alert.type === "message" &&
          <Button
            fbColor="primary"
            component={AdapterLink}
            to="/coach"
            style={{ display: "block", marginLeft: "auto" }}
          >Go to Messages
          </Button>
        */}
      </Box>
    </Card>
  );
};

// const useStyles = makeStyles(headerStyles);

export default MessageCard;
