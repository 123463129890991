import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";

import { Box, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import Button from "src/components/Button";
import {
  getAccountProviders as selectProviders,
  getAccountsLoaded,
  getPublicToken as selectPublicToken,
} from "src/store/account/selector";
import { getAccountProviders, getPublicToken } from "src/store/account/actions";
import { addPublicTokenApi } from "src/apiService";
import { ConfirmDialog } from "src/components";
import LinkAccountDialog from "src/components/LinkAccountDialog";
import AddManualTransactionDialog from "../Dialogs/AddManualTransactionDialog";

export const AddTransactionsCard = ({ openConfirmTransactionsDialog }: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const publicToken = useSelector(selectPublicToken);
  const providers = useSelector(selectProviders);
  const loaded = useSelector(getAccountsLoaded);

  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string) => {
      try {
        await addPublicTokenApi({ public_token });
        setLinkAccountDialogVisible(false);
        setConfirmDialogVisible(true);
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const config: PlaidLinkOptions = {
    token: publicToken?.link_token || "",
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (!linkAccountDialogVisible && !confirmDialogVisible) return;
    dispatch(getPublicToken(undefined));
  }, [linkAccountDialogVisible, confirmDialogVisible]);

  useEffect(() => {
    if ((!providers || !providers.length) && !loaded) {
      dispatch(getAccountProviders());
    }
  }, [dispatch]);

  const closeDialogs = () => {
    setConfirmDialogVisible(false);
    setLinkAccountDialogVisible(false);
    setAddDialogVisible(false);
  };

  return (
    <Card title="Add Transactions">
      <Box className="my-5">
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={() => setLinkAccountDialogVisible(true)}
        >
          By Linking Accounts
        </Button>
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={() => setAddDialogVisible(true)}
        >
          Manually
        </Button>
      </Box>
      <LinkAccountDialog
        isOpen={linkAccountDialogVisible}
        onClose={() => setLinkAccountDialogVisible(false)}
        openPlaid={() => open()}
        ready={ready}
      />
      <ConfirmDialog
        visible={confirmDialogVisible}
        title="Add More Accounts"
        message="Would you like to link another account?"
        onCancel={closeDialogs}
        onConfirm={() => open()}
        cancelButton="No, Thank You"
        confirmButton="Yes, Please"
      />
      <AddManualTransactionDialog
        visible={addDialogVisible}
        onClose={closeDialogs}
        openConfirmDialog={openConfirmTransactionsDialog}
      />
    </Card>
  );
};

export default AddTransactionsCard;

const useStyles = makeStyles({
  button: {
    width: "100%",
    margin: "7px 0",
  },
});
