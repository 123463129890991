import React from "react";

import { Box, Typography } from "@material-ui/core";

const IncomeExplainer = () => {
  return (
    <Box>
      <img
        src="/assets/images/animations/wave.gif"
        alt="Waving Hand"
        style={{ display: "block", width: "200px", margin: "0 auto" }}
      />
      <Typography variant="h2" component="h2">
        Income Transactions
      </Typography>
      <Typography paragraph variant="body1">
        Your Total Income is key to tracking your plan. Please enter your Total
        Income and your deductions from your paycheck on the next two screens.
        Be sure that the Net Income calculated (Net Income = Total Income minus
        Deductions) matches the take home pay on your paycheck.
      </Typography>
      <Typography paragraph variant="body1">
        For recurring transactions of a similar amount, you will only need to do
        this once.
      </Typography>
    </Box>
  );
};

export default IncomeExplainer;
