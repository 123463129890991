import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import RiskManagement from "./MainForm";
import GetStarted from "./GetStarted";

const personalInfoViews = {
  [PROFILE_BUILD_STEPS.RISK_MANAGEMENT_INTRO]: GetStarted,
  [PROFILE_BUILD_STEPS.RISK_MANAGEMENT]: RiskManagement,
};

export default personalInfoViews;
