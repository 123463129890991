import { DashboardMessage } from "src/interfaces";

export const DASHBOARD_MESSAGES: DashboardMessage[] = [
  {
    id: 1,
    message: "Get your questions answered and get help building your plan.",
    title: "Need help building your plan?",
    action: "Coach",
  },
  {
    id: 2,
    message:
      "Your Human Capital Value is HC_VALUE. Convert your human capital to financial capital and maintain a high FitBUX Score",
    title: "Need help building your plan?",
    action: "Coach",
  },
  {
    id: 3,
    message:
      "Your Human Capital Value is HC_VALUE. Convert your human capital to financial capital and increase your FitBUX Score.",
    title: "Need help building your plan?",
    action: "Coach",
  },
  {
    id: 4,
    message:
      "Your Human Capital Value is HC_VALUE. Convert your human capital to financial capital and increase your FitBUX Score.",
    title: "Need help building your plan?",
    action: "Coach",
  },
  {
    id: 5,
    message:
      "Explore how you can convert your human capital to financial capital and maintain/increase your FitBUX Score. Click the plan button above to review your plans. Then click implement on the plan you'd like to follow.",
    title: "Build a plan and start hitting your goals now!",
    action: "Plan",
  },
  {
    id: 6,
    message:
      "Explore how you can convert your human capital to financial capital, reduce financial risk, and increase your FitBUX Score over time.",
    title: "Build a plan and start hitting your goals now!",
    action: "Plan",
  },
  {
    id: 7,
    message:
      "Explore how you can convert your human capital to financial capital, reduce financial risk, and increase your FitBUX Score over time. Click the plan button above to review your plans. Then click implement on the plan you'd like to follow.",
    title: "Build a plan and start hitting your goals now!",
    action: "Plan",
  },
  {
    id: 8,
    message:
      "Nice work building your plan! Get step-by-step guidance to make sure you don't make any major financial mistakes. Click the plan button above to review your plans. Then click implement on the plan you'd like to follow.",
    title: "Implement a plan and start hitting your goals now!",
    action: "Plan",
  },
  {
    id: 9,
    message:
      "Nice work building your plan! Get step-by-step guidance to make sure you don't make any major financial mistakes. Click the plan button above to review your plans. Then click implement on the plan you'd like to follow.",
    title: "Implement a plan and start hitting your goals now!",
    action: "Plan",
  },
  {
    id: 10,
    message:
      "You built a plan, now its time to make sure you don't make any mistakes. Click implement to get step-by-step guidance.",
    title: "Implement a plan and start hitting your goals now!",
    action: "Plan",
  },
  {
    id: 11,
    message:
      "Great News! You should be able to save by refinancing your private loan(s). Get expert help using our free student loan refinance service.",
    action: "Schedule A Call",
    title: "Save by refinancing your student loans!",
  },
  {
    id: 12,
    message:
      "Make sure your loan servicer is applying your prepayments correctly to maximize your savings and your FitBUX Score.",
    title: "Upgrade Your Subscription",
    action: "Upgrade",
  },
  {
    id: 13,
    message:
      "You should be able to save money by refinancing your student loans. Get expert help for free.",
    action: "Schedule A Call",
    title: "Save by refinancing your student loans!",
  },
  {
    id: 14,
    message:
      "While on IDR, it is critical to start saving early to cover your tax liability. This will reduce your financial risk and boost your FitBUX Score",
    action: "Upgrade/Wealth",
    title: "Don't forget to save for your IDR tax bill",
  },
  {
    id: 15,
    message:
      "Build an emergency fund of TARGET_FUND and increase your FitBUX Score to TARGET_SCORE. Be sure to include a checking and savings account as an asset on your accounts screen.",
    action: "Update Profile",
    title: "It's time to build your emergency fund",
  },
  {
    id: 16,
    message:
      "Costly credit card debt decreases your FitBUX Score. See how much you could save by refinancing this debt with one of our partners.",
    action: "Check Rates",
    title: "Save by consolidating your credit card debt",
  },
  {
    id: 17,
    message:
      "Having disability insurance would increase your FitBUX Score. If you need insurance, shop offers from our partner Policy Genius.",
    action: "insurance:disability",
    title: "Should you get disability insurance?",
  },
  {
    id: 18,
    message:
      "Based on your profile, we recommend that you get (additional) life insurance. This would increase your FitBUX Score. Shop with our partner Policy Genius.",
    action: "insurance:life",
    title: "Consider (additional) life insurance. Check your premiums now.",
  },
  {
    id: 19,
    message: "Be sure to log in frequently to refresh your FitBUX Score.",
    title: "Don't forget to refresh your score regularly",
  },
  {
    id: 20,
    message: "You can make money by referring your friends to FitBUX.",
    action: "Refer A Friend",
    title: "Refer a friend with your personalized code",
  },
  {
    id: 21,
    message:
      "Congratulations on refinancing your student loans!. Be sure to update your profile accordingly.",
    action: "Update Profile",
    title: "Refinance done! Update your profile now.",
  },
  {
    id: 22,
    message:
      "Congratulations on refinancing your credit cards! Be sure to update your profile accordingly. ",
    action: "Update Profile",
    title: "Refinance done! Update your profile now.",
  },
  {
    id: 23,
    message:
      "Congratulations on getting life insurance. Please update your account by entering the combined death benefit of your life insurance policies and the monthly/annual premiums.",
    action: "Update Insurance",
    title: "Life Insurance Complete! Update your profile now.",
  },
  {
    id: 24,
    message:
      'Congratulations on getting disability insurance. Please update your account by choosing "Yes" that you have disability insurance and entering in the monthly/annual premiums.',
    action: "Update Insurance",
    title: "Life Insurance Complete! Update your profile now.",
  },
  {
    id: 25,
    message:
      "You can now open IRAs and taxable brokerage accounts, as well as roll-over old employer 401(k)/403(b) accounts with FitBUX. FitBUX investment accounts are based on your FitBUX Score, which encommpasses your current and projected financial situation, your goals, as well as your Human Capital. Therefore, we have the unique ability to manage your money based on your ability and willingness to take risk.",
    action: "Open Account",
    title: "Open An Investment Account",
  },
  {
    id: 26,
    message:
      "FitBUX provides you with free calculators to help decide everything from student loan repayment plans to buying a home. Our products, such as student loan refinancing, will help you take action on your financial plan.",
    action: "Tools & Products",
    title: "Use our free calculators and products",
  },
  {
    id: 27,
    message:
      "Schedule a free call with a Coach. Want a designated Coach with longer call times?  Be sure to sign up for our premium membership.",
    action: "Schedule A Call",
    title: "Schedule a call with a FitBUX Coach",
  },
];

export const PAID_DASHBOARD_MESSAGES_ENUM = {
  FIRST_STEP_BUILD_PLAN: {
    id: 91,
    message:
      "Your first step is to build a plan that you will implement and track. You can build multiple plans for easy comparison before make a decision.",
    title: "Build & Implement Your Plan",
    action: "Plan",
  },
  LINK_ACCOUNTS: {
    id: 92,
    message: "Linking your financial accounts will keep your plan up to date.",
    title: "Link Your Accounts",
    action: "Link",
  },
  CONFIRM_LINKED_ACCOUNTS: {
    id: 93,
    message: "Please confirm your recently linked accounts.",
    title: "Confirm Your Linked Account",
    action: "Confirm",
  },
  REVIEW_ACCOUNTS: {
    id: 94,
    message:
      "Review, edit, or add your accounts to ensure that your plan includes the most up-to-date information.",
    action: "Review Accounts",
    title: "View/Edit/Add Accounts",
  },
  REVIEW_TRANSACTIONS: {
    id: 95,
    message:
      "Review, edit, or add transactions details to ensure that your plan includes the most up-to-date information.",
    action: "Review Transactions",
    title: "View/Edit/Add Transactions",
  },
  STUDENT_LOAN_UPDATE: {
    id: 96,
    message: "",
    title: "Your Student Loan Update",
    action: "Plan",
  },
  UPDATE_LINKED_ACCOUNTS: {
    id: 97,
    message:
      "One of your financial institutions has modified their website. Please click the alerts button to relink your account.",
    title: "Update Your Linked Account",
    action: "Alerts",
  },
  UNUSED_98: {
    id: 98,
    message: "",
    title: "",
    action: "",
  },
  ISL_REFINANCE: {
    id: 99,
    message:
      "We've partnered with ISL, a lender which may let you refinance your private loan(s) while in school. Click below to see your rates. Getting rates will not impact your credit. If you have questions, simply schedule a free call and we will be glad to help.",
    title: "Save Money - Refinance Your Private Loan",
    action: "Tax Savings Account",
  },
  OPEN_INVESTMENT_ACCOUNT: {
    id: 910,
    message:
      "Your plan calls for AMOUNT per month going to ACCOUNT_TYPE. Would you like to open that account with your FitBUX partners?",
    title: "Set Up Your Investment Accounts",
    action: "Open Account",
  },
  ROLL_OVER_GENERAL: {
    id: 911,
    message:
      "It looks like you've stopped making retirement contributions. You should consider rolling over this account to an IRA.",
    action: "Roll Over",
    title: "Roll Over Your Retirement Account",
  },
  ROLL_OVER_SPECIFIC: {
    id: 912,
    message:
      "It looks like your balance on your retirement account ACCOUNT_NAME hasn't been changed and you do not have any transactions into that account.  If you are no longer working with that employer you should rollover that account (Learn More).  If you are at that employer then visit your account and transactions screen to update your account.",
    title: "Retirement Accounts To Be Rolled Over?",
    action: "Roll Over",
  },
  REFI_STUDENT_LOANS: {
    id: 913,
    message:
      "You should be able to save money by refinancing your student loans. Get expert help for free.",
    action: "Schedule A Call",
    title: "Save by refinancing your student loans!",
  },
  SAVE_FOR_IDR: {
    id: 914,
    message:
      "While on IDR, it is critical to start saving early to cover your tax liability. This will reduce your financial risk and boost your FitBUX Score",
    action: "Upgrade/Wealth",
    title: "Don't forget to save for your IDR tax bill",
  },
  BUILD_EMERGENCY_FUND: {
    id: 915,
    message:
      "Build an emergency fund of TARGET_FUND and increase your FitBUX Score to TARGET_SCORE. Be sure to include a checking and savings account as an asset on your accounts screen.",
    action: "Update Profile",
    title: "It's time to build your emergency fund",
  },
  CONSOLIDATE_CREDIT_CARDS: {
    id: 916,
    message:
      "Costly credit card debt decreases your FitBUX Score. See how much you could save by refinancing this debt with one of our partners.",
    action: "Check Rates",
    title: "Save by consolidating your credit card debt",
  },
  GET_DISABILITY_INSURANCE: {
    id: 917,
    message:
      "Having disability insurance would increase your FitBUX Score. If you need insurance, shop offers from our partner Policy Genius.",
    action: "insurance:disability",
    title: "Should you get disability insurance?",
  },
  GET_LIFE_INSURANCE: {
    id: 918,
    message:
      "Based on your profile, we recommend that you get (additional) life insurance. This would increase your FitBUX Score. Shop with our partner Policy Genius.",
    action: "insurance:life",
    title: "Consider (additional) life insurance. Check your premiums now.",
  },
  LOG_IN_FREQUENTLY: {
    id: 919,
    message: "Be sure to log in frequently to refresh your FitBUX Score.",
    title: "Don't forget to refresh your score regularly",
  },
  REFER_A_FRIEND: {
    id: 920,
    message: "You can make money by referring your friends to FitBUX.",
    action: "Refer A Friend",
    title: "Refer a friend with your personalized code",
  },
  REFINANCE_COMPLETE: {
    id: 921,
    message:
      "Congratulations on refinancing your student loans!. Be sure to update your profile accordingly.",
    action: "Update Profile",
    title: "Refinance done! Update your profile now.",
  },
};

export const PAID_DASHBOARD_MESSAGES: DashboardMessage[] = [
  {
    id: 91,
    message:
      "Your first step is to build a plan that you will implement and track. You can build multiple plans for easy comparison before make a decision.",
    title: "Build & Implement Your Plan",
    action: "Plan",
  },
  {
    id: 92,
    message: "Linking your financial accounts will keep your plan up to date.",
    title: "Link Your Accounts",
    action: "Link",
  },
  {
    id: 93,
    message: "Please confirm your recently linked accounts.",
    title: "Confirm Your Linked Account",
    action: "Confirm",
  },
  {
    id: 94,
    message: "Be sure to update the balance of your manual accounts monthly.",
    title: "Updating Your Manual Account Balances",
    action: "Coach",
  },
  {
    id: 95,
    message:
      "For your linked accounts: make sure to update your net income to gross income as this is the value your plan uses. For accounts you have not linked to FitBUX, make sure to update your transactions manually.",
    title: "Update Your Transactions",
    action: "Transactions",
    actionLabel: "Update",
  },
  {
    id: 96,
    message: "",
    title: "Your Student Loan Update",
    action: "Plan",
  },
  {
    id: 97,
    message:
      "One of your financial institutions has modified their website. Please click the alerts button to relink your account.",
    title: "Update Your Linked Account",
    action: "Alerts",
  },
  {
    id: 98,
    message: "",
    title: "",
    action: "",
  },
  {
    id: 99,
    message:
      "We've partnered with ISL, a lender which may let you refinance your private loan(s) while in school. Click below to see your rates. Getting rates will not impact your credit. If you have questions, simply schedule a free call and we will be glad to help.",
    title: "Save Money - Refinance Your Private Loan",
    action: "Tax Savings Account",
  },
  {
    id: 910,
    message:
      "Your plan calls for AMOUNT per month going to ACCOUNT_TYPE. Would you like to open that account with your FitBUX partners?",
    title: "Set Up Your Investment Accounts",
    action: "Open Account",
  },
  {
    id: 911,
    message:
      "It looks like you've stopped making retirement contributions. You should consider rolling over this account to an IRA.",
    action: "Roll Over",
    title: "Roll Over Your Retirement Account",
  },
  {
    id: 912,
    message:
      "It looks like your balance on your retirement account ACCOUNT_NAME hasn't been changed and you do not have any transactions into that account.  If you are no longer working with that employer you should rollover that account (Learn More).  If you are at that employer then visit your account and transactions screen to update your account.",
    title: "Retirement Accounts To Be Rolled Over?",
    action: "Roll Over",
  },
  {
    id: 913,
    message:
      "You should be able to save money by refinancing your student loans. Get expert help for free.",
    action: "Schedule A Call",
    title: "Save by refinancing your student loans!",
  },
  {
    id: 914,
    message:
      "While on IDR, it is critical to start saving early to cover your tax liability. This will reduce your financial risk and boost your FitBUX Score",
    action: "Upgrade/Wealth",
    title: "Don't forget to save for your IDR tax bill",
  },
  {
    id: 915,
    message:
      "Build an emergency fund of TARGET_FUND and increase your FitBUX Score to TARGET_SCORE. Be sure to include a checking and savings account as an asset on your accounts screen.",
    action: "Update Profile",
    title: "It's time to build your emergency fund",
  },
  {
    id: 916,
    message:
      "Costly credit card debt decreases your FitBUX Score. See how much you could save by refinancing this debt with one of our partners.",
    action: "Check Rates",
    title: "Save by consolidating your credit card debt",
  },
  {
    id: 917,
    message:
      "Having disability insurance would increase your FitBUX Score. If you need insurance, shop offers from our partner Policy Genius.",
    action: "insurance:disability",
    title: "Should you get disability insurance?",
  },
  {
    id: 918,
    message:
      "Based on your profile, we recommend that you get (additional) life insurance. This would increase your FitBUX Score. Shop with our partner Policy Genius.",
    action: "insurance:life",
    title: "Consider (additional) life insurance. Check your premiums now.",
  },
  {
    id: 919,
    message: "Be sure to log in frequently to refresh your FitBUX Score.",
    title: "Don't forget to refresh your score regularly",
  },
  {
    id: 920,
    message: "You can make money by referring your friends to FitBUX.",
    action: "Refer A Friend",
    title: "Refer a friend with your personalized code",
  },
  {
    id: 921,
    message:
      "Congratulations on refinancing your student loans!. Be sure to update your profile accordingly.",
    action: "Update Profile",
    title: "Refinance done! Update your profile now.",
  },
];

export const STUDENT_DASHBOARD_MESSAGES: DashboardMessage[] = [
  {
    id: 103,
    message:
      "Upgrade your account to Premium and use our technology to implement your plan. You'll receive step-by-step guidance along your financial journey insuring you hit your goals.",
    title: "Step-By-Step Guidance On Your Plan",
    action: "Upgrade",
  },
];

export const PAID_STUDENT_DASHBOARD_MESSAGES: DashboardMessage[] = [
  {
    id: 191,
    message:
      "Now that you are a Platinum Member, your next step is finish building your plan, implement it and track it.",
    title: "Implement Your Plan",
    action: "Plan",
  },
  {
    id: 192,
    message:
      "We've partnered with ISL, a lender which may let you refinance your private loan(s) while in school. Click below to see your rates. Getting rates will not impact your credit. If you have questions, simply schedule a free call and we will be glad to help.",
    title: "Save Money - Refinance Your Private Loan",
    action: "Refi My Private Loan",
  },
];
