import * as React from "react";

import { Box, makeStyles } from "@material-ui/core";

import Card from "src/components/Card";
import StatusIndicator from "src/components/StatusIndicator";
import Icon from "src/components/Icon";
import { AccountProvider } from "src/interfaces";
import ConfirmLinkedAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/ConfirmLinkedAccountDialog";
import RelinkLinkedAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/RelinkLinkedAccountDialog";
import colors from "src/theme/colors";

const useStyles = makeStyles({
  alert: {
    "&:hover": {
      background: colors.gradLightHorizontal,
      cursor: "pointer",
    },
  },
});

const ProviderCard = ({
  actionType,
  provider,
}: {
  actionType: string;
  provider: AccountProvider;
}) => {
  const styles = useStyles();
  const [confirmDialogVisible, setConfirmDialogVisible] = React.useState(false);
  const [relinkDialogVisible, setRelinkDialogVisible] = React.useState(false);

  return (
    <Card
      className={styles.alert + " w-full mt-3"}
      onClick={
        !confirmDialogVisible && !relinkDialogVisible
          ? () => {
              if (actionType === "new") {
                setConfirmDialogVisible(true);
              } else {
                setRelinkDialogVisible(true);
              }
            }
          : undefined
      }
    >
      <Box className="flex justify-between w-full">
        <Box className="flex justify-start items-center">
          <Box className="flex w-8 mr-8">
            {provider.new ||
              (provider.error && <StatusIndicator fill={colors.warn} />)}
            <Icon
              iconName={actionType === "new" ? "fb-flow-chart" : "fb-warning"}
            />
          </Box>
          <Box>
            {actionType === "new"
              ? `Your ${provider.name} account is ready to be linked`
              : `Please relink ${provider.name} account.`}
          </Box>
        </Box>
        <Icon iconName="fb-chevron-right" />
        <ConfirmLinkedAccountDialog
          provider={provider}
          open={confirmDialogVisible}
          onClose={() => setConfirmDialogVisible(false)}
        />
        <RelinkLinkedAccountDialog
          item_id={provider.item_id}
          open={relinkDialogVisible}
          onClose={() => setRelinkDialogVisible(false)}
        />
      </Box>
    </Card>
  );
};

// const useStyles = makeStyles(headerStyles);

export default ProviderCard;
