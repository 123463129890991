import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Divider,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import { planBuilderStyles, budgetOverviewStyle } from "src/theme";
import { setBuildStep, setMonthlyOverview } from "src/store/planBuild/actions";
import {
  currentPlanAllocationTotals,
  currentPlanIncomeTotal,
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  currentPlanSurplus,
  getIsMonthly,
  isCurrentPlanImplemented,
  livePlanAllocationTotals,
  livePlanExpenseTotal,
  livePlanIncomeTotal,
  livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import { getHeaderClicked } from "src/store/dashboard/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { formatWholeDollars } from "src/utils";
import Card from "src/components/Card";
import CircularProgressBar from "src/components/CircleProgressChart";
import CurrentStudentSidebarContent from "./CurrentStudentSidebarContent";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import PingEffect from "src/components/PingAnimation";
import Button from "src/components/Button";

const useStyles = makeStyles(planBuilderStyles);
const useBudgetStyles = makeStyles(budgetOverviewStyle);

const RightSidebar = ({
  onNext,
  nextDisabled,
  nextLabel,
  nextTooltip,
}: any) => {
  const styles = useStyles();
  const budgeStyles = useBudgetStyles();
  const dispatch = useDispatch();
  const isMonthly = useSelector(getIsMonthly);
  const planIncomeTotal = useSelector(currentPlanIncomeTotal);
  const planExpenseTotal = useSelector(currentPlanExpenseTotal);
  const liveIncomeTotal = useSelector(livePlanIncomeTotal);
  const liveExpenseTotal = useSelector(livePlanExpenseTotal);
  const planRiskTotal = useSelector(currentPlanRiskManagementTotal);
  const liveRiskTotal = useSelector(livePlanRiskManagementTotal);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const {
    assetsTotal: planAssetsTotal,
    debtsTotal: planDebtsTotal,
  } = useSelector(currentPlanAllocationTotals);
  const {
    assetsTotal: liveAssetsTotal,
    debtsTotal: liveDebtsTotal,
  } = useSelector(livePlanAllocationTotals);
  const planRemainingMoney = useSelector(currentPlanSurplus);
  const shortScreen = useMediaQuery("(max-height:720px)");
  // this component is rerendered everytime "Next" is hit.
  // So sync triggerCount with triggerAnimation on initialization,
  // and if they are nolonger in sync due to triggerAnimation changing then run animation
  const headerClickedCount = useSelector(getHeaderClicked);
  const [triggerCount, setTriggerCount] = useState(headerClickedCount);

  const {
    incomeTotal,
    expenseTotal,
    riskTotal,
    assetsTotal,
    debtsTotal,
  } = useMemo(() => {
    if (!currentPlanIsImplemented) {
      return {
        incomeTotal: planIncomeTotal,
        expenseTotal: planExpenseTotal,
        riskTotal: planRiskTotal,
        assetsTotal: planAssetsTotal,
        debtsTotal: planDebtsTotal,
      };
    }
    return {
      incomeTotal: liveIncomeTotal,
      expenseTotal: liveExpenseTotal,
      riskTotal: liveRiskTotal,
      assetsTotal: planAssetsTotal,
      debtsTotal: planDebtsTotal,
    };
  }, [
    currentPlanIsImplemented,
    planIncomeTotal,
    planExpenseTotal,
    planAssetsTotal,
    planDebtsTotal,
    planRiskTotal,
    liveIncomeTotal,
    liveExpenseTotal,
    liveRiskTotal,
    liveAssetsTotal,
    liveDebtsTotal,
  ]);

  const remainingMoney = !currentPlanIsImplemented
    ? planRemainingMoney
    : incomeTotal - expenseTotal - riskTotal - assetsTotal - debtsTotal;

  const remainingMoneyStyle =
    remainingMoney >= 0 ? styles.emphasis : styles.danger;

  const setIsMonthly = (e: React.ChangeEvent<any>) =>
    dispatch(setMonthlyOverview(e.target.value === "y"));

  const formatValue = (value: number) => {
    const formatted = isMonthly ? Math.floor(value / 12) : Math.floor(value);
    return formatWholeDollars(formatted);
  };

  const chartData = [
    { key: "Total Income", value: incomeTotal - remainingMoney },
    { key: "Vacation $", value: remainingMoney },
  ];

  // if triggerAnimation is updated, run the animation
  useEffect(() => {
    if (headerClickedCount !== triggerCount) {
      setTriggerCount(headerClickedCount);
    }
  }, [headerClickedCount]);

  const renderNextButton = () => {
    const button = (
      <Box className="flex relative">
        <PingEffect trigger={headerClickedCount !== triggerCount} />
        <Button
          color="primary"
          disabled={nextDisabled}
          onClick={onNext}
          className="w-full"
        >
          {nextLabel || "Next"}
        </Button>
      </Box>
    );
    if (nextTooltip) {
      return (
        <Tooltip
          placement="left"
          title={
            <div style={{ fontSize: 12, lineHeight: "16px", padding: 2 }}>
              {nextTooltip}
            </div>
          }
        >
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  };

  const renderGraduatedContent = () => (
    <div
    // onClick={()=>{dispatch(setHeaderClicked(!triggerAnimation))}}
    >
      {!shortScreen && (
        <Box>
          <CircularProgressBar
            format="list"
            data={chartData}
            labelXOffset={-45}
            labelYOffset={-45}
          />
        </Box>
      )}
      <Box className="w-full">
        <Select
          className={budgeStyles.chartOption}
          onChange={setIsMonthly}
          fullWidth={shortScreen}
          value={isMonthly ? "y" : "n"}
          variant="outlined"
          margin="dense"
        >
          <MenuItem value="y">Monthly</MenuItem>
          <MenuItem value="n">Annually</MenuItem>
        </Select>
      </Box>
      <Divider className="w-full mt-16" />
      <Box className="mb-3">
        <Box>
          <Box
            className={budgeStyles.title}
            onClick={() => {
              dispatch(setBuildStep(PLAN_BUILD_STEPS.INCOME_AND_EXPENSES));
            }}
          >
            <Typography> Day-To-Day Money </Typography>
          </Box>
          <Box className={budgeStyles.item}>
            <Typography>Total Income</Typography>
            <Typography className="text-right">
              {formatValue(incomeTotal)}
            </Typography>
          </Box>

          <Box className={budgeStyles.item}>
            <Typography>Day-to-Day Exp.</Typography>
            <Typography className="text-right">
              {formatValue(expenseTotal)}
            </Typography>
          </Box>
        </Box>

        <Divider className="w-full my-2" />

        <Box>
          <Box
            className={budgeStyles.title}
            onClick={() => {
              dispatch(setBuildStep(PLAN_BUILD_STEPS.ASSETS_AND_DEBTS));
            }}
          >
            <Typography> Money For Future Goals</Typography>
          </Box>
          <Box className={budgeStyles.item}>
            <Typography>Asset Contributions</Typography>
            <Typography className="text-right">
              {formatValue(assetsTotal)}
            </Typography>
          </Box>

          <Box className={budgeStyles.item}>
            <Typography>Debt Payments</Typography>
            <Typography className="text-right">
              {formatValue(debtsTotal)}
            </Typography>
          </Box>
        </Box>

        <Divider className="w-full my-2" />

        <Box>
          <Box
            className={budgeStyles.title}
            onClick={() => {
              dispatch(setBuildStep(PLAN_BUILD_STEPS.RISK_MANAGEMENT));
            }}
          >
            <Typography> Risk Management </Typography>
          </Box>
          <Box className={budgeStyles.item}>
            <Typography>Premium Payments</Typography>
            <Typography className="text-right">
              {formatValue(riskTotal)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider className="w-full my-2" />

      <Box className={budgeStyles.item}>
        <Typography className={remainingMoneyStyle}>
          Vacation/Fun Money
        </Typography>

        <Typography className={remainingMoneyStyle}>
          {formatValue(remainingMoney)}
        </Typography>
      </Box>
    </div>
  );

  return (
    <Card
      className={styles.sidebar}
      title={"Budget Overview"}
      noPadding
      titleTag="h6"
    >
      <Divider />
      <Box
        className={shortScreen ? styles.rightCenterShort : styles.rightCenter}
      >
        {isCurrentStudent ? (
          <CurrentStudentSidebarContent />
        ) : (
          renderGraduatedContent()
        )}
      </Box>
      <Divider />
      <Box className={styles.rightBottom}>{renderNextButton()}</Box>
    </Card>
  );
};

export default RightSidebar;
