import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  Box,
  // Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import Button from "src/components/Button";

import { navigatorStyles } from "src/theme";
import { navigation, nestedNavigation } from "src/config/navigation";
import { getRouterState } from "src/store/router/selector";
import { setActivePage } from "src/store/dashboard/actions";
import { getActivePage } from "src/store/dashboard/selector";

// import { AdapterLink } from "src/utils";
import { LOGO, LOGO_COMPACT } from "src/assets/svgs";

// import NavMenu from "./NavMenu";
import ExpandableNavMenuItem from "./ExpandableNavMenuItem";

import Icon from "../Icon";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";

const useStyles = makeStyles(navigatorStyles);

const Navigator = (props: any) => {
  const dispatch = useDispatch();
  const isSubscribed = useSelector(getIsSubscribed);
  const router = useSelector(getRouterState);
  const activePage = useSelector(getActivePage);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const classes = useStyles();
  const {
    title,
    variant,
    closeMobileOpen,
    collapsed,
    onCollapse,
    ...other
  } = props;

  useEffect(() => {
    let nav: any = navigation.find((e) => e.href === router.location.pathname);
    if (
      router?.location?.hash === "#hc" &&
      router?.location?.pathname === "/wealth"
    ) {
      nav = navigation.find((e) => e.id === "human-capital");
    }
    if (nav && nav.id) {
      dispatch(setActivePage(nav.id));
      const newExpandedIndex = nestedNavigation.findIndex(
        (heading) => !!heading.items.find((item) => item.id === nav.id)
      );
      if (newExpandedIndex >= 0) {
        setExpandedIndex(newExpandedIndex);
      } else {
        setExpandedIndex(-1);
      }
      if (closeMobileOpen) {
        closeMobileOpen();
      }
    }
  }, [router, closeMobileOpen]);

  const handleItemClick = (index: number) =>
    setExpandedIndex((current) => {
      if (current === index) {
        return -1;
      }
      return index;
    });

  return (
    <Drawer variant={variant} {...other}>
      <List className="py-14">
        <ListItem
          className={clsx(
            classes.logoContainer,
            collapsed && classes.navSwitchCollapsed
          )}
          key="1"
        >
          <img src={collapsed ? LOGO_COMPACT : LOGO} alt={title} />
        </ListItem>
        {nestedNavigation.map(({ title, icon, items }, index) => (
          <ExpandableNavMenuItem
            key={title}
            title={title}
            items={items}
            icon={icon}
            expanded={index === expandedIndex}
            onClick={() => handleItemClick(index)}
            onItemClick={(id: string) => {
              dispatch(setActivePage(id));
              closeMobileOpen();
            }}
            activeId={activePage}
            barCollapsed={collapsed}
          />
        ))}
        {/*navigation.map(
          ({ id: childId, icon, title, onClick, href, isBottom, freeOnly }) =>
            !isBottom &&
            !(subscribed && freeOnly) && (
              <React.Fragment key={childId}>
                <NavMenuItem
                  title={title}
                  id={childId}
                  icon={icon}
                  onClick={onClick}
                  onItemClick={(id: string) => {
                    dispatch(setActivePage(id));
                    closeMobileOpen();
                  }}
                  href={href}
                  collapsed={collapsed}
                  active={childId === activePage}
                />
              </React.Fragment>
            )
        )}

        {!collapsed && (
          <Box
            className={clsx(
              classes.bottomLinks,
              collapsed && classes.navSwitchCollapsed
            )}
          >
            {navigation.map(
              ({
                id: childId,
                icon,
                title,
                onClick,
                href,
                isBottom,
                freeOnly,
              }) =>
                isBottom &&
                !(subscribed && freeOnly) && (
                  <React.Fragment key={childId}>
                    <NavMenuItem
                      title={title}
                      id={childId}
                      icon={collapsed && icon}
                      onClick={onClick}
                      onItemClick={(id: string) => {
                        dispatch(setActivePage(id));
                        closeMobileOpen();
                      }}
                      href={href}
                      collapsed={false}
                      active={childId === activePage}
                    />
                  </React.Fragment>
                )
            )}
          </Box>
        )*/}

        <ListItem
          button
          className={clsx(
            classes.navUpgradeButton,
            collapsed && classes.navSwitchCollapsed
          )}
        >
          {!collapsed && !isSubscribed && (
            <Box className="flex justify-center w-full">
              <Button
                fbColor="primary"
                onClick={() => dispatch(openUpgradeDialog())}
              >
                Upgrade to Premium
              </Button>
            </Box>
          )}
        </ListItem>
        <ListItem
          button
          className={clsx(
            classes.navSwitch,
            collapsed && classes.navSwitchCollapsed
          )}
          onClick={onCollapse}
        >
          <Icon iconName={collapsed ? "fb-chevron-right" : "fb-chevron-left"} />
          {!collapsed && (
            <ListItemText className={classes.collapseText}>
              Collapse
            </ListItemText>
          )}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Navigator;
