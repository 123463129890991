import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { addPublicTokenApi } from "src/apiService";
import Button from "src/components/Button";
import ConfirmDialog from "src/components/Dialogs/ConfirmDialog";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import { INVESTMENT_TYPES } from "src/interfaces";
import AccountsListCard from "src/pages/Dashboard/MyProfile/Accounts/components/AccountsListCard";
import AddEditManualAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/AddEditManualAccountDialog";
import LinkAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/LinkAccountDialog";
import RelinkLinkedAccountDialog from "src/pages/Dashboard/MyProfile/Accounts/components/RelinkLinkedAccountDialog";
import { getPublicToken } from "src/store/account/actions";
import {
  getAccountProviders as selectProviders,
  getLinkedAccounts,
  getPublicToken as selectPublicToken,
} from "src/store/account/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { colors } from "src/theme";

const useStyles = makeStyles({
  instructions: {},
  instructionsList: {
    fontSize: 15,
    "& > li": {
      marginBottom: 10,
      display: "list-style",
      justifyContent: "center",
    },
  },
  accountRow: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  accountCard: {
    marginRight: 20,
    width: "calc(100% - 50px)",
  },
  buttonContainer: {
    marginTop: 40,
    textAlign: "center",
  },
  addButton: {
    width: 200,
    "& > span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  blockButton: {
    display: "block",
    margin: "20px auto",
  },
  addLinkedOrManualButton: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    margin: "15px 0",
  },
  lockIconContainer: {
    border: `1px solid ${colors.blueGray3}`,
    width: 27,
    height: 27,
    borderRadius: 28,
    padding: 4,
    "& svg": {
      color: colors.brandingBlue1,
      fontSize: 14,
    },
  },
});

export const ReviewAccounts = ({
  next,
  refresh,
  withInvestments,
  withoutInvestments,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const linkedAccounts = useSelector(getLinkedAccounts);
  const providers = useSelector(selectProviders);
  const publicToken = useSelector(selectPublicToken);
  const subscribed = useSelector(getIsSubscribed);
  const [isLinked, setIsLinked] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [
    addEditManualAccountDialogVisible,
    setAddEditManualAccountDialogVisible,
  ] = useState(false);
  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );
  const [
    relinkLinkedAccountDialogVisible,
    setRelinkLinkedAccountDialogVisible,
  ] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>();

  const isLinkedAccount = (id: number) => {
    return linkedAccounts.findIndex((e) => e.id === id) > -1;
  };

  const hasError = (id: number) => {
    const linkedAccount = linkedAccounts.find((e) => e.id === id);
    if (linkedAccount) {
      const provider = providers.find(
        (e) => e.item_id === linkedAccount.item_id
      );
      return !!provider?.error;
    }
    return false;
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string) => {
      try {
        await addPublicTokenApi({ public_token });
        setLinkAccountDialogVisible(false);
        setConfirmDialogVisible(true);
      } catch (error) {
        return;
      }
    },
    []
  );

  const config: PlaidLinkOptions = {
    token: publicToken?.link_token || "",
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (linkAccountDialogVisible || confirmDialogVisible) {
      dispatch(getPublicToken(undefined));
    }
  }, [linkAccountDialogVisible, confirmDialogVisible]);

  const openAddManualDialog = () => {
    setIsLinked(false);
    setSelectedAccount(undefined);
    setAddDialogVisible(false);
    setAddEditManualAccountDialogVisible(true);
  };

  const openLinkDialog = () => {
    setAddDialogVisible(false);
    setLinkAccountDialogVisible(true);
  };

  const accounts = [...withInvestments, ...withoutInvestments];

  const handleEdit = (id: number) => {
    if (accounts && id) {
      const index = accounts.findIndex((account) => account.id === id);
      if (index > -1) {
        setSelectedAccount(accounts[index]);
        if (isLinkedAccount(id)) {
          setIsLinked(true);
          if (hasError(id)) {
            setRelinkLinkedAccountDialogVisible(true);
            return;
          }
        } else {
          setIsLinked(false);
        }
        setAddEditManualAccountDialogVisible(true);
      }
    }
  };

  const closeDialogs = () => {
    setAddDialogVisible(false);
    setAddEditManualAccountDialogVisible(false);
    setRelinkLinkedAccountDialogVisible(false);
    setLinkAccountDialogVisible(false);
  };

  return (
    <>
      <Box className={classes.instructions}>
        <Typography variant="h2" component="h2" className="text-center">
          Steps
        </Typography>
        <ol className={classes.instructionsList}>
          <li>
            If there are items listed under "Accounts Needing Details", click
            edit and enter the investment details.
          </li>
          <li>
            If you have no accounts listed in either column, click add accounts
            to add them to your profile.
          </li>
          <li>
            Once all your accounts are listed under the column titled "Accounts
            That Have Details" you may click continue.
          </li>
        </ol>
      </Box>
      <Box className={classes.accountRow}>
        <Box className={classes.accountCard}>
          <AccountsListCard
            title="Accounts Needing Details"
            data={withoutInvestments}
            isAsset={true}
            onEdit={handleEdit}
            startExpanded
            footerContent={
              <Box className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.addButton}
                  color="primary"
                  onClick={() => setAddDialogVisible(true)}
                  endIcon={<Icon iconName="fb-add-alt" />}
                >
                  Add account
                </Button>
              </Box>
            }
          />
        </Box>
        <Box className={classes.accountCard}>
          <AccountsListCard
            title="Accounts That Have Details"
            data={withInvestments}
            isAsset={true}
            onEdit={handleEdit}
            allExpanded
          />
        </Box>
      </Box>
      <Button
        disabled={!!withoutInvestments.length}
        fbColor="primary"
        className={classes.blockButton}
        onClick={next}
      >
        Continue
      </Button>
      <CustomDialog
        isOpen={addDialogVisible}
        onClose={() => setAddDialogVisible(false)}
        size="sm"
        title=""
      >
        <Box className="my-5">
          {!subscribed ? (
            <Button
              variant="outlined"
              disabled
              fbColor="secondary"
              className={classes.addLinkedOrManualButton}
            >
              <span>Add Linked Account</span>
              <span className={classes.lockIconContainer}>
                <Icon iconName="fb-lock" />
              </span>
            </Button>
          ) : (
            <Button
              fbColor="secondary"
              className={classes.addLinkedOrManualButton}
              onClick={openLinkDialog}
            >
              <span>Add Linked Account</span>
              <Icon iconName="fb-flow-chart" />
            </Button>
          )}
          <Button
            fbColor="primary"
            className={classes.addLinkedOrManualButton}
            onClick={openAddManualDialog}
          >
            <span>Add Manual Account</span>
            <Icon iconName="fb-construction" />
          </Button>
        </Box>
      </CustomDialog>
      <LinkAccountDialog
        isOpen={linkAccountDialogVisible}
        onClose={closeDialogs}
        ready={ready}
        openPlaid={() => open()}
      />
      <ConfirmDialog
        visible={confirmDialogVisible}
        title="Add More Accounts"
        message="Would you like to link another account?"
        onCancel={() => setConfirmDialogVisible(false)}
        onConfirm={() => open()}
        cancelButton="No, Thank You"
        confirmButton="Yes, Please"
      />
      <AddEditManualAccountDialog
        open={addEditManualAccountDialogVisible}
        selected={selectedAccount}
        setSelected={setSelectedAccount}
        isLinked={isLinked}
        onClose={() => {
          refresh();
          setAddEditManualAccountDialogVisible(false);
        }}
        accountTypes={INVESTMENT_TYPES}
        closeOnSave
      />
      <RelinkLinkedAccountDialog
        open={relinkLinkedAccountDialogVisible}
        id={selectedAccount?.id}
        onClose={() => {
          setRelinkLinkedAccountDialogVisible(false);
        }}
      />
    </>
  );
};

export default ReviewAccounts;
