import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { mapValues, some } from "lodash";

import {
  Box,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";

import CalculatorLayout from "src/layouts/CalculatorLayout";
import Button from "src/components/Button";
import StickyFooter from "src/components/StickyFooter";
import TitleCard from "src/components/TitleCard";
import NewOrRefinanceInterstitial from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/NewOrRefinanceInterstitial";
import { getCostOfLivingAPI } from "src/apiService";
import {
  CostOfLivingAPIResponsePayload,
  CostOfLivingResult,
} from "src/interfaces";
import { colors } from "src/theme";
import {
  DollarTextField,
  formatWholeDollars,
  formatZip,
  validateZip,
} from "src/utils";

const CostOfLivingTool = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    locationOneIncome: "",
    locationOneZip: "",
    locationTwoIncome: "",
    locationTwoZip: "",
  });
  const [error, setError] = useState({
    zipCodeOne: false,
    zipCodeTwo: false,
    incomeOne: false,
    incomeTwo: false,
  });
  const [validInput, setValidInput] = useState(true);
  const [showInterstitial, setShowInterstitial] = useState(false);

  const [result, setResult] = useState<any>(null);

  const handleBack = () => {
    dispatch(push("/homebuying"));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((preValue) => ({
      ...preValue,

      [e.target.name]:
        e.target.name === "locationOneZip" || e.target.name === "locationTwoZip"
          ? formatZip(e.target.value)
          : e.target.value,
    }));
  };

  useEffect(() => {
    let test = false;
    mapValues(data, (item: any) => {
      if (item === "") {
        test = true;
      }
    });
    setValidInput(test);
  }, [data]);

  useEffect(() => {
    some(error, (item: any) => {
      if (item === true) {
        setResult(null);
        return true;
      }
      return false;
    });
  }, [error]);

  const handleCalculate = async () => {
    if (
      Number(data.locationOneIncome) === 0 ||
      Number(data.locationTwoIncome) === 0 ||
      !validateZip(data.locationOneZip) ||
      !validateZip(data.locationTwoZip)
    )
      return setError({
        ...error,
        incomeOne: Number(data.locationOneIncome) === 0,
        incomeTwo: Number(data.locationTwoIncome) === 0,
        zipCodeOne: !validateZip(data.locationOneZip),
        zipCodeTwo: !validateZip(data.locationTwoZip),
      });

    const resultPayload = await Promise.all([
      getCostOfLivingAPI({
        income: Number(data.locationOneIncome),
        zip: data.locationOneZip,
      }),
      getCostOfLivingAPI({
        income: Number(data.locationTwoIncome),
        zip: data.locationTwoZip,
      }),
    ]);

    const locationOne: CostOfLivingAPIResponsePayload = resultPayload[0];
    const locationTwo: CostOfLivingAPIResponsePayload = resultPayload[1];

    if (!locationOne || !locationTwo)
      return setError((pre) => ({
        ...pre,
        zipCodeOne: !locationOne,
        zipCodeTwo: !locationTwo,
      }));

    const locationOneExpenses: any = locationOne.expenses.reduce(
      (obj, item) => ({ ...obj, [item.type]: item.payment * 12 }),
      {}
    );
    const locationOneExpenseSum = locationOne.expenses.reduce((a, b) => ({
      type: "sum",
      payment: a.payment + b.payment,
    }));

    const locationTwoExpenses: any = locationTwo.expenses.reduce(
      (obj, item) => ({ ...obj, [item.type]: item.payment * 12 }),
      {}
    );
    const locationTwoExpenseSum = locationTwo.expenses.reduce((a, b) => ({
      type: "sum",
      payment: a.payment + b.payment,
    }));

    const locationOneRemainingCash =
      locationOne.income - locationOneExpenseSum.payment * 12;
    const locationTwoRemainingCash =
      locationTwo.income - locationTwoExpenseSum.payment * 12;

    const locationOneResult: CostOfLivingResult = {
      ...locationOneExpenses,
      utilities: locationOneExpenses.water + locationOneExpenses.power,
      remainingCash: locationOneRemainingCash,
      totalExpenses: locationOneExpenseSum.payment * 12,
      grossIncome: locationOne.income,
    };

    const locationTwoResult: CostOfLivingResult = {
      ...locationTwoExpenses,
      utilities: locationTwoExpenses.water + locationTwoExpenses.power,
      remainingCash: locationTwoRemainingCash,
      totalExpenses: locationTwoExpenseSum.payment * 12,
      grossIncome: locationTwo.income,
    };
    setResult({
      locationOne: locationOneResult,
      locationTwo: locationTwoResult,
    });
  };

  return (
    <CalculatorLayout
      title="Cost Of Living"
      onBackClick={handleBack}
      footer={
        result ? (
          <StickyFooter text="Check purchase or refinance rates with FitBUX's mortgage lending partners.">
            <Button
              className="mr-10"
              fbColor="accent"
              onClick={() => setShowInterstitial(true)}
            >
              Check My Rates
            </Button>
          </StickyFooter>
        ) : null
      }
    >
      <TitleCard title="What Would My Cost Of Living Be?">
        <Box className={classes.container}>
          <Typography>
            This calculator allows you to compare the cost of living in various
            zip codes.
          </Typography>
          <Typography>
            Simply enter the zip code of the desired locations and your expected
            gross income in both locations. Then hit “Calculate” to see an
            example of how common day-to-day money will be impacted.
          </Typography>
          <Box>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headRow}>
                  <TableCell className={classes.headCell} />
                  <TableCell className={classes.headCell}>Location 1</TableCell>
                  <TableCell className={classes.headCell}>Location 2</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell className={classes.titleCell}>
                    <Typography>Annual Gross Income</Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <DollarTextField
                      variant="outlined"
                      onChange={handleInputChange}
                      name="locationOneIncome"
                      error={error.incomeOne}
                      placeholder="$100,000"
                      onFocus={() => setError({ ...error, incomeOne: false })}
                      value={data.locationOneIncome}
                      helperText={
                        error.incomeOne ? "Enter income > $0." : undefined
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <DollarTextField
                      variant="outlined"
                      onChange={handleInputChange}
                      name="locationTwoIncome"
                      error={error.incomeTwo}
                      placeholder="$75,000"
                      onFocus={() => setError({ ...error, incomeTwo: false })}
                      value={data.locationTwoIncome}
                      helperText={
                        error.incomeTwo ? "Enter income > $0." : undefined
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.titleCell}>
                    <Typography>Zip Code</Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <TextField
                      variant="outlined"
                      placeholder="90210"
                      name="locationOneZip"
                      error={error.zipCodeOne}
                      onFocus={() => setError({ ...error, zipCodeOne: false })}
                      value={data.locationOneZip}
                      onChange={handleInputChange}
                      helperText={
                        error.zipCodeOne ? "Enter a valid ZIP code." : undefined
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <TextField
                      variant="outlined"
                      placeholder="78705"
                      name="locationTwoZip"
                      error={error.zipCodeTwo}
                      onFocus={() => setError({ ...error, zipCodeTwo: false })}
                      value={data.locationTwoZip}
                      onChange={handleInputChange}
                      helperText={
                        error.zipCodeTwo ? "Enter a valid ZIP code." : undefined
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          <Box className={classes.actionContainer}>
            {validInput ? (
              <Tooltip title="All fields must be filled out to continue">
                <span>
                  <Button disabled fbColor="primary">
                    Calculate
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button fbColor="primary" onClick={handleCalculate}>
                Calculate
              </Button>
            )}
          </Box>

          {result && (
            <Box className={classes.resultContainer}>
              <Divider />
              <Box className={classes.resultContent}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.headRow}>
                      <TableCell className={classes.headCell} />
                      <TableCell className={classes.headCell}>
                        Location 1
                      </TableCell>
                      <TableCell className={classes.headCell}>
                        Location 2
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography className={classes.tableTitleText}>
                          Annual Gross Income
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableTitleTextCell}>
                        {formatWholeDollars(result.locationOne.grossIncome)}
                      </TableCell>
                      <TableCell className={classes.tableTitleTextCell}>
                        {formatWholeDollars(result.locationTwo.grossIncome)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography className={classes.tableTitleText}>
                          Total Annual Expenses
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableTitleTextCell}>
                        {formatWholeDollars(result.locationOne.totalExpenses)}
                      </TableCell>
                      <TableCell className={classes.tableTitleTextCell}>
                        {formatWholeDollars(result.locationTwo.totalExpenses)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Taxes</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.taxes)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.taxes)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Rent</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.rent)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.rent)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Utilities</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.utilities)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.utilities)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Groceries</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.grocery)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.grocery)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Fuel</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.fuel)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.fuel)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Clothing</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.clothing)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.clothing)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.titleCell}>
                        <Typography>Telephone</Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationOne.phone)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatWholeDollars(result.locationTwo.phone)}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                      <TableCell className={classes.remainingCashTitleCell}>
                        <Typography className={classes.tableTitleText}>
                          Remaining Cash
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.remainingCashCell}>
                        {formatWholeDollars(result.locationOne.remainingCash)}
                      </TableCell>
                      <TableCell className={classes.remainingCashCell}>
                        {formatWholeDollars(result.locationTwo.remainingCash)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box className={classes.bottomContainer}>
                <Typography className={classes.title}>
                  What This Means:
                </Typography>
                <br />
                <Typography>
                  Based on your inputs and our cost of living data, Location
                  {` ${
                    result.locationOne.remainingCash >
                    result.locationTwo.remainingCash
                      ? 1
                      : 2
                  } `}
                  would give you the most remaining cash. Having a greater
                  amount of remaining cash allows you to more easily build
                  assets/investments, pay off debt, or afford insurance products
                  that protect your net wealth (e.g., life insurance).
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </TitleCard>
      <NewOrRefinanceInterstitial
        open={showInterstitial}
        onClose={() => setShowInterstitial(false)}
        title="Cost Of Living"
      />
    </CalculatorLayout>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  table: {
    width: "80%",
    margin: "auto",
  },
  headRow: {},
  headCell: {
    fontWeight: 600,
    border: 0,
    textAlign: "center",
    color: colors.brandingBlue1,
  },
  row: {
    border: 0,
  },
  titleCell: {
    border: 0,
    textAlign: "left",
  },
  cell: {
    border: 0,
    textAlign: "center",
  },
  tableTitleTextCell: {
    fontSize: 14,
    fontWeight: 600,
    border: 0,
    textAlign: "center",
  },
  tableTitleText: {
    fontSize: 14,
    fontWeight: 600,
    border: 0,
  },
  contentItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: 20,
  },
  textField: {
    width: 150,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: 20,
  },
  errorText: {
    color: colors.error,
  },
  resultContainer: {
    marginTop: theme.spacing(2),
  },
  resultContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
    fontSize: 16,
    fontWeight: 600,
  },
  bottomContainer: {
    backgroundColor: "#ebebeb",
    padding: theme.spacing(4, 2),
  },
  title: {
    fontWeight: 600,
  },
  remainingCashTitleCell: {
    textAlign: "left",
    border: 0,
    fontWeight: 600,
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    fontSize: 14,
  },
  remainingCashCell: {
    textAlign: "center",
    border: 0,
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    fontWeight: 600,
    fontSize: 14,
  },
}));
export default CostOfLivingTool;
