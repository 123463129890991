import { createStyles, Theme } from "@material-ui/core/styles";

import colors from "./colors";

export const drawerWidth = 210;
export const collapsedDrawerWidth = 60;

/* layouts */
export const adminStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
    },
    drawer: {
      transition: "width .15s",
      background: colors.gradLightHorizontal,
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      "&>div>div>div": {
        transition: "width .15s",
        borderRight: "none",
        boxShadow: theme.shadows[10],
      },
    },
    collapsedDrawer: {
      [theme.breakpoints.up("sm")]: {
        width: collapsedDrawerWidth,
      },
    },
    appContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  });

export const loginStyles = () =>
  createStyles({
    root: {
      height: "100vh",
    },
    contentLeft: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: colors.white,
    },
    contentRight: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      background: colors.gradLightAltVertical2,
    },
    title: {
      color: colors.brandingBlue1,
      fontWeight: 600,
      fontSize: 24,
      position: "relative",
      marginBottom: 30,
      "&:before": {
        content: "''",
        position: "absolute",
        display: "block",
        bottom: -10,
        left: "calc(50% - 16px)",
        width: 32,
        height: 2,
        background: colors.blueGray5,
      },
    },
    list: {
      maxWidth: 500,
      width: "100%",
    },
    listItem: {},
    itemImage: {
      width: 100,
      marginRight: 15,
    },
    itemText: {
      "&>span": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
      },
      "&>p": {
        fontSize: 12,
      },
    },
  });

export const WealthHCSteps = (theme: Theme) =>
  createStyles({
    content: {
      width: "100%",
      position: "relative",
    },
    footer: {
      position: "absolute",
      backgroundColor: colors.opaqueLight80,
      backdropFilter: "blur(12px)",
      height: 72,
      bottom: 50,
      borderRadius: "1.5rem",
      width: "100%",
      boxShadow: theme.shadows[5],
      zIndex: 55,
      "&>*": {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  });
export const profileBuilderStyles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      overflowY: "auto",
    },
    content: {
      paddingTop: 150,
    },
    footer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.opaqueLight80,
      backdropFilter: "blur(12px)",
      height: 72,
      boxShadow: theme.shadows[5],
      zIndex: 55,
      "&>*": {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    btnCancel: {
      display: "block",
      margin: "20px auto",
      width: "calc(100% - 50px)",
    },
  });

export const investmentsStyles = {
  root: {
    width: "100%",
  },
  content: {
    marginLeft: 80,
    marginRight: 80,
    marginTop: 75,
  },
  mainContainer: {
    backgroundColor: "#fff",
    marginTop: 20,
    padding: "20px 40px",
  },
  mainCard: {
    margin: "0 auto",
    width: 932,
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 500,
  },
  wrapped: {
    wordBreak: "break-word" as any,
  },
  blockButton: {
    border: `2px solid ${colors.border}`,
    display: "block",
    margin: "20px auto",
    width: 500,
  },
  bigLabel: {
    display: "block",
    fontSize: 20,
    fontWeight: 600,
    color: colors.brandingBlue1,
  },
  littleLabel: {
    display: "block",
    fontWeight: 400,
    fontSize: 12,
    color: colors.brandingBlue1,
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 50,
  },
  pageText: {
    fontSize: "14px",
    marginLeft: "21px",
  },
  continueButton: {
    display: "block",
    margin: "30px auto",
  },
};

export const toolsStyles = {
  toolsHeader: {
    fontSize: 20,
  },
};

export const planBuilderStyles = () =>
  createStyles({
    root: {
      height: "100vh",
      overflowY: "auto",
    },
    content: {
      display: "flex",
      paddingTop: 70,
      justifyContent: "space-between",
      height: "calc(100vh - 25px)",
      width: "100%",
    },
    shortContent: {
      display: "flex",
      paddingTop: 70,
      justifyContent: "space-between",
      // height: "calc(100vh - 25px)",
      width: "100%",
    },
    main: {
      height: "calc(100% + 20px)",
      width: "100%",
      maxHeight: "calc(100vh - 90px)",
      overflowY: "auto",
      borderRadius: 12,
      padding: "10px 0 10px 10px",
      marginTop: -10,
      marginRight: 10,
    },
    sidebar: {
      borderRadius: 12,
      height: "100%",
      maxHeight: "calc(100vh - 110px)",
      "&>div": {
        height: "100%",
        "& h6": {
          "font-size": "15px !important",
        },
      },
      maxWidth: 240,
      minWidth: 240,
    },
    shortSidebar: {
      borderRadius: 12,
      height: 600,
      maxHeight: "calc(100vh - 110px)",
      "&>div": {
        height: "100%",
      },
      maxWidth: 232,
      minWidth: 232,
    },
    navButtons: {
      fontSize: 22,
      marginTop: 20,
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    leftCenter: {
      height: "100%",
      maxHeight: "calc(100vh - 250px - 100px)",
      padding: 24,
    },
    leftBottom: {
      height: 250,
      padding: "24",
      "&>p": {
        fontSize: 12,
      },
    },
    step: {
      fontSize: 13,
      color: colors.blueGray3,
      opacity: 0.6,
      fontWeight: 500,
      marginBottom: 15,
      cursor: "pointer",
    },
    activeStep: {
      fontSize: `13px !important`,
      opacity: `1 !important`,
      color: `${colors.brandingBlue1} !important`,
    },
    activeReviewStep: {
      opacity: `1 !important`,
      color: `${colors.brandingBlue1} !important`,
    },
    completedStep: {
      color: `${colors.brandingBlue1} !important`,
    },
    rightCenter: {
      height: `calc(100vh - 140px - 128px)`,
      padding: 24,
    },
    rightCenterShort: {
      height: `calc(100vh - 140px - 128px)`,
      padding: "10px 24px",
    },
    rightBottom: {
      height: 88,
      padding: 24,
      "&>p": {
        fontSize: 12,
      },
    },
    chartOption: {
      width: 100,
      marginTop: 15,
      marginBottom: 15,
      float: "right",
      "&>div": {
        fontSize: 10,
        padding: `7px 10px`,
        color: colors.brandingBlue1,
      },
    },
    row: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0px",
      "&>div": {
        color: colors.brandingBlue1,
        fontSize: 10,
        fontWeight: 500,
        width: "33.33%",
        textAlign: "center",
      },
      "& >div:nth-of-type(1)": {
        width: "50%",
      },
    },
    rightRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "10px 0px",
      "&>div": {
        color: colors.brandingBlue1,
        fontSize: 10,
        fontWeight: 500,
        width: "33.33%",
      },
      "& >div:nth-of-type(1)": {
        width: "50%",
      },
    },
    emphasis: {
      fontWeight: 600,
    },
    danger: {
      color: "rgb(226, 68, 92) !important",
      fontWeight: 600,
    },
  });

export const budgetOverviewStyle = () =>
  createStyles({
    chartOption: {
      width: 100,
      marginTop: 15,
      marginBottom: 15,
      float: "right",
      "&>div": {
        fontSize: 13,
        padding: `7px 10px`,
        color: colors.brandingBlue1,
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "10px 0px",
      cursor: "pointer",
      "&>p": {
        color: colors.brandingBlue1,
        fontSize: 12,
        fontWeight: 500,
      },
      "& >div:nth-of-type(1)": {
        width: "50%",
      },
    },
    item: {
      display: "flex",
      justifyContent: "space-between",
      "& p": {
        fontSize: 12,
      },
    },
  });

/* components */
export const navigatorStyles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    headerItem: {
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
    item: {
      paddingTop: 4,
      color: theme.palette.primary.main,
      opacity: 0.5,
      padding: "10px 18px",
      cursor: "pointer",
      "&>*": {
        margin: 0,
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "&:hover": {},
    },
    indented: {
      padding: "6px 36px",
    },
    logoContainer: {
      padding: 16,
      opacity: 1,
      display: "flex",
      height: 55,
      position: "fixed",
      top: 0,
      width: drawerWidth,
      "&>img": {
        maxWidth: 60,
      },
    },
    itemActionable: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
        opacity: 1,
      },
    },
    itemActiveItem: {
      opacity: 1,
    },
    itemPrimary: {
      color: "inherit",
      fontSize: 12,
      marginLeft: 10,
      fontWeight: 500,
    },
    collapseText: {
      maginLeft: 10,
      "& > span": {
        fontSize: 12,
      },
    },
    navSwitch: {
      position: "fixed",
      bottom: 0,
      color: theme.palette.primary.main,
      padding: 10,
      width: drawerWidth,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    navUpgradeButton: {
      position: "fixed",
      bottom: 50,
      color: theme.palette.primary.main,
      padding: 10,
      width: drawerWidth,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    navSwitchCollapsed: {
      maxWidth: collapsedDrawerWidth,
    },
    textDense: {},
    divider: {
      marginTop: theme.spacing(2),
    },
    bottomLinks: {
      position: "fixed",
      bottom: 55,
      width: drawerWidth,
      borderTop: `1px solid ${colors.blueGray5}`,
      paddinTop: 10,
      "& a": { opacity: 1 },
      "& div": { marginLeft: "-10px" },
    },
  });

export const headerStyles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    link: {
      textDecoration: "none",
      color: colors.lightBorder,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: colors.lightBorder,
    },
    title: {
      fontSize: 12,
      fontWeight: "bold",
      marginLeft: 15,
    },
    text: {
      fontSize: 12,
    },
    mainContent: {
      flex: 1,
      height: "100%",
      padding: "10px 30px",
      backgroundColor: "transparent",
      position: "relative",
    },
    logo: {
      width: 64,
    },
    headerTitle: {
      fontSize: 18,
      fontWeight: 600,
      textAlign: "left",
      width: "100%",
      marginLeft: 30,
    },
    planBuilder: {
      boxShadow: "none",
      backgroundColor: "transparent",
      backgroundImage: "none",
    },
    profileBuilder: {
      boxShadow: theme.shadows[2],
      backgroundColor: colors.white,
    },
  });

export const stepLabelStyles = (theme: Theme) =>
  createStyles({
    label: {
      color: colors.blueGray5,
      fontSize: 12,
      marginTop: `10px !important`,
    },
    active: {
      color: `${theme.palette.secondary.main} !important`,
    },
    completed: {
      color: `${theme.palette.secondary.main} !important`,
    },
  });
export const stepIconStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white,
      border: `2px solid ${colors.blueGray5}`,
      zIndex: 1,
      color: colors.blueGray5,
      width: 36,
      height: 36,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      "&>svg": {
        fontSize: 12,
      },
    },
    dot: {
      backgroundColor: "transparent",
      border: `2px solid ${colors.blueGray5}`,
      zIndex: 1,
      color: colors.blueGray5,
      width: 8,
      height: 8,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 14,
    },
    active: {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
    },
    completed: {
      backgroundImage: colors.gradBlueBubbleAngle,
      color: colors.white,
      border: "none",
    },
  });
export const stepConnectorStyles = () =>
  createStyles({
    alternativeLabel: {
      top: 17,
      left: `calc(-50% + 18px)`,
      right: `calc(50% + 18px)`,
      "&.compact": {
        left: `calc(-50% + 3px)`,
        right: `calc(50% + 3px)`,
      },
    },
    active: {
      "& $line": {
        backgroundImage: colors.gradBlueBubbleAngle,
      },
    },
    completed: {
      "& $line": {
        backgroundImage: colors.gradBlueBubbleAngle,
      },
    },
    line: {
      height: 2,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  });

export const accordionContainerStyles = () =>
  createStyles({
    headerRow: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    contentRow: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      lineHeight: "42px",
      minHeight: "42px",
      // paddingLeft: '40px',
      // paddingRight: '16px',
      width: "100%",
    },
    root: {},
    footer: {
      alignItems: "center",
      borderTop: "1px solid rgba(157, 165, 178, 0.3)",
      display: "flex",
      justifyContent: "flex-end",
      lineHeight: "42px",
      minHeight: "42px",
      paddingRight: "16px",
      width: "100%",
    },
    footerTotal: {
      fontWeight: "bold",
      marginLeft: "12px",
    },
  });
export const accordionSectionStyles = () =>
  createStyles({
    expanded: {
      minHeight: "20px",
    },
    root: {
      "&:before": { display: "none" },
      boxShadow: "none",
      margin: "0 !important",
      minHeight: "20px",
    },
  });
export const accordionSummaryStyles = () =>
  createStyles({
    content: {
      alignItems: "center",
      display: "flex",
      fontWeight: "bold",
      margin: "0 !important",
    },
    expanded: {},
    root: {
      minHeight: "42px !important",
      paddingLeft: "0",
    },
  });
export const accordionDetailStyles = () =>
  createStyles({
    root: {
      display: "block",
      padding: "0 16px 0 25px",
    },
  });

export const tableStyles = (theme: Theme) =>
  createStyles({
    primary: {
      "& td, & th": {
        fontSize: theme.typography.fontSize,
        color: theme.palette.text.primary,
        borderBottom: "none",
      },
      "&>thead": {
        "&>tr>th": {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          borderBottom: `1px solid ${colors.border}`,
        },
      },
      "&>tfoot": {
        "&>tr>th": {
          color: theme.palette.primary.main,
          fontWeight: "bold",
        },
        "& td, & th": {
          borderTop: `1px solid ${colors.border}`,
        },
      },
      "&>tbody": {
        "&>tr": {
          "&>th, &>td": {
            borderBottom: "none",
          },
        },
      },
    },
  });

export const listItemStyles = (theme: Theme) =>
  createStyles({
    primary: {
      boxShadow: theme.shadows[4],
      backgroundColor: colors.gradWidget,
      borderRadius: 20,
      marginBottom: 15,
      "&:hover": {
        backgroundColor: colors.blueGray8,
      },
    },
    primaryRightButton: {
      color: theme.palette.primary.main,
    },
    primaryLeftIcon: {
      color: theme.palette.primary.main,
      fontSize: 30,
      marginTop: 5,
      textAlign: "center",
    },
    primaryLeftImage: {
      width: 60,
      marginTop: 5,
    },
    primaryCenterText: {
      "&>span": {
        color: theme.palette.primary.main,
        fontSize: 18,
        fontWeight: 600,
      },
    },
  });

export const checkboxStyles = (theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 4,
      width: 17,
      height: 17,
      boxShadow: `inset 0 0 0 2px ${colors.blueGray3}, inset 0 -2px 0 ${colors.blueGray3}`,
      backgroundColor: "#f5f8fa",
      "$root.Mui-focusVisible &": {
        outline: `2px auto ${colors.blueGray3}`,
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: colors.blueGray5,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: colors.blueGray7,
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: colors.gradRadioCheckbox,
      position: "relative",
      boxShadow: `none`,
      "input:disabled ~ &": {
        boxShadow: "none",
        background: colors.blueGray7,
      },
      "&:before": {
        display: "block",
        width: 13,
        height: 11,
        top: 3,
        left: 2,
        position: "absolute",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M4.06282 8.44669L1.42409 5.80797C1.28202 5.66558 1.08913 5.58556 0.887979 5.58556C0.686829 5.58556 0.493942 5.66558 0.351868 5.80797C0.0552956 6.10454 0.0552956 6.58362 0.351868 6.88019L3.53051 10.0588C3.82709 10.3554 4.30616 10.3554 4.60274 10.0588L12.6482 2.01339C12.9448 1.71681 12.9448 1.23774 12.6482 0.941167C12.5061 0.798773 12.3132 0.71875 12.1121 0.71875C11.9109 0.71875 11.7181 0.798773 11.576 0.941167L4.06282 8.44669Z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
  });

export const radioStyles = (theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 18,
      width: 18,
      height: 18,
      backgroundColor: colors.white,
      border: `2px solid ${colors.blueGray3}`,
      "$root.Mui-focusVisible &": {
        outline: `2px auto ${colors.blueGray3}`,
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: colors.blueGray5,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: colors.blueGray7,
      },
    },
    checkedIcon: {
      backgroundColor: colors.white,
      position: "relative",
      border: `2px solid ${theme.palette.primary.main}`,
      "input:disabled ~ &": {
        boxShadow: "none",
        background: colors.blueGray7,
      },
      "&:before": {
        display: "block",
        top: 2,
        left: 2,
        width: 10,
        height: 10,
        borderRadius: 10,
        position: "absolute",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundImage: colors.gradRadioCheckbox,
        content: '""',
      },
    },
  });

export const progressBarStyles = () =>
  createStyles({
    root: {
      height: 7,
      borderRadius: 7,
      background: colors.gradButtonPrimary,
      borderLeft: `1px solid ${colors.brandingBlue1}`,
      "&>div": {
        borderRadius: 5,
        height: 5,
        marginTop: 1,
      },
    },
  });

export const textFieldStyles = () =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      borderRadius: 8,
      marginBottom: 20,
      "&>label": {
        transform: "translate(0, -20px) scale(1) !important",
        fontSize: 12,
      },
      "& input, & select": {
        padding: 16,
        borderRadius: 8,
      },
      // "& fieldset": {
      //   // borderRadius: 8,
      //   "&>legend>span": {
      //     display: "none !important",
      //   }
      // }
    },
  });

export const chartStyles = () =>
  createStyles({
    tooltip: {
      backgroundColor: colors.blueGray7,
      borderRadius: 8,
      padding: "10px 20px",
      "&>.fb-chart-tooltip-title": {
        color: colors.gray1,
        fontSize: 12,
        fontWeight: 500,
        margin: "0 0 10px 0",
      },
      "&>ul": {
        listStyleType: "none",
        padding: 0,
        margin: 0,
        "&>li": {
          fontSize: 12,
          display: "flex",
          alignItems: "center",

          "&>span": {
            display: "block",
            width: 12,
            height: 12,
            borderRadius: 12,
            top: 5,
            marginRight: 10,
          },
          "&>strong": {
            marginLeft: 10,
          },
        },
      },
    },
    container: {
      position: "relative",
      width: 340,
    },
    layer: {
      backgroundColor: colors.brandingBlue1,
      opacity: "0.1",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    blur: {
      filter: "blur(2px)",
    },
    upgradeBtn: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      top: "80%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    lockIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "12%",
      left: "90%",
      transform: "translate(-50%, -50%)",
      background: colors.white,
      width: 40,
      height: 40,
      borderRadius: "50%",
      zIndex: 1,
    },
    chartLink: {
      color: colors.brandingBlue1,
      display: "inline-block",
      fontSize: 12,
      textAlign: "right",
      textDecoration: "none",
      width: 100,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  });
export const heroTextStyle = () =>
  createStyles({
    container: {
      width: "100%",
      padding: "30px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    heading: {
      display: "flex",
      alignItems: "center",
      "& h1": {
        fontSize: 24,
        margin: "0px 0px 12px 0px",
        color: "#056E9D",
        whiteSpace: "pre-line",
      },
      "& span": {
        color: "#404040",
        borderBottom: `1px solid #404040`,
        fontSize: "28px",
        fontWeight: 600,
        margin: "0px 0px 12px 0px",
        lineHeight: "30px",
      },
    },
    text: {
      color: "#535F74",
      whiteSpace: "pre-line",
      fontSize: 14,
      lineHeight: "25px",
    },
    buttons: {
      marginTop: 30,
      display: "flex",
      alignItems: "center",
    },
    img: {
      height: 100,
      "& img": {
        width: "100%",
        height: "inherit",
        objectFit: "contain",
      },
    },
  });

export const statusIndicatorStyles = () =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start !important",
    },
    indicator: {
      margin: "0px 8px 0px 0px",
      width: 8,
      height: 8,
      borderRadius: 50,
      display: "inline-block",
      background: colors.brandingBlue1,
    },
    text: {
      color: "#535F74",
      whiteSpace: "pre-line",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "25px",
      margin: 0,
    },
  });

export const selectStyles = () =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      borderRadius: 8,
      marginBottom: 20,
      width: "100%",
      border: `1px solid ${colors.blueGray3}`,
      "&:before,&:after ": {
        borderBottom: "none !important",
      },
      "& svg": {
        marginRight: 9,
      },
      "& select": {
        padding: "20px 34px 20px 11px",
        borderRadius: 8,
        "& option": {
          font: "-moz-pull-down-menu",
        },
      },
      icon: {
        fontSize: 20,
      },
    },
  });
