import React from "react";

import { FormControl, FormLabel, Grid } from "@material-ui/core";

import { FormComponentType } from "./interfaces";
import { DollarTextField } from "src/utils/inputMask";

const CreditCardDebtForm: FormComponentType = ({ formState, updateField }) => (
  <>
    <Grid item xs={12} className="mt-5">
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className="mb-3 h-4">
          What is the outstanding balance carried month to month?
        </FormLabel>
        <DollarTextField
          name="balance"
          style={{ maxWidth: 350, width: "100%" }}
          onChange={updateField}
          variant="outlined"
          value={formState.balance || 0}
        />
      </FormControl>
    </Grid>
    <Grid item xs={6} className="mt-5">
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className="mb-3 h-4">
          What is the minimum monthly payment?
        </FormLabel>
        <DollarTextField
          name="payment"
          style={{ maxWidth: 350, width: "100%" }}
          onChange={updateField}
          variant="outlined"
          value={formState.payment || 0}
        />
      </FormControl>
    </Grid>
  </>
);

export default CreditCardDebtForm;
