import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";

export interface MortgageLeadRequest {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  contact: {
    phone: boolean;
    sms: boolean;
    email: boolean;
  };
  state: string;
  residence: string;
  home_value: number;
  down_payment?: number;
  mortgage_balance?: number;
  credit_score: number;
  profession: string;
  income: number;
  payments: {
    home?: number;
    student: number;
    auto: number;
    other: number;
  };
  lenders: string[];
}

export const submitMortgageLeadApi = (payload: MortgageLeadRequest) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/mortgage/mortgage", payload);
    return data;
  });

export const submitRefinanceLeadApi = (payload: MortgageLeadRequest) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/mortgage/refinance", payload);
    return data;
  });

export const submitHelocLeadApi = (payload: MortgageLeadRequest) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/mortgage/heloc", payload);
    return data;
  });

export const mortgagePingApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/ping/mortgage/mortgage");
    return data;
  });

export const refinancePingApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/ping/mortgage/refinance");
    return data;
  });

export const helocPingApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/ping/mortgage/heloc");
    return data;
  });
