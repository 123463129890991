import * as React from "react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { headerStyles } from "src/theme";

// import { AppLoader } from "src/components";
import {
  getAccountProviders,
  getLinkedAccountsLoading,
} from "src/store/account/selector";
import { getAlerts } from "src/store/dashboard/selector";
import MessageCard from "./MessageCard";
import { AccountProvider } from "src/interfaces";
import ProviderCard from "./ProviderCard";

const Alerts = () => {
  const classes = useStyles();
  const alerts: any[] = useSelector(getAlerts);
  const providers: AccountProvider[] = useSelector(getAccountProviders);
  const loadingLinkedAccounts = useSelector(getLinkedAccountsLoading);

  return (
    <main className={classes.mainContent}>
      <Card className="h-full w-full p-6">
        {alerts.map((alert) => (
          <MessageCard key={alert.toString()} alert={alert} />
        ))}
        <Divider className="m-4" />
        {loadingLinkedAccounts && (
          <Box className="text-center w-full h-32 py-10">
            <CircularProgress size={40} thickness={4} />
          </Box>
        )}
        {providers
          .filter((provider) => provider.new)
          .map((provider) => (
            <ProviderCard
              key={provider.item_id}
              actionType="new"
              provider={provider}
            />
          ))}
        {providers
          .filter((provider) => provider.error)
          .map((provider) => (
            <ProviderCard
              key={provider.item_id}
              actionType="relink"
              provider={provider}
            />
          ))}
      </Card>
    </main>
  );
};

const useStyles = makeStyles(headerStyles);

export default Alerts;
