import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@material-ui/core";

import HeaderText from "src/components/HeaderText";
import Tabs from "./Components/Tabs";
import NetSummary from "./Blocks/NetSummary";
import AssetSummary from "./Blocks/AssetSummary";
import DebtSummary from "./Blocks/DebtSummary";
import HumanCapital from "./Blocks/HumanCapital";
import IDRSummary from "./Blocks/IDRSummary";
import { activeTabText } from "./data";
import { SidebarContent } from "src/interfaces";
import { HELP } from "src/store/dashboard/helpTopics";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import { getRouterState } from "src/store/router/selector";
import { getIsSubscribed, getHasPlan } from "src/store/system/selector";
import { wealthPageText } from "src/text";

export const Content = () => {
  const dispatch = useDispatch();
  const routerState: any = useSelector(getRouterState);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const subscribed = useSelector(getIsSubscribed);
  const hasPlan = useSelector(getHasPlan);
  const [currentTab, setCurrentTab] = useState(0);
  const { heading, helpTopic } = activeTabText[currentTab] || {};

  let helpContent: any = null;

  // TODO this is an ugly hack to temporarily bridge between two competing help interfaces
  if (helpTopic) {
    const foundHelp = HELP[helpTopic];
    const content: SidebarContent = {
      header: foundHelp.topic,
      body: foundHelp.content,
      videoKey: foundHelp.videoKey,
    };
    helpContent = [content];
  }

  useEffect(() => {
    if (routerState?.location?.hash === "#hc") {
      setCurrentTab(2);
    } else if (routerState?.location?.hash === "#debt") {
      setCurrentTab(3);
    }
  }, [routerState]);
  useEffect(() => {
    if (refreshNeeded.liveProjection) {
      dispatch(fetchLivePlanProjection(false));
    }
  }, [refreshNeeded.liveProjection]);

  const mainContent = (
    <>
      <Typography paragraph className="text-sm">
        Tracking your net wealth helps you visualize:
      </Typography>
      <ol>
        <Typography variant="body1" component="li" className="text-sm">
          How you are converting your intangible wealth (i.e., your human
          capital) into tangible financial wealth over time, and
        </Typography>
        <Typography variant="body1" component="li" className="text-sm">
          Whether you are on track to achieve your retirement goals.
        </Typography>
      </ol>
    </>
  );

  let buttons;

  if (!currentTab) {
    if (subscribed && hasPlan) {
      buttons = activeTabText[0].subscribedButtons;
    } else {
      buttons = activeTabText[0].buttons;
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HeaderText
          className="mb-0"
          heading={heading}
          helpContent={helpContent}
          // helpTopic={helpTopic}
          description={currentTab ? wealthPageText[currentTab] : undefined}
          content={!currentTab ? mainContent : undefined}
          buttons={buttons}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {currentTab === 3 && <IDRSummary />}
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid item xs={12} md={6}>
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {currentTab === 0 && <NetSummary />}
        {currentTab === 1 && <AssetSummary />}
        {currentTab === 2 && <HumanCapital />}
        {currentTab === 3 && <DebtSummary />}
      </Grid>
    </Grid>
  );
};
