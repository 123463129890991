import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";

import { Box, makeStyles, Tooltip } from "@material-ui/core";

import Card from "src/components/Card";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import colors from "src/theme/colors";
import {
  getAccountProviders as selectProviders,
  getAccountsLoaded,
  getPublicToken as selectPublicToken,
} from "src/store/account/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { getAccountProviders, getPublicToken } from "src/store/account/actions";
import { addPublicTokenApi } from "src/apiService";
import { ConfirmDialog } from "src/components";
import LinkAccountDialog from "src/components/LinkAccountDialog";

interface IAddAccountCard {
  className?: string;
  onAdd?: (manual: boolean) => void;
}

export const AddAccountCard = ({ className, onAdd }: IAddAccountCard) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const publicToken = useSelector(selectPublicToken);
  const providers = useSelector(selectProviders);
  const loaded = useSelector(getAccountsLoaded);
  const subscribed = useSelector(getIsSubscribed);

  const [linkAccountDialogVisible, setLinkAccountDialogVisible] = useState(
    false
  );
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string) => {
      try {
        await addPublicTokenApi({ public_token });
        setLinkAccountDialogVisible(false);
        setConfirmDialogVisible(true);
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const config: PlaidLinkOptions = {
    token: publicToken?.link_token || "",
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (!linkAccountDialogVisible && !confirmDialogVisible) return;
    dispatch(getPublicToken(undefined));
  }, [linkAccountDialogVisible, confirmDialogVisible]);

  useEffect(() => {
    if ((!providers || !providers.length) && !loaded) {
      dispatch(getAccountProviders());
    }
  }, [dispatch]);

  const handleAddManual = () => {
    if (onAdd) {
      onAdd(true);
    }
  };

  return (
    <Card className={className} title="Accounts">
      <Box className="my-5">
        {!subscribed ? (
          <Tooltip
            placement="top-start"
            title="When you upgrade to a premium membership, you can link your financial accounts to FitBUX."
          >
            <span>
              <Button
                disabled
                style={{ background: colors.gray1, color: "white" }}
                className={styles.button}
              >
                <span style={{ height: 27, lineHeight: "27px" }}>
                  Add Linked Account
                </span>
                <Icon style={{ color: "white" }} iconName="fb-flow-chart" />
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            fbColor="secondary"
            className={styles.button}
            onClick={() => setLinkAccountDialogVisible(true)}
          >
            <span style={{ height: 27, lineHeight: "27px" }}>
              Add Linked Account
            </span>
            <Icon iconName="fb-flow-chart" />
          </Button>
        )}
        <Button
          fbColor="primary"
          className={styles.button}
          onClick={handleAddManual}
        >
          <span style={{ height: 27, lineHeight: "27px" }}>
            Add Manual Account
          </span>
          <Icon iconName="fb-construction" />
        </Button>
      </Box>
      <LinkAccountDialog
        isOpen={linkAccountDialogVisible}
        onClose={() => setLinkAccountDialogVisible(false)}
        openPlaid={() => open()}
        ready={ready}
      />
      <ConfirmDialog
        visible={confirmDialogVisible}
        title="Add More Accounts"
        message="Would you like to link another account?"
        onCancel={() => setConfirmDialogVisible(false)}
        onConfirm={() => open()}
        cancelButton="No, Thank You"
        confirmButton="Yes, Please"
      />
    </Card>
  );
};

export default AddAccountCard;

const useStyles = makeStyles({
  button: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    margin: "7px 0",
  },
  lockIconContainer: {
    border: `1px solid ${colors.blueGray3}`,
    width: 27,
    height: 27,
    borderRadius: 28,
    padding: 4,
    "& svg": {
      color: colors.brandingBlue1,
      fontSize: 14,
    },
  },
});
