export const text = {
  title: "How Would You Like To Build Your Plan?",

  disabled: {
    tooltip: "This option is only available to those that have graduated. If you have graduated, please go to the human capital section of your profile and update your graduation date.",
  },

  // Optimized
  standard: {
    heading: "Using FitBUX's Financial Plan Optimizer",
    body: "Our AI-powered plan optimizer will automatically recommend how to best allocate your income in order to reach your goals faster.",
  },
  student: {
    heading: "FitBUX's Optimized Financial Plan Builder",
    body: "Our AI will tell you what percent of your paycheck should go where to optimize your money. From student loans to investments, and even home buying. We'll optimize it all!",
  },
  // Manually
  manual: {
    heading: "Manually",
    body: "Our easy-to-use plan building technology allows you to set up and simulate your plan.",
  }
};