import React from "react";

import { cloneDeep } from "lodash";

import {
  Divider,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import { Account, MappedAccount, REPAYMENT_PLANS } from "src/interfaces";

import MainCard from "src/components/MainCard";
import { PROFILE_TYPE } from "src/store/profileBuild/constants";
import { DollarTextField, formatPercent, formatWholeDollars } from "src/utils";

const defaultNewLoan: MappedAccount = {
  id: 0,
  balance: 0,
  income_percent: 0,
  variableLabel: "",
  annual: 0,
  monthly: 0,
  variable: "",
};

interface IProps {
  formValues: any;
  setFormValues: any;
  monthsSinceGraduation: number;
  loading: boolean;
  onChange: any;
  isLinked: boolean;
  selected?: Account;
  isLinkedAccount: any;
  myFedLoans: MappedAccount[];
  spouseFedLoans: MappedAccount[];
}

export const FedLoansForm = ({
  formValues,
  setFormValues,
  monthsSinceGraduation,
  loading,
  onChange,
  isLinked,
  selected,
  isLinkedAccount,
  myFedLoans,
  spouseFedLoans,
}: IProps) => {
  const fedLoanColumns = [
    { label: "Loan No.", field: "no", width: "30%" },
    {
      label: "Current Balance",
      field: "balance",
      type: "number",
      width: "30%",
      formatter: formatWholeDollars,
    },
    {
      label: "Interest Rate",
      field: "rate",
      type: "percent",
      width: "30%",
      formatter: formatPercent,
    },
  ];

  const isSpouse = formValues.who === PROFILE_TYPE.SPOUSE;
  const fedLoans = isSpouse ? spouseFedLoans : myFedLoans;
  if (fedLoans && fedLoans.length && isLinkedAccount(fedLoans[0].id)) {
    fedLoanColumns[1].type = "fixed";
  }

  const handleAddLoan = () => {
    setFormValues((prev: any) => ({
      ...prev,
      fed_loans: [...(prev.fed_loans || []), defaultNewLoan],
    }));
  };

  const handleRemoveLoan = (e: any, i: number) => {
    const newFormValues = {
      ...cloneDeep(formValues),
      fed_loans: (formValues.fed_loans || []).filter(
        (loan: any, index: number) => index !== i
      ),
    };
    const loanToRemove = (formValues.fed_loans || [])[i];
    if (loanToRemove && loanToRemove.id) {
      if (!newFormValues.toRemove) {
        newFormValues.toRemove = [];
      }
      newFormValues.toRemove.push(loanToRemove.id);
    }
    setFormValues(newFormValues);
  };

  const handleSaveLoan = (_e: any, n: any, i: number) => {
    setFormValues((current: any) => {
      const newFormValues = {
        ...current,
        fed_loans: (current.fed_loans || []).slice(),
      };
      newFormValues.fed_loans[i] = n;
      return newFormValues;
    });
  };

  return (
    <>
      {monthsSinceGraduation >= 6 && (
        <>
          <FormLabel component="legend" className="mb-2 mt-4">
            Current Federal Student Loan Plan
          </FormLabel>
          <Select
            variant="outlined"
            fullWidth
            value={formValues.fed_repayment_plan}
            disabled={loading}
            onChange={onChange("fed_repayment_plan")}
          >
            {Object.keys(REPAYMENT_PLANS).map((type) => (
              <MenuItem value={type} key={type}>
                {REPAYMENT_PLANS[type]}
              </MenuItem>
            ))}
          </Select>
          <FormLabel component="legend" className="mb-2 mt-4">
            Monthly Payment
          </FormLabel>
          <DollarTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="payment_input"
            label=""
            onChange={onChange("payment")}
            value={formValues.payment}
            disabled={isLinked && !formValues?.manual?.payment}
          />
          {/*(formValues.fed_repayment_plan === "ibr" || formValues.fed_repayment_plan === "new_ibr") && 
        <>
          <FormLabel component="legend" className="mb-2 mt-4">How Long Have Your Been In repayment:</FormLabel>
          <Box className="flex justify-between items-center">
            <TextField label="Years" variant="outlined" onChange={handleChange("years")} value={formValues.years} type="number" />
            <TextField label="Months" variant="outlined" onChange={handleChange("months")} value={formValues.months} type="number" />
          </Box>
        </>*/}
          <Divider className="my-5" />
        </>
      )}
      <FormLabel component="legend" className="mb-2 mt-4">
        Enter your loan details below. You should have between 5 and 20 Federal
        student loans.
      </FormLabel>
      <MainCard
        title="Federal student loan"
        noContainer
        hideTitle
        columns={fedLoanColumns}
        fixedRows={
          selected
            ? (formValues.fed_loans || [])
                .filter((loan: any) => isLinked && !loan?.manual?.rate)
                .map((loan: any, index: number) => index)
            : []
        }
        enableDeleteFixed
        data={(formValues.fed_loans || []).map((loan: any, index: number) => ({
          ...loan,
          no: index + 1,
        }))}
        allowSelect
        saveOnChange
        defaultNewItem={defaultNewLoan}
        onAdd={handleAddLoan}
        onDelete={handleRemoveLoan}
        onSave={handleSaveLoan}
      />
      {formValues.fed_repayment_plan === "new_ibr" && (
        <Typography>
          *Graduated plan is assumed to be amortized on a straight line basis.
        </Typography>
      )}
    </>
  );
};

export default FedLoansForm;
