import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";
import { push } from "connected-react-router";

import {
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import createStyles from "@material-ui/core/styles/createStyles";

import { resetPasswordApi } from "src/apiService";
import TextField from "src/components/TextField";
import Icon from "src/components/Icon";

import { getRouterState } from "src/store/router/selector";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink, validatePassword } from "src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

interface MyFormValues {
  password: string;
}

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useSelector(getRouterState);
  const { loggedIn } = useSelector(loginStateSelector);
  const resetkey = useRef(router.location?.query?.resetkey || "");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    if (values.password && !passwordError && !success && !loading) {
      resetPasswordApi(resetkey.current, values.password)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => dispatch(push("/login")), 3000);
        })
        .catch(() => {
          setLoading(false);
          // setApiError(true);
        });
    }
  };
  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{ password: "" }}
        onSubmit={onFormikSubmit}
        render={(formikBag: FormikProps<MyFormValues>) => (
          <form
            className={classes.form}
            noValidate
            onSubmit={formikBag.handleSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              type="password"
              label="Enter a new password"
              name="password"
              placeholder="Password"
              onChange={formikBag.handleChange}
              onFocus={() => setPasswordError(false)}
              onBlur={(...args) => {
                formikBag.handleBlur(...args);
                if (!validatePassword(formikBag.values.password)) {
                  setPasswordError(true);
                }
              }}
              value={formikBag.values.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconName="fb-at" />
                  </InputAdornment>
                ),
              }}
            />
            {!!passwordError && (
              <Alert severity="error">
                Password must be at least 8 characters long and include at least
                one lowercase letter, one uppercase letter, and one numeral.
              </Alert>
            )}
            {!!success && (
              <Alert severity="success">
                Password successfully changed. You will be redirected to the
                login page in a few seconds.
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !validatePassword(formikBag.values.password) ||
                loading ||
                success
              }
            >
              {!loading && "Set Password"}
              {loading && (
                <CircularProgress color="inherit" className="w-6 h-6" />
              )}
            </Button>
            <Grid container>
              <Grid item className="text-center w-full">
                <AdapterLink
                  to="/login"
                  variant="body2"
                  className="text-gray-3"
                >
                  Go to Login
                </AdapterLink>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default ResetPassword;
