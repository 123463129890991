import React from "react";

import { FormControl, FormLabel, Grid } from "@material-ui/core";

import { FormComponentType } from "./interfaces";
import { DollarTextField } from "src/utils/inputMask";

const BasicAssetForm: FormComponentType = ({ formState, updateField }) => (
  <Grid item xs={12}>
    <FormControl component="fieldset" fullWidth className="mt-5 mb-10">
      <FormLabel component="legend" className="mb-3 h-4">
        What is the current balance in your account?
      </FormLabel>
      <DollarTextField
        id="outlined-basic"
        label="Balance"
        style={{ maxWidth: 350, width: "100%" }}
        name="balance"
        onChange={updateField}
        variant="outlined"
        value={formState.balance}
      />
    </FormControl>
  </Grid>
);

export default BasicAssetForm;
