import React from "react";
import { useDispatch } from "react-redux";
import Slider from "src/components/Slider";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { ViewComponent } from "src/interfaces/viewComponent.interface";

const slides = [
  {
    image: "/assets/images/animations/wave.gif",
    subline: "Welcome to FitBUX",
    title: "Money Made Easy",
    description:
      "To get started, let's build your FitBUX profile. This will value your human capital, calculate your FitBUX Score, and get you set up to create your financial plan.",
  },
  // {
  //   image: "/assets/images/animations/fingers.gif",
  //   subline: "Welcome to FitBUX",
  //   title: "Finances Made Easy",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum orci gravida semper blandit est sapien, risus ut. Ullamcorper non quis nullam dignissim. Your FitBUX score will increase as you progress through this form",
  // },
];

const Welcome: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  // const page1: boolean =
  //   useSelector(getCurrentStep) === PROFILE_BUILD_STEPS.WELCOME_1;
  const nextStep: PROFILE_BUILD_STEPS =
    /*page1 ? PROFILE_BUILD_STEPS.WELCOME_2 :*/
    PROFILE_BUILD_STEPS.PERSONAL_INFO;
  // const prevStep: PROFILE_BUILD_STEPS = null; /*| null = page1 ? null :
  //   PROFILE_BUILD_STEPS.WELCOME_1;
  const onNext = () => dispatch(setProfileStep(nextStep));
  const onPrev = undefined; //  prevStep ? () => dispatch(setProfileStep(prevStep)) : undefined;
  // const onSkip = prevStep ? undefined : () =>
  //   dispatch(setProfileStep(PROFILE_BUILD_STEPS.PERSONAL_INFO))

  if (!render) {
    return <div />;
  }

  return render({
    component: <Slider slides={slides} slide={0} />,
    onNext,
    onPrev,
    // onSkip,
  });
};

export default Welcome;
