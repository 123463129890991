import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import {
  Box,
  Divider,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";

import CalculatorLayout from "src/layouts/CalculatorLayout";
import Button from "src/components/Button";
import StickyFooter from "src/components/StickyFooter";
import TitleCard from "src/components/TitleCard";
import NewOrRefinanceInterstitial from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/NewOrRefinanceInterstitial";
import { DollarTextField, formatWholeDollars } from "src/utils";
import { STATES } from "src/constants";
import { getPropertyTaxAPI } from "src/apiService";

const PropertyTaxEstimate: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    property_value: 0,
    state: "",
  });
  const [error, setError] = useState(true);
  const [propertyTax, setPropertyTax] = useState(0);
  const [showInterstitial, setShowInterstitial] = useState(false);

  const handleBack = () => {
    dispatch(push("/homebuying"));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((preValue) => ({
      ...preValue,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (data.property_value > 0 && data.state !== "") {
      setError(false);
    } else {
      setError(true);
    }
  }, [data]);

  const handleCalculate = async () => {
    const res = await getPropertyTaxAPI(data);
    setPropertyTax(res.property_tax);
  };

  return (
    <CalculatorLayout
      title="Property Tax Estimator"
      onBackClick={handleBack}
      disclaimer="Disclaimer: This is a rough estimate to use when planning.  Each state, county, and city has unique laws. Therefore, you should double check with a realtor."
      footer={
        propertyTax ? (
          <StickyFooter text="Check purchase or refinance rates with FitBUX's mortgage lending partners.">
            <Button
              className="mr-10"
              fbColor="accent"
              onClick={() => setShowInterstitial(true)}
            >
              Check My Rates
            </Button>
          </StickyFooter>
        ) : null
      }
    >
      <TitleCard title="Estimating Your Annual Property Tax">
        <Box className={classes.container}>
          <Typography>
            This tool allows you to estimate your property tax bill based on
            state and home value.
          </Typography>
          <Box className={classes.contentContainer}>
            <Box className={classes.contentItem}>
              <Typography>Property Value</Typography>
              <DollarTextField
                name="property_value"
                variant="outlined"
                placeholder="$300,000"
                decimalScope="none"
                value={data.property_value === 0 ? "" : data.property_value}
                className={classes.textField}
                onChange={handleInputChange}
              />
            </Box>
            <Box className={classes.contentItem}>
              <Typography>State</Typography>
              <TextField
                select
                variant="outlined"
                className={classes.textField}
                onChange={handleInputChange}
                name="state"
                value={data.state}
              >
                <MenuItem
                  value={""}
                  style={{
                    display: "none",
                  }}
                ></MenuItem>
                {STATES.map((state: string, index) => (
                  <MenuItem value={state} key={index}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box className={classes.actionContainer}>
            {error ? (
              <Tooltip title="All fields must be filled out to continue">
                <span>
                  <Button disabled fbColor="primary">
                    Calculate
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button fbColor="primary" onClick={handleCalculate}>
                Calculate
              </Button>
            )}
          </Box>

          {propertyTax > 0 && (
            <Box className={classes.resultContainer}>
              <Divider />
              <Box className={classes.resultContent}>
                Your estimated annual property tax:{" "}
                {formatWholeDollars(propertyTax)}
              </Box>
            </Box>
          )}
        </Box>
      </TitleCard>
      <NewOrRefinanceInterstitial
        open={showInterstitial}
        onClose={() => setShowInterstitial(false)}
        title="Property Tax Estimator"
      />
    </CalculatorLayout>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    margin: "auto",
  },
  contentItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: 20,
  },
  textField: {
    width: 150,
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  resultContainer: {
    marginTop: theme.spacing(2),
  },
  resultContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
    fontSize: 16,
    fontWeight: 600,
  },
}));
export default PropertyTaxEstimate;
