export const REVIEW_MESSAGES = {
  RETIRE_HIGH:
    "If you implement this plan you'll have a PERCENT% probability of retiring at age AGE with enough money to last you throughout retirement.",
  RETIRE_LOW:
    "If you implement this plan you'll have a PERCENT% probability of retiring at age AGE and not running out of money during retirement. To increase these odds, you can edit your plan to review your income/expense assumptions and/or modify your retirement age.",
  SCORE_INCREASED_FROM_IDR:
    "Your FitBUX Score would increase today because your required payment dropped on your student loans. This gives your current budget more flexibility and provides you with more options. Therefore, your current financial risk decreases.",
  SCORE_INCREASES_FROM_SL_PAYOFF:
    "Congrats, your FB Score increases on DATE because you paid off your student loans, which reduced your financial risk.",
  BOUGHT_HOUSE_ON_TARGET:
    "Congratulations on your home purchase! Your FB Score decreases on x date because debt increases your financial risk. Primary question: Is the return in the long run worth the risk now?  Answer: See what your FitBUX Score is at retirement relative to if you don't buy the house!",
  MISSED_HOUSE_TARGET:
    "You wanted to buy your house by TARGET. However, based on this plan, you won't be able to save & purchase it till ACTUAL. Edit your plan and allocate more money to savings and checking for your down payment or click implement if you are ok with this plan.",
  SCORE_INCREASES_FROM_MARRIAGE:
    "Congrats on getting married! Your FitBUX Score increased thanks to your dual income and your lower combined expenses, which reduces your financial risk.",
  SCORE_DECREASES_FROM_MARRIAGE:
    "Your FitBUX Score decreases when you get married because you'll have more expenses relative to the increase in your combined incomes: this increases your financial risk.",
  HAVE_A_CHILD:
    "Your FitBUX Score decreases when you have children because of increased expenses will reduce your budget flexibility, all else equal. This, in turn, increases your financial risk.",
  SCORE_INCREASES_PAST_500:
    "Congrats...on xx/xx your Score increases past 500: you've significantly decreased the risk to your financial plan. Keep going!",
  SCORE_INCREASES_PAST_700:
    "Congrats...on xx/xx your Score increases past 700. That means you've significantly decreased the risk to your financial plan. Keep going!",
  SCORE_STAYS_HIGH:
    "Congrats, this plan allows you to keep you FitBUX Score above a 700. Your financial risk is limited and you’re in great financial shape overall!.",
};
