import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router";
import { Switch } from "react-router-dom";

import DashboardLayout from "src/layouts/DashboardLayout";
import InvestmentsLayout from "src/layouts/InvestmentsLayout";
import PlanBuilderLayout from "src/layouts/PlanBuilderLayout";
import ProfileBuilderLayout from "src/layouts/ProfileBuilderLayout";

import LogOut from "src/pages/Authentication/LogOut";
import Popup from "src/components/Popup";

import Alerts from "src/pages/Dashboard/Alerts";
import BuildPlan from "src/pages/Dashboard/Plan/BuildPlan";
import ComparePlans from "src/pages/Dashboard/Plan/ComparePlans";
import PlanList from "src/pages/Dashboard/Plan/PlanList";
import PlanSummary from "src/pages/Dashboard/Plan/PlanSummary";
import Wealth from "src/pages/Dashboard/MoneyDetail/NetWealth";
import Budget from "src/pages/Dashboard/MoneyDetail/DayToDay";
import RiskManagement from "src/pages/Dashboard/MoneyDetail/RiskManagement";
import Settings from "src/pages/Dashboard/Settings";
import ScoreFactors from "src/pages/Dashboard/ScoreFactors";
import Accounts from "src/pages/Dashboard/MyProfile/Accounts";
import Transactions from "src/pages/Dashboard/MyProfile/Transactions";
import Solutions from "src/pages/Dashboard/deprecated/Solutions";
import HomeAffordability from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/HomeAffordability";
import Tools from "src/pages/Dashboard/Tools";
import Coach from "src/pages/Dashboard/Coach";
import Investments from "src/pages/Dashboard/ToolsAndProducts/Investments";
import StudentLoans from "src/pages/Dashboard/ToolsAndProducts/StudentLoans";
import CreditPersonalLoans from "src/pages/Dashboard/ToolsAndProducts/CreditPersonalLoans";
import OtherFinancialItems from "src/pages/Dashboard/ToolsAndProducts/OtherFinancialItems";
import HomeBuying from "src/pages/Dashboard/ToolsAndProducts/HomeBuying";
import Insurance from "src/pages/Dashboard/ToolsAndProducts/Insurance";
import Referral from "src/pages/Referral";
import TaxBomb from "src/pages/Dashboard/ToolsAndProducts/StudentLoans/TaxBomb";
import MortgageRates from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/MortgageRates";
import HelocRates from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/HelocRates";
import RefinanceRates from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/RefinanceRates";
import HelocRepayment from "src/pages/Dashboard/ToolsAndProducts/HomeBuying/HelocRepayment";

import CreateBettermentAccount from "src/pages/Investments/BettermentAccount/CreateAccount";
import EditBettermentAccount from "src/pages/Investments/BettermentAccount/EditAccount";
import InvestmentAllocations from "src/pages/Investments/AccountAllocations";
import HowToInvest from "src/pages/Investments/HowToInvest";
import HowToAdjust from "src/pages/Investments/HowToAdjust";
// import PlanComparisonTool from "src/pages/PlanComparisonTool";
import PlanBuilder from "src/pages/PlanBuilder";
import ProfileBuilder from "src/pages/ProfileBuilder";

import PointsCalculator from "src/pages/PointsCalculator";
import MortgagePayment from "src/pages/MortgagePayment";
import PropertyTaxEstimate from "src/pages/PropertyTaxEstimate";
import CostOfLivingTool from "src/pages/CostOfLiving";
import EmergencyFundAmount from "src/pages/EmergencyFundAmount";
import RentVsBuy from "src/pages/RentVsBuy";

import { PROFILE_PROGRESS } from "src/store/profileBuild/constants";
import { getScore } from "src/store/dashboard/actions";
import { getRefreshNeeded } from "src/store/dashboard/selector";
import { getRouterState } from "src/store/router/selector";
import {
  getProfileLoaded,
  getProfileProgress,
} from "src/store/profileBuild/selector";
import { allowPlanBuilder } from "src/store/system/selector";
import PlanSelect from "src/components/PlanTypeSelect";
import OptimizedPlan from "src/pages/OptimizedPlan";

const PrivateRouter = () => {
  const dispatch = useDispatch();
  const refreshNeeded = useSelector(getRefreshNeeded);
  useEffect(() => {
    if (refreshNeeded.score) {
      dispatch(getScore());
    }
  }, [refreshNeeded.score]);
  const router = useSelector(getRouterState);
  const profileLoaded = useSelector(getProfileLoaded);
  const profileProgress = useSelector(getProfileProgress);
  const planBuilder = useSelector(allowPlanBuilder);
  return (
    <Switch>
      <Route path="/profile-builder" exact>
        <ProfileBuilderLayout pageTitle="Profile builder">
          <ProfileBuilder />
        </ProfileBuilderLayout>
      </Route>
      <Route path="/investment-allocations" exact>
        <InvestmentsLayout
          goBackTarget={
            router.location?.query?.return === "summary"
              ? "/plan-summary"
              : "/investments"
          }
          headerText="Investment Recommendations"
        >
          <InvestmentAllocations />
        </InvestmentsLayout>
      </Route>
      <Route path="/how-to-invest" exact>
        <InvestmentsLayout
          goBackTarget="/investments"
          headerText="How Should I Invest My Money?"
        >
          <HowToInvest />
        </InvestmentsLayout>
      </Route>
      <Route path="/how-to-adjust" exact>
        <InvestmentsLayout
          goBackTarget="/investments"
          headerText="How Should I Adjust My Portfolio?"
        >
          <HowToAdjust />
        </InvestmentsLayout>
      </Route>
      <Route path="/tax-bomb" exact>
        <InvestmentsLayout
          goBackTarget="/studentloans"
          headerText="Am I Saving Enough For The IDR Tax Bomb?"
        >
          <TaxBomb />
        </InvestmentsLayout>
      </Route>
      <Route path="/betterment/create" exact>
        <InvestmentsLayout
          goBackTarget="/investments"
          headerText="Open An Investment Account With FitBUX"
        >
          <CreateBettermentAccount />
        </InvestmentsLayout>
      </Route>
      <Route path="/betterment/edit" exact>
        <InvestmentsLayout
          goBackTarget="/investments"
          headerText="Your Betterment Account"
        >
          <EditBettermentAccount />
        </InvestmentsLayout>
      </Route>
      <Route exact path="/logout">
        <Popup />
        <LogOut />
      </Route>
      {profileLoaded.progress &&
        profileProgress < PROFILE_PROGRESS.COMPLETE && (
          <Redirect to="/profile-builder" />
        )}
      <Route path="/cost-of-living" exact>
        <CostOfLivingTool />
      </Route>
      <Route path="/alerts" exact>
        <DashboardLayout pageTitle="Alerts">
          <Alerts />
        </DashboardLayout>
      </Route>
      <Route path="/emergency-fund-amount">
        <EmergencyFundAmount />
      </Route>
      <Route path="/build-plan" exact>
        <DashboardLayout pageTitle="Build My Plan">
          <BuildPlan />
        </DashboardLayout>
      </Route>
      <Route path="/compare-plans" exact>
        <DashboardLayout pageTitle="Compare Plans">
          <ComparePlans />
        </DashboardLayout>
      </Route>
      <Route path="/plan-summary" exact>
        <DashboardLayout pageTitle="Plans">
          <PlanSummary />
        </DashboardLayout>
      </Route>
      <Route path="/plan-list" exact>
        <DashboardLayout pageTitle="Edit/Review Plans">
          <PlanList />
        </DashboardLayout>
      </Route>
      <Route path="/property-tax-estimate" exact>
        <PropertyTaxEstimate />
      </Route>
      <Route path="/wealth" exact>
        <DashboardLayout pageTitle="Net Wealth">
          <Wealth />
        </DashboardLayout>
      </Route>
      <Route path="/accounts" exact>
        <DashboardLayout pageTitle="Accounts">
          <Accounts />
        </DashboardLayout>
      </Route>
      <Route path="/budget" exact>
        <DashboardLayout pageTitle="Day-to-Day Money">
          <Budget />
        </DashboardLayout>
      </Route>
      <Route path="/settings" exact>
        <DashboardLayout pageTitle="Settings">
          <Settings />
        </DashboardLayout>
      </Route>
      <Route path="/rent-vs-buy" exact>
        <RentVsBuy />
      </Route>
      <Route path="/risk" exact>
        <DashboardLayout pageTitle="Risk Management">
          <RiskManagement />
        </DashboardLayout>
      </Route>
      <Route path="/scorefactors" exact>
        <DashboardLayout pageTitle="Score Factors">
          <ScoreFactors />
        </DashboardLayout>
      </Route>
      <Route path="/transactions" exact>
        <DashboardLayout pageTitle="Transactions">
          <Transactions />
        </DashboardLayout>
      </Route>
      <Route path="/solutions" exact>
        <DashboardLayout pageTitle="Solutions">
          <Solutions />
        </DashboardLayout>
      </Route>
      <Route path="/studentloans" exact>
        <DashboardLayout pageTitle="Student Loan Tools and Products">
          <StudentLoans />
        </DashboardLayout>
      </Route>
      <Route path="/investments" exact>
        <DashboardLayout pageTitle="Investment Tools and Products">
          <Investments />
        </DashboardLayout>
      </Route>
      <Route path="/studentloans" exact>
        <DashboardLayout pageTitle="Student Loan Products and Tools">
          <StudentLoans />
        </DashboardLayout>
      </Route>
      <Route path="/homebuying" exact>
        <DashboardLayout pageTitle="Home Ownership Tools and Products">
          <HomeBuying />
        </DashboardLayout>
      </Route>
      <Route path="/insurance" exact>
        <DashboardLayout pageTitle="Insurance Tools and Products">
          <Insurance />
        </DashboardLayout>
      </Route>
      <Route path="/credit" exact>
        <DashboardLayout pageTitle="Credit/Personal Loan Tools and Products">
          <CreditPersonalLoans />
        </DashboardLayout>
      </Route>
      <Route path="/otheritems" exact>
        <DashboardLayout pageTitle="Other Financial Tools and Products">
          <OtherFinancialItems />
        </DashboardLayout>
      </Route>
      <Route path="/solutions" exact>
        <DashboardLayout pageTitle="Solutions">
          <Solutions />
        </DashboardLayout>
      </Route>
      {/*<Route path="/upgrade" exact>
        <DashboardLayout pageTitle="Solutions">
          <Solutions />
        </DashboardLayout>
      </Route>*/}
      <Route path="/homeaffordability" exact>
        <HomeAffordability />
      </Route>
      <Route path="/mortgage-payment" exact>
        <MortgagePayment />
      </Route>
      <Route path="/mortgage-rates" exact>
        <MortgageRates />
      </Route>
      <Route path="/heloc-rates" exact>
        <HelocRates />
      </Route>
      <Route path="/refi-rates" exact>
        <RefinanceRates />
      </Route>
      <Route path="/heloc-payment" exact>
        <HelocRepayment />
      </Route>
      <Route path="/coach" exact>
        <DashboardLayout pageTitle="FitBUX Coaching">
          <Coach />
        </DashboardLayout>
      </Route>
      {/*<Route path="/betterment" exact>
        <DashboardLayout pageT="Solutions">
          <BettermentMain />
        </DashboardLayout>
      </Route>*/}
      <Route path="/plan-select" exact>
        <PlanSelect />
      </Route>
      {planBuilder && (
        <Route path="/plan-builder" exact>
          <PlanBuilderLayout
            steps={["Build Plan", "Review & Compare", "Implement"]}
            pageTitle="Build a plan"
          >
            <PlanBuilder />
          </PlanBuilderLayout>
        </Route>
      )}
      {planBuilder && (
        <Route path="/plan-builder-optimized" exact>
          <PlanBuilderLayout
            steps={["Build Plan", "Review & Compare", "Implement"]}
            pageTitle="Set Up My Optimized Financial Plan"
          >
            <OptimizedPlan />
          </PlanBuilderLayout>
        </Route>
      )}
      {/*<Route path="/plan-comparison" exact>
        <PlanBuilderLayout
          fixedStep={1}
          steps={["Build Plan", "Review & Compare", "Implement"]}
          pageTitle="Simulate &amp; Review"
        >
          <PlanComparisonTool />
        </PlanBuilderLayout>
      </Route>*/}
      <Route path="/points-calculator" exact>
        <PointsCalculator />
      </Route>
      <Route path="/tools">
        <Tools />
      </Route>
      <Route path="/referral" exact>
        <DashboardLayout pageTitle="Refer A Friend">
          <Referral />
        </DashboardLayout>
      </Route>
      <Route component={() => <Redirect to="/plan-summary" />} />
    </Switch>
  );
};

export default PrivateRouter;
