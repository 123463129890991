import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "@material-ui/core";

import MainCard, { IItem } from "./components/MainCard";
import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { getScore } from "src/store/dashboard/actions";
import {
  ADIDS,
  LANGUAGE_LEVEL_ARRAY,
  PROFILE_BUILD_STEPS,
} from "src/store/profileBuild/constants";
import {
  fetchFields,
  fetchProfessions,
  setProfileStep,
} from "src/store/profileBuild/actions";
import { fetchSchools } from "src/store/profileBuild/actions";
import { fetchUser } from "src/store/system/actions";

import { CareerSection, EducationSection, OtherSection } from "src/interfaces";

import {
  getCareer,
  getEducation,
  getProfileLoading,
  getFields,
  getProfileLoaded,
  getOtherHC,
  getProfessions,
  getSchools,
  getSpouseOtherHC,
  getSpouseCareer,
  getSpouseEducation,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const schools = useSelector(getSchools);
  const loading = useSelector(getProfileLoading);
  const loaded = useSelector(getProfileLoaded);
  const fields = useSelector(getFields);
  const myEducation = useSelector(getEducation);
  const spouseEducation = useSelector(getSpouseEducation);
  const myCareer = useSelector(getCareer);
  const spouseCareer = useSelector(getSpouseCareer);
  const myOtherHC = useSelector(getOtherHC);
  const spouseOtherHC = useSelector(getSpouseOtherHC);

  const isMarried = useSelector(getIsMarried);
  const professions = useSelector(getProfessions);

  const [educations, setEducations] = useState<Array<IItem>>([]);
  const [careers, setCareers] = useState<Array<IItem>>([]);
  const [otherHCs, setOtherHCs] = useState<Array<IItem>>([]);

  useEffect(() => {
    if (!loaded.schools && !loading.schools) {
      dispatch(fetchSchools());
    }
    if (!loaded.fields && !loading.fields) {
      // fetch undergrad fiends
      dispatch(fetchFields());
    }
    if (!loaded.professions && !loading.professions) {
      dispatch(fetchProfessions());
    }
  }, [dispatch]);

  useEffect(() => {
    let initialEducations: Array<IItem> = [];
    initialEducations = generateEducationList(myEducation, true);
    if (isMarried) {
      initialEducations = initialEducations.concat(
        generateEducationList(spouseEducation, false)
      );
    }
    setEducations(initialEducations);
  }, [myEducation, spouseEducation, schools]);

  useEffect(() => {
    let initialCareer: Array<IItem> = [];
    initialCareer = generateCareerList(myCareer, true);
    if (isMarried) {
      initialCareer = initialCareer.concat(
        generateCareerList(spouseCareer, false)
      );
    }
    setCareers(initialCareer);
  }, [myCareer, spouseCareer, professions]);

  useEffect(() => {
    let initial: Array<IItem> = [];
    initial = generateOtherHCList(myOtherHC, true);
    if (isMarried) {
      initial = initial.concat(generateOtherHCList(spouseOtherHC, false));
    }
    setOtherHCs(initial);
  }, [myOtherHC, spouseOtherHC]);

  const schoolNameById = (id?: number) => {
    if (schools && schools.length) {
      return schools.find((e) => e.id === id)?.name;
    }
  };

  const fieldNameById = (id?: number) => {
    if (fields && fields.length) {
      return fields.find((e) => e.id === id)?.name || "";
    }
  };

  // const schoolNameByIndex = (index?: number) => {
  //   if (index !== undefined && schools && schools.length) {
  //     return schools[index]?.name || "";
  //   }
  // }

  const generateEducationList = (edu: EducationSection, isMine: boolean) => {
    const spouseLabel = isMine ? "" : " (Spouse)";
    const initialEducations: Array<IItem> = [];

    if (edu.phd_deg === "y") {
      initialEducations.push({
        leftPrimary: "Ph.D" + spouseLabel,
        leftSecondary: schoolNameById(edu.phd_school),
        right: `${edu.phd_grad_month}/${edu.phd_grad_year}`,
      });
    }

    /* advanced */
    if (edu.adv_deg === "y") {
      initialEducations.push({
        leftPrimary:
          `${
            ADIDS.find((e) => e.id === edu.adid)?.name ||
            "Master's or doctorate degree"
          }` + spouseLabel,
        leftSecondary: schoolNameById(edu.adv_school),
        right: `${edu.date_last_school_month}/${edu.date_last_school_year}`, // TODO: check if it's the right date
      });
    }

    /* advanced second */
    if (edu.adv_second_deg === "y") {
      initialEducations.push({
        leftPrimary: edu.adv_second_deg_name + spouseLabel,
        leftSecondary: edu.adv_second_school,
      });
    }

    /* undergrad */
    if (edu.undergrad === "y") {
      const field = fieldNameById(edu.undergrad_field);
      const fieldString = field ? ` (${field})` : "";
      initialEducations.push({
        leftPrimary: `Bachelor's degree${fieldString}` + spouseLabel,
        leftSecondary: schoolNameById(edu.undergrad_school),
        right: edu?.undergrad_grad_month
          ? `${edu.undergrad_grad_month}/${edu.undergrad_grad_year}`
          : "",
      });
    }

    /* aa */
    if (edu.aa_deg === "y") {
      initialEducations.push({
        leftPrimary: "Associate's degree" + spouseLabel,
        leftSecondary: "", // schoolNameById(edu.aa_school),
        right: `${edu.aa_grad_month}/${edu.aa_grad_year}`,
      });
    }

    return initialEducations;
  };

  const generateCareerList = (ca: CareerSection, isMine: boolean) => {
    const spouseLabel = isMine ? "" : " (Spouse)";
    const initialEducations: Array<IItem> = [];
    const profession = professions.find((p) => p.id === ca.profession);
    if (profession) {
      initialEducations.push({
        leftPrimary: `${profession.name}` + (isMine ? "" : "(Spouse)"),
        right: `${ca.years_work_exp} year(s)`,
      });
    }
    if (ca.prior_annual_salary) {
      initialEducations.push({
        leftPrimary: `Income for the fist job post-graduation` + spouseLabel,
        right: `$${ca.prior_annual_salary}`,
      });
    }

    return initialEducations;
  };

  const generateOtherHCList = (hc: OtherSection, isMine: boolean) => {
    const spouseLabel = isMine ? "" : " (Spouse)";
    const initialList: Array<IItem> = [];

    if (hc.lang === "y") {
      initialList.push({
        leftPrimary: "Multilingual" + spouseLabel,
        right: "",
      });
    }

    if (hc.lang === "y" && hc.lang_1) {
      initialList.push({
        leftPrimary: hc.lang_1 + spouseLabel,
        right: LANGUAGE_LEVEL_ARRAY[+(hc.lang_speaking_1 || 0)],
      });
    }

    if (hc.lang === "y" && hc.lang_2) {
      initialList.push({
        leftPrimary: hc.lang_2 + spouseLabel,
        right: LANGUAGE_LEVEL_ARRAY[+(hc.lang_speaking_2 || 0)],
      });
    }

    /* Undergrad & Previous: */
    if (hc.qual_gifted_and_talented === "y") {
      initialList.push({
        leftPrimary: "Gifted and talented" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_intl_bacc === "y") {
      initialList.push({
        leftPrimary: "IB graduate" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_ap_scholar === "y") {
      initialList.push({
        leftPrimary: "AP scholar" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_scholarship === "y") {
      initialList.push({
        leftPrimary: "Merit scholarship" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_full_scholarship === "y") {
      initialList.push({
        leftPrimary: "Full scholarship" + spouseLabel,
        right: "",
      });
    }

    /* Skill Sets: */
    if (hc.qual_electronics === "y") {
      initialList.push({
        leftPrimary: `Electronics/electrical` + spouseLabel,
      });
    }

    if (hc.qual_web === "y") {
      initialList.push({
        leftPrimary: `Web Design` + spouseLabel,
      });
    }

    if (hc.qual_work_prog === "y") {
      initialList.push({
        leftPrimary: `Computer Programming` + spouseLabel,
      });
    }

    if (hc.qual_blog === "y") {
      initialList.push({
        leftPrimary: `Blogging/Vlogging` + spouseLabel,
      });
    }

    if (hc.qual_craft === "y") {
      initialList.push({
        leftPrimary: `Crafts and design` + spouseLabel,
      });
    }

    if (hc.qual_autobody === "y") {
      initialList.push({
        leftPrimary: `Automobile DIY projects` + spouseLabel,
      });
    }

    /* Financial Proactivenss */
    if (hc.delinquency === "y") {
      initialList.push({
        leftPrimary: "Loan delinquency" + spouseLabel,
        right: "",
      });
    }

    /* Extracurricular */
    if (hc.qual_club_sports === "y") {
      initialList.push({
        leftPrimary: "Club sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_ncaa_sports === "y") {
      initialList.push({
        leftPrimary: "NCAA sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_prof_sports === "y") {
      initialList.push({
        leftPrimary: "Professional sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_hobby_sports === "y") {
      initialList.push({
        leftPrimary: "Hobby sports" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_marathons === "y") {
      initialList.push({
        leftPrimary: "Marathon/triathlon" + spouseLabel,
        right: "",
      });
    }

    /* Military and Voluneer */
    if (hc.qual_military === "y") {
      initialList.push({
        leftPrimary: "Military service" + spouseLabel,
        right: "",
      });
    }
    if (hc.qual_peace_corps === "y") {
      initialList.push({
        leftPrimary: "Peace Corps" + spouseLabel,
        right: "",
      });
    }
    return initialList;
  };

  if (!render) {
    return <div />;
  }

  return render({
    component: (
      <Container className="pb-32 p-0">
        <MainCard
          iconName="fb-user-education"
          title="Education"
          data={educations}
          onClick={(isMine: boolean) =>
            dispatch(
              setProfileStep(
                isMine
                  ? PROFILE_BUILD_STEPS.MY_EDUCATION
                  : PROFILE_BUILD_STEPS.SPOUSE_EDUCATION
              )
            )
          }
        />
        <MainCard
          iconName="fb-portfolio"
          title="Career"
          data={careers}
          onClick={(isMine: boolean) =>
            dispatch(
              setProfileStep(
                isMine
                  ? PROFILE_BUILD_STEPS.MY_CAREER
                  : PROFILE_BUILD_STEPS.SPOUSE_CAREER
              )
            )
          }
        />
        <MainCard
          iconName="fb-person"
          title="Other Human Capital"
          data={otherHCs}
          onClick={(isMine: boolean) =>
            dispatch(
              setProfileStep(
                isMine
                  ? PROFILE_BUILD_STEPS.MY_OTHER_HUMAN_CAPITAL
                  : PROFILE_BUILD_STEPS.SPOUSE_OTHER_HUMAN_CAPITAL
              )
            )
          }
        />
      </Container>
    ),
    onPrev: () => {
      getScore();
      dispatch(setProfileStep(PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_1));
    },
    onNext: () => {
      getScore();
      dispatch(fetchUser());
      dispatch(setProfileStep(PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_1));
    },
  });
};

export default MainForm;
