import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { cloneDeep, groupBy } from "lodash";

import {
  addPlanApi,
  deletePlanApi,
  calculateEmergencyExpensesApi,
  estimateTaxesApi,
  getLiabilitiesApi,
  getLivePlanProjectionApi,
  getPlanApi,
  getPlanProjectionApi,
  getStudentLoanLiabilitiesApi,
  implementPlanApi,
  listPlansApi,
  updatePlanApi,
  setOptimizedPlanApi,
} from "src/apiService/modules/planBuild";
import {
  Cashflow,
  Earning,
  EducationFunding,
  Expense,
  EXPENSE_TYPES,
  FbAction,
  INCOME_TYPES,
  Plan,
  PlanListRecord,
  PlanProjection,
  PlanAllocations,
  PlanWithMessages,
  RISK_MANAGEMENT_TYPES,
  Who,
} from "src/interfaces";

import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import { PLAN_BUILD_STEPS } from "./constants";
import {
  getComparePlan,
  getCurrentPlan,
  getLivePlan,
  getPlanIsDirty,
  getPlans,
  getRawExpenses,
  getRawIncomes,
  getRawRisks,
  isCurrentPlanImplemented,
} from "./selector";
import { makeCashflowUpdates, makePlanCopy } from "./util";
import { fetchTaxes } from "../cashflow/actions";
import { getRawCashflows } from "../cashflow/selector";
import { loadDashboardData } from "../dashboard/actions";
import { getHasPlan, getIsCurrentStudent } from "../system/selector";
import { OptimizedPlan } from "src/interfaces/optimizedPlanBuild.interface";

function* loadPlan({ payload }: FbAction<actions.SetCurrentPlanPayload>) {
  try {
    const data: Plan = yield call(getPlanApi, payload.planId);
    let copiedData = data;
    const isCurrentStudent: boolean = yield select(getIsCurrentStudent);
    if (payload.isImplemented && !isCurrentStudent) {
      const defaultPlan: Plan = yield call(getPlanApi);
      const currentPlan: Plan = yield select(getCurrentPlan);
      copiedData = makePlanCopy(data, defaultPlan);
      copiedData.assumptions.investments = currentPlan.assumptions.investments;
    }
    const newPlan = { ...copiedData, id: payload.planId };
    yield put({
      type: actions.SET_CURRENT_PLAN + SUCCESS,
      // keepId is true, copy the data from an existing plan, and mark the plan dirty
      payload: { plan: newPlan, keepId: payload.keepId, dirty: payload.keepId },
    });
    if ("education" in newPlan) {
      // IN_SCHOOL
      yield estimateCurrentPlanStudentTaxes();
    } else {
      const isImplemented: boolean = yield select(isCurrentPlanImplemented);
      if (!isImplemented) {
        yield estimateCurrentPlanTaxes();
      }
    }
    yield estimateEmergencyFund();
  } catch (error) {
    yield put({ type: actions.SET_CURRENT_PLAN + FAIL, payload: error });
  }
}

function* loadOptimizedPlan({
  payload,
}: FbAction<{
  planId: number;
  keepId: boolean;
  questionnaire: OptimizedPlan;
  planName: string;
}>) {
  try {
    const data: PlanWithMessages = yield call(
      setOptimizedPlanApi,
      payload.questionnaire
    );
    const newPlan = {
      ...data.plan,
      name: payload.planName,
      id: payload.planId,
      messages: data.messages,
      questionnaire: payload.questionnaire,
    };
    yield put({
      type: actions.SET_CURRENT_PLAN + SUCCESS,
      // After generating the optimized plan, mark it dirty so it can be persisted
      payload: { plan: newPlan, keepId: payload.keepId, dirty: true },
    });
    if ("education" in newPlan) {
      // IN_SCHOOL
      yield estimateCurrentPlanStudentTaxes();
    } else {
      const isImplemented: boolean = yield select(isCurrentPlanImplemented);
      if (!isImplemented) {
        yield estimateCurrentPlanTaxes();
      }
    }
    yield estimateEmergencyFund();
    yield savePlan({ payload: PLAN_BUILD_STEPS.REVIEW, type: "null" });
    yield put({
      type: actions.GET_PLAN_PROJECTION + START,
      // payload: null
    });
  } catch (error) {
    yield put({ type: actions.SET_CURRENT_PLAN + FAIL, payload: error });
  }
}

function* convertOptimizedToManual() {
  try {
    const plan: Plan = yield select(getCurrentPlan);
    const newPlan = cloneDeep(plan);
    delete newPlan.messages;
    delete newPlan.questionnaire;
    newPlan.name = `Manual ${newPlan.name.slice(9)}`;
    yield put({
      type: actions.REPLACE_CURRENT_PLAN,
      payload: newPlan,
    });
    // save the plan that was stored in redux to the database
    yield savePlan({ payload: null, type: "null" });
    yield fetchPlans();
  } catch (error) {
    yield put({ type: actions.SET_CURRENT_PLAN + FAIL, payload: error });
  }
}

function* fetchPlans(): any {
  try {
    const data: Array<[number, string, boolean, boolean, boolean]> = yield call(
      listPlansApi
    );
    let livePlanId = -1;
    const plans: PlanListRecord[] = data.map(
      (item: [number, string, boolean, boolean, boolean]) => {
        if (item[2]) {
          livePlanId = item[0];
        }
        return {
          id: item[0],
          name: item[1],
          implemented: item[2],
          questionnaire: item[3],
          messages: item[4],
        };
      }
    );
    if (livePlanId >= 0) {
      const livePlan: Plan = yield call(getPlanApi, livePlanId);
      livePlan.id = livePlanId;
      const isCurrentStudent: boolean = yield select(getIsCurrentStudent);
      const firstAllocation: PlanAllocations = livePlan.allocations[0];
      const [{ expenses }, studentTax] = yield all([
        isCurrentStudent
          ? { expenses: 0 }
          : call(calculateEmergencyExpensesApi, livePlan),
        isCurrentStudent
          ? call(
              estimateStudentTaxes,
              livePlan.education || [],
              firstAllocation
            )
          : {},
      ]);
      yield put({
        type: actions.LOAD_LIVE_PLAN + SUCCESS,
        payload: {
          plan: livePlan,
          emergencyFund: expenses,
          studentTax,
          allocations: livePlan.allocations,
        },
      });
    }
    yield put({ type: actions.FETCH_PLANS + SUCCESS, payload: plans });
  } catch (error) {
    yield put({ type: actions.FETCH_PLANS + FAIL, payload: error });
  }
}

function* savePlan({ payload }: FbAction<PLAN_BUILD_STEPS | null>) {
  try {
    const dirty: boolean = yield select(getPlanIsDirty);
    if (dirty) {
      const currentPlan: Plan = yield select(getCurrentPlan);
      let id = currentPlan.id || 0;
      if (currentPlan.id && currentPlan.id > 0) {
        yield call(updatePlanApi, currentPlan.id, currentPlan);
      } else {
        const { headers } = yield call(addPlanApi, currentPlan);
        if (headers["location"]) {
          // example location: /api/plan/plan.php?id=224
          id = parseInt(
            new URL(
              headers["location"],
              window.location.origin
            ).searchParams.get("id") || ""
          );
          yield put(actions.updateCurrentPlan({ id: id }));
        } else {
          yield* fetchPlans();
        }
      }
      yield put({ type: actions.SAVE_PLAN + SUCCESS, payload: id });
      yield estimateEmergencyFund();
    }
    if (payload) {
      yield put(actions.setBuildStep(payload));
    }
    // yield estimateTaxes();
    // yield getLiabilities();
  } catch (error) {
    yield put({ type: actions.SAVE_PLAN + FAIL, payload: error });
  }
}

function* updateCashflowsAndSavePlan(
  action: FbAction<PLAN_BUILD_STEPS | null>
) {
  const planImplemented: boolean = yield select(getHasPlan);
  if (planImplemented) {
    yield savePlan(action);
    return;
  }
  const dirty: boolean = yield select(getPlanIsDirty);
  if (dirty) {
    const rawIncomes: Earning[] = yield select(getRawIncomes);
    const rawExpenses: Expense[] = yield select(getRawExpenses);
    const rawRisks: Expense[] = yield select(getRawRisks);
    const rawCashflows: Cashflow[] = yield select(getRawCashflows);
    const updateActions = makeCashflowUpdates(
      rawIncomes,
      rawExpenses,
      rawRisks,
      rawCashflows
    );
    yield all(updateActions.map((a) => put(a)));
  }
  yield savePlan(action);
}

function* estimateCurrentPlanTaxes(): any {
  try {
    const currentPlanIsImplemented = yield select(isCurrentPlanImplemented);
    const plan: Plan = yield select(getCurrentPlan);
    if (currentPlanIsImplemented) {
      yield put(fetchTaxes(plan));
    } else {
      const firstAllocation: PlanAllocations = plan.allocations[0];
      const { payment } = yield call(
        estimateTaxesApi,
        plan.incomes,
        firstAllocation,
        false
      );
      yield put({ type: actions.ESTIMATE_TAXES + SUCCESS, payload: payment });
    }
  } catch (error) {
    yield put({ type: actions.ESTIMATE_TAXES + FAIL, payload: error });
  }
}

async function estimateStudentTaxes(
  funding: EducationFunding[],
  firstAllocation: PlanAllocations
) {
  const incomes = funding.filter((item) => !!item.earning);
  const earningsByYear = groupBy(incomes, "date");
  const taxesByYear: any = {};
  for (const year in earningsByYear) {
    const { payment } = await estimateTaxesApi(
      earningsByYear[year] as Earning[],
      firstAllocation,
      true
    );
    taxesByYear[year] = payment;
  }
  return taxesByYear;
}

function* estimateCurrentPlanStudentTaxes(): any {
  try {
    const plan: Plan = yield select(getCurrentPlan);
    const firstAllocation: PlanAllocations = plan.allocations[0];
    const taxes = yield call(
      estimateStudentTaxes,
      plan.education || [],
      firstAllocation
    );
    yield put({
      type: actions.ESTIMATE_STUDENT_TAXES + SUCCESS,
      payload: taxes,
    });
  } catch (error) {
    yield put({ type: actions.ESTIMATE_STUDENT_TAXES + FAIL, payload: error });
  }
}

function* estimateLivePlanStudentTaxes(): any {
  try {
    const plan: Plan = yield select(getLivePlan);
    const firstAllocation: PlanAllocations = plan.allocations[0];
    const taxes = yield call(
      estimateStudentTaxes,
      plan.education || [],
      firstAllocation
    );
    yield put({
      type: actions.ESTIMATE_STUDENT_TAXES + SUCCESS,
      payload: taxes,
    });
  } catch (error) {
    yield put({ type: actions.ESTIMATE_STUDENT_TAXES + FAIL, payload: error });
  }
}

function* estimateEmergencyFund(): any {
  try {
    const currentPlan: Plan = yield select(getCurrentPlan);
    const plan = { ...currentPlan };
    const isLive = yield select(isCurrentPlanImplemented);
    if (isLive) {
      const liveCashflows: Cashflow[] = yield select(getRawCashflows);
      const liveIncomes = liveCashflows
        .filter((item) => !!INCOME_TYPES[item.type])
        .map((item) => {
          const result = {
            type: item.type,
            earning: item.amount || 0,
            who: item.whose === "spouse" ? "spouse" : ("applicant" as Who),
          };
          switch (item.type) {
            case "other_income":
              result.type = "other";
              break;
            case "rental_income":
              result.type = "rent";
              break;
            default:
              break;
          }
          return result;
        });
      const liveExpenses = liveCashflows
        .filter((item) => item.type in EXPENSE_TYPES)
        .map((item) => ({
          type: item.type === "other_expense" ? "other" : item.type,
          payment: item.amount || 0,
          who: item.whose === "spouse" ? "spouse" : "applicant",
        }));
      const liveRisks = liveCashflows
        .filter((item) => item.type in RISK_MANAGEMENT_TYPES)
        .map((item) => ({
          type: item.type,
          payment: item.amount || 0,
          who: item.whose === "spouse" ? "spouse" : "applicant",
        }));
      plan.incomes = liveIncomes;
      plan.expenses = liveExpenses;
      plan.risks = liveRisks;
    }
    const { expenses } = yield call(calculateEmergencyExpensesApi, plan);
    yield put({
      type: actions.ESTIMATE_EMERGENCY_FUND + SUCCESS,
      payload: expenses,
    });
  } catch (error) {
    yield put({ type: actions.ESTIMATE_EMERGENCY_FUND + FAIL, payload: error });
  }
}

function* getLiabilities({
  payload,
}: FbAction<actions.GetLiabilitiesPayload>): any {
  try {
    const [liabilities, studentLoan] = yield all([
      call(getLiabilitiesApi),
      call(getStudentLoanLiabilitiesApi, payload),
    ]);
    liabilities.min = { ...liabilities.min, ...studentLoan.min };
    yield estimateEmergencyFund();
    yield put({
      type: actions.GET_LIABILITIES + SUCCESS,
      payload: liabilities,
    });
  } catch (error) {
    yield put({ type: actions.GET_LIABILITIES + FAIL, payload: error });
  }
}

function* deletePlan({ payload }: FbAction<number>) {
  try {
    yield call(deletePlanApi, payload);
    yield put({ type: actions.DELETE_PLAN + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.DELETE_PLAN + FAIL, payload: error });
  }
}

function* loadComparisonPlan({ payload }: FbAction<number>): any {
  try {
    const planItems: PlanListRecord[] = yield select(getPlans);
    const selectedPlan = planItems[payload];
    const planId = selectedPlan.id;
    const plan = yield call(getPlanApi, planId);
    const isCurrentStudent = yield select(getIsCurrentStudent);
    const firstAllocation = plan.allocations[0];
    const [taxResult, studentTax, emergencyFund] = yield all([
      isCurrentStudent
        ? { payment: 0 }
        : call(
            estimateTaxesApi,
            plan.incomes,
            firstAllocation,
            isCurrentStudent
          ),
      isCurrentStudent
        ? call(estimateStudentTaxes, plan.education || [], firstAllocation)
        : {},
      isCurrentStudent
        ? { expenses: 0 }
        : call(calculateEmergencyExpensesApi, plan),
    ]);
    yield put({
      type: actions.LOAD_COMPARISON_PLAN + SUCCESS,
      payload: {
        plan,
        tax: taxResult.payment,
        emergencyExpenses: emergencyFund.expenses,
        studentTax,
      },
    });
  } catch (error) {
    yield put({ type: actions.LOAD_COMPARISON_PLAN + FAIL, payload: error });
  }
}

function* implementPlan({ payload }: FbAction<number>) {
  try {
    let plan: Plan = yield select(getCurrentPlan);
    if (plan.id !== payload) {
      plan = yield select(getComparePlan);
    }
    yield call(implementPlanApi, payload);
    yield put(loadDashboardData());
    yield put({ type: actions.IMPLEMENT_PLAN + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.IMPLEMENT_PLAN + FAIL, payload: error });
  }
}

function* getPlanProjection() {
  try {
    const plan: Plan = yield select(getCurrentPlan);
    const apiCalls = [
      call<any>(getPlanProjectionApi, plan.id),
      call<any>(getPlanProjectionApi, plan.id, true),
    ];
    const [planProjection, minimalProjection]: any[] = yield all(apiCalls);
    yield put({
      type: actions.GET_PLAN_PROJECTION + SUCCESS,
      payload: { planProjection, minimalProjection },
    });
  } catch (error) {
    yield put({ type: actions.GET_PLAN_PROJECTION + FAIL, payload: error });
  }
}

function* getSavedPlanProjections() {
  try {
    const plans: PlanListRecord[] = yield select(getPlans);
    const apiCalls = plans.map((item) => {
      if (item.implemented) {
        return Promise.resolve();
      }
      return call(getPlanProjectionApi, item.id);
    });
    const projections: PlanProjection[] = yield all(apiCalls);
    yield put({
      type: actions.GET_SAVED_PLAN_PROJECTIONS + SUCCESS,
      payload: projections,
    });
  } catch (error) {
    yield put({
      type: actions.GET_SAVED_PLAN_PROJECTIONS + FAIL,
      payload: error,
    });
  }
}

function* getLivePlanProjection({
  payload: getMinimal,
}: FbAction<boolean>): any {
  try {
    const liveProjection = yield call(getLivePlanProjectionApi);
    let minimalProjection = null;
    if (getMinimal) {
      const plan: Plan = yield select(getCurrentPlan);
      minimalProjection = yield call<any>(getPlanProjectionApi, plan.id, true);
    }
    yield put({
      type: actions.GET_LIVE_PLAN_PROJECTION + SUCCESS,
      payload: {
        liveProjection,
        minimalProjection,
      },
    });
  } catch (error) {
    yield put({
      type: actions.GET_LIVE_PLAN_PROJECTION + FAIL,
      payload: error,
    });
  }
}

export function* planBuildSagas() {
  yield all([
    takeLatest(actions.SET_CURRENT_PLAN + START, loadPlan),
    takeLatest(actions.SET_OPTIMIZED_PLAN + START, loadOptimizedPlan),
    takeLatest(
      actions.UPDATE_OPTIMIZED_TO_MANUAL + START,
      convertOptimizedToManual
    ),
    takeLatest(actions.FETCH_PLANS + START, fetchPlans),
    takeEvery(actions.SAVE_PLAN + START, savePlan),
    takeEvery(actions.SAVE_PLAN_AND_CASHFLOWS, updateCashflowsAndSavePlan),
    takeLatest(actions.ESTIMATE_TAXES + START, estimateCurrentPlanTaxes),
    takeLatest(
      actions.ESTIMATE_STUDENT_TAXES + START,
      estimateCurrentPlanStudentTaxes
    ),
    takeLatest(
      actions.ESTIMATE_LIVE_STUDENT_TAXES + START,
      estimateLivePlanStudentTaxes
    ),
    takeLatest(actions.ESTIMATE_EMERGENCY_FUND + START, estimateEmergencyFund),
    takeLatest(actions.GET_LIABILITIES + START, getLiabilities),
    takeEvery(actions.DELETE_PLAN + START, deletePlan),
    takeLatest(actions.IMPLEMENT_PLAN + START, implementPlan),
    takeLatest(actions.LOAD_COMPARISON_PLAN + START, loadComparisonPlan),
    takeLatest(actions.GET_PLAN_PROJECTION + START, getPlanProjection),
    takeLatest(actions.GET_LIVE_PLAN_PROJECTION + START, getLivePlanProjection),
    takeLatest(
      actions.GET_SAVED_PLAN_PROJECTIONS + START,
      getSavedPlanProjections
    ),
  ]);
}
