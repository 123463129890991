import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";

import {
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { Alert } from "@material-ui/lab";

import TextField from "src/components/TextField";
import Checkbox from "src/components/Checkbox";
import Button from "src/components/Button";
import Icon from "src/components/Icon";

import { signup } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink, validateEmail, validatePassword } from "src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

interface MyFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn, loading, error } = useSelector(loginStateSelector);
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    if (
      values.firstName !== "" &&
      values.lastName !== "" &&
      validateEmail(values.email) &&
      validatePassword(values.password) &&
      !loading
    ) {
      dispatch(signup({ ...values }));
      // dispatch(setVideo("post_signup"));
    }
  };

  const toggleAcceptedPolicy = () => setAcceptedPolicy((current) => !current);
  const privacyPolicyLink = (
    <a href="https://www.fitbux.com/privacy-policy/" target="_blank">
      Privacy Policy
    </a>
  );
  const termsOfUseLink = (
    <a href="https://www.fitbux.com/terms-of-use/" target="_blank">
      Terms of Use
    </a>
  );
  const disclosureConsentLink = (
    <a
      href="https://www.fitbux.com/statement-of-electronic-disclosures/"
      target="_blank"
    >
      consent to Electronic Disclosures
    </a>
  );
  const displayError = error || passwordError || emailError;
  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{
          password: "",
          email: "",
          firstName: "",
          lastName: "",
        }}
        onSubmit={onFormikSubmit}
        render={(formikBag: FormikProps<MyFormValues>) => {
          const ready =
            acceptedPolicy &&
            formikBag.values.firstName &&
            formikBag.values.lastName &&
            formikBag.values.password;
          return (
            <form className={classes.form} onSubmit={formikBag.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                placeholder="First"
                autoFocus
                onChange={formikBag.handleChange}
                onBlur={formikBag.handleBlur}
                value={formikBag.values.firstName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon iconName="fb-user" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                placeholder="Last"
                autoFocus
                onChange={formikBag.handleChange}
                onBlur={formikBag.handleBlur}
                value={formikBag.values.lastName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon iconName="fb-user " />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={!!emailError}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                placeholder="Email"
                onFocus={() => setEmailError("")}
                onChange={formikBag.handleChange}
                value={formikBag.values.email}
                onBlur={(...args) => {
                  formikBag.handleBlur(...args);
                  if (!validateEmail(formikBag.values.email)) {
                    setEmailError("Please enter a valid email address.");
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon iconName="fb-at" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={!!passwordError}
                name="password"
                label="Password"
                type="password"
                id="password"
                placeholder="Password"
                autoComplete="current-password"
                onChange={formikBag.handleChange}
                onFocus={() => setPasswordError("")}
                onBlur={(...args) => {
                  formikBag.handleBlur(...args);
                  if (!validatePassword(formikBag.values.password)) {
                    setPasswordError(
                      "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one numeral."
                    );
                  }
                }}
                value={formikBag.values.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon iconName="fb-password" />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <Typography variant="body1">
                    I acknowledge and agree to FitBUX's {privacyPolicyLink},{" "}
                    {termsOfUseLink}, and {disclosureConsentLink}.
                  </Typography>
                }
                checked={acceptedPolicy}
                onChange={toggleAcceptedPolicy}
              />
              {displayError && !loading && (
                <Alert severity="error">{displayError}</Alert>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                fbColor="primary"
                className={classes.submit}
                disabled={!ready}
              >
                {!loading && "Sign Up"}
                {loading && (
                  <CircularProgress color="inherit" className="w-6 h-6" />
                )}
              </Button>
              <Grid container>
                <Grid item className="text-center w-full">
                  <AdapterLink
                    to="/login"
                    variant="body2"
                    className="text-gray-3"
                  >
                    Sign In Instead
                  </AdapterLink>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Container>
  );
};

export default Register;
