export const getDateString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

export const thirtyDaysAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date;
};

export const ninetyDaysAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 90);
  return date;
};

export const oneYearAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 365);
  return date;
};
