import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";

import colors from "src/theme/colors";

import Icon from "src/components/Icon";
import Button from "src/components/Button";

import { getCouponsApi, getMoneySchoolPlansApi } from "src/apiService";

import { Coupon, SubscriptionPayload } from "src/interfaces";
import {
  selectPurchasePlans,
  selectStripeLoading,
} from "src/store/stripe/selector";
import {
  createSubscription,
  getAllPurchaseData,
} from "src/store/stripe/actions";
import {
  getIsCurrentStudent,
  getIsSubscribed,
} from "src/store/system/selector";
import { formatDollarsAndCents } from "src/utils";
import { TapConverted } from "src/tapfiliate";

import { implementPlan } from "src/store/planBuild/actions";
import { getPopupPlan } from "src/store/dashboard/selector";

interface IFormValues extends SubscriptionPayload {
  association?: string;
  name?: string;
}

const StripeForm = ({
  moneySchool,
  onClose,
}: {
  moneySchool?: boolean;
  onClose?: VoidFunction;
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const loading = useSelector(selectStripeLoading);

  const plans = useSelector(selectPurchasePlans) || [];
  const isSubscribed = useSelector(getIsSubscribed);
  const isCurrentStudent = useSelector(getIsCurrentStudent);

  const APTA_PROMO_CODE = isCurrentStudent ? "APTASTUDENT" : "APTA";

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [validPromo, setValidPromo] = useState<boolean | undefined>();
  const [checkingPromo, setCheckingPromo] = useState<boolean | undefined>();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [cardError, setCardError] = useState<StripeError>();
  const [values, setValues] = useState<IFormValues>({});
  const [moneySchoolPlans, setMoneySchoolPlans] = useState([]);
  const [promoType, setPromoType] = useState("");

  const popupPlan: number | null = useSelector(getPopupPlan);

  useEffect(() => {
    if (onClose && isSubscribed) {
      if (popupPlan) {
        // dispatch(setTutorial("post_subscription"));
        // dispatch(setVideo("post_subscription"));
        dispatch(implementPlan(popupPlan));
      }
      onClose();
    }
  }, [onClose, isSubscribed]);

  useEffect(() => {
    if (loading) return;
    if (!moneySchool) {
      if (!plans || !plans.length) {
        dispatch(getAllPurchaseData());
      } else if (!values.plan) {
        setValues({
          ...values,
          plan: plans[0].id,
        });
      }
    }
    if (!values.promo) {
      setValidPromo(undefined);
    } else {
      setValidPromo(coupons && coupons.length ? true : false);
    }
  }, [loading, dispatch, moneySchool]);

  useEffect(() => {
    if (moneySchool && !moneySchoolPlans.length) {
      getMoneySchoolPlansApi().then((results) => {
        if (results && results.length) {
          setMoneySchoolPlans(results);
          setValues((v) => ({ ...v, plan: results[0].id }));
        }
      });
    }
  }, [moneySchool, moneySchoolPlans.length]);

  const MAX_DELAY_LIMIT = 1200;

  const handleChangeValues = (prop: keyof IFormValues) => (
    event: React.ChangeEvent<any>
  ) => {
    const value = event.target.value;
    if (prop === "promo") {
      setValidPromo(undefined);
      if (timer) {
        clearTimeout(timer);
        setTimer(undefined);
      }
      if (value) {
        // record promo being input
        setPromoType(prop);
        setCheckingPromo(true);
        const newTimer = setTimeout(() => {
          setTimer(undefined);
          getCouponsApi(value)
            .then((result) => {
              setCheckingPromo(false);
              setCoupons(result);
              if (!result.length) {
                setValidPromo(false);
              } else {
                setValidPromo(true);
              }
            })
            .catch(() => setCoupons([]));
        }, MAX_DELAY_LIMIT);
        setTimer(newTimer);
      } else {
        setPromoType("");
        setCoupons([]);
      }
    } else if (prop === "association") {
      // record association being set
      setPromoType(prop);
      const checkValue = value ? APTA_PROMO_CODE : "";
      getCouponsApi(checkValue)
        .then((result) => {
          setCheckingPromo(false);
          setCoupons(result);
          if (!result.length) {
            setValidPromo(false);
            // remove promo
            setPromoType("");
          } else {
            setValidPromo(true);
          }
        })
        .catch(() => setCoupons([]));
    }
    setValues({
      ...values,
      [prop]: value,
    });
  };

  const handleBlur = () => null;
  const handleChange = () => null;
  const handleFocus = () => null;
  const handleReady = () => null;

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setCardError(undefined);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }

    const { error, token } = await stripe.createToken(cardElement);
    if (error || !token) {
      setCardError(error);
    } else {
      TapConverted(token.id, isCurrentStudent);
      const payload: SubscriptionPayload = {
        token: token.id,
        plan: values.plan,
        promo: "",
      };
      if ((values.promo || values.association === "APTA") && coupons?.length) {
        payload.promo = coupons[0].id;
      }
      dispatch(createSubscription(payload));
      // dispatch(setTutorial("post_subscription"));
      // dispatch(setVideo("post_subscription"));
      if (popupPlan) {
        dispatch(implementPlan(popupPlan));
      }
    }
  };

  const renderPromoAdornment = () => {
    if (!checkingPromo && validPromo === undefined) {
      return undefined;
    } else if (!values.association && !values.promo) {
      return undefined;
    }
    return (
      <InputAdornment position="start">
        {checkingPromo && !validPromo && <CircularProgress size={21} />}
        {(!checkingPromo || validPromo) && (
          <Icon
            iconName={validPromo ? "fb-checkmark" : "fb-close"}
            htmlColor={validPromo ? colors.success : colors.error}
          />
        )}
      </InputAdornment>
    );
  };

  const renderSpecialDiscount = () => {
    const specialSelect = (
      <Select
        variant="outlined"
        onChange={handleChangeValues("association")}
        placeholder="Select..."
        value={values.association || ""}
        fullWidth
        disabled={promoType === "promo"}
      >
        <MenuItem value="">(None)</MenuItem>
        <MenuItem value="APTA">APTA</MenuItem>
      </Select>
    );
    if (promoType === "promo") {
      return (
        <Tooltip
          placement="left"
          title="If you are a member of an association partnered with FitBUX, please contact us and we can reflect your membership association discount after your promotion code expires."
        >
          {specialSelect}
        </Tooltip>
      );
    } else {
      return specialSelect;
    }
  };

  const couponAmount = validPromo ? coupons?.[0]?.amount || 0 : 0;

  const getSubscriptionButton = () => {
    const percentOff = coupons?.[0]?.percent_off || 0;
    if (
      coupons &&
      coupons[0] &&
      coupons[0].duration_in_months &&
      coupons[0].percent_off
    ) {
      return (moneySchool ? moneySchoolPlans : plans).map((plan: any) => (
        <ToggleButton value={plan.id} key={plan.id} className={styles.toggle}>
          <Box
            className={`${styles.toggleBody} ${
              values.plan === plan.id ? "active" : ""
            }`}
          >
            <Box className="flex justify-end">
              <Typography className={styles.toggleTitle}>
                ${plan.amount * ((100 - percentOff) / 100)} For First{" "}
                {coupons[0].duration_in_months} Months
              </Typography>
            </Box>

            {!moneySchool && (
              <Box className="flex justify-end">
                <Typography className={styles.toggleDescription}>
                  ${plan.amount}/{plan.interval === "year" ? "yr" : "mo"}{" "}
                  thereafter
                </Typography>
              </Box>
            )}
          </Box>
        </ToggleButton>
      ));
    }
    return (moneySchool ? moneySchoolPlans : plans).map((plan: any) => (
      <ToggleButton value={plan.id} key={plan.id} className={styles.toggle}>
        <Box
          className={`${styles.toggleBody} ${
            values.plan === plan.id ? "active" : ""
          }`}
        >
          <Box className="flex justify-between">
            <Typography className={styles.toggleTitle}>
              {moneySchool ? "FitBUX Money School" : plan.name}
            </Typography>
            <Typography className={styles.toggleTitle}>
              {formatDollarsAndCents(
                couponAmount
                  ? plan.amount - couponAmount
                  : plan.amount * ((100 - percentOff) / 100)
              )}
            </Typography>
          </Box>
          {!moneySchool && (
            <Box className="flex justify-btween">
              <Typography className={styles.toggleDescription}>
                {plan.months} Month(s)
              </Typography>
            </Box>
          )}
        </Box>
      </ToggleButton>
    ));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} className="mt-5">
        <Grid item xs={12}>
          <FormLabel component="legend" className={styles.label}>
            Subscription
          </FormLabel>
          <ToggleButtonGroup
            value={values.plan || ""}
            exclusive
            className="w-full"
            onChange={(e, v) => {
              if (v) {
                setValues({
                  ...values,
                  plan: v,
                });
              }
            }}
            aria-label="text alignment"
          >
            {getSubscriptionButton()}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" className={styles.label}>
            Cardholder Name
          </FormLabel>
          <TextField
            variant="outlined"
            onChange={handleChangeValues("name")}
            type="text"
            required
            placeholder="Cardholder Name"
            value={values.name || ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" className={styles.label}>
            Card Number
          </FormLabel>
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...cardInputProps}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="legend" className={styles.label}>
            Expire Date
          </FormLabel>
          <CardExpiryElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...cardInputProps}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="legend" className={styles.label}>
            CVC
          </FormLabel>
          <CardCvcElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...cardInputProps}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" className={styles.label}>
            Promo Code
          </FormLabel>
          <TextField
            error={validPromo === false && !values.association}
            disabled={!!values.association}
            // helperText={validPromo === false && !values.association ? "Invalid promo code!" : ""}
            variant="outlined"
            onChange={handleChangeValues("promo")}
            type="text"
            InputProps={{ startAdornment: renderPromoAdornment() }}
            placeholder="Promo Code"
            value={values.association ? "(APTA member)" : values.promo || ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend" className={styles.label}>
            If you are a member of one of the following associations, select it
            below. You'll get a special discount!
          </FormLabel>
          {renderSpecialDiscount()}
        </Grid>
        {cardError && cardError.message && (
          <Grid item xs={12} className="mt-5">
            <Alert severity="error">{cardError.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            fbColor="primary"
            disabled={loading || validPromo === false || timer !== undefined}
            type="submit"
            variant="contained"
            className="w-full my-5"
          >
            Subscribe
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default StripeForm;

const useStyles = makeStyles({
  subtitle: {
    fontSize: 14,
    marginBottom: 30,
    marginTop: 10,
  },
  item: {
    paddingLeft: 0,
  },
  itemIcon: {
    color: colors.brandingBlue1,
    marginRight: -10,
    width: 30,
  },
  itemText: {
    "&>span": {
      fontSize: 14,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 10,
  },
  toggle: {
    border: `none`,
    width: "33%",
    padding: 0,
    margin: `5px 10px`,
    borderRadius: `10px !important`,
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  toggleBody: {
    border: `1px solid ${colors.blueGray4}`,
    width: "100%",
    height: 80,
    borderRadius: 10,
    padding: `15px 10px`,
    "&.active": {
      border: `1px solid ${colors.brandingBlue1}`,
      "& p": {
        color: colors.brandingBlue1,
      },
    },
  },
  toggleTitle: {
    textTransform: "initial",
    fontSize: 14,
  },
  toggleDescription: {
    textTransform: "initial",
    textAlign: "left",
    fontSize: 10,
  },
});

const cardInputProps = {
  style: {
    base: {
      fontSize: "14px",
      color: "#BBC3D1",
      "::placeholder": {
        color: "#BBC3D1",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
