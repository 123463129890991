import { createAction } from "redux-actions";
import { Profile, User } from "../../interfaces";
import { START, SUCCESS } from "../common";

export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_USER = "FETCH_PROFILE_SOLO";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_IMPLEMENTED_PLAN = "DELETE_IMPLEMENTED_PLAN";
export const SIGNUP = "SIGNUP";
export const ADD_SPOUSE = "ADD_SPOUSE";
export const SUSPEND_UPDATES = "SUSPEND_UPDATES";
export const RESUME_UPDATES = "RESUME_UPDATES";
export const SET_GRADUATED_MODE = "SET_GRADUATED_MODE";
export const SET_PREVIOUS_ROUTE = "SET_PREVIOUS_ROUTE";
export const OPEN_UPGRADE_DIALOG = "OPEN_UPGRADE_DIALOG";
export const OPEN_MONEY_SCHOOL_DIALOG = "OPEN_MONEY_SCHOOL_DIALOG";
export const CLOSE_UPGRADE_DIALOG = "CLOSE_UPGRADE_DIALOG";
export const LOG_OUT = "LOG_OUT";

export interface LoginPayload {
  email: string;
  password: string;
}

export interface FetchLoginSuccessPayload {
  token: string;
  user: User;
}

export interface SignupPayload {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface AddSpousePayload {
  spouse?: { email: string; password: string };
  newSpouse?: Partial<Profile>;
}

export const fetchLogin = createAction<LoginPayload>(FETCH_LOGIN + START);
export const fetchUser = createAction(FETCH_USER + START);
export const updateUser = createAction<{
  first_name?: string;
  last_name?: string;
  email?: string;
}>(UPDATE_USER + SUCCESS);
export const deleteImplementedPlan = createAction(DELETE_IMPLEMENTED_PLAN);
export const signup = createAction<SignupPayload>(SIGNUP + START);
export const addSpouse = createAction<AddSpousePayload>(ADD_SPOUSE + START);
export const addSpouseSuccess = createAction<{ linked: boolean }>(
  ADD_SPOUSE + SUCCESS
);
export const suspendUpdates = createAction(SUSPEND_UPDATES);
export const resumeUpdates = createAction(RESUME_UPDATES);
export const setGraduatedMode = createAction(SET_GRADUATED_MODE);
export const setPreviousRoute = createAction(SET_PREVIOUS_ROUTE);
export const openUpgradeDialog = createAction(OPEN_UPGRADE_DIALOG);
export const openMoneySchoolDialog = createAction(OPEN_MONEY_SCHOOL_DIALOG);
export const closeUpgradeDialog = createAction(CLOSE_UPGRADE_DIALOG);
export const logOut = createAction(LOG_OUT);
