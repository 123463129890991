import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import CalculatorLayout from "src/layouts/CalculatorLayout";
import { getEmergencyFundAmountAPI } from "src/apiService";
import { getRouterState } from "src/store/router/selector";
import { DISCLAIMER, STEPS } from "./CONSTANTS";
import LandingStep from "./Steps/LandingStep";
import ResultStep from "./Steps/ResultStep";

const EmergencyFundAmount = () => {
  const dispatch = useDispatch();
  const router = useSelector(getRouterState);
  const [step, setStep] = React.useState(STEPS.LANDING_SCREEN);

  const [result, setResult] = React.useState<{
    months_expenses_to_save: number;
    monthly_expenses: number;
  }>({
    monthly_expenses: 0,
    months_expenses_to_save: 0,
  });

  const fetchResult = async () => {
    const data = await getEmergencyFundAmountAPI();
    setResult(data);
  };

  React.useEffect(() => {
    fetchResult();
  }, []);

  const handleNextClick = () => {
    setStep(STEPS.RESULT_SCREEN);
  };

  const handleBack = () => {
    dispatch(
      push(
        router.location.query?.return === "investments"
          ? "/investments"
          : "/otheritems"
      )
    );
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.LANDING_SCREEN:
        return <LandingStep onNext={handleNextClick} />;
      case STEPS.RESULT_SCREEN:
        return (
          <ResultStep
            monthly_expenses={result?.monthly_expenses}
            months_expenses_to_save={result?.months_expenses_to_save}
          />
        );
    }
  };

  return (
    <CalculatorLayout
      title="Emergency Fund Optimization"
      onBackClick={handleBack}
      disclaimer={DISCLAIMER}
    >
      {renderStep()}
    </CalculatorLayout>
  );
};

export default EmergencyFundAmount;
