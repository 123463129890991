import React from "react";

import { Box, IconButton, Tooltip } from "@material-ui/core";

import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import StatusIndicator from "src/components/StatusIndicator";

export const LinkAccountDialog = ({
  isOpen,
  openPlaid,
  onClose,
  ready,
}: any) => (
  <CustomDialog
    title="Link Your Financial Accounts To FitBUX"
    size="sm"
    isOpen={isOpen}
    onClose={onClose}
  >
    <Box className="py-3">
      <p>
        Once you link your account it may take a few moments for your data to
        appear.
      </p>
      <p>
        An alert will appear on the upper right corner of your dashboard once a
        linked account is ready to confirm. The alert will look like the
        following icon:
      </p>
      <Box style={{ textAlign: "center" }}>
        <Tooltip title="Alerts">
          <IconButton color="inherit" className="p-1">
            <Icon iconName="fb-notification" />
            <Box style={{ position: "absolute", right: -5, bottom: 3 }}>
              <StatusIndicator fill="#B81414" />
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
      <p>
        Note: Not all financial institutions have the technology to link
        accounts. If yours doesn't, then you can manually add the account to
        FitBUX
      </p>
    </Box>
    <Box className="w-full text-right mt-5">
      <Button fbColor="primary" disabled={!ready} onClick={openPlaid}>
        <span>Continue</span>
      </Button>
    </Box>
  </CustomDialog>
);

export default LinkAccountDialog;
