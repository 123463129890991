import * as React from "react";
import { makeStyles } from "@material-ui/core";

import { headerStyles } from "src/theme";

import { Content, GeneralPageContentProps } from "./Content";

const useStyles = makeStyles(headerStyles);

export const OtherItems = ({ data }: GeneralPageContentProps) => {
  const classes = useStyles();
  return (
    <main className={classes.mainContent}>
      <Content data={data} />
    </main>
  );
};

export default OtherItems;
