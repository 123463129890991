import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { forOwn, pick, range } from "lodash";

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  FormHelperText,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import Button from "src/components/Button";
import Radio from "src/components/Radio";

import {
  addSpouseApi,
  authorizeSpouseApi,
  updateHouseholdApi,
  updateProfileApi,
} from "src/apiService";
import { DAYS_IN_MONTH, MONTHS } from "src/constants";
import { getAccounts } from "src/store/account/actions";
import { fetchCashflowItems } from "src/store/cashflow/actions";
import {
  fetchSpouseProfile,
  setProfileStep,
  updateHouseholdSuccess,
  updatePersonalInfo,
  UpdatePersonalInfoPayload,
  updatePersonalInfoSuccess,
} from "src/store/profileBuild/actions";
import { addSpouseSuccess } from "src/store/system/actions";
import {
  getHousehold,
  getPersonalInfo,
  getSpousePersonalInfo,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  PERSONAL_INFO_KEYS,
  HOUSEHOLD_KEYS,
} from "src/store/profileBuild/selectorKeys";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import {
  FbAction,
  PersonalInfoSection,
  Gender,
  ViewComponent,
} from "src/interfaces";
import { colors } from "src/theme";
import { formatZip, validateZip } from "src/utils";

interface MyFormValues extends PersonalInfoSection {
  enter_spouse_manually: boolean;
  married: string;
  spouse_is_member: string;
  zip: string;
  has_children: string;
  children_qty: number;
  spouse_email: string;
  spouse_sex: Gender;
  spouse_dob_day: number;
  spouse_dob_month: number;
  spouse_dob_year: number;
  child_1_age: number;
  child_2_age: number;
  child_3_age: number;
  child_4_age: number;
  child_5_age: number;
  spouse_password: string;
}

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const existingProfile = useSelector(getPersonalInfo);
  const spouseProfile = useSelector(getSpousePersonalInfo);
  const isMarried = useSelector(getIsMarried);
  const household = useSelector(getHousehold);
  const [spouseLinked, setSpouseLinked] = useState(false);
  const [spouseLoading, setSpouseLoading] = useState(false);
  const [errors, setErrors] = useState({
    dob_month: false,
    dob_day: false,
    dob_year: false,
    sex: false,
    zip: false,
  });
  const [spouseError, setSpouseError] = useState(false);
  const [formState, setFormState] = useState<MyFormValues>({
    phone_number: "",
    dob_month: 1,
    dob_day: 1,
    dob_year: 1999,
    sex: "o",
    zip: "",
    married: "n",
    has_children: "n",
    children_qty: 0,
    child_1_age: 0,
    child_2_age: 0,
    child_3_age: 0,
    child_4_age: 0,
    child_5_age: 0,
    spouse_is_member: "n",
    spouse_sex: "o",
    spouse_dob_day: 1,
    spouse_dob_month: 1,
    spouse_dob_year: 1999,
    spouse_email: "",
    spouse_password: "",
    enter_spouse_manually: false,
  });

  useEffect(() => {
    setFormState((current) => {
      const foundHousehold = household || {};
      const newFormState = {
        ...current,
        ...foundHousehold,
      };
      if (newFormState.children_qty > 0) {
        newFormState.has_children = "y";
      }
      return newFormState;
    });
  }, [household]);

  useEffect(() => {
    setFormState((current) => {
      const newFormState = {
        ...current,
        spouse_sex: spouseProfile.sex || "o",
        spouse_dob_day: spouseProfile.dob_day || 1,
        spouse_dob_month: spouseProfile.dob_month || 1,
        spouse_dob_year: spouseProfile.dob_year || 1999,
      };
      return newFormState;
    });
  }, [spouseProfile]);

  useEffect(() => {
    setFormState((current) => {
      const newFormState = {
        ...current,
        ...existingProfile,
      };
      return newFormState;
    });
  }, [existingProfile]);

  const linkSpouse = () => {
    setSpouseLoading(true);
    authorizeSpouseApi(formState.spouse_email, formState.spouse_password)
      .then(() => {
        dispatch(addSpouseSuccess({ linked: true }));

        const householdUpdate = pick(formState, HOUSEHOLD_KEYS);
        const profileValues = pick(formState, PERSONAL_INFO_KEYS);
        return updateHouseholdApi(householdUpdate)
          .then(() => {
            dispatch(updateHouseholdSuccess({ update: householdUpdate }));
            return updateProfileApi({
              who: "applicant",
              profile: profileValues,
            } as any);
          })
          .then(() => {
            dispatch(
              updatePersonalInfoSuccess({
                who: "applicant",
                profile: profileValues,
              } as any)
            );
            dispatch(fetchSpouseProfile());
            dispatch(getAccounts());
            dispatch(fetchCashflowItems());
            setSpouseLinked(true);
          });
      })
      .catch(() => {
        setSpouseError(true);
      })
      .finally(() => {
        setSpouseLoading(false);
      });
  };

  const setFormValue = (e: any) => {
    const target = e.target;
    let value = target.value;
    if (target.name === "zip") {
      value = formatZip(value);
    }
    if (target.name.slice(0, 5) === "child") {
      value = Math.floor(+value);
    }
    const update = { ...formState, [target.name]: value };
    if (target.name === "has_children") {
      if (target.value === "y") {
        update.children_qty = 1;
      } else {
        update.children_qty = 0;
        update.child_1_age = 0;
        update.child_2_age = 0;
        update.child_3_age = 0;
        update.child_4_age = 0;
        update.child_5_age = 0;
      }
    }
    setFormState(update);
  };

  const next = () => {
    const profileValues = pick(formState, PERSONAL_INFO_KEYS);
    const actions: Array<FbAction<UpdatePersonalInfoPayload>> = [
      updatePersonalInfo({
        update: profileValues,
        nextPage: PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_1,
      }),
    ];
    let promise: Promise<any> = Promise.resolve();
    if (formState.married === "y" && !isMarried) {
      // if (formState.spouse_is_member === "y") {
      //   const spousePayload: AddSpousePayload = {};
      //   spousePayload.spouse = {
      //     email: formState.spouse_email,
      //     password: formState.spouse_password,
      //   };
      //   promise = authorizeSpouseApi(formState.spouse_email, formState.spouse_password)
      //   .then(() => {
      //     dispatch(addSpouseSuccess({ linked: true }));
      //     dispatch(fetchSpouseProfile());
      //   });
      // } else {
      const spousePayload: UpdatePersonalInfoPayload = {
        who: "spouse",
        update: {
          sex: formState.spouse_sex,
          dob_day: formState.spouse_dob_day,
          dob_month: formState.spouse_dob_month,
          dob_year: formState.spouse_dob_year,
        },
      };
      promise = addSpouseApi().then(() => {
        dispatch(addSpouseSuccess({ linked: false }));
        dispatch(fetchSpouseProfile());
      });
      actions.push(updatePersonalInfo(spousePayload));
    } else if (isMarried) {
      const spousePayload: UpdatePersonalInfoPayload = {
        who: "spouse",
        update: {
          sex: formState.spouse_sex,
          dob_day: formState.spouse_dob_day,
          dob_month: formState.spouse_dob_month,
          dob_year: formState.spouse_dob_year,
        },
      };
      actions.push(updatePersonalInfo(spousePayload));
    }
    const householdUpdate = pick(formState, HOUSEHOLD_KEYS);
    promise
      .then(() => {
        return updateHouseholdApi(householdUpdate);
      })
      .then(() => {
        dispatch(updateHouseholdSuccess({ update: householdUpdate }));
        actions.forEach(dispatch);
      });
  };

  const nowYear = new Date().getFullYear();
  const startYear = nowYear - 70;
  const endYear = nowYear - 16;

  const checkError = (fieldName: keyof MyFormValues) => {
    if (
      !formState[fieldName] ||
      (fieldName === "zip" && !validateZip(formState[fieldName]))
    ) {
      setErrors({ ...errors, [fieldName]: true });
    }
  };
  const clearError = (fieldName: keyof MyFormValues) => {
    setErrors({ ...errors, [fieldName]: false });
  };

  if (!render) {
    return <div />;
  }

  let nextDisabled = false;
  let nextTooltip: string | undefined = undefined;
  forOwn(errors, (value: boolean) => {
    if (value) {
      nextDisabled = true;
      nextTooltip = "Please check your input and complete all required fields.";
    }
  });
  if (!nextDisabled) {
    ["dob_year", "dob_month", "dob_day", "zip", "sex"].forEach((key) => {
      if (!formState[key as keyof MyFormValues]) {
        nextDisabled = true;
        nextTooltip =
          "Please check your input and complete all required fields.";
      }
    });
  }
  if (!isMarried && !spouseLinked && formState.spouse_is_member === "y") {
    nextDisabled = true;
    nextTooltip =
      "If you are married to a current FitBUX member, please finish linking your spouse's account before proceeding.";
  }

  return render({
    component: (
      <Container className="pb-32">
        <Typography component="h1" variant="h1" className="mb-4">
          Personal Info
        </Typography>
        <Typography component="p" className="mb-4">
          Please fill out your profile.
        </Typography>
        <Divider />
        <Box>
          <Box className="block mt-4">
            <FormLabel component="legend" className="mb-4">
              What is your date of birth?
            </FormLabel>
            <Box className="flex">
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="dob_month"
                onChange={setFormValue}
                error={errors.dob_month}
                value={formState.dob_month || ""}
                className="mr-4"
                onBlur={() => checkError("dob_month")}
                onFocus={() => clearError("dob_month")}
              >
                {MONTHS.map((month: string, index: number) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="dob_day"
                onChange={setFormValue}
                error={errors.dob_day}
                value={formState.dob_day || ""}
                className="mr-4"
                onBlur={() => checkError("dob_day")}
                onFocus={() => clearError("dob_day")}
              >
                {range(1, DAYS_IN_MONTH[formState.dob_month] + 1).map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="dob_year"
                onChange={setFormValue}
                error={errors.dob_year}
                value={formState.dob_year || ""}
                onBlur={() => checkError("dob_year")}
                onFocus={() => clearError("dob_year")}
              >
                {range(startYear, endYear + 1)
                  .reverse()
                  .map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            {errors.dob_month && (
              <FormHelperText>Please select a month.</FormHelperText>
            )}
            {errors.dob_day && (
              <FormHelperText>Please select a day.</FormHelperText>
            )}
            {errors.dob_year && (
              <FormHelperText>Please select a year.</FormHelperText>
            )}
          </Box>
          <FormControl
            component="fieldset"
            error={errors.zip}
            className="block mt-6"
          >
            <FormLabel component="legend" className="mb-4">
              What is your zip code?
            </FormLabel>
            <TextField
              variant="outlined"
              placeholder="90210"
              name="zip"
              value={formState.zip}
              error={errors.zip}
              onChange={setFormValue}
              onBlur={() => checkError("zip")}
              onFocus={() => clearError("zip")}
            />
          </FormControl>
          {errors.zip && (
            <FormHelperText>Please enter a valid ZIP code.</FormHelperText>
          )}
          <FormControl
            component="fieldset"
            error={errors.sex}
            className="block mt-6"
          >
            <FormLabel component="legend" className="mb-4">
              What is your gender?
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="sex"
              value={formState.sex}
              className="flex flex-row"
              onChange={setFormValue}
              onBlur={() => checkError("sex")}
              onFocus={() => clearError("sex")}
            >
              <FormControlLabel
                value="m"
                className="pr-4"
                control={<Radio />}
                label="Male"
              />
              <FormControlLabel
                value="f"
                className="pr-4"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="o"
                className="pr-4"
                control={<Radio />}
                label="Other/Prefer not to say"
              />
            </RadioGroup>
            {errors.sex && (
              <FormHelperText>Please select a gender.</FormHelperText>
            )}
          </FormControl>
          {!isMarried && (
            <FormControl component="fieldset" className="block mt-6">
              <FormLabel component="legend" className="mb-4">
                Are you currently married?
              </FormLabel>
              <RadioGroup
                aria-label="married"
                name="married"
                value={formState.married}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<Radio />} label="No" />
                <FormControlLabel value="y" control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          )}
          {!isMarried && formState.married === "y" && (
            <>
              <Typography component="h2" variant="h2" className="mt-6">
                Spouse Info
              </Typography>
              <FormControl component="fieldset" className="mt-4">
                <FormLabel component="legend" className="mb-2">
                  Is your spouse a FitBUX member?
                </FormLabel>
                <RadioGroup
                  aria-label="spouse_is_member"
                  name="spouse_is_member"
                  value={formState.spouse_is_member}
                  className="flex flex-row"
                  onChange={setFormValue}
                >
                  <FormControlLabel value="n" control={<Radio />} label="No" />
                  <FormControlLabel value="y" control={<Radio />} label="Yes" />
                </RadioGroup>
              </FormControl>
              {formState.spouse_is_member === "y" && (
                <>
                  <Box className="block mt-6">
                    <FormLabel component="legend" className="mb-4">
                      Enter your spouse's info to link their account.
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      label="Email Address"
                      placeholder="Email Address"
                      name="spouse_email"
                      error={spouseError}
                      onFocus={() => setSpouseError(false)}
                      onChange={setFormValue}
                    />
                    <TextField
                      className="block mt-2"
                      variant="outlined"
                      label="Password"
                      placeholder="Password"
                      name="spouse_password"
                      error={spouseError}
                      onFocus={() => setSpouseError(false)}
                      helperText={
                        spouseError
                          ? "We failed to link your spouse's account. Please make sure you've entered the correct email address and password, then try again."
                          : undefined
                      }
                      type="password"
                      onChange={setFormValue}
                    />
                  </Box>
                  <Button
                    className="mt-2 mb-6"
                    color="primary"
                    disabled={
                      !formState.spouse_email ||
                      !formState.spouse_password ||
                      spouseLoading
                    }
                    onClick={linkSpouse}
                    type="button"
                  >
                    {spouseLoading ? "Linking..." : "Link spouse account"}
                    {spouseLoading && (
                      <CircularProgress
                        color="inherit"
                        className="ml-4 w-6 h-6"
                      />
                    )}
                  </Button>
                </>
              )}
            </>
          )}
          {spouseLinked && (
            <Typography
              variant="h3"
              className="my-6 has-underline"
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: colors.brandingBlue1,
              }}
            >
              Your spouse's profile has been successfully linked.
            </Typography>
          )}
          {!isMarried &&
            formState.married === "y" &&
            formState.spouse_is_member === "n" && (
              <Typography
                component="p"
                variant="body1"
                className="mb-4 has-underline"
              >
                You can enter your spouse's financial information manually as
                you build your profile.
              </Typography>
            )}
          {(isMarried ||
            (formState.married === "y" &&
              formState.spouse_is_member !== "y")) && (
            <>
              <FormControl component="fieldset" className="block mt-6">
                <FormLabel component="legend" className="mb-4">
                  What is your spouse's date of birth?
                </FormLabel>
                <Box className="flex">
                  <Select
                    variant="outlined"
                    style={{ width: "150px" }}
                    name="spouse_dob_month"
                    onChange={setFormValue}
                    value={formState.spouse_dob_month}
                    className="mr-4"
                  >
                    {MONTHS.map((month: string, index: number) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    variant="outlined"
                    style={{ width: "150px" }}
                    name="spouse_dob_day"
                    onChange={setFormValue}
                    value={formState.spouse_dob_day}
                    className="mr-4"
                  >
                    {range(
                      1,
                      DAYS_IN_MONTH[formState.spouse_dob_month] + 1
                    ).map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    variant="outlined"
                    style={{ width: "150px" }}
                    name="spouse_dob_year"
                    onChange={setFormValue}
                    value={formState.spouse_dob_year}
                  >
                    {range(startYear, endYear + 1)
                      .reverse()
                      .map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </FormControl>
              <FormControl component="fieldset" className="block mt-6">
                <FormLabel component="legend" className="mb-4">
                  What is your spouse's gender?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="spouse_sex"
                  value={formState.spouse_sex}
                  className="flex flex-row"
                  onChange={setFormValue}
                >
                  <FormControlLabel
                    value="m"
                    className="pr-4"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="f"
                    className="pr-4"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="o"
                    className="pr-4"
                    control={<Radio />}
                    label="Other/Prefer not to say"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
          <FormControl component="fieldset" className="block mt-6">
            <FormLabel component="legend" className="mb-4">
              Do you have any children?
            </FormLabel>
            <RadioGroup
              aria-label="has_children"
              name="has_children"
              value={formState.has_children}
              className="flex flex-row"
              onChange={setFormValue}
            >
              <FormControlLabel value="n" control={<Radio />} label="No" />
              <FormControlLabel value="y" control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormControl>
          {formState.has_children === "y" && (
            <>
              <FormControl component="fieldset" className="block mt-6">
                <FormLabel component="legend" className="mb-4">
                  How many children do you have?
                </FormLabel>
                <Select
                  variant="outlined"
                  style={{ width: "150px" }}
                  name="children_qty"
                  onChange={setFormValue}
                  value={formState.children_qty || ""}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5 or more</MenuItem>
                </Select>
              </FormControl>
              <FormControl component="fieldset" className="block mt-6">
                <FormLabel component="legend" className="mb-4">
                  Enter the ages of your children:
                </FormLabel>
                <Grid container spacing={3}>
                  {range(1, formState.children_qty + 1).map((n: number) => (
                    <Grid item xs={3} key={`child-${n}`}>
                      <TextField
                        variant="outlined"
                        placeholder={`Child ${n} age`}
                        name={`child_${n}_age`}
                        value={
                          formState[`child_${n}_age` as keyof MyFormValues] ||
                          ""
                        }
                        onChange={setFormValue}
                        type="number"
                        style={{ borderWidth: "2px", minWidth: 100 }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </>
          )}
        </Box>
      </Container>
    ),
    nextDisabled,
    nextTooltip,
    onPrev: () => dispatch(setProfileStep(PROFILE_BUILD_STEPS.WELCOME_2)),
    onNext: next,
  });
};

export default MainForm;
