import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";

import InvestmentsHeader from "../components/Headers/InvestmentsHeader";
import Help from "../components/Help";
import Tutorial from "../components/Tutorial";
import Video from "../components/Video";

import { investmentsStyles, theme } from "../theme";

const useStyles = makeStyles(investmentsStyles);

export const InvestmentLayout = (props: any) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <InvestmentsHeader
        text={props.headerText}
        goBack={props.goBack}
        extraContent={props.headerContent}
        goBackTarget={props.goBackTarget || "/investments"}
      />
      <Box className={classes.root}>{props.children || ""}</Box>
      <Help />
      <Tutorial />
      <Video />
    </ThemeProvider>
  );
};

export default InvestmentLayout;
