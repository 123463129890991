import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Container, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import { openUpgradeDialog } from "src/store/system/actions";
import { allowPlanBuilder } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  img: {
    maxHeight: 250,
    maxWidth: 250,
    "& img": {
      width: "100%",
    },
  },
  textOverride: {
    fontSize: "15px",
  },
  listItem: {
    "& li::marker": {
      content: 'counter(list-item) ") "',
    },
  },
});

const LandingPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const planBuilder = useSelector(allowPlanBuilder);

  const button = planBuilder ? (
    <Button
      className="mt-12"
      fbColor="primary"
      component={AdapterLink}
      to="/plan-select"
    >
      Build My Plan
    </Button>
  ) : (
    <Button
      className="mt-12"
      fbColor="primary"
      onClick={() => dispatch(openUpgradeDialog())}
    >
      Build My Plan
    </Button>
  );

  return (
    <Container className="py-48 flex justify-center">
      <Box className=" w-3/4">
        <Typography variant="h2" component="h2" className="mb-3">
          Build, Simulate, and Implement Your Financial Plan
        </Typography>
        <Typography variant="body2">
          There are three steps to implement your plan and start crushing your
          goals:
        </Typography>
        <ol className={classes.listItem}>
          <li>Set up your plan</li>
          <li>Simulate and review your plan</li>
          <li>Implement your plan and track your progress</li>
        </ol>
        <Typography variant="body2">
          Let's start making money complement your life style, not dictate it!
        </Typography>
        {button}
      </Box>
      <Box className={classes.img}>
        <img src="/assets/images/png/fb-map.png" alt="map" />
      </Box>
    </Container>
  );
};

export default LandingPage;
