import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";

import MainForm from "./MainForm";
import GetStarted from "./GetStarted";

const incomeAndExpensesView = {
  [PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS_INTRO]: GetStarted,
  [PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS]: MainForm,
};

export default incomeAndExpensesView;
