import React from "react";
import { useDispatch } from "react-redux";
import Slider from "src/components/Slider";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { ViewComponent } from "src/interfaces/viewComponent.interface";

const slides = [
  {
    image: "/assets/images/animations/expenses.gif",
    subline: "",
    title: "Risk Management",
    description:
      "Risk Management are the actions we take to minimize the risk to our projected income. This includes, but is not limited to, purchasing life insurance, disability insurance etc.",
  },
];

const GetStarted: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const onNext = () =>
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.RISK_MANAGEMENT));
  const onPrev = () =>
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS));

  if (!render) {
    return <div />;
  }

  return render({
    component: <Slider slides={slides} slide={0} />,
    onNext,
    onPrev,
  });
};

export default GetStarted;
