import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";

import {
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import createStyles from "@material-ui/core/styles/createStyles";

import TextField from "src/components/TextField";
import Icon from "src/components/Icon";

import { forgotPasswordApi } from "src/apiService";
import { isLoggedInSelector } from "src/store/system/selector";
import { AdapterLink, validateEmail } from "src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

interface MyFormValues {
  email: string;
}

const ForgotPassword = () => {
  const classes = useStyles();
  const loggedIn = useSelector(isLoggedInSelector);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    if (!!values.email && !emailError && !loading && !success) {
      setLoading(true);
      forgotPasswordApi(values.email)
        .then(() => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setSuccess(false);
        });
    }
  };
  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={onFormikSubmit}
        render={(formikBag: FormikProps<MyFormValues>) => (
          <form
            className={classes.form}
            noValidate
            onSubmit={formikBag.handleSubmit}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              placeholder="Email"
              onFocus={() => setEmailError(false)}
              onChange={formikBag.handleChange}
              value={formikBag.values.email}
              onBlur={(...args) => {
                formikBag.handleBlur(...args);
                if (!validateEmail(formikBag.values.email)) {
                  setEmailError(true);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconName="fb-at" />
                  </InputAdornment>
                ),
              }}
            />
            {!!emailError && !loading && (
              <Alert severity="error">
                Please enter a valid email address.
              </Alert>
            )}
            {!!success && !loading && (
              <Alert severity="success">
                Request submitted. Check your email for instructions to reset
                your password.
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {!loading && "Forgot Password"}
              {loading && (
                <CircularProgress color="inherit" className="w-6 h-6" />
              )}
            </Button>
            <Grid container>
              <Grid item className="text-center w-full">
                <AdapterLink
                  to="/login"
                  variant="body2"
                  className="text-gray-3"
                >
                  Back to Login
                </AdapterLink>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default ForgotPassword;
