import React from "react";

import {
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import LoginHeader from "../components/Headers/LoginHeader";
import { loginStyles, theme } from "../theme";

const items = [
  {
    image: "/assets/images/png/fb-brain.png",
    title: "Formulate a plan for the future",
    description:
      "Build your FitBUX profile and get your FitBUX Score to easily understand your current situation.",
  },
  {
    image: "/assets/images/png/fb-calendar.png",
    title: "Understand your current financial status",
    description:
      "Define your priorities and goals. Compare different strategies and pick the optimal one for you.",
  },
  {
    image: "/assets/images/png/fb-rocket.png",
    title: "Implement your new plan to get on track",
    description:
      "Implement your selected strategy, track your progress, make adjustments as needed with help from a FitBUX financial expert if needed.",
  },
];

const useStyles = makeStyles(loginStyles);

const LoginLayout = ({
  children,
  pageTitle,
}: {
  children: React.ReactNode;
  pageTitle: string;
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoginHeader />
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} md={6} className={classes.contentLeft}>
          <Typography component="h2" className={classes.title}>
            {pageTitle}
          </Typography>
          {children}
        </Grid>
        <Grid item xs={12} md={6} className={classes.contentRight}>
          <List className={classes.list}>
            {items.map((item, index) => (
              <ListItem key={index} className={classes.listItem}>
                <ListItemAvatar className="mr-2">
                  <img
                    src={item.image}
                    className={classes.itemImage}
                    alt={item.title}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.title}
                  secondary={item.description}
                  className={classes.itemText}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginLayout;
