import React from "react";
import { useSelector } from "react-redux";

import {
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";

import { FormComponentType } from "./interfaces";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField } from "src/utils/inputMask";

const RetirementAccountForm: FormComponentType = ({
  formState,
  updateField,
}) => {
  const isMarried = useSelector(getIsMarried);
  return (
    <>
      <Grid container spacing={3}>
        {isMarried && (
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-3">
                Who is the owner of the account?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                name="who"
                onChange={updateField}
                value={formState.who}
                style={{ maxWidth: 350, width: "100%" }}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">Spouse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            What is the current balance in the account?
          </FormLabel>
          <DollarTextField
            name="balance"
            onChange={updateField}
            variant="outlined"
            value={formState.balance || 0}
            style={{ maxWidth: 350, width: "100%" }}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default RetirementAccountForm;
