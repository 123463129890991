import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { headerStyles } from "src/theme";

import { AppLoader } from "src/components";
import LandingPage from "./LandingPage";
import Content from "./Content";
import { getLoadingPlans, getPlans } from "src/store/planBuild/selector";
import { allowPlanBuilder } from "src/store/system/selector";

const PlanListContainer = () => {
  const classes = useStyles();
  const loadingPlans = useSelector(getLoadingPlans);
  const plans = useSelector(getPlans);
  const planBuilder = useSelector(allowPlanBuilder);

  const renderContent = () => {
    if (!planBuilder || !plans || !plans.length) {
      if (loadingPlans) {
        return <AppLoader />;
      }
      return <LandingPage />;
    }
    return <Content />;
  };

  return <main className={classes.mainContent}>{renderContent()}</main>;
};

const useStyles = makeStyles(headerStyles);

export default PlanListContainer;
