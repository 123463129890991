import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";

import CustomDialog from "src/components/Dialogs/CustomDialog";

import { Typography } from "@material-ui/core";
import {
  getAccountsLoading,
  getPublicToken as selectPublicToken,
  getLinkedAccounts,
} from "src/store/account/selector";
import {
  removeAccount,
  getPublicToken,
  relinkLinkedAccount,
} from "src/store/account/actions";

import FbButton from "src/components/Button";

import { usePlaidLink, PlaidLinkOptions } from "react-plaid-link";
import { ConfirmDialog } from "src/components";
import { removeLinkedAccountApi } from "src/apiService";

interface IState {
  open: boolean;
  onClose: VoidFunction;
  id?: number;
  item_id?: string;
}

const RelinkLinkedAccountDialog = ({ open, onClose, id, item_id }: IState) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const linkedAccounts = useSelector(getLinkedAccounts);
  const loadingToken = useSelector(getAccountsLoading);
  const publicToken = useSelector(selectPublicToken);

  const [loading, setLoading] = useState(false);
  const [itemId, setItemId] = useState("");
  const [deleteConfirmDialogVisible, setDeleteConfirmDialogVisible] = useState(
    false
  );

  const config: PlaidLinkOptions = {
    token: publicToken?.link_token || "",
    onSuccess: () => {
      dispatch(relinkLinkedAccount(itemId));
      return onClose();
    },
    onExit: () => onClose(),
  };

  const { open: relink, ready } = usePlaidLink(config);

  useEffect(() => {
    if (loadingToken) return;
    let accountItemId = item_id;
    if (!accountItemId) {
      const linkedAccount = linkedAccounts.find((account) => account.id === id);
      if (!linkedAccount || !linkedAccount.item_id) {
        return;
      }
      accountItemId = linkedAccount.item_id;
    }
    setItemId(accountItemId);
    dispatch(getPublicToken(accountItemId));
  }, [open]);

  const handleRelink = async () => {
    relink();
  };

  const handleDelete = async () => {
    if (!id) return;
    setLoading(true);
    try {
      await removeLinkedAccountApi({ id });
      dispatch(removeAccount(id));
    } catch {
      // TODO: something
    }
    setLoading(false);
    setDeleteConfirmDialogVisible(false);
    onClose();
  };

  return (
    <>
      <CustomDialog
        title="Relink Account"
        size="sm"
        isOpen={open}
        onClose={() => {
          if (!loading && onClose) {
            onClose();
          }
        }}
      >
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography>
              Your financial institution has updated their technology and your
              account needs to be relinked/refreshed.
            </Typography>
          </Grid>

          <Grid item xs={12} className="text-right">
            <FbButton
              fbColor="primary"
              className="mr-3"
              onClick={handleRelink}
              disabled={loading || loadingToken || !ready}
            >
              Relink/Refresh My Account
            </FbButton>
            {!!id && (
              <FbButton
                fbColor="primary"
                onClick={() => setDeleteConfirmDialogVisible(true)}
                disabled={loading || loadingToken}
              >
                Remove This Account
              </FbButton>
            )}
          </Grid>
        </Grid>
      </CustomDialog>
      <ConfirmDialog
        visible={deleteConfirmDialogVisible}
        title="Delete Account"
        message="Are you sure to unlink the account?"
        onCancel={() => setDeleteConfirmDialogVisible(false)}
        onConfirm={() => handleDelete()}
        cancelButton="No"
        confirmButton="Yes"
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "20px 6px",
  },
});

export default RelinkLinkedAccountDialog;
