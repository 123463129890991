export const DISCLAIMER =
  "Disclaimer: The analysis in this recommendation does not include other life event or goals you may have and is only meant as a general guide. We highly recommend using our financial planning technology so you can include life events and goals into your rent vs buy decision.";

export enum STEP {
  LANDING_STEP,
  HOUSE_INPUT_STEP,
  RENT_INPUT_STEP,
  RESULT_STEP,
}

export const LANDING_STEP_HELP = [
  {
    header: "How We Get The Answer",
    body: [` We use thousands of data points in our proprietary algorithm.`],
    lists: [
      {
        title: `"Your" data:`,
        list: [
          `Your age`,
          `Your current assets and debts`,
          `Your income type`,
          `Whether you're using our FitBUX Score-based asset allocation recommendation`,
        ],
      },
      {
        title: `Local Real Estate Market Data:`,
        list: [
          `The cost of homes and rent prices`,
          `The cost of other home expenses (property taxes, insurance,  etc.)`,
          `Historical real estate investment returns`,
        ],
      },
    ],
  },
];

export const RENT_RESULT_DESCRIPTION = [
  "Adding a mortgage will increase your level of debt and level of financial risk. This explains the initial decrease in your FitBUX Score.",
  "As you repay your mortgage, your score will increase as your mortgage debt is converted into a valuable real estate over time. This is represented by an increase in your FitBUX Score in the long run.",
  "However, your FiBUX Score is expected to be lower at Retirement if you buy a home rather than keep renting.",
  "Of course, this is purely from a financial perspective. You may have different non-financial reasons to purchase this home and the final decision is ultimately a very personal choice.",
];

export const BUY_RESULT_DESCRIPTION = [
  `Adding a mortgage will increase your level of debt and level of financial risk.`,
  `As you repay your mortgage, your score will increase as your mortgage debt is converted into a valuable real estate over time. This is represented by an increase in your FitBUX Score in the long run.`,
  `Beyond the numbers, you have to decide whether you are comfortable taking on additional financial risk today in order to grow an asset over 30 years. Note that it is impossible to predict how the real estate market will evolve, so this is an element of risk you will have to consider.`,
  `The greater the FitBUX Score at Retirement when "Buying This Home" compared to "Continuing to Rent", the more beneficial it is to you to Buy instead of Rent.`,
];
