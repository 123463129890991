import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { sortBy } from "lodash";

import { makeStyles } from "@material-ui/core";

import { fetchAccountTickersApi } from "src/apiService";
import { Account } from "src/interfaces";
import { getInvestmentAccounts } from "src/store/account/selector";
import { getRouterState } from "src/store/router/selector";
import { investmentsStyles } from "src/theme";

import ReviewAccounts from "./ReviewAccounts";
import RiskSummary from "./RiskSummary";

const useStyles = makeStyles(investmentsStyles);

export const InvestmentRecommendations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accounts = useSelector(getInvestmentAccounts);
  const router = useSelector(getRouterState);
  const [accountInvestments, setAccountInvestments] = useState<any>({});
  const [step, setStep] = useState(0);
  const [returnToTools, setReturnToTools] = useState(false);

  useEffect(() => {
    if (router.location.hash === "#tools") {
      setReturnToTools(true);
    }
  }, []);

  const refresh = () => {
    const newInvestments: any = {};
    Promise.all(
      accounts.map((account) =>
        fetchAccountTickersApi(account.id).then((results) => {
          const sorted = sortBy(
            results,
            (item: any) => -(item.quantity * item.close_price)
          );
          newInvestments[account.id] = sorted;
        })
      )
    ).then(() => {
      setAccountInvestments((current: any) => ({
        ...current,
        ...newInvestments,
      }));
    });
  };

  useEffect(refresh, [accounts]);

  const accountBuckets = useMemo(() => {
    const withInvestments: Account[] = [];
    const withoutInvestments: Account[] = [];
    accounts.forEach((account) => {
      if (accountInvestments[account.id]?.length) {
        withInvestments.push(account);
      } else {
        withoutInvestments.push(account);
      }
    });
    return { withInvestments, withoutInvestments };
  }, [accountInvestments]);

  const exit = () => dispatch(push("/plan-summary"));

  const doneReviewing = () => {
    if (!returnToTools) {
      return setStep(1);
    }
    dispatch(push("/tools/investment#step2"));
  };

  const content = step ? (
    <RiskSummary next={() => setStep(2)} goBack={() => setStep(0)} />
  ) : (
    <ReviewAccounts
      next={doneReviewing}
      goBack={returnToTools ? doneReviewing : exit}
      refresh={refresh}
      withInvestments={accountBuckets.withInvestments}
      withoutInvestments={accountBuckets.withoutInvestments}
    />
  );

  return <main className={classes.content}>{content}</main>;
};

export default InvestmentRecommendations;
