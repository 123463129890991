import axiosClient, { SHORT_ENDPOINT } from "../axiosClient";
import NodeClient from "../nodeClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import { YesNo } from "../../interfaces";
import { HousingTenureResult } from "src/interfaces/tools.interface";

export interface PayoffAccountsPayload {
  defaultExtendTerms: { [accountId: string]: number };
  selectedTerms: { [accountId: string]: number };
}

export interface PayoffAccountsResponse {
  id: number;
  type: "federal" | "perkins" | "private";
  balance: number;
  interest: number;
  payment: number;
  term: number;
  whose: "mine" | "spouse";
}

export interface PayoffAnalysisPayload {
  sno: number;
  extra_prepay: number;
  drop_off_ratio: number;
  defaultExtendedTerms: { [accountId: string]: number };
  selectedTerms: { [accountId: string]: number };
}

export interface PayoffAnalysisResponse {
  detail: {
    extend_compare_payment: string;
    extend_payment_diff: string;
    extend_timing: string;
    prepay_compare_payment: string;
    prepay_payment_diff: string;
    prepay_timing: string;
    end_score: number;
    normal_dept_ratio: string;
    normal_months: number;
    normal_overall_payment: string;
    normal_payment: string;
    normal_start_score: number;
    normal_total_payment: string;
    normal_years: string;
    extend_dept_ratio: string;
    extend_months: number;
    extend_overall_payment: string;
    extend_payment: string;
    extend_start_score: number;
    extend_total_payment: string;
    extend_years: string;
    discretionary: string;
    prepay_dept_ratio: string;
    prepay_months: number;
    prepay_overall_payment: string;
    prepay_payment: string;
    prepay_start_score: number;
    prepay_total_payment: string;
    prepay_years: string;
  };
  FitBUX: number[];
  normal: number[];
  extend: number[];
  prepay: number[];
}

export interface IDRVsPrepayTableRequest {
  idrcalc_tax_filing_status: number;
  idrcalc_household_size: number;
  idrcalc_graduate_loans: YesNo;
  idrcalc_agi: number;
  idrcalc_spouse_agi: number;
  idrcalc_agi_ann_incr: number;
  idrcalc_state: string;
  idrcalc_pslf: YesNo;
}

export interface IDRVsPrepayTableResponse {
  repaye: number[];
  paye: number[];
  ibr: number[];
  ibr_for_new_borrowers: number[];
}

export interface IDRVsPrepayResultsRequest extends IDRVsPrepayTableRequest {
  idrcalc_federal_extended: YesNo;
  idrcalc_mthly_pmt: number;
  idrcalc_select_plan: number;
  idrcalc_breakeven_ret: number;
  idr_model?: number;
}

export interface IDRVsPrepayResultsResponse {
  detail: {
    idr_ini_payment: number;
    idr_pmt_period: number;
    idr_sf: number;
    idr_sp: number;
    idr_total_pmt: number;
    idr_total_saving: number;
    idr_uf: number;
    idr_up: number;
    prepay_ini_payment: number;
    prepay_pmt_period: number;
    prepay_sf: number;
    prepay_sp: number;
    prepay_total_pmt: number;
    prepay_total_saving: number;
    prepay_uf: number;
    prepay_up: number;
    saving_period: number;
    tax: number;
  };
  idr: number[];
  ppm: number[];
  ppmscore: number[];
}

export interface RefinanceRequest {
  no_graph: YesNo;
  noRefinancingPrepayment: number;
  refinancingMonthlyPayment: number;
  balances: number[];
  interests: number[];
  payments: number[];
  who: string;
}

export interface RefinanceResponse {
  detail: {
    no_refi_start_score: number;
    no_refi_payment: number;
    no_refi_months: number;
    no_refi_discretionary: number;
    no_refi_total_payment: number;
    no_refi_dept_ratio: number;
    no_refi_accu_payment: number;
    no_refi_years: number;
    refi_start_score: number;
    refi_payment: number;
    refi_months: number;
    refi_discretionary: number;
    refi_total_payment: number;
    refi_dept_ratio: number;
    refi_accu_payment: number;
    refi_years: number;
  };
  no_refi: number[];
  prepay: number[];
  FitBUX: number[];
}

interface PropertyTaxPayload {
  state: string;
  property_value: number;
}

interface CostOfLivingPayload {
  income: number;
  zip: string;
}

interface HousingTenurePayload {
  home_down: number;
  home_zip_code: string;
  rent_amount: number;
  rent_insurance: number;
  rent_zip_code: string;
}

interface HousingTenurePayload {
  home_down: number;
  home_zip_code: string;
  rent_amount: number;
  rent_insurance: number;
  rent_zip_code: string;
}

export const getPayoffAnalysisLoansApi = (payload?: PayoffAccountsPayload) =>
  makeAuthenticatedRequest(async () => {
    const postPayload = payload || {};
    const { data } = await axiosClient.post(
      "overview.get.php",
      { ...postPayload, get: "extendLoanDetailData" },
      { baseURL: SHORT_ENDPOINT }
    );
    return data as PayoffAccountsResponse[];
  });

export const getPayoffAnalysisResultsApi = (payload: PayoffAnalysisPayload) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("get_prepay_data_v2.php", payload, {
      baseURL: SHORT_ENDPOINT,
    });
    return data as PayoffAnalysisResponse;
  });

export const getIDRVsPrepayTableApi = (payload?: IDRVsPrepayTableRequest) =>
  makeAuthenticatedRequest(async () => {
    const postPayload = payload || {};
    const { data } = await axiosClient.post(
      "get_idr_vs_prepay_cal_table.php",
      postPayload,
      { baseURL: SHORT_ENDPOINT }
    );
    return data as IDRVsPrepayTableResponse;
  });

export const getIDRVsPrepayResultsApi = (payload: IDRVsPrepayResultsRequest) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      "get_idr_vs_prepay_cal_data.php",
      payload,
      { baseURL: SHORT_ENDPOINT }
    );
    return data as IDRVsPrepayResultsResponse;
  });

export const getRefinanceApi = (payload: RefinanceRequest) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      "get_refi_vs_prepay_cal_data.php",
      payload,
      { baseURL: SHORT_ENDPOINT }
    );
    return data as RefinanceResponse;
  });

export const listCoursesApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("courses/courses.php");
    return data;
  });

export const getHomeResults = (payload: any) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await NodeClient.post("/tool/mortgage", payload);
    return data;
  });

export const getPropertyTaxAPI = async (payload: PropertyTaxPayload) => {
  const data = await makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("/tool/proptax.php", payload);
    return data;
  });
  return data;
};

export const getCostOfLivingAPI = async (payload: CostOfLivingPayload) => {
  const data = await makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("/tool/costofliving.php", payload);
    return data;
  });
  return data;
};

export const getEmergencyFundAmountAPI = async () => {
  const data = await makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/v2/tool/emergency_fund");
    return data;
  });
  return data;
};

export const getHousingTenureAPI = async (payload: HousingTenurePayload) => {
  const data = await makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("/v2/tool/housing_tenure", payload);
    return data as HousingTenureResult;
  });
  return data as HousingTenureResult;
};


