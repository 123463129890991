import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pick } from "lodash";
import Icon from "src/components/Icon";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { updateHouseholdApi } from "src/apiService";
import colors from "src/theme/colors";
// import { setTutorial } from "src/store/dashboard/actions";
import { addCashflow, editCashflow } from "src/store/cashflow/actions";
import {
  setProfileProgress,
  updateHousehold,
  updateHouseholdSuccess,
} from "src/store/profileBuild/actions";
import {
  PROFILE_BUILD_STEPS,
  PROFILE_PROGRESS,
} from "src/store/profileBuild/constants";
import { getRawCashflows, getRiskSummary } from "src/store/cashflow/selector";
import { getHousehold } from "src/store/profileBuild/selector";
import { HOUSEHOLD_KEYS } from "src/store/profileBuild/selectorKeys";
import { getIsMarried } from "src/store/system/selector";
import FbRadio from "src/components/Radio";
import {
  Household,
  RiskManagementSection,
  ViewComponent,
  YesNo,
} from "src/interfaces";
import { DollarTextField } from "src/utils/inputMask";

interface FormValues extends RiskManagementSection {
  my_death_benefit: number;
  spouse_life_insure: YesNo;
  spouse_death_benefit: number;
  rental_insurance: YesNo;
  home_insurance: YesNo;
  vehicle_insurance: YesNo;
  health_insurance: YesNo;
}

const MainForm: ViewComponent = ({ onClose, render }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const emptyValues = {
    disable_insure: "n",
    qual_life_insure: "n",
    qual_trust: "n",
    qual_will: "n",
    my_death_benefit: 0,
    spouse_life_insure: "n",
    spouse_death_benefit: 0,
    rental_insurance: "n",
    home_insurance: "n",
    vehicle_insurance: "n",
    health_insurance: "n",
  };
  const summaryData: any = useSelector(getRiskSummary);
  const cashflows = useSelector(getRawCashflows);
  const initialValues = {
    ...emptyValues,
    ...summaryData,
  };
  cashflows.forEach((cashflow) => {
    [
      "rental_insurance",
      "home_insurance",
      "vehicle_insurance",
      "health_insurance",
    ].forEach((type: string) => {
      if (cashflow.type === type) {
        initialValues[type] = "y";
      }
    });
  });
  const [formState, setFormState] = useState<FormValues>(initialValues);
  const prev = () => {
    dispatch(
      updateHousehold({
        update: formState,
        nextPage: PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS,
      })
    );
  };
  const addLifeInsuranceCashflow = (owner: string) => {
    const formKey =
      owner === "mine" ? "my_death_benefit" : "spouse_death_benefit";
    const existingCashflow = cashflows.find(
      (item) => item.type === "life_insurance" && item.whose === owner
    );
    if (existingCashflow) {
      dispatch(
        editCashflow({
          id: existingCashflow.id,
          death_benefit: formState[formKey],
          amount: 0,
        })
      );
    } else {
      dispatch(
        addCashflow({
          temporaryId: 0,
          cashflow: {
            type: "life_insurance",
            amount: 0,
            who: owner === "mine" ? "applicant" : "spouse",
            death_benefit: formState[formKey],
          },
        })
      );
    }
  };

  const addInsuranceCashflow = (type: string) => {
    const existingCashflow = cashflows.find((item) => item.type === type);
    if (!existingCashflow) {
      dispatch(
        addCashflow({
          temporaryId: 0,
          cashflow: {
            amount: 1,
            who: "applicant",
            type,
          },
        })
      );
    }
  };

  const next = () => {
    if (formState.qual_life_insure === "y" && formState.my_death_benefit) {
      addLifeInsuranceCashflow("mine");
    }
    if (
      formState.spouse_life_insure === "y" &&
      formState.spouse_death_benefit
    ) {
      addLifeInsuranceCashflow("spouse");
    }
    [
      "health_insurance",
      "vehicle_insurance",
      "rental_insurance",
      "home_insurance",
    ].forEach((key) => {
      if (formState[key as keyof FormValues] === "y") {
        addInsuranceCashflow(key);
      }
    });
    const payload = pick(formState, HOUSEHOLD_KEYS);
    updateHouseholdApi(payload).then(() => {
      dispatch(updateHouseholdSuccess({ update: payload }));
      dispatch(setProfileProgress(PROFILE_PROGRESS.COMPLETE));
      if (onClose) {
        onClose();
        // } else {
        //   dispatch(setTutorial("post_profile"));
      }
    });
  };

  const household = useSelector(getHousehold);
  const isMarried = useSelector(getIsMarried);

  useEffect(() => {
    const newFormState: any = { ...formState };
    (Object.keys(formState) as Array<keyof FormValues>).forEach(
      (key: keyof FormValues) => {
        if (household[key as keyof Household]) {
          newFormState[key] = household[key as keyof Household];
        }
      }
    );
    setFormState(newFormState);
  }, [household]);

  const setFormValue = (e: any) => {
    const target = e.target;
    setFormState({ ...formState, [target.name]: target.value });
  };

  const component = (
    <Card className={styles.root}>
      <CardContent className={styles.content}>
        <Box className="flex mb-4">
          <Icon iconName="fb-life-saver" className={styles.icon} />
          <Typography className={styles.title}>Risk Management</Typography>
        </Box>
        <form className="py-5">
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have life insurance?
              </FormLabel>
              <RadioGroup
                aria-label="qual_life_insure"
                name="qual_life_insure"
                value={formState["qual_life_insure"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          {formState.qual_life_insure === "y" && (
            <Box className="px-10 mb-6">
              <FormControl component="fieldset">
                <FormLabel component="legend" className="mb-4">
                  What is the death benefit amount?
                </FormLabel>
                <DollarTextField
                  aria-label="my_death_benefit"
                  name="my_death_benefit"
                  value={formState.my_death_benefit}
                  variant="outlined"
                  onChange={setFormValue}
                />
              </FormControl>
            </Box>
          )}
          {isMarried && (
            <>
              <Box className="px-10 mb-6">
                <FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-4">
                    Does your spouse have life insurance?
                  </FormLabel>
                  <RadioGroup
                    aria-label="spouse_life_insure"
                    name="spouse_life_insure"
                    value={formState.spouse_life_insure}
                    className="flex flex-row"
                    onChange={setFormValue}
                  >
                    <FormControlLabel
                      value="n"
                      control={<FbRadio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="y"
                      control={<FbRadio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {formState.spouse_life_insure === "y" && (
                <Box className="px-10 mb-6">
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className="mb-4">
                      What is your spouse's death benefit amount?
                    </FormLabel>
                    <DollarTextField
                      aria-label="spouse_death_benefit"
                      name="spouse_death_benefit"
                      value={formState.spouse_death_benefit}
                      variant="outlined"
                      onChange={setFormValue}
                    />
                  </FormControl>
                </Box>
              )}
            </>
          )}
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have disability insurance?
              </FormLabel>
              <RadioGroup
                aria-label="disable_insure"
                name="disable_insure"
                value={formState["disable_insure"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have renter's insurance?
              </FormLabel>
              <RadioGroup
                aria-label="rental_insurance"
                name="rental_insurance"
                value={formState["rental_insurance"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have homeowner's insurance?
              </FormLabel>
              <RadioGroup
                aria-label="home_insurance"
                name="home_insurance"
                value={formState["home_insurance"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have auto insurance?
              </FormLabel>
              <RadioGroup
                aria-label="vehicle_insurance"
                name="vehicle_insurance"
                value={formState["vehicle_insurance"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have health insurance?
              </FormLabel>
              <RadioGroup
                aria-label="health_insurance"
                name="health_insurance"
                value={formState["health_insurance"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have a trust?
              </FormLabel>
              <RadioGroup
                aria-label="qual_trust"
                name="qual_trust"
                value={formState["qual_trust"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className="px-10 mb-6">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-4">
                Do you have a will?
              </FormLabel>
              <RadioGroup
                aria-label="qual_will"
                name="qual_will"
                value={formState["qual_will"]}
                className="flex flex-row"
                onChange={setFormValue}
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>
          {!!onClose && <Button onClick={next}>Save and close</Button>}
        </form>
      </CardContent>
    </Card>
  );

  if (render) {
    return render({
      component,
      onNext: next,
      onPrev: prev,
    });
  }

  return component;
};

export default MainForm;

const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    marginBottom: 10,
  },
  content: {
    padding: 20,
  },
  icon: {
    color: colors.brandingBlue1,
    fontSize: 20,
    marginRight: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.brandingBlue1,
  },
  button: {
    width: "100%",
    "&>span": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
      fontWeight: 500,
    },
  },
});
