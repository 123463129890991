import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import { Household } from "../../interfaces/household.interface";
import { Profile } from "../../interfaces/profile.interface";
import { PROFILE_TYPE } from "src/store/profileBuild/constants";

export const fetchSecondaryEducationApi = (level?: number) =>
  makeAuthenticatedRequest(async () => {
    const data = await axiosClient.get(
      `profile/institution.php${level ? "?level=" + level : ""}`
    );
    return data;
  });

export const fetchSpecialtiesApi = (id?: number) =>
  makeAuthenticatedRequest(async () => {
    const data = await axiosClient.get(
      `profile/specialty.php${id ? "?id=" + id : ""}`
    );
    return data;
  });

export const getFieldsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("profile/field.php");
    return data;
  });

export const getProfessionsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("profile/profession.php");
    return data;
  });

export const getEmploymentsApi = () =>
  makeAuthenticatedRequest(async () => {
    const data = await axiosClient.get("profile/employment.php?id=29-1123");
    return data;
  });

export const getFellowshipsApi = (id?: number) =>
  makeAuthenticatedRequest(async () => {
    const data = await axiosClient.get(
      `profile/fellowship.php${id !== undefined ? "?id=" + id : ""}`
    );
    return data;
  });

export const getDesignationsApi = () =>
  makeAuthenticatedRequest(async () => {
    const data = await axiosClient.get("profile/designation.php");
    return data;
  });

export const getProfileApi = (who?: PROFILE_TYPE) =>
  makeAuthenticatedRequest(async () => {
    const whoString = who === PROFILE_TYPE.SPOUSE ? "spouse" : "applicant";
    const { data } = await axiosClient.get(`profile/solo.php?who=${whoString}`);
    return data;
  });

export const getProfileProgressApi = (who?: PROFILE_TYPE) =>
  makeAuthenticatedRequest(async () => {
    const whoString = who === PROFILE_TYPE.SPOUSE ? "spouse" : "applicant";
    const { data } = await axiosClient.get(
      `profile/progress.php?who=${whoString}`
    );
    return data;
  });

export const setProfileProgressApi = (progress: number, who?: PROFILE_TYPE) =>
  makeAuthenticatedRequest(async () => {
    const whoString = who === PROFILE_TYPE.SPOUSE ? "spouse" : "applicant";
    const { data } = await axiosClient.put(
      `profile/progress.php?who=${whoString}`,
      {
        progress,
      }
    );
    return data;
  });

export const updateProfileApi = ({
  who,
  profile,
}: {
  who?: PROFILE_TYPE;
  profile: Partial<Profile>;
}) =>
  makeAuthenticatedRequest(async () => {
    const whoString = who === PROFILE_TYPE.SPOUSE ? "spouse" : "applicant";
    const { data } = await axiosClient.patch(
      `profile/solo.php?who=${whoString}`,
      profile as any
    );
    return data;
  });

export const getHouseholdApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("profile/household.php");
    return data;
  });

export const updateHouseholdApi = (household: Partial<Household>) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.patch(
      "profile/household.php",
      household as any
    );
    return data;
  });

export const fetchCategoriesApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("admin/categories");
    return data;
  });

export const validateZipApi = (zipCode: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`v2/profile/zip/${zipCode}`);
    return data;
  });
