import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import colors from "src/theme/colors";
import Button from "src/components/Button";
import Card from "src/components/Card";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Icon from "src/components/Icon";
import StripeForm from "src/components/StripeForm";
import { AUTH_STATUS } from "src/interfaces";

import { getAllPurchaseData } from "src/store/stripe/actions";
import { selectPurchasePlans } from "src/store/stripe/selector";
import { logOut } from "src/store/system/actions";
import {
  authStatusSelector,
  getIsCurrentStudent,
  getIsSubscribed,
  isMoneySchoolDialogOpen,
} from "src/store/system/selector";
import { formatDollarsAndCents } from "src/utils";

const useStyles = makeStyles({
  topHeader: {
    textAlign: "right",
    position: "absolute",
    right: "40px",
  },
  main: {
    // background: colors.gradLightAltVertical2,
  },
  headerText: {
    display: "block",
    marginBottom: 16,
    textAlign: "center",
  },
  list: {
    marginBottom: 20,
    marginTop: 20,
  },
  listItemText: {
    marginLeft: 19,
  },
  subheading: {
    marginBottom: 40,
    display: "block",
    textAlign: "center",
  },
  action: {},
  image: {
    height: 80,
    width: 80,
  },
});

const dialogStyle = {
  backgroundImage: colors.gradLightAltVertical2,
};

const UpgradeDialog = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isSubscribed = useSelector(getIsSubscribed);
  const plans = useSelector(selectPurchasePlans) || [];
  const authStatus = useSelector(authStatusSelector);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const moneySchool = useSelector(isMoneySchoolDialogOpen);
  const [showStripe, goToStripe] = useState(true);
  const [stripeMoneySchool, setStripeMoneySchool] = useState(false);

  const handleRightButton = () => {
    setStripeMoneySchool(true);
    goToStripe(true);
  };

  useEffect(() => {
    dispatch(getAllPurchaseData());
  }, []);

  const proBulletPoints = [
    "Financial plan building and implementation technology",
    "Dedicated FitBUX Coach with 60 minute calls because building trust and rapport matter",
    "Student Debt repayment technology (Is my servicer making mistakes? Am I saving enough for IDR tax bomb? Am I qualifying for PSLF? etc.)",
    "Online Financial accounts linking for financial plan",
    "Real-time alerts and recommendations",
    "A.I.-powered financial plan optimizer",
    "A.I.-powered investment allocation recommendations",
    "A.I.-powered emergency fund optimizer",
    "A.I.-powered Home Ownership technology (How much can I truly afford? Should I rent or buy? etc.)",
    "Lifetime access to FitBUX's online Money School (a $945 value!)",
  ];

  if (isCurrentStudent) {
    proBulletPoints.push(
      "Locked in post-graduation price (Save up to $315 per year)"
    );
  }

  const basicBulletPoints = [
    "Your FitBUX Score",
    "Free calls with a FitBUX Coach (30 Minutes)",
    "Customized Financial Calculators (Student loans, mortgages, etc.)",
    "FitBUX Student Loan Refinance Solution",
    "Hybrid Robo-Advisor Investment Accounts (in partnership with Betterment)",
    "Retirement Accounts (401(k)/403(b) rollovers, IRA, Roth IRA, etc., in partnership with Betterment)",
    "Shop Mortgages, HELOCs, and Home Owners Insurance with FitBUX Partners",
    "FitBUX Personal Loan/Credit Card Consolidation Solution",
    "Auto loan Refinancing",
    "Shop Life and Disability Insurance With FitBUX Partners",
  ];

  let monthlyPrice = " $18.99/mo";
  let annualPrice = " 189/yr";
  let discountedMonthlyPrice = " $11.99";
  if (!moneySchool && plans?.[0]?.amount) {
    const annualPlan = plans.find((plan) => plan.months === 12) || plans[0];
    const monthlyPlan = plans.find((plan) => plan.months === 1) || plans[0];
    monthlyPrice = ` ${formatDollarsAndCents(monthlyPlan.amount)}/mo`;
    annualPrice = ` ${formatDollarsAndCents(annualPlan.amount)}/yr`;
    discountedMonthlyPrice = formatDollarsAndCents(annualPlan.amount / 12);
  }

  return (
    <CustomDialog
      size="md"
      isOpen={authStatus === AUTH_STATUS.SUCCEED && !isSubscribed}
      title=""
      style={dialogStyle}
    >
      <Container className={styles.main}>
        <Box className={styles.topHeader}>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(logOut())}
          />
        </Box>
        <Typography component="h1" variant="h1" className={styles.headerText}>
          Let's Get Started
        </Typography>
        <Typography variant="body2" paragraph>
          Legacy financial planners often charge over $2,500 per year.
        </Typography>
        <Typography variant="body2" paragraph>
          Sign up for FitBUX for as little as $15/month,{" "}
          <strong>risk-free with our 14-day money-back guarantee.</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Note: If you or your spouse is an existing paying customer, please{" "}
          <a href="mailto:info@fitbux.com?subject=FitBUX Premium - Account Update">
            email us
          </a>{" "}
          so we can update your account.
        </Typography>
        {showStripe && <StripeForm moneySchool={stripeMoneySchool} />}
        {!showStripe && (
          <>
            <Typography variant="body1" className={styles.subheading}>
              Take advantage of our limited-time offer and get up to 65% off on
              our Premium Membership. As low as {discountedMonthlyPrice}/month
              (Billed yearly).
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card
                  headerImage="/assets/images/png/hero.png"
                  title="Premium"
                  secondRowHeadComponent={
                    <Box className="flex justify-between items-center h-16">
                      <Typography
                        variant="h2"
                        component="h2"
                        className="text-lg text-center w-full"
                      >
                        <span style={{ textDecoration: "2px line-through" }}>
                          {isCurrentStudent ? "$227/yr" : "$540/yr"}
                        </span>
                        <br />
                        {annualPrice}
                      </Typography>
                      <Typography
                        variant="h2"
                        component="h2"
                        className="text-sm"
                      >
                        or
                      </Typography>
                      <Typography
                        variant="h2"
                        component="h2"
                        className="text-lg text-center w-full"
                      >
                        <span style={{ textDecoration: "2px line-through" }}>
                          {isCurrentStudent ? "$18.99/mo" : "$45/mo"}
                        </span>
                        <br />
                        {monthlyPrice}
                      </Typography>
                    </Box>
                  }
                  imageClassName={styles.image}
                  className="mr-4"
                >
                  <Divider className="my-3" />
                  <Box className={styles.list}>
                    <Typography className="mb-4">
                      All Free Membership benefits, plus:
                    </Typography>
                    {proBulletPoints.map((text) => (
                      <Box key={text} className="flex items-center mb-4">
                        <Icon iconName="fb-checkmark-outline" color="primary" />
                        <Typography
                          variant="body1"
                          className={styles.listItemText}
                        >
                          {text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Divider className="mb-3" />
                  <Button
                    fbColor="primary"
                    fullWidth
                    className={styles.action}
                    onClick={() => goToStripe(true)}
                  >
                    Upgrade to Premium
                  </Button>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  headerImage="/assets/images/png/human 1.png"
                  title={moneySchool ? "Money School" : "Free (Current)"}
                  headComponent={
                    <Typography variant="h2" component="h2">
                      {moneySchool ? "$750.00" : ""}
                    </Typography>
                  }
                  secondRowHeadComponent={<div className="h-16" />}
                  imageClassName={styles.image}
                >
                  {!moneySchool && (
                    <>
                      <Divider className="my-3" />
                      <Box className={styles.list}>
                        {basicBulletPoints.map((text) => (
                          <Box key={text} className="flex items-center mb-4">
                            <Icon iconName="fb-checkmark-outline" />
                            <Typography
                              variant="body1"
                              className={styles.listItemText}
                            >
                              {text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}
                  {moneySchool && (
                    <>
                      <Divider className="mb-3" />
                      <Button
                        variant="outlined"
                        fullWidth
                        className={styles.action}
                        onClick={handleRightButton}
                      >
                        Purchase
                      </Button>
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </CustomDialog>
  );
};

export default UpgradeDialog;
