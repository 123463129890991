import React from "react";
import { useDispatch } from "react-redux";

import { Box, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { openUpgradeDialog } from "src/store/system/actions";
import { investmentsStyles } from "src/theme";

const useStyles = makeStyles({
  ...investmentsStyles,
});

export const UnsubscribedIntro = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <Card divider titleTag="h6" title="Let's Find Out!" className="mb-5">
        <Box className="mt-5">
          <Typography className={classes.pageText} paragraph>
            When you are on an income-driven repayment plan (IBR, PAYE, REPAYE,
            ICR), the amount forgiven at the end of your plan period is taxed as
            regular income. It is a true ticking time bomb if you don't plan for
            it!
          </Typography>
          <Typography className={classes.pageText} paragraph>
            With our Premium Membership, you will be able to:
          </Typography>
          <ol>
            <li>Estimate your current expected tax liablility;</li>
            <li>
              Dynamically track your tax estimate over time after different life
              events and when tax laws change;
            </li>
            <li>
              Understand the amount we recommend setting aside monthly to cover
              your expected tax liability; and
            </li>
            <li>
              Visualize whether you're on track based on the amount currently
              saved and/or make adjustments if needed.
            </li>
          </ol>
        </Box>
      </Card>
      <Button
        fbColor="primary"
        className={classes.continueButton}
        onClick={() => dispatch(openUpgradeDialog())}
      >
        Upgrade to Premium
      </Button>
    </>
  );
};

export default UnsubscribedIntro;
