import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Slider from "src/components/Slider";
import { fetchCashflowItems } from "src/store/cashflow/actions";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { ViewComponent } from "src/interfaces/viewComponent.interface";

const slides = [
  {
    image: "/assets/images/animations/bills.gif",
    subline: "",
    title: "Your Income & Expenses (Your Budget)",
    description:
      "FitBUX uses 2 simple formulas to make money easy to understand. The first one is Income - Expenses = Remaining Cash. The first formula helps determine how your Human Capital and behavior affect your overall financial plan.",
  },
];

const GetStarted: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCashflowItems());
  }, []);
  const onNext = () =>
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.INCOME_EXPENSES));
  const onPrev = () =>
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW));

  if (!render) {
    return <div />;
  }

  return render({
    component: <Slider slides={slides} slide={0} />,
    onNext,
    onPrev,
  });
};

export default GetStarted;
