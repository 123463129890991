import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { Redirect } from "react-router";

import {
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Theme,
  makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import createStyles from "@material-ui/core/styles/createStyles";

import TextField from "src/components/TextField";
import Icon from "src/components/Icon";

import { fetchLogin } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

interface MyFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn, loading, error } = useSelector(loginStateSelector);

  const onFormikSubmit = async (
    values: MyFormValues
    // { setSubmitting }: FormikActions<MyFormValues>
  ) => {
    if (values.email !== "" && values.password !== "" && !loading) {
      dispatch(fetchLogin({ ...values }));
    }
  };
  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }
  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{
          password: "",
          email: "",
        }}
        onSubmit={onFormikSubmit}
        render={(formikBag: FormikProps<MyFormValues>) => (
          <form className={classes.form} onSubmit={formikBag.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              placeholder="Email"
              autoFocus
              onChange={formikBag.handleChange}
              onBlur={formikBag.handleBlur}
              value={formikBag.values.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconName="fb-at" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              placeholder="Password"
              autoComplete="current-password"
              onChange={formikBag.handleChange}
              onBlur={formikBag.handleBlur}
              value={formikBag.values.password}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon iconName="fb-password" />
                  </InputAdornment>
                ),
              }}
            />
            {error && !loading && <Alert severity="error">{error}</Alert>}
            <Grid container>
              {/*<Grid item xs>
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
              </Grid>*/}
              <Grid item className="flex items-center">
                <AdapterLink
                  to="/forgot-password"
                  variant="body2"
                  className="text-gray-3 underline"
                >
                  Forgot password?
                </AdapterLink>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {!loading && "Sign In"}
              {loading && (
                <CircularProgress color="inherit" className="w-6 h-6" />
              )}
            </Button>
            <Grid container>
              <Grid item className="text-center w-full">
                <AdapterLink
                  to="/register"
                  variant="body2"
                  type="button"
                  className="text-gray-3"
                >
                  {"Sign Up Instead"}
                </AdapterLink>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};

export default Login;
