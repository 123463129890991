import React from "react";
import { FormControl, FormLabel, Grid } from "@material-ui/core";

import { FormComponentType } from "./interfaces";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";

const RentalMortgageForm: FormComponentType = ({
  formState,
  updateField,
  hideValue,
}) => (
  <>
    <Grid item xs={hideValue ? 12 : 6} className="mt-5">
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" className="mb-3 h-4">
          What is the outstanding balance?
        </FormLabel>
        <DollarTextField
          name="balance"
          style={{ maxWidth: 350, width: "100%" }}
          onChange={updateField}
          variant="outlined"
          value={formState.balance || 0}
        />
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl component="fieldset" fullWidth className="mt-5">
        <FormLabel component="legend" className="mb-3 h-4">
          What is the monthly payment?
        </FormLabel>
        <DollarTextField
          name="payment"
          onChange={updateField}
          style={{ maxWidth: 350, width: "100%" }}
          variant="outlined"
          value={formState.payment || 0}
        />
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl component="fieldset" fullWidth className="mt-5">
        <FormLabel component="legend" className="mb-3 h-4">
          What is the interest rate?
        </FormLabel>
        <PercentTextField
          allowZero
          name="rate"
          style={{ maxWidth: 350, width: "100%" }}
          onChange={updateField}
          variant="outlined"
          value={formState.rate || 0}
        />
      </FormControl>
    </Grid>
    {!hideValue && (
      <Grid item xs={6}>
        <FormControl component="fieldset" fullWidth className="mt-5">
          <FormLabel component="legend" className="mb-3 h-4">
            What is the value of the property?
          </FormLabel>
          <DollarTextField
            name="inv_prop_value"
            style={{ maxWidth: 350, width: "100%" }}
            onChange={updateField}
            variant="outlined"
            value={formState.inv_prop_value}
          />
        </FormControl>
      </Grid>
    )}
  </>
);

export default RentalMortgageForm;
