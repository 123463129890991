import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  AppBar,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import Icon from "../../Icon";
import { LOGO } from "../../../assets/svgs/";
import ProgressBar from "../../ProgressBar";
import Stepper from "../../Stepper";
import UserMenu from "../UserMenu";

import { headerStyles } from "src/theme";
import { AdapterLink } from "src/utils";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { getCurrentStep } from "src/store/profileBuild/selector";
import { getScore } from "src/store/dashboard/selector";

const steps = [
  {
    label: "Personal Info",
    subSteps: [
      PROFILE_BUILD_STEPS.WELCOME_1,
      PROFILE_BUILD_STEPS.WELCOME_2,
      PROFILE_BUILD_STEPS.PERSONAL_INFO,
    ],
  },
  {
    label: "Human Capital",
    subSteps: [
      PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_1,
      PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO_2,
      PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW,
      PROFILE_BUILD_STEPS.MY_EDUCATION,
      PROFILE_BUILD_STEPS.MY_CAREER,
      PROFILE_BUILD_STEPS.MY_OTHER_HUMAN_CAPITAL,
      PROFILE_BUILD_STEPS.SPOUSE_EDUCATION,
      PROFILE_BUILD_STEPS.SPOUSE_CAREER,
      PROFILE_BUILD_STEPS.SPOUSE_OTHER_HUMAN_CAPITAL,
    ],
  },
  {
    label: "Income & Expenses",
    subSteps: [
      PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_1,
      PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_2,
      PROFILE_BUILD_STEPS.INCOME_EXPENSES,
    ],
  },
  {
    label: "Assets & Debts",
    subSteps: [
      PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS_INTRO,
      PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS,
    ],
  },
  {
    label: "Risk Management",
    subSteps: [
      PROFILE_BUILD_STEPS.RISK_MANAGEMENT_INTRO,
      PROFILE_BUILD_STEPS.RISK_MANAGEMENT,
    ],
  },
];

const useStyles = makeStyles(headerStyles);

const ProfileBuilderHeader = () => {
  const classes = useStyles();
  const score = useSelector(getScore);
  const [currentScore, setCurrentScore] = useState(score);
  const [animating, setAnimating] = useState(false);
  const scoreRef = useRef(currentScore);
  const finished = useRef(false);
  const shortScreen = useMediaQuery("(max-height:720px)");

  const animateScore = () => {
    setAnimating(true);
    finished.current = false;
    const targetScore = score;
    const tickScore = () => {
      setCurrentScore((c) => {
        if (c === targetScore || targetScore !== score) {
          finished.current = true;
          return c;
        }
        const diff = targetScore - c;
        if (diff > 0) {
          return c + Math.floor(Math.max(1, diff / 80));
        }
        return c + Math.floor(Math.min(-1, diff / 80));
      });
      if (!finished.current) {
        window.requestAnimationFrame(tickScore);
      } else {
        setAnimating(false);
      }
    };
    window.requestAnimationFrame(tickScore);
  };

  useEffect(() => {
    setCurrentScore(scoreRef.current);
    scoreRef.current = score;
    animateScore();
    return () => {
      finished.current = true;
    };
  }, [score]);

  const currentStep = useSelector(getCurrentStep);
  const renderUserBar = () => {
    return (
      <>
        <Grid item>
          <div className="flex items-center">
            <Typography component="h4" className={`${classes.text} mr-2`}>
              FitBUX Score
            </Typography>
            <Icon iconName="fb-meter" />
          </div>
        </Grid>
        <Grid item>
          <div className="flex items-center">
            <ProgressBar
              fbType="round"
              className="w-32"
              variant="determinate"
              value={currentScore / 10}
            />
            <Typography
              component="h4"
              className={`${classes.text} ml-2`}
              style={
                animating
                  ? {
                      fontSize: 28,
                      lineHeight: "18px",
                      marginRight: 8,
                      maxHeight: 18,
                      width: 40,
                    }
                  : {}
              }
            >
              {currentScore}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <UserMenu />
        </Grid>
      </>
    );
  };
  const currentStepIndex = steps.findIndex((step) => {
    const index = step.subSteps.findIndex(
      (stepName) => stepName === currentStep
    );
    return index > -1;
  });
  return (
    <>
      <AppBar
        position="absolute"
        elevation={0}
        className={classes.profileBuilder}
      >
        <Toolbar>
          <Grid container justify="center">
            <Grid container spacing={1} alignItems="center" className="pt-2">
              <AdapterLink to="/plan-summary">
                <img className={classes.logo} src={LOGO} />
              </AdapterLink>
              <Grid item xs></Grid>
              {renderUserBar()}
            </Grid>
            {!shortScreen && (
              <Container maxWidth="md">
                <Stepper
                  steps={steps.map((e) => e.label)}
                  active={currentStepIndex}
                />
              </Container>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ProfileBuilderHeader;
