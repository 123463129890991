import { debounce } from "lodash";

import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";
import { Account } from "../../interfaces";

export const getAccountApi = (id: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/account/account.php?id=${id}`);
    return data;
  });

export const getAccountsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/account/account.php");
    return data;
  });

export const initializeAccountsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/account/initialize.php");
    return data;
  });

export const updateAccountApi = (id: number, account: Partial<Account>) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(
      `/account/account.php?id=${id}`,
      account
    );
    return data;
  });

export const addAccountApi = (account: Partial<Account>) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("/account/account.php", account);
    if (data.length) {
      return data[0];
    }
    return null;
  });

export const getPlaidPublicTokenApi = (itemId?: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/account/public_token.php${itemId ? "?php=php&item_id=" + itemId : ""}`
    );
    return data;
  });

export const updatePlaidPublicTokenApi = (itemId: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(
      `/account/public_token.php?item_id=${itemId}`
    );
    return data;
  });

export const relinkAccountProviderApi = (itemId: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(
      `/account/providers.php?item_id=${itemId}`
    );
    return data;
  });

export const addPublicTokenApi = (payload: { public_token: string }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post(
      "/account/public_token.php",
      payload
    );
    return data;
  });

export const fetchAccountProvidersApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("/account/providers.php");
    return data;
  });

export const fetchLinkedAccountsApi = (payload: { item_id: string }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/tracking/accounts/provider.php?item_id=${payload.item_id}`
    );
    return data;
  });

export const fetchNewLinkedAccountsApi = (itemId: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      `/tracking/accounts/latest.php?item_id=${itemId}`
    );
    return data;
  });

export const fetchManualAccountsApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/account/manual.php`);
    return data;
  });

export const updateLinkedFedloanAccountApi = (payload: {
  item_id?: string;
  fed_repayment_plan: string;
  fed_loan_mthly_pmt: number;
}) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(
      `/tracking/accounts/fedloans.php?item_id=${payload.item_id}`,
      payload
    );
    return data;
  });

export const replaceAccountApi = (payload: { id: number }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`/account/replace.php`, {
      duplicate: {},
      replaced: {
        [payload.id]: null,
      },
    });
    return data;
  });

export const removeLinkedAccountApi = (payload: { id: number }) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`/account/replace.php`, {
      duplicate: {
        [payload.id]: null,
      },
    });
    return data;
  });

export const updateAccountCategoryApi = (payload: {
  category: { [accountId: string]: string };
}) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(`/tracking/categories.php`, payload);
    return data;
  });

export const markAllAccountsApi = (payload: string) =>
  makeAuthenticatedRequest(async () => {
    const {
      data,
    } = await axiosClient.put(
      `/account/providers.php?item_id=${payload}&php=php`,
      { setup: true }
    );
    return data;
  });

export const fetchAccountTickersApi = (accountId: number) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/invest/holding/${accountId}`);
    return data;
  });

export const updateAccountTickerApi = (
  accountId: number,
  ticker: string,
  quantity: number
) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put(
      `/v2/invest/holding/${accountId}/${ticker}`,
      {
        quantity,
      }
    );
    return data;
  });

export const deleteAccountTickerApi = (accountId: number, ticker: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.delete(
      `/v2/invest/holding/${accountId}/${ticker}`
    );
    return data;
  });

export const tickerSearchApi = (query: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/invest/ticker/search/${query}`);
    return data;
  });

export const getTickerPriceApi = (ticker: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(`/v2/invest/ticker/price/${ticker}`);
    if (!data.close_price) {
      console.error(`No price found for ticker ${ticker}`);
      return 0;
    }
    return data.close_price;
  });

const _fetchTickerOptions = debounce(
  (query, callback) => tickerSearchApi(query).then(callback),
  800
);
export const fetchTickerOptions = (query: string) =>
  new Promise((resolve) => _fetchTickerOptions(query, resolve));
