import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  makeStyles,
  RadioGroup,
  Select,
} from "@material-ui/core";

import Button from "src/components/Button";
import FbCheckbox from "src/components/Checkbox";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import FbRadio from "src/components/Radio";
import TextField from "src/components/TextField";

import { fetchNewLinkedAccountsApi, getAccountApi } from "src/apiService";
import { DollarTextField, PercentTextField } from "src/utils";
import {
  Account,
  AccountProvider,
  ASSET_TYPES,
  DEBT_TYPES,
  LinkedAccount,
  MappedAccount,
  REPAYMENT_PLANS,
  YesNo,
} from "src/interfaces";
import {
  getAccountsLoading,
  getLinkedAccounts,
} from "src/store/account/selector";
import {
  addAccount,
  getAccount,
  markProviderConfirmed,
  receiveAccount,
  removeAccount,
} from "src/store/account/actions";
import { PROFILE_TYPE } from "src/store/profileBuild/constants";
import { resumeUpdates, suspendUpdates } from "src/store/system/actions";
import { getIsMarried } from "src/store/system/selector";
import AccountItem from "./AccountItem";
import {
  fetchManualAccountsApi,
  markAllAccountsApi,
  replaceAccountApi,
  updateAccountApi,
  updateAccountCategoryApi,
  updateLinkedFedloanAccountApi,
} from "src/apiService";

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  provider: AccountProvider;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface FormValues extends Account {
  replacing?: YesNo;
  is_included?: YesNo;
  has_asset: YesNo;
  fed_repayment_plan?: string;
  fed_loans?: MappedAccount[];
}

const initialValues: FormValues = {
  id: 0,
  type: "cash_value",
  firm: "",
  name: "",
  who: PROFILE_TYPE.APPLICANT,
  balance: 0,
  balance_live: 0,
  rate: 0,
  payment: 0,
  carrying: "y",
  "asset-balance": 0,
  has_asset: "n",
  replacing: "n",
  is_included: "n",
  fed_loans: [],
  fed_repayment_plan: Object.keys(REPAYMENT_PLANS)[0],
};

const ConfirmLinkedAccountDialog = ({ open, onClose, provider }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(getAccountsLoading);
  const linkedAccounts = useSelector(getLinkedAccounts);
  const isMarried = useSelector(getIsMarried);

  const [accounts, setAccounts] = useState<LinkedAccount[]>([]);
  const [selected, setSelected] = useState<LinkedAccount | undefined>();
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [formCompleted, setFormCompleted] = useState<boolean>(false);
  const [manualAccounts, setManualAccounts] = useState<Account[]>([]);
  const [selectedManualAccounts, setSelectedManualAccounts] = useState<
    Set<number>
  >(new Set([]));
  const [confirming, setConfirming] = useState<boolean>(false);
  const [replacing, setReplacing] = useState<boolean>(false);
  const [fedLoans, setFedLoans] = useState<Account[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(suspendUpdates());
    } else {
      dispatch(resumeUpdates());
    }
  }, [open]);

  useEffect(() => {
    if (provider && open) {
      const confirmed: number[] = getConfirmedList();
      fetchNewLinkedAccountsApi(provider.item_id)
        .then(({ accounts }) => {
          const newFedLoans: Account[] = [];
          let fedLoanId: number;
          const promises: Array<Promise<any>> = [];
          const linkedAccounts = accounts
            .map((account: LinkedAccount) => {
              if (account.category === "fed_loan") {
                newFedLoans.push(account);
                if (!fedLoanId) {
                  fedLoanId = account.id;
                  return account;
                } else {
                  return null;
                }
              } else {
                if (account.category === "credit_card") {
                  promises.push(
                    getAccountApi(account.id).then((accountDetail) => {
                      Object.assign(account, accountDetail);
                    })
                  );
                }
                return account;
              }
            })
            .filter(
              (account: LinkedAccount | null) =>
                account !== null && confirmed.indexOf(account.id) < 0
            );
          return Promise.all(promises).then(() => {
            setFedLoans(newFedLoans);
            setAccounts(linkedAccounts);
            if (!linkedAccounts.length) {
              markProvider();
            }
          });
        })
        .catch(console.error);
    } else {
      setSelected(undefined);
    }
  }, [linkedAccounts, provider, open]);

  useEffect(() => {
    if (selected) {
      getAccountApi(selected.id).then((account) => {
        dispatch(receiveAccount(account));
        const newFormValues = {
          ...initialValues,
          firm: provider.name,
          type: account.variable,
          ...account,
          carrying: account.carrying ? "y" : "n",
        };
        if (account.variable === "fed_loan") {
          newFormValues.balance = selected.balance;
          newFormValues.rate = selected.rate;
        }
        setFormValues(newFormValues);
        setFormCompleted(false);
        setSelectedManualAccounts(new Set([]));
      });
    } else {
      setFormValues(initialValues);
    }
  }, [selected]);

  const getConfirmedList = () => {
    try {
      const confirmed: number[] = JSON.parse(
        window.localStorage.getItem(`confirmed_${provider.item_id || ""}`) ||
          "[]"
      );
      return confirmed;
    } catch {
      return [];
    }
  };

  const isDebt = (type?: string) => {
    if (type) {
      return !!DEBT_TYPES[type];
    }
    return false;
  };

  const onSelect = (id: number) => {
    const account = accounts.find((e) => e.id === id);
    if (account) {
      if (account.category === "fed_loan") {
        let totalBalance = 0;
        let totalRate = 0;
        Promise.all(fedLoans.map((loan) => getAccountApi(loan.id))).then(
          (accounts) => {
            accounts.forEach((loan) => {
              totalBalance += loan?.balance || 0;
              totalRate += (loan?.rate || 0) * (loan?.balance || 0);
            });
            const newSelected = {
              ...account,
              balance: totalBalance,
              rate: totalBalance ? totalRate / totalBalance : 0,
            };
            setSelected(newSelected);
          }
        );
      } else {
        setSelected(account);
      }
    }
  };

  const markProvider = async () => {
    try {
      await markAllAccountsApi(provider.item_id);
      dispatch(markProviderConfirmed(provider.item_id));
      onClose();
    } catch {
      // TODO;
    }
  };

  const handleChange = (prop: keyof FormValues) => (
    event: React.ChangeEvent<any>
  ) => {
    let value = event.target.value;
    let carrying = formValues.carrying;

    if (prop === "type" && value === "credit_card") {
      carrying = "y";
    } else if (prop === "carrying") {
      if (carrying === "y") {
        carrying = "n";
      } else {
        carrying = "y";
      }
    } else if (
      prop === "balance" ||
      prop === "balance_live" ||
      prop === "payment" ||
      prop === "asset-balance" ||
      prop === "rate"
    ) {
      value = Math.abs(value);
    }

    setFormValues({
      ...formValues,
      [prop]: value,
      carrying,
    });
  };

  const handleConfirm = async () => {
    if (!selected?.id) return;
    setConfirming(true);
    try {
      const confirmed = getConfirmedList();
      if (
        formValues.type &&
        selected &&
        selected.category !== formValues.type
      ) {
        await updateAccountCategoryApi({
          category: { ["" + selected.id]: formValues.type },
        });
      }
      switch (formValues.type) {
        case "fed_loan":
          await updateLinkedFedloanAccountApi({
            item_id: provider.item_id,
            fed_repayment_plan: formValues.fed_repayment_plan || "std_plan",
            fed_loan_mthly_pmt: formValues.payment,
          });
          accounts.forEach((a) => {
            confirmed.push(a.id);
          });
          break;
        case "priv_loan":
        case "perkins_loan":
        case "personal_loan":
        case "other_debt":
          await updateAccountApi(selected?.id || 0, {
            type: formValues.type,
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance,
            rate: formValues.rate,
            who: formValues.who,
          });
          confirmed.push(selected.id);
          break;
        case "property_loan":
        case "auto_loan":
        case "home_loan":
          await updateAccountApi(selected?.id || 0, {
            type: formValues.type,
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance,
            payment: formValues.payment,
            rate: formValues.rate,
            who: formValues.who,
          });
          confirmed.push(selected.id);
          if (formValues.has_asset && formValues.type) {
            dispatch(
              addAccount({
                account: {
                  type: formValues.type.replace("_loan", "_value"),
                  firm: formValues.firm,
                  name: formValues.name,
                  balance: formValues["asset-balance"] || 0,
                  who: formValues.who,
                },
              })
            );
          }

          break;
        case "credit_card":
          await updateAccountApi(selected?.id || 0, {
            type: formValues.type,
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance || 0,
            balance_live: formValues.balance_live || formValues.balance || 0,
            carrying: formValues.carrying,
            rate: formValues.rate,
            payment: formValues.payment,
            who: formValues.who,
          });
          confirmed.push(selected.id);
          break;
        default:
          // assets
          await updateAccountApi(selected?.id || 0, {
            type: formValues.type,
            firm: formValues.firm,
            name: formValues.name,
            balance: formValues.balance,
            who: formValues.who,
          });
          confirmed.push(selected.id);
      }

      localStorage.setItem(
        `confirmed_${provider.item_id || ""}`,
        JSON.stringify(confirmed)
      );
      const newAccounts = accounts.filter((a) => {
        const index = confirmed.indexOf(a.id);
        return index === -1;
      });
      setAccounts(newAccounts);

      setFormCompleted(true);
      if (formValues.replacing === "y") {
        const allManuals: Account[] = await fetchManualAccountsApi();
        const manuals = allManuals.filter(
          (e) => e.variable === (formValues.type || selected.category)
        );
        setManualAccounts(manuals);
      } else if (!newAccounts.length) {
        markProvider();
      }
      dispatch(getAccount(selected.id));
    } catch {
      // TODO
    }
    setConfirming(false);
  };

  const handleReplace = async () => {
    if (!selectedManualAccounts.size) {
      return;
    }
    try {
      setReplacing(true);
      const replaceAccountIds = Array.from(selectedManualAccounts);
      for (let i = 0; i < replaceAccountIds.length; i++) {
        const id = replaceAccountIds[i];
        await replaceAccountApi({ id });
        dispatch(removeAccount(id));
      }
    } catch {
      // nothing
    }
    setFormValues(() => initialValues);
    setManualAccounts([]);
    if (!accounts.length) {
      markProvider();
    }
    setReplacing(false);
  };

  const availableTypes = isDebt(formValues.type || selected?.category)
    ? DEBT_TYPES
    : ASSET_TYPES;
  const toggleSelectedManualAccount = (id: number) => {
    const newManualAccountIds = new Set(selectedManualAccounts);
    if (newManualAccountIds.has(id)) {
      newManualAccountIds.delete(id);
    } else {
      newManualAccountIds.add(id);
    }
    setSelectedManualAccounts(newManualAccountIds);
  };

  let title = selected
    ? selected.name
    : "Link Your Financial Accounts To FitBUX";
  if (selected?.category === "fed_loan") {
    title = "Federal Student Loans";
  }

  return (
    <>
      <CustomDialog
        title={title}
        size="sm"
        isOpen={open}
        onClose={() => {
          if (!confirming && onClose) {
            onClose();
          }
        }}
      >
        <Box className={classes.container}>
          {!selected ? (
            <Grid item xs={12}>
              {accounts.map((account, index) => {
                let value = account.balance_live || account.balance || 0;
                let accountName = account.name;
                if (account.category === "fed_loan") {
                  accountName = "Federal Student Loans";
                  value = fedLoans.reduce(
                    (total, loan) => total + (loan?.balance || 0),
                    0
                  );
                }
                return (
                  <AccountItem
                    key={index}
                    title={accountName}
                    value={formatter.format(value)}
                    hasIcon={true}
                    id={account.id}
                    onConfirm={onSelect}
                    nAccounts={
                      account.category === "fed_loan"
                        ? fedLoans?.length || 0
                        : 0
                    }
                  />
                );
              })}
            </Grid>
          ) : (
            <>
              {!formCompleted ? (
                <>
                  <FormLabel component="legend" className="mb-2 mt-4">
                    Account Type
                  </FormLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={formValues.type || selected.category}
                    disabled={loading || selected.category === "fed_loan"}
                    onChange={handleChange("type")}
                  >
                    {Object.keys(availableTypes).map((type) => (
                      <MenuItem value={type} key={type}>
                        {availableTypes[type]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormLabel component="legend" className="mb-2 mt-4">
                    Financial Institution
                  </FormLabel>
                  <TextField
                    className="mb-0 mt-1"
                    label=""
                    variant="outlined"
                    onChange={handleChange("firm")}
                    disabled
                    value={formValues.firm}
                    fullWidth
                  />
                  {selected.category !== "fed_loan" && (
                    <>
                      <FormLabel component="legend" className="mb-2 mt-4">
                        Account Name
                      </FormLabel>
                      <TextField
                        className="mb-0 mt-1"
                        label=""
                        variant="outlined"
                        onChange={handleChange("name")}
                        value={formValues.name}
                        fullWidth
                      />
                    </>
                  )}
                  {formValues.type === "credit_card" && (
                    <FormControl component="fieldset" className="pl-1 mt-4">
                      <FormControlLabel
                        control={
                          <FbCheckbox
                            value={formValues.carrying === "y" ? "n" : "y"}
                            checked={formValues.carrying === "n"}
                            onChange={handleChange("carrying")}
                            disabled={formValues.type !== "credit_card"}
                          />
                        }
                        label="I pay this off monthly"
                      />
                    </FormControl>
                  )}
                  <FormLabel component="legend" className="mb-2 mt-4">
                    Balance
                  </FormLabel>
                  <DollarTextField
                    variant="outlined"
                    fullWidth
                    id="balance_input"
                    label=""
                    disabled={!formValues?.manual?.balance}
                    onChange={handleChange("balance")}
                    value={formValues.balance_live || formValues.balance}
                  />
                  {isDebt(formValues.type || selected?.category) && (
                    <>
                      <FormLabel component="legend" className="mb-2 mt-4">
                        Interest Rate
                      </FormLabel>
                      <PercentTextField
                        label=""
                        variant="outlined"
                        disabled={!formValues?.manual?.rate}
                        onChange={handleChange("rate")}
                        value={formValues.rate}
                        fullWidth
                      />
                      {selected.category !== "fed_loan" && (
                        <>
                          <FormLabel component="legend" className="mb-2 mt-4">
                            Monthly Payment
                          </FormLabel>
                          <DollarTextField
                            variant="outlined"
                            fullWidth
                            disabled={!formValues?.manual?.payment}
                            id="payment_input"
                            label=""
                            onChange={handleChange("payment")}
                            value={formValues.payment}
                          />
                        </>
                      )}
                      {selected.category === "fed_loan" && (
                        <>
                          <FormLabel component="legend" className="mb-2 mt-4">
                            Repayment Plan
                          </FormLabel>
                          <Select
                            variant="outlined"
                            fullWidth
                            name="fed_repayment_plan"
                            value={formValues.fed_repayment_plan}
                            disabled={loading}
                            onChange={handleChange("fed_repayment_plan")}
                          >
                            {Object.keys(REPAYMENT_PLANS).map((type) => (
                              <MenuItem value={type} key={type}>
                                {REPAYMENT_PLANS[type]}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </>
                  )}
                  {(formValues.type === "auto_loan" ||
                    formValues.type === "home_loan" ||
                    formValues.type === "property_loan") && (
                    <>
                      <FormControl component="fieldset" className="mt-4">
                        <FormLabel component="legend" className="mb-2">
                          Do you already have a corresponding asset account?
                        </FormLabel>
                        <RadioGroup
                          value={formValues.has_asset}
                          className="flex flex-row"
                          onChange={handleChange("has_asset")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                      {formValues.has_asset === "y" && (
                        <>
                          <FormLabel component="legend" className="mb-2 mt-4">
                            Corresponding Asset Value
                          </FormLabel>
                          <DollarTextField
                            variant="outlined"
                            fullWidth
                            disabled={formValues.is_included === "y"}
                            id="est_market_value_input"
                            label=""
                            onChange={handleChange("asset-balance")}
                            value={formValues["asset-balance"]}
                          />
                          <FormControl
                            component="fieldset"
                            className="block mt-4"
                          >
                            <FormLabel component="legend" className="mb-2">
                              Have you already included this asset in your
                              FitBUX profile?
                            </FormLabel>
                            <RadioGroup
                              value={formValues.is_included}
                              className="flex flex-row"
                              onChange={handleChange("is_included")}
                            >
                              <FormControlLabel
                                value={"n"}
                                control={<FbRadio />}
                                label="No"
                              />
                              <FormControlLabel
                                value={"y"}
                                control={<FbRadio />}
                                label="Yes"
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                  <FormControl component="fieldset" className="block mt-4">
                    <FormLabel component="legend" className="mb-2">
                      Replacing Manual account
                    </FormLabel>
                    <RadioGroup
                      value={formValues.replacing}
                      className="flex flex-row"
                      onChange={handleChange("replacing")}
                    >
                      <FormControlLabel
                        value={"n"}
                        control={<FbRadio />}
                        label="No"
                      />
                      <FormControlLabel
                        value={"y"}
                        control={<FbRadio />}
                        label="Yes"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box className="text-right mt-4">
                    <Button
                      fbColor="primary"
                      disabled={loading || confirming}
                      className="mr-3"
                      onClick={() => setSelected(undefined)}
                    >
                      Back
                    </Button>
                    <Button
                      fbColor="primary"
                      disabled={loading || confirming}
                      onClick={() => handleConfirm()}
                    >
                      Confirm
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {formValues.replacing === "y" ? (
                    <>
                      {manualAccounts && !!manualAccounts.length ? (
                        <>
                          <Grid item xs={12}>
                            {manualAccounts.map((account, index) => {
                              let whose = "";
                              if (isMarried) {
                                whose = ` | ${
                                  account.who === "spouse"
                                    ? "(Spouse)"
                                    : "(Mine)"
                                }`;
                              }
                              return (
                                <AccountItem
                                  key={index}
                                  title={`${account.name || "Unnamed"}${whose}`}
                                  selected={selectedManualAccounts.has(
                                    account.id
                                  )}
                                  value={formatter.format(account.balance || 0)}
                                  hasIcon={false}
                                  id={account.id}
                                  onSelect={() =>
                                    toggleSelectedManualAccount(account.id)
                                  }
                                />
                              );
                            })}
                          </Grid>
                          <Grid item xs={12} className="text-right">
                            <Button
                              fbColor="primary"
                              className="mr-3"
                              onClick={() =>
                                setFormValues({
                                  ...formValues,
                                  replacing: "n",
                                })
                              }
                              disabled={loading || replacing}
                            >
                              Cancel
                            </Button>
                            <Button
                              fbColor="primary"
                              disabled={
                                loading ||
                                !selectedManualAccounts.size ||
                                replacing
                              }
                              onClick={handleReplace}
                            >
                              Replace
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            No account to be replaced
                          </Grid>
                          <Grid item xs={12} className="text-right">
                            <Button
                              fbColor="primary"
                              className="mr-3"
                              onClick={() =>
                                setFormValues({
                                  ...formValues,
                                  replacing: "n",
                                })
                              }
                              disabled={loading}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        Are there additional accounts to confirm?
                      </Grid>
                      <Grid item xs={12} className="text-right">
                        <Button
                          fbColor="primary"
                          className="mr-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                          }}
                        >
                          No
                        </Button>
                        <Button
                          fbColor="primary"
                          onClick={() => setSelected(undefined)}
                          disabled={loading}
                        >
                          Yes
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </CustomDialog>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    padding: "0 6px",
    maxHeight: 600,
    overflowY: "auto",
  },
});

export default ConfirmLinkedAccountDialog;
