import React, { useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import { getIsSubscribed } from "src/store/system/selector";
import { investmentsStyles } from "src/theme";

import UnsubscribedIntro from "./UnsubscribedIntro";
import TaxBombIntro from "./TaxBombIntro";
import AccountsAndAssumptions from "./AccountsAndAssumptions";
import IdrDetails from "./IdrDetails";

const useStyles = makeStyles(investmentsStyles);

export const TaxBomb = () => {
  const classes = useStyles();
  const subscribed = useSelector(getIsSubscribed);
  const [step, setStep] = useState(0);
  const [showSpouseData, setShowSpouseData] = useState(false);

  const toggleShowSpouseData = () => setShowSpouseData((current) => !current);

  const renderContent = () => {
    if (!subscribed) {
      return <UnsubscribedIntro />;
    }

    switch (step) {
      case 0:
        return (
          <TaxBombIntro
            next={() => setStep(1)}
            setShowSpouseData={setShowSpouseData}
          />
        );
      case 1:
        return (
          <IdrDetails
            next={() => setStep(2)}
            showSpouseData={showSpouseData}
            toggleShowSpouseData={toggleShowSpouseData}
          />
        );
      case 2:
      default:
        return (
          <AccountsAndAssumptions
            goBack={() => setStep(1)}
            showSpouseData={showSpouseData}
            toggleShowSpouseData={toggleShowSpouseData}
          />
        );
    }
  };

  return <main className={classes.content}>{renderContent()}</main>;
};

export default TaxBomb;
