import React from "react";
import { useSelector } from "react-redux";

import {
  Grid,
  makeStyles,
} from "@material-ui/core";

import ActionItems from "./Blocks/ActionItems";
import PlanSummaryChart from "./Blocks/PlanSummaryChart";
// import StudentBudget from "./Blocks/StudentBudget";
import UpgradeCard from "./Blocks/UpgradeCard";
import ScoreSummary from "src/pages/Dashboard/ScoreFactors/Blocks/FitbuxScore";
import { getIsSubscribed } from "src/store/system/selector";

const useStyles = makeStyles({
  freeFocus: {
    order: -1,
  },
});

export const NoPlanSummary = () => {
  const styles = useStyles();
  const isSubscribed = useSelector(getIsSubscribed);
  // const isCurrentStudent = useSelector(getIsCurrentStudent);

  const orderStyle = isSubscribed ? undefined : styles.freeFocus;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ScoreSummary showLink />
      </Grid>
      <Grid item xs={12} md={6} className={orderStyle}>
        <PlanSummaryChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <ActionItems />
      </Grid>
      {!isSubscribed && (
        <Grid item xs={12} md={6}>
          <UpgradeCard />
        </Grid>
      )}
    </Grid>
  );
};

export default NoPlanSummary;
