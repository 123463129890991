import React, { useEffect, useMemo, useState } from "react";
import { Finance } from "financejs";

import {
  getPayoffAnalysisLoansApi,
  getPayoffAnalysisResultsApi,
  PayoffAccountsPayload,
  PayoffAccountsResponse,
  PayoffAnalysisPayload,
  PayoffAnalysisResponse,
} from "src/apiService";
import PayoffAccounts from "./PayoffAccounts";
import PayoffResults from "./PayoffResults";

const finance = new Finance();

export const PayoffAnalysis = ({ goBack }: any) => {
  const [loanData, setLoanData] = useState<PayoffAccountsResponse[]>([]);
  const [selectedTerms, setSelectedTerms] = useState<{
    [accountId: string]: number;
  }>({});
  const [resultsData, setResultsData] = useState<PayoffAnalysisResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [loadingResults, setLoadingResults] = useState(false);
  const [
    fetchAnalysisPayload,
    setAnalysisPayload,
  ] = useState<PayoffAnalysisPayload | null>(null);

  const goToPage = (nextPage: number) => {
    setCurrentPage(nextPage);
    window.scrollTo(0, 0);
  };

  const defaultExtendTerms = loanData
    ? loanData.reduce((result: any, loan) => {
        result["" + loan.id] = loan.term;
        return result;
      }, {})
    : {};

  useEffect(() => {
    fetchLoanData();
  }, []);

  const getPayment = (loan: PayoffAccountsResponse) => {
    if (loan.type !== "federal") {
      return loan.payment;
    }
    const term = selectedTerms["" + loan.id];
    if (!term) {
      return loan.payment;
    }
    return finance.PMT(loan.interest / 100 / 12, term * 12, -loan.balance);
  };

  const totals = useMemo(() => {
    const output = loanData.reduce(
      (result, loan) => {
        result.balance += loan.balance;
        result.interest += loan.interest;
        result.term += loan.term;
        result.payment += getPayment(loan);
        return result;
      },
      {
        id: -999,
        balance: 0,
        interest: 0,
        payment: 0,
        term: 0,
        type: "federal",
        whose: "mine",
      }
    );
    output.interest = output.interest / output.balance;
    output.term = output.term / output.balance;
    return output;
  }, [loanData]);

  const totalPayment = useMemo(() => {
    return loanData.reduce((result, loan) => {
      return result + loan.payment;
    }, 0);
  }, [loanData]);

  const fetchLoanData = (payload?: PayoffAccountsPayload) => {
    if (payload) {
      setSelectedTerms(payload.selectedTerms);
    }
    return getPayoffAnalysisLoansApi(payload).then((loans) => {
      setLoanData(loans);
    });
  };

  const fetchAnalysisResults = (
    payload: PayoffAnalysisPayload,
    isNew?: boolean
  ) => {
    setAnalysisPayload(payload);
    setLoadingResults(true);
    return getPayoffAnalysisResultsApi(payload)
      .then((result) => {
        setLoadingResults(false);
        const index = 4 - payload.sno;
        const newResultsData = isNew ? [] : [...resultsData];
        newResultsData[index] = result;
        setResultsData(newResultsData);
      })
      .catch(console.error);
  };

  const selectTab = (tabIndex: number) => {
    if (!resultsData[tabIndex]) {
      const payload: PayoffAnalysisPayload = {
        ...(fetchAnalysisPayload as PayoffAnalysisPayload),
        sno: 4 - tabIndex,
      };
      setAnalysisPayload(payload);
      fetchAnalysisResults(payload);
    }
    setCurrentTab(tabIndex);
  };

  const loadNewResults = (extraPrepay: number, dropoffRate: number) => {
    const payload = {
      sno: 4 - currentTab,
      extra_prepay: extraPrepay,
      drop_off_ratio: dropoffRate,
      defaultExtendedTerms: defaultExtendTerms,
      selectedTerms: selectedTerms,
    };
    return fetchAnalysisResults(payload, true);
  };

  const getExtend = () => {
    for (let i = 0; i < loanData.length; i++) {
      const selectedTerm = selectedTerms["" + loanData[i].id];
      if (selectedTerm && selectedTerm !== loanData[i].term) {
        return true;
      }
    }
    return false;
  };

  switch (currentPage) {
    case 0:
      return (
        <PayoffAccounts
          loans={loanData}
          totals={totals}
          update={fetchLoanData}
          getPayment={getPayment}
          defaultExtendTerms={defaultExtendTerms}
          selectedTerms={selectedTerms}
          setSelectedTerms={setSelectedTerms}
          onNext={() => goToPage(1)}
          goBack={goBack}
        />
      );
    case 1:
    default:
      return (
        <PayoffResults
          totals={totals}
          totalPayment={totalPayment}
          tab={currentTab}
          setTab={selectTab}
          extend={getExtend()}
          loaded={!!resultsData.length}
          data={resultsData[currentTab]}
          loadNewResults={loadNewResults}
          onBack={() => goToPage(0)}
          loading={loadingResults}
        />
      );
  }
};

export default PayoffAnalysis;
