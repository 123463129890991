import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { pick, range } from "lodash";

import {
  Box,
  Grid,
  makeStyles,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import Button from "src/components/Button";
import CustomDialog from "src/components/Dialogs/CustomDialog";
import Radio from "src/components/Radio";

import { updateHouseholdApi, updateUserApi } from "src/apiService";
import { DAYS_IN_MONTH, MONTHS, STATES } from "src/constants";
import { fetchTaxes } from "src/store/cashflow/actions";
import {
  updateHouseholdSuccess,
  updatePersonalInfo,
  UpdatePersonalInfoPayload,
} from "src/store/profileBuild/actions";
import { getHousehold, getPersonalInfo } from "src/store/profileBuild/selector";
import { updateUser } from "src/store/system/actions";
import { userSelector } from "src/store/system/selector";
import {
  PERSONAL_INFO_KEYS,
  DASHBOARD_HOUSEHOLD_KEYS,
} from "src/store/profileBuild/selectorKeys";
import { PersonalInfoSection, Household, User } from "src/interfaces";

import InformationCard from "../Components/InformationCard";
import ProfileInformationCard from "../Components/profileInformationCard";
import {
  formatZip,
  PhoneNumberInput,
  validateEmail,
  validateZip,
} from "src/utils";

interface MyFormValues extends PersonalInfoSection, User, Partial<Household> {
  zip: string;
  children_qty: number;
  child_1_age: number;
  child_2_age: number;
  child_3_age: number;
  child_4_age: number;
  child_5_age: number;
}

const ProfileInformation = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  const existingProfile = useSelector(getPersonalInfo);
  const household = useSelector(getHousehold);
  const user = useSelector(userSelector);
  const [emailError, setEmailError] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [formState, setFormState] = useState<MyFormValues>({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    dob_month: 1,
    dob_day: 1,
    dob_year: 1999,
    sex: "o",
    zip: "",
    children_qty: 0,
    child_1_age: 0,
    child_2_age: 0,
    child_3_age: 0,
    child_4_age: 0,
    child_5_age: 0,
  });

  useEffect(() => {
    if (dialogOpen) {
      const foundHousehold = household || {};
      const foundUser = user || {};
      const newFormState = {
        ...formState,
        ...foundUser,
        ...existingProfile,
        ...foundHousehold,
      };
      setFormState(newFormState);
    }
  }, [existingProfile, household, user, dialogOpen]);

  const setFormValue = (e: any) => {
    const target = e.target;
    let value = target.value;
    if (target.name === "zip") {
      value = formatZip(value);
    }
    if (target.name.slice(0, 5) === "child") {
      value = Math.floor(+value);
    }
    const update = { ...formState, [target.name]: value };
    if (target.name === "children_qty") {
      if (target.value === 0) {
        update.child_1_age = 0;
        update.child_2_age = 0;
        update.child_3_age = 0;
        update.child_4_age = 0;
        update.child_5_age = 0;
      }
    }
    setFormState(update);
  };

  const save = () => {
    const profileValues = pick(formState, PERSONAL_INFO_KEYS);
    const payload: UpdatePersonalInfoPayload = { update: profileValues };
    const userUpdate = pick(formState, ["first_name", "last_name", "email"]);
    const householdUpdate = pick(formState, DASHBOARD_HOUSEHOLD_KEYS);
    updateUserApi(userUpdate)
      .then(() => {
        dispatch(updateUser(userUpdate));
        return updateHouseholdApi(householdUpdate);
      })
      .then(() => {
        dispatch(updateHouseholdSuccess({ update: householdUpdate }));
        dispatch(updatePersonalInfo(payload));
        dispatch(fetchTaxes(null));
        closeDialog();
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          setDuplicateEmail(true);
        }
      });
  };

  const checkEmail = () => setEmailError(!validateEmail(formState.email));
  const checkZip = () => setZipError(!validateZip(formState.zip));

  const nowYear = new Date().getFullYear();
  const startYear = nowYear - 70;
  const endYear = nowYear - 16;

  return (
    <InformationCard title="Profile Information">
      <Box className={styles.container}>
        <ProfileInformationCard
          title="Personal Info"
          handleClick={openDialog}
        />
        <ProfileInformationCard
          title="Human Capital"
          handleClick={() => dispatch(push("/wealth#hc"))}
        />
        <ProfileInformationCard
          title={`Income & Expenses`}
          handleClick={() => dispatch(push("/budget"))}
        />
        <ProfileInformationCard
          title={`Assets & Debts`}
          handleClick={() => dispatch(push("/accounts"))}
        />
        <ProfileInformationCard
          title="Risk Management"
          handleClick={() => dispatch(push("/risk"))}
        />
      </Box>
      <CustomDialog
        title="Personal Information"
        isOpen={dialogOpen}
        onClose={closeDialog}
        size="sm"
      >
        <form>
          <Box className="flex">
            <Box className="mr-2">
              <FormLabel component="legend" className="mb-2 mt-4">
                First name
              </FormLabel>
              <TextField
                variant="outlined"
                placeholder=""
                name="first_name"
                value={formState.first_name}
                onChange={setFormValue}
              />
            </Box>
            <Box>
              <FormLabel component="legend" className="mb-2 mt-4">
                Last name
              </FormLabel>
              <TextField
                variant="outlined"
                placeholder=""
                name="last_name"
                value={formState.last_name}
                onChange={setFormValue}
              />
            </Box>
          </Box>
          <FormLabel component="legend" className="mb-2 mt-4">
            Email
          </FormLabel>
          <TextField
            variant="outlined"
            placeholder=""
            name="email"
            error={emailError || duplicateEmail}
            helperText={
              duplicateEmail
                ? "Another user with the given email address already exists."
                : emailError
                ? "Please enter a valid email address."
                : undefined
            }
            value={formState.email}
            onBlur={checkEmail}
            onFocus={() => {
              setDuplicateEmail(false);
              setEmailError(false);
            }}
            onChange={setFormValue}
          />
          <FormLabel component="legend" className="mb-2 mt-4">
            Date of birth
          </FormLabel>
          <Box className="flex">
            <Select
              variant="outlined"
              style={{ width: "150px" }}
              name="dob_month"
              onChange={setFormValue}
              value={formState.dob_month}
              className="mr-4"
            >
              {MONTHS.map((month: string, index: number) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
            <Select
              variant="outlined"
              style={{ width: "150px" }}
              name="dob_day"
              onChange={setFormValue}
              value={formState.dob_day}
              className="mr-4"
            >
              {range(1, DAYS_IN_MONTH[formState.dob_month] + 1).map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
            <Select
              variant="outlined"
              style={{ width: "150px" }}
              name="dob_year"
              onChange={setFormValue}
              value={formState.dob_year}
            >
              {range(startYear, endYear + 1).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <FormLabel component="legend" className="mb-2 mt-4">
            Street Address
          </FormLabel>
          <TextField
            variant="outlined"
            placeholder=""
            name="street_address"
            value={formState.street_address}
            onChange={setFormValue}
          />
          <FormLabel component="legend" className="mb-2 mt-4">
            City
          </FormLabel>
          <TextField
            variant="outlined"
            name="city"
            value={formState.city}
            onChange={setFormValue}
          />
          <Box className="flex">
            <Box className="mr-4">
              <FormLabel component="legend" className="mb-2 mt-4">
                State
              </FormLabel>
              <Select
                variant="outlined"
                name="state"
                value={formState.state}
                onChange={setFormValue}
              >
                {STATES.map((state) => (
                  <MenuItem value={state}>{state}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel component="legend" className="mb-2 mt-4">
                ZIP Code
              </FormLabel>
              <TextField
                variant="outlined"
                placeholder="90210"
                name="zip"
                error={zipError}
                helperText={
                  zipError ? "Please enter a valid ZIP code." : undefined
                }
                onBlur={checkZip}
                onFocus={() => setZipError(false)}
                value={formState.zip}
                onChange={setFormValue}
              />
            </Box>
          </Box>
          <FormLabel component="legend" className="mb-2 mt-4">
            Phone number
          </FormLabel>
          <PhoneNumberInput
            variant="outlined"
            name="phone_number"
            value={formState.phone_number}
            onChange={setFormValue}
          />
          <FormLabel component="legend" className="mb-2 mt-4">
            Gender
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="sex"
            value={formState.sex}
            className="flex flex-row"
            onChange={setFormValue}
          >
            <FormControlLabel
              value="m"
              className="pr-4"
              control={<Radio />}
              label="Male"
            />
            <FormControlLabel
              value="f"
              className="pr-4"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel
              value="o"
              className="pr-4"
              control={<Radio />}
              label="Other/Prefer not to say"
            />
          </RadioGroup>
          <FormLabel component="legend" className="mb-2 mt-4">
            Number of children
          </FormLabel>
          <Select
            variant="outlined"
            style={{ width: "150px" }}
            name="children_qty"
            onChange={setFormValue}
            value={formState.children_qty}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5 or more</MenuItem>
          </Select>
          {formState.children_qty > 0 && (
            <>
              <FormLabel component="legend" className="mb-2 mt-4">
                Enter the ages of your children:
              </FormLabel>
              <Grid container spacing={3}>
                {range(1, formState.children_qty + 1).map((n: number) => (
                  <Grid item xs={2} key={`child-${n}`}>
                    <TextField
                      variant="outlined"
                      placeholder={`Child ${n} age`}
                      name={`child_${n}_age`}
                      value={
                        formState[`child_${n}_age` as keyof MyFormValues] || ""
                      }
                      onChange={setFormValue}
                      type="number"
                      InputProps={{ inputProps: { step: "1" } }}
                      style={{ borderWidth: "2px", minWidth: 60 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Button
            fbColor="primary"
            style={{ display: "block", margin: "20px auto" }}
            onClick={save}
            disabled={
              !validateEmail(formState.email) || !validateZip(formState.zip)
            }
          >
            Save
          </Button>
        </form>
      </CustomDialog>
    </InformationCard>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    minWidth: 0,
    paddingTop: 20,
  },
});
export default ProfileInformation;
