import React from "react";
import { useSelector } from "react-redux";
import { Box, Container, makeStyles } from "@material-ui/core";
import Button from "src/components/Button";
import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { getCurrentStep } from "src/store/profileBuild/selector";
import HumanCapitalViews from "src/pages/ProfileBuilder/Views/HumanCapital";
import { WealthHCSteps } from "src/theme";

const useStyles = makeStyles(WealthHCSteps);

const HCCustomViews: { [key: string]: string } = {
  HUMAN_CAPITAL_OVERVIEW: "HUMAN_CAPITAL_OVERVIEW",
  MY_EDUCATION: "MY_EDUCATION",
  SPOUSE_EDUCATION: "SPOUSE_EDUCATION",
  MY_CAREER: "MY_CAREER",
  SPOUSE_CAREER: "SPOUSE_CAREER",
  MY_OTHER_HUMAN_CAPITAL: "MY_OTHER_HUMAN_CAPITAL",
  SPOUSE_OTHER_HUMAN_CAPITAL: "SPOUSE_OTHER_HUMAN_CAPITAL",
};

const HumanCapitaForm = () => {
  const styles = useStyles();
  const step = useSelector(getCurrentStep);
  const isSteps = Boolean(
    HCCustomViews[step] && step !== "HUMAN_CAPITAL_OVERVIEW"
  );
  const stepView = HCCustomViews[step] || "HUMAN_CAPITAL_OVERVIEW";
  const renderFunctions: { [step: string]: ViewComponent } = {
    ...HumanCapitalViews,
  };
  const View = renderFunctions[stepView];

  return (
    <Box className={styles.content}>
      <View
        render={({ component, onPrev, onNext, nextLabel, nextDisabled }) => (
          <>
            <Container className="p-0">{component}</Container>
            {isSteps && (
              <Box className={styles.footer}>
                <Container>
                  <Box>
                    {onPrev && (
                      <Button
                        color="primary"
                        variant="outlined"
                        className="mr-3"
                        onClick={onPrev}
                      >
                        Back
                      </Button>
                    )}
                  </Box>
                  <Box>
                    {onNext && (
                      <Button
                        color="primary"
                        onClick={onNext}
                        disabled={nextDisabled}
                      >
                        {nextLabel || "Next"}
                      </Button>
                    )}
                  </Box>
                </Container>
              </Box>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default HumanCapitaForm;
