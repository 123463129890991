import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles } from "@material-ui/core";

import {
  Earning,
  PlanViewComponent,
  INCOME_TYPES,
  PLAN_INCOME_TYPES,
  Who,
} from "src/interfaces";
import { getRawCashflows } from "src/store/cashflow/selector";
import { setMiscState, setRefreshNeeded } from "src/store/dashboard/actions";
import { getMiscState } from "src/store/dashboard/selector";
import { getLiabilities, savePlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getCurrentStep,
  getPlanBuilderReady,
  getRawIncomes,
  getStudentLoanData,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import LeftSidebar from "./Components/LeftSidebar";
import RightSidebar from "./Components/RightSidebar";
import ReviewSidebar from "./Components/ReviewSidebar";
import introViews from "./Views/Introduction";
import incomeAndExpensesViews from "./Views/DayToDayMoney";
import expensesAndInsuranceViews from "./Views/ExpensesAndInsurance";
import futureQuestionsViews from "./Views/FutureQuestions";
import assetsAndDebtsViews from "./Views/MoneyForFutureGoals";
import studentAssetsAndDebtsViews from "./Views/StudentAssetsAndDebts";
import riskManagementViews from "./Views/RiskManagement";
import educationFundingViews from "./Views/EducationFunding";
import lifeEventsViews from "./Views/LifeEvents";
import graduatedLifeEventsViews from "./Views/GraduatedLifeEvents";
import emergencyFundViews from "./Views/EmergencyFund";
import recommendedViews from "./Views/Review";
import reviewViews from "./Views/Review";
import implementViews from "./Views/Implement";

import { planBuilderStyles } from "src/theme";

const useStyles = makeStyles(planBuilderStyles);

const graduateRenderFunctions: { [key: number]: PlanViewComponent } = {
  ...introViews,
  ...graduatedLifeEventsViews,
  ...incomeAndExpensesViews,
  ...assetsAndDebtsViews,
  ...riskManagementViews,
  ...emergencyFundViews,
  ...recommendedViews,
  ...reviewViews,
  ...implementViews,
};

const studentRenderFunctions: { [key: number]: PlanViewComponent } = {
  ...introViews,
  ...educationFundingViews,
  ...expensesAndInsuranceViews,
  ...studentAssetsAndDebtsViews,
  ...futureQuestionsViews,
  ...lifeEventsViews,
  ...reviewViews,
  ...implementViews,
};

export const Content = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const step: PLAN_BUILD_STEPS = useSelector(getCurrentStep);
  const currentPlan = useSelector(getCurrentPlan);
  const planIncomes = useSelector(getRawIncomes);
  const liveCashflows = useSelector(getRawCashflows);
  const ready = useSelector(getPlanBuilderReady);
  const miscState = useSelector(getMiscState);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const studentloan = useSelector(getStudentLoanData);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);

  const inschool = !!currentPlan.education;

  // save plan on unmount (save plan will be a no-op if planBuildState.dirty is not set)
  useEffect(() => {
    return () => {
      dispatch(savePlan(null));
    };
  }, []);

  const incomes: Earning[] = useMemo(() => {
    if (isCurrentStudent || !currentPlanIsImplemented) {
      return planIncomes;
    }
    return liveCashflows
      .filter((item) => !!INCOME_TYPES[item.type])
      .map((item) => {
        const result = {
          type: item.type,
          who: item.whose === "spouse" ? "spouse" : ("applicant" as Who),
          earning: item.amount || 0,
        };
        if (item.type === "other_income") {
          result.type = "other";
        } else if (item.type === "rental_income") {
          result.type = "rent";
        }
        return result;
      });
  }, [planIncomes, liveCashflows, currentPlanIsImplemented, isCurrentStudent]);

  useEffect(() => {
    if (miscState && !miscState.seenPlanIntro) {
      // dispatch(
      //   setVideo(isCurrentStudent ? "planIntroStudent" : "planIntroGraduated")
      // );
      dispatch(setMiscState({ ...miscState, seenPlanIntro: true }));
    }
    dispatch(setRefreshNeeded({ projection: true }));
  }, []);

  useEffect(() => {
    if (!ready) {
      dispatch(push("/dashboard"));
    }
  }, []);

  const emptyView: PlanViewComponent = ({ render }) =>
    render({ component: <div>No view</div> });

  useEffect(() => {
    if (isCurrentStudent || !currentPlanIsImplemented) {
      const liabilityIncomes: Earning[] = isCurrentStudent
        ? ((currentPlan.education || []).filter(
            (item) =>
              !!PLAN_INCOME_TYPES[item.type] &&
              +(item.date || 0) === new Date().getFullYear()
          ) as Earning[])
        : incomes || [];
      dispatch(
        getLiabilities({
          filing_jointly: currentPlan.profile.filing_jointly,
          inschool,
          incomes: liabilityIncomes,
          studentloan,
          allocations: currentPlan.allocations[0],
        })
      );
    }
  }, [
    currentPlan,
    planIncomes,
    isCurrentStudent,
    liveCashflows,
    currentPlanIsImplemented,
  ]);

  const renderFunctions: any = isCurrentStudent
    ? studentRenderFunctions
    : graduateRenderFunctions;

  const View: PlanViewComponent = renderFunctions[step] || emptyView;
  return (
    <Box className={styles.content}>
      <View
        render={({
          component,
          onNext,
          nextDisabled,
          nextLabel,
          nextTooltip,
          hideSidebars,
          displayReviewSidebar,
        }) => (
          <>
            {!hideSidebars && <LeftSidebar />}
            {displayReviewSidebar && <ReviewSidebar />}
            <Box className={styles.main}>{component}</Box>
            {!hideSidebars && (
              <RightSidebar
                onNext={onNext}
                nextLabel={nextLabel}
                nextDisabled={nextDisabled}
                nextTooltip={nextTooltip}
              />
            )}
          </>
        )}
      />
    </Box>
  );
};
