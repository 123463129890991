import React from "react";
import { useSelector } from "react-redux";

import { FormControl, FormLabel, Grid } from "@material-ui/core";

import { getStudentStatus } from "src/store/profileBuild/selector";
import { FormComponentType } from "./interfaces";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";

const PerkinsLoanForm: FormComponentType = ({ formState, updateField }) => {
  const monthsSinceGraduation = useSelector(getStudentStatus);
  return (
    <>
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            Total Perkins loan balance
          </FormLabel>
          <DollarTextField
            name="balance"
            style={{ maxWidth: 350, width: "100%" }}
            onChange={updateField}
            variant="outlined"
            value={formState.balance || 0}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            What is the average interest rate?
          </FormLabel>
          <PercentTextField
            allowZero
            name="rate"
            style={{ maxWidth: 350, width: "100%" }}
            onChange={updateField}
            variant="outlined"
            value={formState.rate || 0}
          />
        </FormControl>
      </Grid>
      {monthsSinceGraduation >= 6 && (
        <Grid item xs={12} className="mt-5">
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className="mb-3 h-4">
              What is the total monthly payment?
            </FormLabel>
            <DollarTextField
              name="payment"
              style={{ maxWidth: 350, width: "100%" }}
              onChange={updateField}
              variant="outlined"
              value={formState.payment || 0}
            />
          </FormControl>
        </Grid>
      )}
    </>
  );
};

export default PerkinsLoanForm;
