import React from "react";

import { Box, CssBaseline, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import ProfileBuilderHeader from "../components/Headers/ProfileBuilderHeader";
import Tutorial from "../components/Tutorial";
import Video from "../components/Video";

import { profileBuilderStyles, theme } from "../theme";

const useStyles = makeStyles(profileBuilderStyles);

export const ProfileBuilderLayout = (props: any) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProfileBuilderHeader />
      <Box className={classes.root}>{props.children || ""}</Box>
      <Tutorial />
      <Video />
    </ThemeProvider>
  );
};

export default ProfileBuilderLayout;
