import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cloneDeep } from "lodash";

import { Container } from "@material-ui/core";
import MainCard from "src/components/MainCard";

import { getScore } from "src/store/dashboard/actions";
import {
  addCashflow,
  AddCashflowItemPayload,
  addDraftCashflow,
  editCashflow,
  removeCashflow,
  removeDraftCashflow,
  replaceCashflow,
} from "src/store/cashflow/actions";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import {
  getCashflows,
  getLoadedCashflow,
  getLoadingCashflow,
} from "src/store/cashflow/selector";
import {
  getSpouseInSchool,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried, getIsCurrentStudent } from "src/store/system/selector";
import { EXPENSE_TYPES, INCOME_TYPES, WHO_TYPES } from "src/interfaces/common";
import { Cashflow, MappedCashflow } from "src/interfaces/cashflow.interface";
import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { formatAnnually, formatMonthly } from "src/utils";

const now = -Date.now();
const startingExpenses: MappedCashflow[] = [
  {
    id: now - 1,
    type: "grocery",
    typeLabel: "Groceries",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 2,
    type: "hoa",
    typeLabel: "HOA dues",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 3,
    type: "prop_tax",
    typeLabel: "Property taxes",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 4,
    type: "rent",
    typeLabel: "Rent",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 5,
    type: "clothing",
    typeLabel: "Clothing",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 6,
    type: "fuel",
    typeLabel: "Fuel",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 7,
    type: "power",
    typeLabel: "Power",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 8,
    type: "phone",
    typeLabel: "Phone",
    annual: 0,
    monthly: 0,
  },
  {
    id: now - 9,
    type: "water",
    typeLabel: "Water",
    annual: 0,
    monthly: 0,
  },
];

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const { income, expenses } = useSelector(getCashflows);
  const cashflowsLoading = useSelector(getLoadingCashflow);
  const cashflowsLoaded = useSelector(getLoadedCashflow);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const userInSchool = useSelector(getUserInSchool);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const isMarried = useSelector(getIsMarried);
  const [expenseList, updateExpenseList] = useState<MappedCashflow[]>([]);
  const unmounting = useRef(false);
  useEffect(() => {
    if (cashflowsLoaded && !expenseList.length) {
      const newExpenseList = cloneDeep(startingExpenses);
      expenses.forEach((item: MappedCashflow) => {
        const foundExpense = newExpenseList.find(
          (newExpense) => newExpense.type === item.type
        );
        if (foundExpense) {
          (foundExpense.annual as number) += item.annual || 0;
          foundExpense.monthly = Math.round(
            (foundExpense.annual as number) / 12
          );
        }
      });
      updateExpenseList(newExpenseList);
    }
  }, [cashflowsLoaded, expenses, expenseList]);

  const refreshScore = () =>
    window.setTimeout(() => dispatch(getScore()), 1000);

  const incomeNotComplete = () => {
    const myIncome = income.find((item) => item.whose !== "spouse");
    if (!myIncome) {
      return true;
    }
    if (isMarried) {
      const spouseIncome = income.find((item) => item.whose === "spouse");
      if (!spouseIncome) {
        return true;
      }
    }
    return false;
  };

  const saveItem = (
    item: MappedCashflow,
    updateFields?: MappedCashflow,
    updateIndex?: number
  ) => {
    const update = { ...item, ...updateFields };
    const isExpense = !!EXPENSE_TYPES[update.type];
    if (isExpense) {
      const updateItem = expenseList[updateIndex || 0];
      const updated = { ...updateItem, ...updateFields };
      const newExpenseList = [...expenseList];
      newExpenseList[updateIndex || 0] = updated;
      updateExpenseList(newExpenseList);
      if (unmounting.current) {
        saveDrafts(newExpenseList);
      }
      return;
    }
    if (item.id >= 0) {
      if (
        (update.type && item.type !== update.type) ||
        (update.whose && item.whose !== update.whose)
      ) {
        const replacePayload: any = {
          id: item.id,
          newItem: {
            type: update.type,
            amount: Math.abs(update.annual || 0),
          },
        };
        if (update.whose) {
          replacePayload.newItem.who = update.whose;
        }
        return dispatch(replaceCashflow(replacePayload));
      }
      const payload = {
        id: item.id,
        amount: Math.abs(update.annual || 0),
      };
      return dispatch(editCashflow(payload));
    } else {
      const cashflow: any = {
        type: update.type,
        amount: Math.abs(update.annual || 0),
      };
      let inschoolCashflow: any = null;
      if (update.whose === "spouse") {
        cashflow.who = "spouse";
        if (isCurrentStudent && !spouseInSchool) {
          inschoolCashflow = { ...cashflow, inschool: true };
        }
      } else {
        cashflow.who = "applicant";
        if (isCurrentStudent && !userInSchool) {
          inschoolCashflow = { ...cashflow, inschool: true };
        }
      }
      const payload: AddCashflowItemPayload = {
        cashflow,
        temporaryId: item.id,
      };
      // refreshScore();
      dispatch(addCashflow(payload));
      if (inschoolCashflow) {
        const inschoolPayload: AddCashflowItemPayload = {
          cashflow: inschoolCashflow,
          temporaryId: item.id,
        };
        dispatch(addCashflow(inschoolPayload));
      }
    }
  };

  const saveDrafts = (list?: MappedCashflow[]) => {
    const newExpenseList = list || expenseList;
    newExpenseList.forEach((item: MappedCashflow) => {
      let updatedOne = false;
      expenses.forEach((expense: MappedCashflow) => {
        if (expense.type !== item.type) {
          return true;
        }
        const update = {
          id: expense.id,
          amount: Math.abs(item.annual || 0),
        };
        if (updatedOne) {
          update.amount = 0;
        } else {
          updatedOne = true;
          if (item.annual === expense.annual) {
            return true;
          }
        }
        dispatch(editCashflow(update));
      });
      if (!updatedOne && item.annual) {
        dispatch(
          addCashflow({
            temporaryId: 0,
            cashflow: {
              type: item.type,
              amount: Math.abs(item.annual || 0),
            },
          })
        );
      }
    });
  };

  const onNext = () => {
    unmounting.current = true;
    saveDrafts();
    refreshScore();
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS_INTRO));
  };

  const onPrev = () => {
    unmounting.current = true;
    saveDrafts();
    refreshScore();
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.INCOME_EXPENSES_INTRO_1));
  };

  const addIncome = () =>
    dispatch(
      addDraftCashflow({
        amount: null,
        id: -Date.now(),
        type: "salary",
        whose: "mine",
      })
    );

  const cancelAddIncome = () => {
    dispatch(removeDraftCashflow());
  };

  const removeItem = (item: Cashflow) => {
    if (item.id < 0) {
      dispatch(removeDraftCashflow(item.id));
    } else {
      dispatch(removeCashflow(item.id));
      // refreshScore();
    }
  };

  const expenseMessage =
    "To calculate your FitBUX Score, we only need the above living expenses. " +
    "You may add more expenses to track in your budget in our financial planning tool that will be " +
    "located on your Member Dashboard.";

  const makeIncomeTypeLabel = (item: any, label: string) => {
    let inSchool = userInSchool;
    if (isMarried && item.whose === "spouse") {
      inSchool = spouseInSchool;
    }
    const afterGraduation = inSchool ? " (after graduation)" : "";
    return label + afterGraduation;
  };

  const nextDisabled = incomeNotComplete();

  const completeIncomeMessage = nextDisabled
    ? `Please enter at least one source of income${
        isMarried ? " for both you and your spouse" : ""
      }. If you ${isMarried ? "or your spouse " : ""} do not have an income, ` +
      " select one income type and enter a value of $0."
    : undefined;

  const incomeColumns: any[] = [
    {
      label: "Type",
      field: "type",
      items: INCOME_TYPES,
      type: "select",
      width: isMarried ? "30%" : "60%",
      selectLabelFormat: makeIncomeTypeLabel,
    },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "20%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "20%",
      formatter: formatAnnually,
    },
  ];

  if (isMarried) {
    incomeColumns.push({
      label: "Owner",
      field: "whose",
      type: "select",
      width: "30%",
      items: WHO_TYPES,
    });
  }

  if (!render) {
    return <div />;
  }

  return render({
    component: (
      <Container className="pb-32">
        <MainCard
          iconName="fb-money"
          title="Income"
          columns={incomeColumns}
          allowSelect
          data={income}
          onCancelEdit={cancelAddIncome}
          onAdd={addIncome}
          onDelete={removeItem}
          onSave={saveItem}
        />
        <MainCard
          iconName="fb-wallet"
          title="Household Expenses"
          loading={cashflowsLoading}
          columns={[
            {
              label: "Type",
              field: "type",
              type: "select",
              width: "60%",
              items: EXPENSE_TYPES,
            },
            {
              label: "Monthly",
              field: "monthly",
              type: "number",
              width: "20%",
              formatter: formatMonthly,
            },
            {
              label: "Annual",
              field: "annual",
              type: "number",
              width: "20%",
              formatter: formatAnnually,
            },
          ]}
          allowSelect
          data={expenseList}
          onSave={saveItem}
          addTooltip={expenseMessage}
          disableAdd
          disableDelete
          disableEditFields={["type"]}
        />
      </Container>
    ),
    nextDisabled,
    nextTooltip: completeIncomeMessage,
    onNext,
    onPrev,
  });
};

export default MainForm;
