import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { pick, rangeRight } from "lodash";

import {
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Popper,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import emptyEducationValues from "./emptyEducationValues";
import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { getScore, setRefreshNeeded } from "src/store/dashboard/actions";
import {
  ADIDS,
  EDIT_EDU_LEVEL,
  HIGHEST_LEVEL_EDUCATIONS,
  MONTHS,
  PROFILE_BUILD_STEPS,
  SCHOOL_LEVEL,
  PROFILE_TYPE,
} from "src/store/profileBuild/constants";
import {
  fetchFields,
  fetchLeveledSchools,
  fetchSpecialties,
  setProfileStep,
  updateEducation,
  UpdateEducationPayload,
} from "src/store/profileBuild/actions";
import FbRadio from "src/components/Radio";
import { EducationSection, YesNo } from "src/interfaces";
import {
  getCurrentStep,
  getEducation,
  getFields,
  getLeveledSchools,
  getProfileLoading,
  getSpouseStudentStatus,
  getStudentStatus,
  getSpecialties,
  getSpouseEducation,
} from "src/store/profileBuild/selector";
import { EDUCATION_KEYS } from "src/store/profileBuild/selectorKeys";

const years = rangeRight(1950, new Date().getFullYear() + 7);

interface FormValues extends EducationSection {
  current_level?: number;
  changed?: boolean;
  highest_level_education?: number; // HIGHEST_LEVEL_EDUCATIONS (0 ~ 8)
  show_speciality?: YesNo; // Did you Participate in a specialty program?
}

const EducationForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMine: boolean =
    useSelector(getCurrentStep) === PROFILE_BUILD_STEPS.MY_EDUCATION;
  const education = useSelector(isMine ? getEducation : getSpouseEducation);
  const loading = useSelector(getProfileLoading);

  const leveledSchools = useSelector(getLeveledSchools);
  const specialties = useSelector(getSpecialties);
  const fields = useSelector(getFields);
  const monthsSinceGraduation = useSelector(
    isMine ? getStudentStatus : getSpouseStudentStatus
  );

  const [formValues, setFormValues] = useState<FormValues>();
  const [prevPages, setPrevPages] = useState([-1]);

  const addPrevPage = (pageNumber: number) =>
    setPrevPages((current) => [pageNumber, ...current]);
  const shiftPrevPage = () => setPrevPages((current) => current.slice(1));

  const you = isMine ? "you" : "your spouse";
  const your = isMine ? "your" : "your spouse's";
  const they = isMine ? "you" : "they";
  const their = isMine ? "your" : "their";

  useEffect(() => {
    setFormValues({
      ...education,
      highest_level_education: getEducationLevel(education),
      current_level: EDIT_EDU_LEVEL.INIT,
      changed: false,
      show_speciality: education?.adv_speciality ? "y" : "n",
    });
  }, [education]);

  useEffect(() => {
    if (
      formValues?.highest_level_education !== undefined &&
      formValues.highest_level_education > -1
    ) {
      setFormValues({
        ...formValues,
        current_level: EDIT_EDU_LEVEL.INIT,
      });
    }
  }, [formValues?.highest_level_education]);

  /* fetch options data (schools and field) */
  useEffect(() => {
    if (
      formValues?.current_level === EDIT_EDU_LEVEL.PHD &&
      !leveledSchools[SCHOOL_LEVEL.PHD]
    ) {
      // fetch phd schools
      dispatch(fetchLeveledSchools(SCHOOL_LEVEL.PHD));
    }
    if (
      formValues?.current_level === EDIT_EDU_LEVEL.ADV &&
      !leveledSchools[SCHOOL_LEVEL.ADV]
    ) {
      // fetch adv schools
      dispatch(fetchLeveledSchools(SCHOOL_LEVEL.ADV));
    }
    if (
      formValues?.current_level &&
      formValues?.current_level >= EDIT_EDU_LEVEL.MASTER
    ) {
      if (!leveledSchools[SCHOOL_LEVEL.UNDERGRAD]) {
        // fetch undergrad schools
        dispatch(fetchLeveledSchools(SCHOOL_LEVEL.UNDERGRAD));
      }
      if (!fields || !fields.length) {
        // fetch undergrad fiends
        dispatch(fetchFields());
      }
    }
  }, [formValues?.current_level]);

  /* fetch options data (specialy) */
  useEffect(() => {
    const idid = ADIDS.find((e) => e.id === formValues?.adid);
    if (
      formValues?.adid &&
      idid?.has_specialty &&
      formValues.show_speciality === "y"
    ) {
      // specialty by adid(mba, law(jd), ...)
      dispatch(fetchSpecialties(formValues.adid));
    }
    if (formValues?.adid) {
      dispatch(fetchLeveledSchools(SCHOOL_LEVEL.ADV));
    }
  }, [formValues?.adid, formValues?.show_speciality]);

  const handleChange = (prop: keyof FormValues) => (
    event: React.ChangeEvent<any>,
    t?: any
  ) => {
    let value = event.target.value;
    if (
      prop === "phd_school" ||
      prop === "adv_school" ||
      prop === "undergrad_school" ||
      prop === "undergrad_field"
    ) {
      // autocomplete
      value = t?.id || 0;
    }
    const update = { ...formValues, [prop]: value };
    if (prop === "adid") {
      if (value !== 999) {
        update.adv_speciality_other = "";
        update.adv_school_other = 0;
      } else {
        update.adv_speciality = 0;
        update.adv_school = 0;
      }
    }
    setFormValues(update);
  };

  const saveAndNext = () => {
    // TODO: validation?
    const eduValues = pick(formValues, EDUCATION_KEYS);
    const payload: UpdateEducationPayload = {
      who: isMine ? PROFILE_TYPE.APPLICANT : PROFILE_TYPE.SPOUSE,
      update: eduValues,
      nextPage: PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW,
    };
    dispatch(updateEducation(payload));
  };

  const currentLevelSubtitle = "";
  const didYouAttend = () => {
    if (
      formValues?.current_level === EDIT_EDU_LEVEL.C4 ||
      formValues?.current_level === EDIT_EDU_LEVEL.C4_AA
    ) {
      if (isMine) {
        return "are you attending";
      }
      return "is your spouse attending";
    }
    if (isMine) {
      return "did you attend";
    }
    return "did your spouse attend";
  };
  const didYou = () => {
    if (
      formValues?.current_level === EDIT_EDU_LEVEL.C4 ||
      formValues?.current_level === EDIT_EDU_LEVEL.C4_AA
    ) {
      if (isMine) {
        return "will you";
      }
      return "will your spouse";
    }
    if (isMine) {
      return "did you";
    }
    return "did your spouse";
  };
  const wasYour = () => {
    if (
      formValues?.current_level === EDIT_EDU_LEVEL.C4 ||
      formValues?.current_level === EDIT_EDU_LEVEL.C4_AA
    ) {
      if (isMine) {
        return "is your";
      }
      return "is your spouse's";
    }
    if (isMine) {
      return "was your";
    }
    return "was your spouse's";
  };

  const getEducationLevel = (education: EducationSection) => {
    if (education.phd_deg === "y") {
      return 0;
    }
    if (education.adv_deg === "y") {
      return 1;
    }
    if (education.highest_degree_education === 2) {
      return 2;
    }
    if (education.undergrad === "y") {
      if (monthsSinceGraduation > 0) {
        return 3;
      }
      if (education.aa_deg === "y") {
        return 5;
      }
      return 4;
    }
    if (education.aa_deg === "y") {
      return 7;
    }
    return 8;
  };

  if (!render) {
    return <div />;
  }

  return render({
    component: (
      <Container className="pb-32 p-0">
        <Card className="rounded-3xl" style={{ minHeight: 500 }}>
          <CardContent>
            <Typography
              component="h5"
              variant="body1"
              className="text-blue font-bold text-lg"
            >
              Add {isMine ? "My" : "Spouse"} Education
            </Typography>
            <Typography
              component="p"
              variant="body1"
              className="text-primary font-medium text-md mb-3"
            >
              {currentLevelSubtitle}
            </Typography>
            <Divider />
            <Container maxWidth="xs" className="ml-0 mt-8">
              <Grid container spacing={3}>
                {formValues?.current_level === EDIT_EDU_LEVEL.INIT && (
                  <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" className="mb-4">
                        What is {your} highest level of education?
                      </FormLabel>
                      <Select
                        variant="outlined"
                        fullWidth
                        value={
                          formValues?.highest_level_education === undefined
                            ? -1
                            : formValues?.highest_level_education
                        }
                        onChange={handleChange("highest_level_education")}
                      >
                        <MenuItem value="-1">-</MenuItem>
                        {HIGHEST_LEVEL_EDUCATIONS.map((level, index) => (
                          <MenuItem value={index} key={index}>
                            {level}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {/* Attending Or Completed Ph.d */}
                {formValues?.current_level === EDIT_EDU_LEVEL.PHD && (
                  <>
                    <Grid item xs={12}>
                      <Typography>
                        When did {you} complete {their} Ph.D, or when do {they}{" "}
                        expect to complete it?
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.phd_grad_month || 0}
                          placeholder="Month"
                          onChange={handleChange("phd_grad_month")}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {MONTHS.map((month, index) => (
                            <MenuItem value={index + 1} key={index}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.phd_grad_year || 0}
                          placeholder="Year"
                          onChange={handleChange("phd_grad_year")}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {years.map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4">
                          What school did {they} attend for your Ph.D?
                        </FormLabel>
                        <Autocomplete
                          PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" />
                          )}
                          options={leveledSchools[SCHOOL_LEVEL.PHD] || []}
                          disabled={loading.leveledSchools}
                          getOptionLabel={(option) => option?.name || "-"}
                          fullWidth
                          placeholder="Ph.D School"
                          className="mr-4"
                          onChange={handleChange("phd_school")}
                          value={
                            leveledSchools[SCHOOL_LEVEL.PHD]?.find(
                              (school: { id: number | undefined }) =>
                                school?.id === formValues.phd_school
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Ph.D School"
                              variant="outlined"
                              placeholder="Ph.D School"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Do you have a graduate/doctorate/advanced degree other
                          than your Ph.D?
                        </FormLabel>
                        <RadioGroup
                          value={formValues.adv_deg}
                          className="flex flex-row"
                          onChange={handleChange("adv_deg")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* Attending or Completed Graduate/Doctorate/Advanced Degree */}
                {formValues?.current_level === EDIT_EDU_LEVEL.ADV && (
                  <>
                    <Grid item xs={12}>
                      <Typography>
                        When did {you} graduate or expect to graduate?
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.date_last_school_month || 0} // TODO: check this field
                          placeholder="Month"
                          onChange={handleChange("date_last_school_month")} // TODO: check this field
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {MONTHS.map((month, index) => (
                            <MenuItem value={index + 1} key={index}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.date_last_school_year || 0} // TODO: check this field
                          placeholder="Year"
                          onChange={handleChange("date_last_school_year")} // TODO: check this field
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {years.map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4">
                          What is {their} highest graduate degree obtained or
                          pursuing?
                        </FormLabel>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.adid || 0}
                          placeholder="Highest degree"
                          onChange={handleChange("adid")}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {ADIDS.map((e, i) => (
                            <MenuItem value={e.id} key={i}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {ADIDS.find((e) => e.id === formValues.adid)
                      ?.has_specialty && (
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend" className="mb-4">
                            Did {they} participate in a specialty program?
                          </FormLabel>
                          <RadioGroup
                            value={formValues.show_speciality}
                            className="flex flex-row"
                            onChange={handleChange("show_speciality")}
                          >
                            <FormControlLabel
                              value={"n"}
                              control={<FbRadio />}
                              label="No"
                            />
                            <FormControlLabel
                              value={"y"}
                              control={<FbRadio />}
                              label="Yes"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                    {ADIDS.find((e) => e.id === formValues.adid)
                      ?.has_specialty &&
                      formValues.show_speciality === "y" && (
                        <Grid item xs={12}>
                          <FormControl component="fieldset" fullWidth>
                            <Select
                              variant="outlined"
                              disabled={loading.specialties}
                              fullWidth
                              value={formValues.adv_speciality || 0}
                              placeholder="Specialty"
                              onChange={handleChange("adv_speciality")}
                            >
                              <MenuItem value={0}>-</MenuItem>
                              {specialties.map((e, i) => (
                                <MenuItem value={e.id} key={i}>
                                  {e.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    {formValues.adid === 999 && (
                      <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4 h-4">
                            What degree or credential did {they} earn?
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            onChange={handleChange("adv_speciality_other")}
                            type="text"
                            placeholder="Enter degree"
                            value={formValues.adv_speciality_other}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4">
                          Which institution did {they} attend?
                        </FormLabel>
                        <Autocomplete
                          options={leveledSchools[SCHOOL_LEVEL.ADV] || []}
                          disabled={loading.leveledSchools}
                          getOptionLabel={(option) => option?.name || "-"}
                          fullWidth
                          placeholder="Institution"
                          className="mr-4"
                          onChange={handleChange(
                            formValues.adid === 999
                              ? "adv_school_other"
                              : "adv_school"
                          )}
                          value={
                            leveledSchools[SCHOOL_LEVEL.ADV]?.find(
                              (school: { id: number | undefined }) =>
                                school?.id ===
                                (formValues.adid === 999
                                  ? formValues.adv_school_other
                                  : formValues.adv_school)
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Institution"
                              variant="outlined"
                              placeholder="Institution"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4 h-8">
                          What was {their} GPA?
                        </FormLabel>
                        <TextField
                          label="GPA"
                          variant="outlined"
                          onChange={handleChange("adv_deg_gpa")}
                          type="number"
                          value={formValues.adv_deg_gpa}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4 h-8">
                          What was {their} exam score?
                        </FormLabel>
                        <TextField
                          label="Score"
                          variant="outlined"
                          onChange={handleChange("adv_deg_exam")}
                          type="number"
                          value={formValues.adv_deg_exam}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Do {they} have more than one advanced degree?
                        </FormLabel>
                        <RadioGroup
                          value={formValues.adv_second_deg}
                          className="flex flex-row"
                          onChange={handleChange("adv_second_deg")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {formValues.adv_second_deg === "y" && (
                      <Grid item xs={12} className="mb-2">
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4 h-4">
                            What was the degree?
                          </FormLabel>
                          <TextField
                            label="Degree"
                            variant="outlined"
                            onChange={handleChange("adv_second_deg_name")}
                            value={formValues.adv_second_deg_name}
                          />
                        </FormControl>
                      </Grid>
                    )}

                    {formValues.adv_second_deg === "y" && (
                      <Grid item xs={12} className="mb-2">
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel component="legend" className="mb-4 h-4">
                            Which institution did {they} attend?
                          </FormLabel>
                          <TextField
                            label="Institution"
                            variant="outlined"
                            onChange={handleChange("adv_second_school")}
                            value={formValues.adv_second_school}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </>
                )}

                {/* Completed Associates Degree, currently attending 4 year college */}
                {/* Completed Associates Degree */}
                {(formValues?.current_level === EDIT_EDU_LEVEL.C4_AA ||
                  formValues?.current_level === EDIT_EDU_LEVEL.AA) && (
                  <>
                    {formValues?.undergrad === "y" &&
                      formValues?.current_level === EDIT_EDU_LEVEL.AA && (
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend" className="mb-4">
                              Have {they} completed an associate's degree?
                            </FormLabel>
                            <RadioGroup
                              value={formValues.aa_deg}
                              className="flex flex-row"
                              onChange={handleChange("aa_deg")}
                            >
                              <FormControlLabel
                                value={"n"}
                                control={<FbRadio />}
                                label="No"
                              />
                              <FormControlLabel
                                value={"y"}
                                control={<FbRadio />}
                                label="Yes"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                    {formValues.aa_deg === "y" && (
                      <>
                        <Grid item xs={12}>
                          <Typography>
                            When did {you} complete {their} associate's degree?
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl component="fieldset" fullWidth>
                            <Select
                              variant="outlined"
                              fullWidth
                              value={formValues.aa_grad_month || 0}
                              placeholder="Month"
                              onChange={handleChange("aa_grad_month")}
                            >
                              <MenuItem value={0}>-</MenuItem>
                              {MONTHS.map((month, index) => (
                                <MenuItem value={index + 1} key={index}>
                                  {month}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl component="fieldset" fullWidth>
                            <Select
                              variant="outlined"
                              fullWidth
                              value={formValues.aa_grad_year || 0}
                              placeholder="Year"
                              onChange={handleChange("aa_grad_year")}
                            >
                              <MenuItem value={0}>-</MenuItem>
                              {years.map((i) => (
                                <MenuItem value={i} key={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {/* Master's Certificate */}
                {/* Completed B.S/B.A */}
                {/* Currently Attending a 4 year college */}
                {/* Completed Associates Degree, currently attending 4 year college */}
                {(formValues?.current_level === EDIT_EDU_LEVEL.MASTER ||
                  formValues?.current_level === EDIT_EDU_LEVEL.BS ||
                  formValues?.current_level === EDIT_EDU_LEVEL.C4 ||
                  formValues?.current_level === EDIT_EDU_LEVEL.C4_AA) && (
                  <>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4">
                          What Institution {didYouAttend()} for undergrad?
                        </FormLabel>
                        <Autocomplete
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              popperOptions={{
                                modifiers: { "~flip": { enabled: false } },
                              }}
                              placement="bottom-start"
                            />
                          )}
                          options={leveledSchools[SCHOOL_LEVEL.UNDERGRAD] || []}
                          disabled={loading.leveledSchools}
                          getOptionLabel={(option) => option?.name || "-"}
                          fullWidth
                          placeholder="Undergrad School"
                          className="mr-4"
                          onChange={handleChange("undergrad_school")}
                          value={
                            leveledSchools[SCHOOL_LEVEL.UNDERGRAD]?.find(
                              (school: { id: number | undefined }) =>
                                school?.id === formValues.undergrad_school
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Undergrad School"
                              variant="outlined"
                              placeholder="Undergrad School"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>When {didYou()} graduate?</Typography>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.undergrad_grad_month || 0}
                          placeholder="Month"
                          onChange={handleChange("undergrad_grad_month")}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {MONTHS.map((month, index) => (
                            <MenuItem value={index + 1} key={index}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={formValues.undergrad_grad_year || 0}
                          placeholder="Year"
                          onChange={handleChange("undergrad_grad_year")}
                        >
                          <MenuItem value={0}>-</MenuItem>
                          {years.map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-4">
                          What {wasYour()} major?
                        </FormLabel>
                        <Autocomplete
                          options={fields || []}
                          disabled={loading.leveledSchools}
                          getOptionLabel={(option) => option?.name || "-"}
                          fullWidth
                          placeholder="Field"
                          className="mr-4"
                          onChange={handleChange("undergrad_field")}
                          value={
                            fields?.find(
                              (field: { id: number | undefined }) =>
                                field?.id === formValues.undergrad_field
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Field"
                              variant="outlined"
                              placeholder="Field"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-5 h-8">
                          What {wasYour()} GPA?
                        </FormLabel>
                        <TextField
                          label="GPA"
                          variant="outlined"
                          onChange={handleChange("undergrad_gpa")}
                          type="number"
                          value={formValues.undergrad_gpa}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="mb-2">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" className="mb-5 h-8">
                          How many internships have {they} completed?
                        </FormLabel>
                        <Select
                          variant="outlined"
                          fullWidth
                          value={
                            (formValues?.current_level >= EDIT_EDU_LEVEL.C4
                              ? formValues.undergrad_internships
                              : formValues.grad_internships) || 0
                          }
                          placeholder="Internships"
                          onChange={handleChange(
                            formValues?.current_level >= EDIT_EDU_LEVEL.C4
                              ? "undergrad_internships"
                              : "grad_internships"
                          )}
                        >
                          {Array.from(Array(10).keys()).map((i) => (
                            <MenuItem value={i} key={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {/* Other Education Question (common) */}
                {(formValues?.current_level === EDIT_EDU_LEVEL.HS ||
                  formValues?.current_level === EDIT_EDU_LEVEL.C4_DROP) && (
                  <>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Were {they} enrolled in a gifted/talented program in
                          elementary school?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_gifted_and_talented || "n"}
                          className="flex flex-row"
                          onChange={handleChange("qual_gifted_and_talented")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Were {they} an AP Scholar?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_ap_scholar || "n"}
                          className="flex flex-row"
                          onChange={handleChange("qual_ap_scholar")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Did {they} graduate from an international
                          baccalaureate program?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_intl_bacc || "n"}
                          className="flex flex-row"
                          onChange={handleChange("qual_intl_bacc")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Were {they} offered a merit or academic scholarship
                          for college?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_scholarship || "n"}
                          className="flex flex-row"
                          onChange={handleChange("qual_scholarship")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-2">
                      <FormControl component="fieldset">
                        <FormLabel component="legend" className="mb-4">
                          Were {they} offered a scholarship that covered your
                          entire tuition cost?
                        </FormLabel>
                        <RadioGroup
                          value={formValues?.qual_full_scholarship || "n"}
                          className="flex flex-row"
                          onChange={handleChange("qual_full_scholarship")}
                        >
                          <FormControlLabel
                            value={"n"}
                            control={<FbRadio />}
                            label="No"
                          />
                          <FormControlLabel
                            value={"y"}
                            control={<FbRadio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </CardContent>
        </Card>
      </Container>
    ),
    nextDisabled:
      formValues?.highest_level_education === undefined ||
      formValues?.highest_level_education < 0 ||
      !!loading.profile,
    onPrev: () => {
      const current_level = formValues?.current_level || 0;
      if (current_level === EDIT_EDU_LEVEL.INIT) {
        dispatch(setProfileStep(PROFILE_BUILD_STEPS.HUMAN_CAPITAL_OVERVIEW));
        window.setTimeout(() => dispatch(getScore()), 500);
      } else {
        setFormValues({
          ...formValues,
          current_level: prevPages[0],
        });
        shiftPrevPage();
      }
    },
    onNext: () => {
      addPrevPage(formValues?.current_level || -1);
      if (formValues?.current_level === EDIT_EDU_LEVEL.INIT) {
        setFormValues({
          ...formValues,
          ...(emptyEducationValues[formValues?.highest_level_education || 0] ||
            {}),
          current_level: formValues?.highest_level_education,
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.PHD) {
        setFormValues({
          ...formValues,
          phd_deg: "y",
          current_level:
            formValues?.adv_deg === "y"
              ? EDIT_EDU_LEVEL.ADV
              : EDIT_EDU_LEVEL.BS,
          undergrad: "y",
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.ADV) {
        setFormValues({
          ...formValues,
          adv_deg: "y",
          current_level: EDIT_EDU_LEVEL.BS,
          undergrad: "y",
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.MASTER) {
        setFormValues({
          ...formValues,
          aa_deg: "n",
          current_level: EDIT_EDU_LEVEL.AA,
          undergrad: "y",
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.BS) {
        setFormValues({
          ...formValues,
          aa_deg: "n",
          current_level: EDIT_EDU_LEVEL.AA,
          undergrad: "y",
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.C4) {
        setFormValues({
          ...formValues,
          aa_deg: "n",
          current_level: EDIT_EDU_LEVEL.HS,
          undergrad: "y",
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.C4_AA) {
        setFormValues({
          ...formValues,
          aa_deg: "y",
          undergrad: "y",
          current_level: EDIT_EDU_LEVEL.AA,
        });
      } else if (formValues?.current_level === EDIT_EDU_LEVEL.AA) {
        setFormValues({
          ...formValues,
          current_level: EDIT_EDU_LEVEL.HS,
        });
      } else if (
        formValues?.current_level === EDIT_EDU_LEVEL.HS ||
        formValues?.current_level === EDIT_EDU_LEVEL.C4_DROP
      ) {
        setFormValues({
          ...formValues,
          aa_deg: "n",
        });
        saveAndNext();
        window.setTimeout(() => {
          dispatch(getScore());
          dispatch(setRefreshNeeded({ summaries: true }));
        }, 500);
      }
    },
  });
};

export default EducationForm;
