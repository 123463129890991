import React from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
} from "@material-ui/core";

import FbRadio from "src/components/Radio";
import { REPAYMENT_PLANS, SPECIAL_REPAYMENTS } from "src/interfaces/common";
import {
  getStudentStatus,
  getSpouseStudentStatus,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";

import { FormComponentType } from "./interfaces";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";

const FedLoanForm: FormComponentType = ({ formState, updateField }) => {
  const isMarried = useSelector(getIsMarried);
  const isSpouse = formState.who === "spouse";
  const monthsSinceGraduation = useSelector(
    isSpouse ? getSpouseStudentStatus : getStudentStatus
  );
  const their = isSpouse ? "their" : "your";
  const they = isSpouse ? "they" : "you";
  return (
    <>
      {isMarried && (
        <Grid container spacing={3}>
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-3 h-4">
                Who is the borrower on the loan?
              </FormLabel>
              <Select
                variant="outlined"
                style={{ maxWidth: 350, width: "100%" }}
                name="who"
                onChange={updateField}
                value={formState.who}
              >
                <MenuItem value="applicant">Me</MenuItem>
                <MenuItem value="spouse">My Spouse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            What is {their} total student loan balance to date?
          </FormLabel>
          <DollarTextField
            id="outlined-basic"
            name="balance"
            style={{ maxWidth: 350, width: "100%" }}
            onChange={updateField}
            value={formState.balance || 0}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            What is {their} average interest rate?
          </FormLabel>
          <PercentTextField
            allowZero
            id="outlined-basic"
            name="rate"
            style={{ maxWidth: 350, width: "100%" }}
            onChange={updateField}
            value={formState.rate}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      {/*monthsSinceGraduation < 0 &&
        <Grid item xs={12} className="mt-5">
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className="mb-3 h-4">How much in additional loans do {they} expect before graduating?</FormLabel>
            <DollarTextField
              id="outlined-basic"
              name="addl_fedloan_balance"
              style={{maxWidth: 350, width: "100%"}}
              onChange={updateField}
              value={formState.addl_fedloan_balance || 0}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      */}
      {monthsSinceGraduation >= 6 && (
        <>
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-3 h-4">
                What repayment plan are {they} on?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                style={{ maxWidth: 350, width: "100%" }}
                name="fedloan_repayment_plan"
                value={formState.fedloan_repayment_plan}
                onChange={updateField}
                placeholder="Select plan"
              >
                {_.map(REPAYMENT_PLANS, (label, key) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-3 h-4">
                What is the monthly payment?
              </FormLabel>
              <DollarTextField
                id="outlined-basic"
                name="payment"
                label="Payment"
                style={{ maxWidth: 350, width: "100%" }}
                onChange={updateField}
                value={formState.payment}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          {(formState.fedloan_repayment_plan === "std_plan" ||
            formState.fedloan_repayment_plan === "ext_fixed") && (
            <>
              <Grid item xs={12} className="mt-5">
                <FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-3">
                    Do {they} make prepayments?
                  </FormLabel>
                  <RadioGroup
                    aria-label="student_loan_prepayments"
                    name="student_loan_prepayments"
                    onChange={updateField}
                    value={formState.student_loan_prepayments}
                    className="flex flex-row"
                  >
                    <FormControlLabel
                      value="n"
                      control={<FbRadio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="y"
                      control={<FbRadio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formState.student_loan_prepayments === "y" && (
                <Grid item xs={12} className="mt-5">
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className="mb-3">
                      Do {they} make...
                    </FormLabel>
                    <RadioGroup
                      aria-label="student_loan_prepayments_type"
                      name="student_loan_prepayments_type"
                      onChange={updateField}
                      value={formState.student_loan_prepayments_type}
                      className="flex flex-row"
                    >
                      <FormControlLabel
                        value="1"
                        control={<FbRadio />}
                        label="Scheduled prepayments"
                      />
                      <FormControlLabel
                        value="2"
                        control={<FbRadio />}
                        label={`Prepayments whenever ${they} can`}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
          {isMarried &&
            SPECIAL_REPAYMENTS.indexOf(
              formState.fedloan_repayment_plan || ""
            ) >= 0 && (
              <Grid item xs={12} className="mt-5">
                <FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-3">
                    How do you file your taxes?
                  </FormLabel>
                  <RadioGroup
                    aria-label="filing_jointly"
                    name="filing_jointly"
                    value={formState["filing_jointly"]}
                    className="flex flex-row"
                    onChange={updateField}
                  >
                    <FormControlLabel
                      value="n"
                      control={<FbRadio />}
                      label="Separately"
                    />
                    <FormControlLabel
                      value="y"
                      control={<FbRadio />}
                      label="Jointly"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
        </>
      )}
    </>
  );
};

export default FedLoanForm;
