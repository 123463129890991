import React from "react";
import { useDispatch } from "react-redux";
import Slider from "src/components/Slider";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import { ViewComponent } from "src/interfaces/viewComponent.interface";

const slides = [
  {
    image: "/assets/images/animations/assets.gif",
    subline: "",
    title: "Your Assets & Debts (Your Net Wealth)",
    description:
      "The second formula we use to make money easy to understand is Assets - Debts = Net Wealth. Your goal over time is to convert your Human Capital into either financial assets or a way to pay off your debts, thus increasing your FitBUX Score over time.",
  },
];

const GetStarted: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const onNext = () =>
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.ASSETS_AND_DEBTS));
  const onPrev = () =>
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.INCOME_EXPENSES));

  if (!render) {
    return <div />;
  }

  return render({
    component: <Slider slides={slides} slide={0} />,
    onNext,
    onPrev,
  });
};

export default GetStarted;
