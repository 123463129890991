import React from "react";

import { Card, CardContent, Container, Typography } from "@material-ui/core";

import AutoLeaseForm from "./components/AutoLeaseForm";
import AutoLoanForm from "./components/AutoLoanForm";
import AutoValueForm from "./components/AutoValueForm";
import BasicAssetForm from "./components/BasicAssetForm";
import BasicDebtForm from "./components/BasicDebtForm";
import CreditCardDebtForm from "./components/CreditCardDebtForm";
import FedLoanForm from "./components/FedLoanForm";
import HomeMortgageForm from "./components/HomeMortgageForm";
import HomeValueForm from "./components/HomeValueForm";
import InvestmentPropertyForm from "./components/InvestmentPropertyForm";
import PerkinsLoanForm from "./components/PerkinsLoanForm";
import PrivateLoanForm from "./components/PrivateLoanForm";
import RentalMortgageForm from "./components/RentalMortgageForm";
import RetirementAccountForm from "./components/RetirementAccountForm";

import { AccountFormValues, FormComponentType } from "./components/interfaces";

interface AddAssetOrDebtProps {
  formState: AccountFormValues;
  isDebt: boolean;
  setFormState?: (newFormState: AccountFormValues) => void;
  updateField: (e: any) => void;
}

const AddAssetOrDebt = ({ formState, updateField }: AddAssetOrDebtProps) => {
  const getFormForType = () => {
    switch (formState.type) {
      case "credit_card":
        return CreditCardDebtForm;
      case "vehicle_lease":
        return AutoLeaseForm;
      case "fed_loan":
        return FedLoanForm;
      case "perkins_loan":
        return PerkinsLoanForm;
      case "priv_loan":
        return PrivateLoanForm;
      case "auto_loan":
        return AutoLoanForm;
      case "property_loan":
        return RentalMortgageForm;
      case "home_loan":
        return HomeMortgageForm;
      case "personal_loan":
      case "other_debt":
        return BasicDebtForm;
      case "home_value":
        return HomeValueForm;
      case "property_value":
        return InvestmentPropertyForm;
      case "auto_value":
        return AutoValueForm;
      case "401k_value":
      case "roth_401k_value":
      case "roth_ira_value":
      case "ira_value":
        return RetirementAccountForm;
      case "cash_value":
      case "hsa_value":
      case "other_retirement_value":
      case "other_investments_value":
      case "other_assets_value":
      default:
        return BasicAssetForm;
    }
  };

  const FormComponent: FormComponentType = getFormForType();

  return (
    <Container className="pb-32">
      <Card className="rounded-3xl">
        <CardContent>
          <Typography
            component="h5"
            variant="body1"
            className="text-blue font-bold text-lg"
          >
            {formState.typeLabel}
            {formState.who === "spouse" ? " (Spouse)" : ""}
          </Typography>
          <form className="py-3">
            <FormComponent formState={formState} updateField={updateField} />
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddAssetOrDebt;
