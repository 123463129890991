const data = {
  answers: {
    heading: "Upgrade to Premium and find out. Powered by FitBUX's A.I.",
    subscribedHeading: "Powered by FitBUX's A.I.",
    items: [],
    showSubscribeButton: true,
  },
  calculators: {
    heading:
      'An easy way to "do the math" and compare strategies.',
    items: [],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [],
  },
};

export default data;
