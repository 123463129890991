const data = {
  answers: {
    heading: "Upgrade to Premium and find out. Powered by FitBUX's A.I.",
    subscribedHeading: "Powered by FitBUX's A.I.",
    items: [
      {
        iconName: "fa-house-circle-check",
        target: "/homeaffordability",
        label: "How Much Home Can I Afford?",
      },
      {
        iconName: "fa-scale-unbalanced",
        target: "/rent-vs-buy",
        label: "Should I Rent Vs. Buy?",
      },
    ],
    showSubscribeButton: true,
  },
  calculators: {
    heading:
      'An easy way to "do the math" and compare strategies.',
    items: [
      {
        iconName: "fa-chart-line-down",
        target: "/mortgage-payment",
        label: "Mortgage Pay Off Analysis",
      },
      {
        iconName: "fa-calculator",
        target: "/points-calculator",
        label: "Mortgage Points Optimization",
      },
      {
        iconName: "fa-house-circle-exclamation",
        target: "/property-tax-estimate",
        label: "Property Tax Estimation",
      },
      {
        iconName: "fa-truck-ramp-couch",
        target: "/cost-of-living",
        label: "Cost Of Living Comparison",
      },
      {
        iconName: "fa-chart-line-down",
        target: "/heloc-payment",
        label: "HELOC Pay Off Analysis",
      },
    ],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [
      {
        iconName: "fa-percent",
        target: "/mortgage-rates",
        label: "Check Rates & Apply For A Mortgage",
      },
      {
        iconName: "fa-percent",
        target: "/refi-rates",
        label: "Check Mortgage Refi Rates",
      },
      {
        iconName: "fa-hand-holding-dollar",
        target: "/heloc-rates",
        label: "Apply For A HELOC",
      },
      {
        iconName: "fa-house-crack",
        target: "/solutions?solution=policygeniushome",
        label: "Shop Homeowners Insurance",
      },
    ],
  },
};

export default data;
