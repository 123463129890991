import React from "react";
import { useDispatch } from "react-redux";

import { Box } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { openUpgradeDialog } from "src/store/system/actions";
// import colors from "src/theme/colors";

// const useStyles = makeStyles({
//   lineItem: {
//     display: "flex",
//     alignItems: "center",
//     marginTop: 16,
//   },
//   lineText: {
//     color: colors.brandingBlue1,
//     fontSize: 14,
//     fontWeight: 600,
//     marginLeft: 10,
//   },
//   button: {
//     display: "block",
//     margin: "20px auto 0",
//     textAlign: "center",
//     width: 0,
//   },
// });

const UpgradeCard = () => {
  // const styles = useStyles();
  const dispatch = useDispatch();

  return (
    <Card title="Upgrade to a Premium Membership">
      <p className="my-12 text-center">
        Upgrade to our Premium Membership to easily build your optimized
        financial plan, track your progress, and reach your goals more
        efficiently.
      </p>
      <Box className="text-center">
        <Button fbColor="primary" onClick={() => dispatch(openUpgradeDialog())}>
          Upgrade to Premium
        </Button>
      </Box>
    </Card>
  );
};

export default UpgradeCard;
