import React from "react";

import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
} from "@material-ui/core";

import Icon from "../../Icon";
import { LOGO } from "src/assets/svgs/";
import { headerStyles } from "src/theme";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles(headerStyles);

const LoginHeader = () => {
  const classes = useStyles();

  return (
    <AppBar color="transparent" position="absolute" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <AdapterLink to="/plan-summary">
            <img className={classes.logo} src={LOGO} alt="FitBUX logo" />
          </AdapterLink>
          <Grid item xs />
          <Grid item>
            <IconButton color="inherit" component={AdapterLink} className="p-1">
              <Icon iconName="fb-user-avatar-filled" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default LoginHeader;
