import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  deleteCashflowApi,
  addCashflowApi,
  estimateTaxesApi,
  getCashflowDetailApi,
  getCashflowApi,
  updateCashflowApi,
} from "src/apiService";
import { Cashflow, FbAction, INCOME_TYPES, Plan } from "src/interfaces";

import { FAIL, START, SUCCESS } from "../common";
import { getLivePlan } from "../planBuild/selector";
import { getHasPlan, getIsCurrentStudent } from "../system/selector";
import * as actions from "./actions";
import { getRawCashflows } from "./selector";

const NO_PLAN_ALLOCATION = {
  solo: [
    {
      who: "applicant",
      "401k_value": 0,
      ira_value: 0,
    },
    {
      who: "applicant",
      "401k_value": 0,
      ira_value: 0,
    },
  ],
  "401k_value": 0,
  ira_value: 0,
  hsa_value: 0,
};

function* fetchCashflowItems() {
  try {
    const data: Cashflow[] = yield call(getCashflowApi);
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.type === "life_insurance") {
        const itemDetail: Cashflow = yield call(getCashflowDetailApi, item.id);
        Object.assign(item, itemDetail);
      }
    }
    yield put({ type: actions.FETCH_CASHFLOW_ITEMS + SUCCESS, payload: data });
    yield put(actions.fetchTaxes(null));
  } catch (error) {
    yield put({ type: actions.FETCH_CASHFLOW_ITEMS + FAIL, payload: error });
  }
}

function* addCashflowItem({
  payload,
}: FbAction<actions.AddCashflowItemPayload>) {
  try {
    const data: Cashflow = yield call<any>(addCashflowApi, payload.cashflow);
    yield put({
      type: actions.ADD_CASHFLOW_ITEM + SUCCESS,
      payload: { data, originalId: payload.temporaryId || 0 },
    });
    yield put(actions.fetchTaxes(null));
  } catch (error) {
    yield put({ type: actions.ADD_CASHFLOW_ITEM + FAIL, payload: error });
  }
}

function* editCashflowItem({
  payload,
}: FbAction<actions.EditCashflowItemPayload>) {
  try {
    yield call(updateCashflowApi, payload);
    yield put({ type: actions.EDIT_CASHFLOW_ITEM + SUCCESS, payload });
    yield put(actions.fetchTaxes(null));
  } catch (error) {
    yield put({ type: actions.EDIT_CASHFLOW_ITEM + FAIL, payload: error });
  }
}

function* replaceCashflowItem({
  payload,
}: FbAction<actions.ReplaceCashflowItemPayload>) {
  yield all([
    call<any>(addCashflowItem, {
      payload: { temporaryId: payload.id, cashflow: payload.newItem },
    }),
    call<any>(editCashflowItem, { payload: { id: payload.id, amount: 0 } }),
  ]);
  yield put(actions.fetchTaxes(null));
}

function* removeCashflowItem({ payload }: FbAction<number>) {
  try {
    const success: boolean = yield call(deleteCashflowApi, payload);
    if (success) {
      yield put({ type: actions.REMOVE_CASHFLOW_ITEM + SUCCESS, payload });
      yield put(actions.fetchTaxes(null));
    } else {
      throw new Error("Failed to delete cashflow.");
    }
  } catch (error) {
    yield put({ type: actions.REMOVE_CASHFLOW_ITEM + FAIL, payload: error });
  }
}

function* fetchTaxes({ payload }: FbAction<Plan | null>): any {
  try {
    const isCurrentStudent = yield select(getIsCurrentStudent);
    let amount = 0;
    const cashflows = yield select(getRawCashflows);
    const income = cashflows
      .filter(
        (item: Cashflow) =>
          !!INCOME_TYPES[item.type] && (!isCurrentStudent || item.inschool)
      )
      .map((item: Cashflow) => {
        const result = {
          type: item.type === "other_income" ? "other" : item.type,
          earning: item.amount,
          who: item.whose === "spouse" ? "spouse" : "applicant",
        };
        switch (item.type) {
          case "other_income":
            result.type = "other";
            break;
          case "rental_income":
            result.type = "rent";
            break;
          default:
            break;
        }
        return result;
      });
    const hasPlan = yield select(getHasPlan);
    let allocation: any = NO_PLAN_ALLOCATION;
    if (payload) {
      allocation = payload.allocations[0];
    } else if (hasPlan) {
      const livePlan = yield select(getLivePlan);
      allocation = livePlan.allocations[0];
    }
    const result: any = yield call(
      estimateTaxesApi,
      income,
      allocation,
      isCurrentStudent
    );
    amount = result.payment;
    yield put({ type: actions.FETCH_TAXES + SUCCESS, payload: amount });
  } catch (error) {
    yield put({ type: actions.FETCH_TAXES + FAIL, payload: error });
  }
}

export function* cashflowSagas() {
  yield all([
    takeLatest(actions.FETCH_CASHFLOW_ITEMS + START, fetchCashflowItems),
    takeEvery(actions.ADD_CASHFLOW_ITEM + START, addCashflowItem),
    takeEvery(actions.EDIT_CASHFLOW_ITEM + START, editCashflowItem),
    takeEvery(actions.REPLACE_CASHFLOW_ITEM + START, replaceCashflowItem),
    takeEvery(actions.REMOVE_CASHFLOW_ITEM + START, removeCashflowItem),
    takeEvery(actions.FETCH_TAXES + START, fetchTaxes),
  ]);
}
