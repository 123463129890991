import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  getIDRVsPrepayResultsApi,
  IDRVsPrepayResultsRequest,
  IDRVsPrepayResultsResponse,
} from "src/apiService";
import { getMyAGI, getSpouseAGI } from "src/store/cashflow/selector";
import { getHousehold, getProfile } from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import PayoffVsIDRResults from "./PayoffVsIDRResults";
import PayoffVsIDRForm from "./PayoffVsIDRForm";

export const PayoffVsIDR = ({ goBack }: any) => {
  const isMarried = useSelector(getIsMarried);
  const household = useSelector(getHousehold);
  const profile = useSelector(getProfile);
  const myAGI = useSelector(getMyAGI);
  const spouseAGI = useSelector(getSpouseAGI);
  const [formValues, setFormValues] = useState<
    Partial<IDRVsPrepayResultsRequest>
  >({
    idrcalc_tax_filing_status: household.filing_jointly === "y" ? 2 : 1,
    idrcalc_household_size: isMarried
      ? household.children_qty + 2
      : household.children_qty + 1,
    idrcalc_state: household.state,
    idrcalc_agi: Math.floor(myAGI),
    idrcalc_spouse_agi: Math.floor(spouseAGI),
    idrcalc_agi_ann_incr: 3.0,
    idrcalc_select_plan: 0,
    idrcalc_graduate_loans:
      profile.phd_deg === "y" || profile.adv_deg === "y" ? "y" : "n",
    idrcalc_pslf: "n",
    idrcalc_federal_extended: "n",
    idrcalc_mthly_pmt: 0,
    idrcalc_breakeven_ret: 2.0,
  });
  const [resultsData, setResultsData] = useState<IDRVsPrepayResultsResponse[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(0);
  // const [loadingResults, setLoadingResults] = useState(false);

  const handleChange = (e: React.ChangeEvent<any>) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [fieldName]: value });
  };

  const submit = (tabIndex?: number) => {
    const payload: IDRVsPrepayResultsRequest = {
      idrcalc_tax_filing_status: isMarried
        ? formValues.idrcalc_tax_filing_status || 1
        : 0,
      idrcalc_household_size: formValues.idrcalc_household_size || 1,
      idrcalc_graduate_loans: formValues.idrcalc_graduate_loans || "n",
      idrcalc_agi: formValues.idrcalc_agi || 0,
      idrcalc_spouse_agi: formValues.idrcalc_spouse_agi || 0,
      idrcalc_agi_ann_incr: formValues.idrcalc_agi_ann_incr || 0,
      idrcalc_state: formValues.idrcalc_state || "TX",
      idrcalc_pslf: formValues.idrcalc_pslf || "n",
      idrcalc_federal_extended: formValues.idrcalc_federal_extended || "n",
      idrcalc_mthly_pmt: formValues.idrcalc_mthly_pmt || 0,
      idrcalc_select_plan: formValues.idrcalc_select_plan || 0,
      idrcalc_breakeven_ret: formValues.idrcalc_breakeven_ret || 2.0,
    };
    switch (tabIndex) {
      case 0:
        payload.idr_model = 2;
        break;
      case 1:
        payload.idr_model = 4;
        break;
      case 2:
        payload.idr_model = 3;
        break;
      default:
        break;
    }
    setCurrentPage(1);
    // setLoadingResults(true);
    getIDRVsPrepayResultsApi(payload).then((result) => {
      // setLoadingResults(false);
      const newResults = [...resultsData];
      newResults[tabIndex || 0] = result;
      setResultsData(newResults);
    });
  };

  switch (currentPage) {
    case 0:
      return (
        <PayoffVsIDRForm
          formValues={formValues}
          onChange={handleChange}
          goBack={goBack}
          submit={submit}
        />
      );
    case 1:
    default:
      return (
        <PayoffVsIDRResults
          results={resultsData}
          loadNewResults={submit}
          goBack={() => setCurrentPage(0)}
        />
      );
  }
};

export default PayoffVsIDR;
