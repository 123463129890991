import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import {
  Box,
  Container,
  LinearProgress,
  makeStyles,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";

import Button from "src/components/Button";

import { ViewComponent } from "src/interfaces/viewComponent.interface";
import { fetchProfile } from "src/store/profileBuild/actions";
import {
  PROFILE_BUILD_STEPS,
  PROFILE_PROGRESS,
} from "src/store/profileBuild/constants";
import {
  getCurrentStep,
  getProfileError,
  getProfileLoaded,
  getProfileLoading,
  getProfileProgress,
} from "src/store/profileBuild/selector";
import personalInfoViews from "./Views/PersonalInfo";
import incomeAndExpensesViews from "./Views/IncomeAndExpenses";
import humanCapitalViews from "./Views/HumanCapital";
import assetsAndDebtsViews from "./Views/AssetsAndDebts";
import riskManagementViews from "./Views/RiskManagement";

import { profileBuilderStyles } from "src/theme";

const useStyles = makeStyles(profileBuilderStyles);

export const Content = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const step = useSelector(getCurrentStep);
  const loaded = useSelector(getProfileLoaded);
  const loading = useSelector(getProfileLoading);
  const error = useSelector(getProfileError);
  const progress = useSelector(getProfileProgress);
  const shortScreen = useMediaQuery("(max-height:720px)");

  const renderFunctions: { [key in PROFILE_BUILD_STEPS]: ViewComponent } = {
    ...personalInfoViews,
    ...humanCapitalViews,
    ...incomeAndExpensesViews,
    ...assetsAndDebtsViews,
    ...riskManagementViews,
  };

  const View = renderFunctions[step];

  useEffect(() => {
    if (!loaded.profile && !loading.profile && !error.profile) {
      dispatch(fetchProfile());
    }
  }, [dispatch, loaded.profile]);

  useEffect(() => {
    if (progress === PROFILE_PROGRESS.COMPLETE) {
      dispatch(push("/dashboard"));
    }
  }, [progress]);

  // if (loading.profile) {
  //   return <LinearProgress className="absolute w-full left-0" style={{ top: 121 }} />
  // }

  return (
    <>
      {loading.profile && (
        <LinearProgress
          className="absolute w-full left-0"
          style={{ top: 121 }}
        />
      )}
      <Box
        className={styles.content}
        style={shortScreen ? { paddingTop: "60px" } : undefined}
      >
        <View
          render={({
            component,
            onPrev,
            onNext,
            onSkip,
            nextLabel,
            nextDisabled,
            nextTooltip,
          }) => (
            <>
              <Container maxWidth="md">{component}</Container>
              <Box className={styles.footer}>
                <Container maxWidth="md">
                  <Box>
                    {!!onPrev && (
                      <Button
                        color="primary"
                        variant="outlined"
                        className="mr-3"
                        onClick={onPrev}
                        disabled={loading.profile}
                      >
                        Back
                      </Button>
                    )}
                  </Box>
                  <Box>
                    {!!onSkip && (
                      <Button
                        color="secondary"
                        className="mr-3"
                        disabled={loading.profile}
                        onClick={onSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {!!onNext &&
                      (nextTooltip ? (
                        <Tooltip
                          title={
                            <div
                              style={{
                                fontSize: 14,
                                lineHeight: "16px",
                                padding: 2,
                              }}
                            >
                              {nextTooltip}
                            </div>
                          }
                        >
                          <span>
                            <Button
                              color="primary"
                              onClick={onNext}
                              disabled={nextDisabled || loading.profile}
                            >
                              {nextLabel || "Next"}
                            </Button>
                          </span>
                        </Tooltip>
                      ) : (
                        <Button
                          fbColor="primary"
                          onClick={onNext}
                          disabled={nextDisabled || loading.profile}
                        >
                          {nextLabel || "Next"}
                        </Button>
                      ))}
                    {/*complete &&
                      <Button
                        className="ml-3"
                        fbColor="accent"
                        onClick={() => {
                          if (onNext) {
                            onNext();
                          }
                          goToDashboard();
                        }}
                      >Done
                      </Button>
                    */}
                  </Box>
                </Container>
              </Box>
            </>
          )}
        />
      </Box>
    </>
  );
};
