import React from "react";
import { makeStyles } from "@material-ui/core";
import { headerStyles } from "src/theme";
import Content from "./content";

const useStyles = makeStyles(headerStyles);

const OptimizedPlan = () => {
	const classes = useStyles();
    return (
			<main className={classes.mainContent}>
				<Content/>
			</main>
		)
}

export default OptimizedPlan;