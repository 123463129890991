import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@material-ui/icons/Close";
import { Box, makeStyles, Button } from "@material-ui/core";
import { getIsSidebarEnabled } from "src/store/dashboard/selector";
import { disableSidebar } from "src/store/dashboard/actions";
import { SideContent } from "./Content";
import { getRouterState } from "src/store/router/selector";

const useStyles = makeStyles({
  container: {
    height: "100%",
    width: "100%",
    overflowX: "hidden",
    // position: "relative",
  },
  mainContent: {
    height: "100%",
    width: "100%",
    // overflowY: "auto",
  },
  sidebar: {
    position: "fixed",
    width: "30%",
    maxWidth: "450px",
    right: "0px",
    top: 0,
    height: "100%",
    "z-index": 2000,
    display: "flex",
    flexDirection: "column",
    transitionDuration: ".3s",
    backgroundColor: "white",
    overflowY: "auto",
    boxShadow: "0px 0px 10px #88D8FB",
  },
  hiddenSidebar: {
    position: "fixed",
    width: "30%",
    maxWidth: "300px",
    right: "-300px",
    top: "0px",
    height: "100%",
    "z-index": 2000,
    display: "flex",
    flexDirection: "column",
    // transitionDuration: ".1s",
    backgroundColor: "white",
    overflowY: "auto",
    overflow: "hidden",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "right",
  },
  closeButton: {
    minWidth: "20px",
    borderRadius: "100%",
  },

  /* Hide scrollbar for Chrome, Safari and Opera */
  ".example::-webkit-scrollbar": {
    display: "none",
  },
  /* Hide scrollbar for IE, Edge and Firefox */
  ".example": {
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
});

const SidebarWrapper = ({ children }: { children: any }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(getIsSidebarEnabled);
  const router = useSelector(getRouterState);
  const scrollRef = useRef<any>(null);

  // if the route changes for any reason, close the sidebar
  useEffect(() => {
    dispatch(disableSidebar());
    scrollRef.current?.scrollTo(0, 0);
  }, [router.location]);

  const getSidebarStyle = () => {
    if (open) {
      return "sidebar";
    } else {
      return "hiddenSidebar";
    }
  };

  return (
    <div className={classes.container} ref={scrollRef}>
      <Box className={classes.mainContent}>{children}</Box>

      <Box className={classes[getSidebarStyle()]}>
        <Box className={classes.buttonBox}>
          <Button
            className={classes.closeButton}
            onClick={() => {
              dispatch(disableSidebar());
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <SideContent />
      </Box>
    </div>
  );
};

export default SidebarWrapper;
