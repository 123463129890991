import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";

import Accordion from "src/components/Accordion";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { Modal } from "src/components/Dialogs";
import Icon from "src/components/Icon";
import { getCalendlyLinkApi } from "src/apiService";
import { DashboardMessage } from "src/interfaces";
import {
  getDashboardActionItems,
  getSubscribedDashboardItems,
} from "src/store/dashboard/messagesSelector";
import { PLAN_NOTIFICATION_MESSAGES } from "src/store/dashboard/planNotifications";
import colors from "src/theme/colors";
import { AdapterLink } from "src/utils";
import { VIDEOS } from "src/store/dashboard/helpTopics";
import {
  getMiscState as selectMiscState,
  getPlanNotifications,
} from "src/store/dashboard/selector";
import { openUpgradeDialog } from "src/store/system/actions";
import { getIsSubscribed } from "src/store/system/selector";
import {
  dismissAllPlanNotifications,
  setMiscState,
  setVideo,
} from "src/store/dashboard/actions";

const linkTargets = {
  Coach: "/coach",
  Plan: "/build-plan",
  Upgrade: "/settings",
  Alerts: "/alerts",
  Accounts: "/accounts",
  Confirm: "/alerts",
  Update: "/transactions",
  Link: "/accounts",
  "Update Profile": "/accounts",
  "Update Insurance": "/risk",
  "Shop Insurance": "/solutions",
  "insurance:disability": "/solutions?solution=policygeniusdisability",
  "insurance:life": "/solutions?solution=policygeniuslife",
  "Open Account": "/betterment/create",
  "Refer A Friend": "/referral",
  "Refi My Private Loan": "/solutions?solution=newStudentLoans",
  "Tools & Products": "/studentloans",
  "Review Accounts": "/accounts",
  "Review Transactions": "/transactions",
};

const useStyles = makeStyles({
  list: {},
  item: {},
  itemTitle: {
    fontWeight: 600,
  },
  complete: {
    color: colors.blueGray3,
    textDecoration: "line-through",
  },
  cardContent: {
    height: 260,
    overflowY: "auto",
  },
  icon: {
    // marginLeft: 14
  },
  iconComplete: {},
  content: {
    fontWeight: 400,
    fontSize: 12,
  },
  button: {
    padding: 3,
    width: 145,
  },
});

const ActionItems = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const miscState = useSelector(selectMiscState);
  const subscribed = useSelector(getIsSubscribed);
  const messageSelector: any = subscribed
    ? getSubscribedDashboardItems
    : getDashboardActionItems;
  const messages: DashboardMessage[] = useSelector(messageSelector);
  const planNotifications = useSelector(getPlanNotifications);

  const [markedComplete, setMarkedComplete] = useState<Set<number>>(
    new Set([])
  );
  const [calendlyUrl, setCalendlyUrl] = useState("");
  const completeRef = useRef(new Set());

  const openUpgrade = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    dispatch(openUpgradeDialog());
  };

  const markItemComplete = (index: number) => {
    const newMarkedComplete = new Set(markedComplete);
    newMarkedComplete.add(index);
    setMarkedComplete(newMarkedComplete);
    completeRef.current = newMarkedComplete;
  };

  const openCalendlyRefinance = () => {
    getCalendlyLinkApi().then((result) => {
      let url = result.url + "?";
      if (result.prefill) {
        url += `name=${result.prefill.name}&email=${result.prefill.email}`;
        if (result.prefill.customAnswers) {
          url += `&a1=${result.prefill.customAnswers.a1}`;
        }
      }
      setCalendlyUrl(url);
    });
  };

  useEffect(() => {
    return () => {
      const markedMessages = messages.filter((message, index) =>
        completeRef.current.has(index)
      );
      if (!markedMessages.length) {
        return;
      }
      const markedIds = markedMessages.map((message) => message.id);
      const completeActionItems = miscState.completeActionItems || [];
      const newCompleteList = Array.from(
        new Set([...completeActionItems, ...markedIds])
      );
      const newMiscState = {
        ...miscState,
        completeActionItems: newCompleteList,
      };
      dispatch(setMiscState(newMiscState));
    };
  }, []);

  const renderButton = (action: string, index: number) => {
    const buttonProps: any = {
      className: styles.button,
      fbColor: "primary",
      onClick: (event: React.MouseEvent<any>) => {
        event.stopPropagation();
        markItemComplete(index);
      },
      variant: "outlined",
    };
    let label = action;
    if (action === "Upgrade" || action === "Upgrade/Wealth") {
      if (subscribed) {
        if (action === "Upgrade/Wealth") {
          label = "See Details";
          buttonProps.component = AdapterLink;
          buttonProps.to = "/wealth#debt";
        }
      } else {
        label = "Upgrade";
        buttonProps.onClick = (e: React.MouseEvent<any>) => {
          markItemComplete(index);
          openUpgrade(e);
        };
      }
    } else if (action === "Schedule A Call") {
      buttonProps.onClick = () => {
        markItemComplete(index);
        openCalendlyRefinance();
      };
    } else {
      buttonProps.component = AdapterLink;
      if (label.slice(0, 3) === "ins") {
        label = "Shop Insurance";
      }
      buttonProps.to = linkTargets[action as keyof typeof linkTargets];
    }
    return <Button {...buttonProps}>{label}</Button>;
  };

  const renderedPlanNotifications: any[] = [];
  if (planNotifications.length) {
    const item = planNotifications[0];
    const notification = PLAN_NOTIFICATION_MESSAGES.find(
      (found) => found.id === item.message
    );
    if (notification) {
      renderedPlanNotifications.push({
        disabled: false,
        title: (
          <Typography className={styles.itemTitle}>
            Plan progress update
          </Typography>
        ),
        titleDetail: (
          <div className="flex items-center justify-end">
            <IconButton onClick={() => dispatch(dismissAllPlanNotifications())}>
              <Icon iconName="fb-checkmark-outline" className={styles.icon} />
            </IconButton>
          </div>
        ),
        content: (
          <Box className={styles.content}>
            {notification.message(item.data)}
          </Box>
        ),
      });
    }
  }

  // planNotifications.forEach((item: { id: number, message: number, data: any }) => {
  //   const notification = PLAN_NOTIFICATION_MESSAGES.find((found) => found.id === item.message);
  //   if (notification) {
  //     renderedPlanNotifications.push({
  //       disabled: false,
  //       title: <Typography className={styles.itemTitle}>Plan progress update</Typography>,
  //       titleDetail: (
  //         <div className="flex items-center justify-end">
  //           <IconButton onClick={() => dispatch(dismissPlanNotification(item.id))}>
  //             <Icon
  //               iconName="fb-checkmark-outline"
  //               className={styles.icon}
  //             />
  //           </IconButton>
  //         </div>
  //       ),
  //       content: <Box className={styles.content}>{notification.message(item.data)}</Box>,
  //     });
  //   }
  // });

  return (
    <Card title="Action Items" className="h-full">
      <Box className={styles.cardContent}>
        {/*providers.filter(provider => provider.new).map(provider => <Box key={provider.item_id} className="flex items-center justify-between px-4">
          <Typography className="font-bold">Your {provider.name} account is ready to be linked</Typography>
          <Box className="flex items-center justify-end">
            <Button
              className={styles.button}
              fbColor="primary"
              variant="outlined"
              disabled={loading}
              onClick={() =>handleConfirmLinkedAccount(provider)}
            >Confirm Account
            </Button>
            <Icon color={provider.new ? undefined : "primary"} iconName="fb-checkmark-outline" className={styles.icon} />
          </Box>
        </Box>)*/}
        <Accordion
          sections={[
            ...renderedPlanNotifications,
            ...messages.map((message, index) => ({
              disabled: message.complete || markedComplete.has(index),
              title: (
                <Typography
                  className={
                    styles.itemTitle +
                    (message.complete || markedComplete.has(index)
                      ? " " + styles.complete
                      : "")
                  }
                >
                  {message.title}
                </Typography>
              ),
              titleDetail: (
                <div className="flex items-center justify-end">
                  {!!message.action && renderButton(message.action, index)}
                  <IconButton
                    disabled={markedComplete.has(index)}
                    onClick={() => markItemComplete(index)}
                  >
                    <Icon
                      color={markedComplete.has(index) ? "primary" : undefined}
                      iconName="fb-checkmark-outline"
                      className={styles.icon}
                    />
                  </IconButton>
                </div>
              ),
              content:
                message.complete || markedComplete.has(index) ? undefined : (
                  <p className={styles.content}>
                    {message.message}
                    {`actionItems${message.id}` in VIDEOS && (
                      <>
                        {" "}
                        <a
                          href="#"
                          onClick={() =>
                            dispatch(setVideo(`actionItems${message.id}`))
                          }
                        >
                          Learn More
                        </a>
                      </>
                    )}
                  </p>
                ),
            })),
          ]}
        />
        {/*provider && (
          <ConfirmLinkedAccountDialog
            provider={provider}
            open={confirmAccountsDialogVisible}
            onClose={() => setConfirmAccountsDialogVisible(false)}
          />
        )*/}
      </Box>
      <Modal
        title=" "
        size="lg"
        isOpen={!!calendlyUrl}
        onClose={() => setCalendlyUrl("")}
      >
        <Box style={{ height: 850, width: "100%" }}>
          <iframe
            style={{
              border: "none",
              height: "88%",
              width: "100%",
            }}
            src={calendlyUrl}
            title="Calendly scheduler"
          ></iframe>
        </Box>
      </Modal>
    </Card>
  );
};

export default ActionItems;
