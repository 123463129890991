const data = {
  answers: {
    heading: "Upgrade to Premium and find out. Powered by FitBUX's A.I.",
    subscribedHeading: "Powered by FitBUX's A.I.",
    items: [],
    showSubscribeButton: true,
  },
  calculators: {
    heading:
      'An easy way to "do the math" and compare strategies.',
    items: [],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [
      {
        iconName: "fa-heart-pulse",
        target: "/solutions?solution=policygeniuslife",
        label: "Shop Life Insurance",
      },
      {
        iconName: "fa-person-falling-burst",
        target: "/solutions?solution=policygeniusdisability",
        label: "Shop Disability Insurance",
      },
      {
        iconName: "fa-house-crack",
        target: "/solutions?solution=policygeniushome&return=insurance",
        label: "Shop Homeowners Insurance",
      },
    ],
  },
};

export default data;
