import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  addAccountApi,
  getAccountApi,
  initializeAccountsApi,
  updateAccountApi,
  getPlaidPublicTokenApi,
  fetchAccountProvidersApi,
  relinkAccountProviderApi,
  removeLinkedAccountApi,
  fetchLinkedAccountsApi,
} from "../../apiService/modules/account";
import {
  getAccountsLoaded,
  getAccounts as selectAccounts,
  getLinkedAccounts,
} from "./selector";
import { getIsSubscribed, getUpdatesSuspended } from "../system/selector";

import { FAIL, START, SUCCESS } from "../common";
import {
  Account,
  AccountProvider,
  FbAction,
  LinkedAccount,
  PublicToken,
} from "src/interfaces";
import * as actions from "./actions";
import * as profileActions from "../profileBuild/actions";
import * as transactionActions from "../transaction/actions";

function* getAccount({ payload }: FbAction<number>) {
  try {
    const data: Account = yield call(getAccountApi, payload);
    yield put({ type: actions.GET_ACCOUNT + SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.GET_ACCOUNT + FAIL, payload: error });
  }
}

function* getAccounts({ payload }: FbAction<boolean | undefined>) {
  try {
    const data: Account[] = yield call(initializeAccountsApi);
    data.forEach((account) => {
      if (account.variable === "priv_loan") {
        fork(getAccount, { payload: account.id } as FbAction<number>);
      }
    });
    yield put({
      type: actions.GET_ACCOUNTS + SUCCESS,
      payload: { data, overwrite: !!payload },
    });
  } catch (error) {
    yield put({ type: actions.GET_ACCOUNTS + FAIL, payload: error });
  }
}

function* addAccount({ payload }: FbAction<actions.AddAccountPayload>) {
  try {
    const { account, nextProfileStep: step } = payload;
    const data: Account = yield call(addAccountApi, account);
    yield put({ type: actions.ADD_ACCOUNT + SUCCESS, payload: data });
    if (step) {
      yield put({ type: profileActions.SET_PROFILE_STEP, payload: step });
    }
  } catch (error) {
    yield put({ type: actions.ADD_ACCOUNT + FAIL, payload: error });
  }
}

function* updateAccount({ payload }: FbAction<actions.UpdateAccountPayload>) {
  try {
    const data: Account = yield call<any>(
      updateAccountApi,
      payload.id,
      payload.update
    );
    yield put({
      type: actions.UPDATE_ACCOUNT + SUCCESS,
      payload: { id: payload.id, account: data },
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_ACCOUNT + FAIL, payload: error });
  }
}

function* removeAccount({ payload }: FbAction<number>) {
  try {
    yield call<any>(removeLinkedAccountApi, {
      id: payload,
    });
    yield put({ type: actions.DELETE_ACCOUNT + SUCCESS, payload });
  } catch (error) {
    yield put({ type: actions.DELETE_ACCOUNT + FAIL, payload: error });
  }
}

function* loadAllAccounts() {
  try {
    const accounts: Account[] = yield select(selectAccounts);
    const results: Account[] = yield all(
      accounts.map((account) => call(getAccountApi, account.id))
    );
    yield put({
      type: actions.LOAD_ALL_ACCOUNT_DETAILS + SUCCESS,
      payload: results,
    });
  } catch (error) {
    yield put({
      type: actions.LOAD_ALL_ACCOUNT_DETAILS + FAIL,
      payload: error,
    });
  }
}

function* getPlaidPublicToken({ payload }: FbAction<string | undefined>) {
  try {
    const data: PublicToken = yield call(getPlaidPublicTokenApi, payload);
    yield put({
      type: actions.GET_PLAID_PUBLIC_TOKEN + SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.GET_PLAID_PUBLIC_TOKEN + FAIL, payload: error });
  }
}

function* relinkLinkedAccount({ payload }: FbAction<string>) {
  try {
    const data: AccountProvider = yield call(relinkAccountProviderApi, payload);
    yield put({
      type: actions.RELINK_LINKED_ACCOUNT + SUCCESS,
      payload: {
        ...data,
        item_id: payload,
      },
    });
  } catch (error) {
    yield put({ type: actions.RELINK_LINKED_ACCOUNT + FAIL, payload: error });
  }
}

function* getAccountProviders() {
  try {
    const data: AccountProvider[] = yield call(fetchAccountProvidersApi);
    const existingAccounts: LinkedAccount[] = yield select(getLinkedAccounts);
    const linkedAccounts: LinkedAccount[] = existingAccounts.slice();
    const loaded: boolean = yield select(getAccountsLoaded);
    for (let i = 0; i < data.length; i++) {
      const { item_id, holdings } = data[i];
      try {
        if (!existingAccounts.length || data[i].new) {
          const {
            accounts,
          }: { accounts: LinkedAccount[] } = yield call(
            fetchLinkedAccountsApi,
            { item_id }
          );
          accounts.forEach((account) => {
            const accountExists = !!existingAccounts.find(
              (found) => found.id === account.id
            );
            if (!accountExists) {
              linkedAccounts.push({
                ...account,
                item_id,
                confirmed: !data[i].new, // TODO: from localstorage
                holdings,
              });
            }
          });
        }
      } catch (e) {
        console.warn(e);
      }
    }
    if (loaded && existingAccounts.length < linkedAccounts.length) {
      yield put(actions.getAccounts(true));
      yield put(transactionActions.getUnconfirmedTransactions());
    }
    yield put({
      type: actions.GET_ACCOUNT_PROVIDERS + SUCCESS,
      payload: {
        providers: data,
        linkedAccounts,
      },
    });
  } catch (error) {
    yield put({ type: actions.GET_ACCOUNT_PROVIDERS + FAIL, payload: error });
  }
}

function* pollForPlaidUpdates() {
  while (true) {
    yield delay(20000);
    const subscribed: boolean = yield select(getIsSubscribed);
    const suspended: boolean = yield select(getUpdatesSuspended);
    if (subscribed && !suspended) {
      yield getAccountProviders();
    }
  }
}

export function* accountSagas() {
  yield all([
    takeLatest(actions.GET_ACCOUNTS + START, getAccounts),
    takeLatest(actions.GET_PLAID_PUBLIC_TOKEN + START, getPlaidPublicToken),
    takeLatest(actions.GET_ACCOUNT_PROVIDERS + START, getAccountProviders),
    takeEvery(actions.ADD_ACCOUNT + START, addAccount),
    takeEvery(actions.UPDATE_ACCOUNT + START, updateAccount),
    takeEvery(actions.DELETE_ACCOUNT + START, removeAccount),
    takeLatest(actions.LOAD_ALL_ACCOUNT_DETAILS + START, loadAllAccounts),
    takeLatest(actions.RELINK_LINKED_ACCOUNT + START, relinkLinkedAccount),
    pollForPlaidUpdates(),
  ]);
}
