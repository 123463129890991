import { createSelector } from "reselect";
import { last } from "lodash";

import {
  Account,
  Cashflow,
  DashboardMessage,
  Goal,
  InsuranceLiabilities,
  PlanProjection,
  SPECIAL_REPAYMENTS,
} from "src/interfaces";

import {
  DASHBOARD_MESSAGES,
  PAID_DASHBOARD_MESSAGES,
  PAID_DASHBOARD_MESSAGES_ENUM,
  PAID_STUDENT_DASHBOARD_MESSAGES,
  STUDENT_DASHBOARD_MESSAGES,
} from "./dashboardMessages";
import {
  getCalls,
  getHcValue,
  getScore,
  getScoreRanks,
  getEmergencyFundTarget,
  getIdealPrivateLoanRate,
  getMarketingDeals,
  getMiscState,
  getLifeInsuranceLiabilities,
} from "./selector";
import { getAccountProviders, getAccounts } from "../account/selector";
import { getRawCashflows } from "../cashflow/selector";
import { getPortfolioSettings } from "../investments/selector";
import {
  getProfile,
  getSpouseInSchool,
  getSpouseProfile,
  getStudentStatus,
  getUserInSchool,
} from "../profileBuild/selector";
import {
  getCurrentPlan,
  getLivePlanAllocations,
  getLiveProjection,
  getPlanProjection,
  getPlans,
  livePlanIncomeTotal,
} from "../planBuild/selector";
import { featuresSelector, getIsCurrentStudent } from "../system/selector";
import { REVIEW_MESSAGES } from "../planBuild/text";
import { formatWholeDollars } from "src/utils";

const now = new Date();
const nowMonth = now.getMonth() + 1;
const nowYear = now.getFullYear();

export const getDashboardActionItems = createSelector(
  [
    getProfile,
    getCalls,
    getStudentStatus,
    getPlans,
    getCurrentPlan,
    featuresSelector,
    getScore,
    getHcValue,
    getScoreRanks,
    getAccounts,
    getRawCashflows,
    getIdealPrivateLoanRate,
    getMarketingDeals,
    getEmergencyFundTarget,
    getLifeInsuranceLiabilities,
    getMiscState,
    getPortfolioSettings,
    getIsCurrentStudent,
    getUserInSchool,
    getSpouseInSchool,
  ],
  (
    profile,
    callStatus,
    monthsSinceGraduation,
    plans,
    currentPlan,
    features,
    score,
    hcValue,
    scoreRanks,
    accounts,
    cashflows,
    privateLoanRate,
    marketingDeals,
    emergencyFundTarget,
    lifeInsuranceLiabilities: InsuranceLiabilities | null,
    miscState,
    portfolioSettings,
    isCurrentStudent,
    isMarried,
    userInSchool,
    spouseInSchool
  ) => {
    const messages: DashboardMessage[] = [{ ...DASHBOARD_MESSAGES[25] }];
    if (isCurrentStudent && (isMarried || (userInSchool && spouseInSchool))) {
      if (plans.length) {
        messages.push({ ...STUDENT_DASHBOARD_MESSAGES[0] });
      } else {
        const hcMessage = { ...DASHBOARD_MESSAGES[2] };
        hcMessage.message = hcMessage.message.replace(
          "HC_VALUE",
          formatWholeDollars(hcValue)
        );
        messages.push({ ...PAID_DASHBOARD_MESSAGES[0] }, hcMessage, {
          ...STUDENT_DASHBOARD_MESSAGES[0],
        });
      }
    } else if (monthsSinceGraduation < 0 && !plans.length) {
      messages.push({ ...DASHBOARD_MESSAGES[0] });
    }
    if (!portfolioSettings && !isCurrentStudent) {
      messages.push({ ...DASHBOARD_MESSAGES[24] });
    }
    const hiddenIds = miscState?.completeActionItems || [];
    if (!plans.length) {
      if (callStatus === "none") {
        let firstMessage: DashboardMessage = { ...DASHBOARD_MESSAGES[3] };
        if (score > 700) {
          firstMessage = { ...DASHBOARD_MESSAGES[1] };
        } else if (score > 500) {
          firstMessage = { ...DASHBOARD_MESSAGES[2] };
        }
        firstMessage.message = firstMessage.message.replace(
          "HC_VALUE",
          formatWholeDollars(hcValue)
        );
        messages.push(firstMessage);
      } else {
        if (score > 700) {
          messages.push({ ...DASHBOARD_MESSAGES[4] });
        } else if (score > 500) {
          messages.push({ ...DASHBOARD_MESSAGES[5] });
        } else {
          messages.push({ ...DASHBOARD_MESSAGES[6] });
        }
      }
    } else if (!features.hasPlan) {
      if (score > 700) {
        messages.push({ ...DASHBOARD_MESSAGES[7] });
      } else if (score > 500) {
        messages.push({ ...DASHBOARD_MESSAGES[8] });
      } else {
        messages.push({ ...DASHBOARD_MESSAGES[9] });
      }
    }
    let foundCcRefinanceDeal: any = false;
    let ccRefinanceDealComplete = false;
    let foundStudentRefinanceDeal: any = false;
    let studentRefinanceDealComplete = false;
    let foundLifeDeal: any = false;
    let lifeDealComplete = false;
    if (marketingDeals) {
      marketingDeals.forEach((deal: any) => {
        const now = new Date();
        now.setMonth(now.getMonth() - 6);
        const sixMonthsAgo = now.toISOString();
        const sixMonthsAgoNumber = +`${sixMonthsAgo.slice(
          0,
          4
        )}${sixMonthsAgo.slice(5, 7)}`;
        const dealNumber = +`${deal.mdate.slice(0, 4)}${deal.mdate.slice(
          5,
          7
        )}`;
        if (dealNumber < sixMonthsAgoNumber) {
          return true;
        }
        if (deal.group === 4) {
          foundStudentRefinanceDeal = true;
          if (deal.stage === 15) {
            studentRefinanceDealComplete = true;
          }
        } else if (deal.group === 9) {
          foundCcRefinanceDeal = true;
          if (deal.stage === 31) {
            ccRefinanceDealComplete = true;
          }
        } else if (deal.group === 13) {
          foundLifeDeal = true;
          if (deal.stage === 56) {
            lifeDealComplete = true;
          }
        }
      });
    }
    const privateLoan = accounts.find(
      (account: Account) => account.variable === "priv_loan"
    );
    const fedLoan = accounts.find(
      (account: Account) => account.variable === "fed_loan"
    );
    if (!foundStudentRefinanceDeal) {
      if (privateLoan && privateLoan.rate > 4) {
        messages.push({ ...DASHBOARD_MESSAGES[10] });
      } else if (fedLoan) {
        if (
          profile.fed_repayment_plan === "std_plan" ||
          profile.fed_repayment_plan === "ext_fixed"
        ) {
          // messages.push({ ...DASHBOARD_MESSAGES[11] });
          if (fedLoan.rate > 4) {
            messages.push({ ...DASHBOARD_MESSAGES[12] });
          }
          // } else {
          //   messages.push({ ...DASHBOARD_MESSAGES[13] });
        }
      }
    } else if (studentRefinanceDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[20] });
    }
    if (features.hasPlan && emergencyFundTarget && emergencyFundTarget.now) {
      if (
        emergencyFundTarget.now.cash_value <
        emergencyFundTarget.now.reserve_fund_amt_needed
      ) {
        const emergencyFundMessage = { ...DASHBOARD_MESSAGES[14] };
        emergencyFundMessage.message = emergencyFundMessage.message
          .replace(
            "TARGET_FUND",
            formatWholeDollars(emergencyFundTarget.now.reserve_fund_amt_needed)
          )
          .replace("TARGET_SCORE", "" + emergencyFundTarget.future.score);
        messages.push(emergencyFundMessage);
      } else {
        // messages.push({ ...DASHBOARD_MESSAGES[14], complete: true });
      }
    }
    if (!foundCcRefinanceDeal) {
      const creditCardAccount = accounts.find(
        (account: Account) =>
          account.variable === "credit_card" && account.balance_live
      );
      if (creditCardAccount) {
        messages.push({ ...DASHBOARD_MESSAGES[15] });
      }
    } else if (ccRefinanceDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[21] });
    }
    // if (!foundDisabilityDeal) {
    //   const disabilityCashflow = cashflows.find(
    //     (cashflow: Cashflow) => cashflow.type === "disability_insurance"
    //   );
    //   if (!disabilityCashflow) {
    //     messages.push({ ...DASHBOARD_MESSAGES[16] });
    //   }
    // } else if (disabilityDealComplete) {
    //   messages.push({ ...DASHBOARD_MESSAGES[23] });
    // }
    if (!foundLifeDeal) {
      const foundLifeInsurance = cashflows.filter(
        (cashflow: Cashflow) => cashflow.type === "life_insurance"
      );
      if (!foundLifeInsurance.length) {
        const lifeInsuranceMessage = { ...DASHBOARD_MESSAGES[17] };
        lifeInsuranceMessage.message = lifeInsuranceMessage.message.replace(
          "(additional)",
          ""
        );
        lifeInsuranceMessage.title = lifeInsuranceMessage.title.replace(
          "(additional)",
          ""
        );
        messages.push(lifeInsuranceMessage);
      } else if (lifeInsuranceLiabilities) {
        let tooLow = false;
        const target = Object.values(lifeInsuranceLiabilities).reduce(
          (result: number, item: { [field: string]: number }) =>
            result +
            Object.values(item).reduce(
              (subResult: number, subValue: number) => subResult + subValue,
              0
            ),
          0
        );
        foundLifeInsurance.forEach((insurance: Cashflow) => {
          if (
            !tooLow &&
            (!insurance.death_benefit || insurance.death_benefit < target)
          ) {
            tooLow = true;
            const lifeInsuranceMessage = { ...DASHBOARD_MESSAGES[17] };
            lifeInsuranceMessage.message = lifeInsuranceMessage.message.replace(
              "(additional)",
              "additional"
            );
            lifeInsuranceMessage.title = lifeInsuranceMessage.title.replace(
              "(additional)",
              "additional"
            );
            messages.push({ ...DASHBOARD_MESSAGES[17] });
          }
        });
      }
    } else if (lifeDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[22] });
    }
    messages.push({ ...DASHBOARD_MESSAGES[26] });
    messages.push(
      /*{ ...DASHBOARD_MESSAGES[18] }, */ { ...DASHBOARD_MESSAGES[19] }
    );
    return messages.filter((message) => hiddenIds.indexOf(message.id) < 0);
  }
);

export const getSubscribedDashboardItems = createSelector(
  [
    getProfile,
    getLiveProjection,
    getAccountProviders,
    getPlans,
    getLivePlanAllocations,
    featuresSelector,
    livePlanIncomeTotal,
    getHcValue,
    getScoreRanks,
    getAccounts,
    getRawCashflows,
    getIdealPrivateLoanRate,
    getMarketingDeals,
    getEmergencyFundTarget,
    getLifeInsuranceLiabilities,
    getMiscState,
    getPortfolioSettings,
  ],
  (
    profile,
    liveProjection,
    providers,
    plans,
    livePlanAllocations,
    features,
    incomeTotal,
    hcValue,
    scoreRanks,
    accounts,
    cashflows,
    privateLoanRate,
    marketingDeals,
    emergencyFundTarget,
    lifeInsuranceLiabilities: InsuranceLiabilities | null,
    miscState,
    portfolioSettings
  ) => {
    const messages: DashboardMessage[] = [];
    if (features.hasPlan) {
      messages.push(
        { ...PAID_DASHBOARD_MESSAGES_ENUM.REVIEW_ACCOUNTS },
        { ...PAID_DASHBOARD_MESSAGES_ENUM.REVIEW_TRANSACTIONS }
      );
    }
    if (!portfolioSettings) {
      messages.push(DASHBOARD_MESSAGES[24]);
    }
    const hiddenIds = miscState?.completeActionItems || [];
    if (!features.hasPlan) {
      messages.push({ ...PAID_DASHBOARD_MESSAGES_ENUM.FIRST_STEP_BUILD_PLAN });
    }
    if (liveProjection?.start) {
      const daysSincePlanImplemented =
        ((new Date() as any) -
          (new Date(liveProjection.start.slice(0, 10)) as any)) /
        24 /
        60 /
        60 /
        1000;
      if (daysSincePlanImplemented < 30) {
        messages.push({ ...PAID_DASHBOARD_MESSAGES_ENUM.LINK_ACCOUNTS });
      }
    }
    const accountsToConfirm = providers.filter((provider: any) => provider.new);
    if (accountsToConfirm.length) {
      messages.push({
        ...PAID_DASHBOARD_MESSAGES_ENUM.CONFIRM_LINKED_ACCOUNTS,
      });
    }
    const accountsToRelink = providers.filter(
      (provider: any) => provider.error
    );
    if (accountsToRelink.length) {
      messages.push({ ...PAID_DASHBOARD_MESSAGES_ENUM.UPDATE_LINKED_ACCOUNTS });
    }
    if (features.hasPlan) {
      if (
        profile.fed_repayment_plan !== "std_plan" &&
        profile.fed_repayment_plan !== "ext_fixed"
      ) {
        messages.push({ ...PAID_DASHBOARD_MESSAGES_ENUM.ISL_REFINANCE });
      }
      const assetAllocations = livePlanAllocations.assets;
      if (assetAllocations.ira_value) {
        const amount = (incomeTotal * assetAllocations.ira_value) / 100;
        const iraMessage = {
          ...PAID_DASHBOARD_MESSAGES_ENUM.OPEN_INVESTMENT_ACCOUNT,
        };
        iraMessage.message = iraMessage.message
          .replace("AMOUNT", formatWholeDollars(amount))
          .replace("ACCOUNT_TYPE", "an IRA account.");
        messages.push(iraMessage);
      }
      if (assetAllocations.roth_ira_value) {
        const amount = (incomeTotal * assetAllocations.roth_ira_value) / 100;
        const rothIraMessage = {
          ...PAID_DASHBOARD_MESSAGES_ENUM.OPEN_INVESTMENT_ACCOUNT,
        };
        rothIraMessage.message = rothIraMessage.message
          .replace("AMOUNT", formatWholeDollars(amount))
          .replace("ACCOUNT_TYPE", "a Roth IRA account.");
        messages.push(rothIraMessage);
      }
      if (assetAllocations.other_investment_value) {
        const amount =
          (incomeTotal * assetAllocations.other_investment_value) / 100;
        const investmentMessage = {
          ...PAID_DASHBOARD_MESSAGES_ENUM.OPEN_INVESTMENT_ACCOUNT,
        };
        investmentMessage.message = investmentMessage.message
          .replace("AMOUNT", formatWholeDollars(amount))
          .replace("ACCOUNT_TYPE", "a taxable investment account.");
        messages.push(investmentMessage);
      }
    }
    let foundCcRefinanceDeal: any = false;
    let ccRefinanceDealComplete = false;
    let foundStudentRefinanceDeal: any = false;
    let studentRefinanceDealComplete = false;
    let foundLifeDeal: any = false;
    let lifeDealComplete = false;
    let foundDisabilityDeal: any = false;
    let disabilityDealComplete = false;
    if (marketingDeals) {
      marketingDeals.forEach((deal: any) => {
        const now = new Date();
        now.setMonth(now.getMonth() - 6);
        const sixMonthsAgo = now.toISOString();
        const sixMonthsAgoNumber = +`${sixMonthsAgo.slice(
          0,
          4
        )}${sixMonthsAgo.slice(5, 7)}`;
        const dealNumber = +`${deal.mdate.slice(0, 4)}${deal.mdate.slice(
          5,
          7
        )}`;
        if (dealNumber < sixMonthsAgoNumber) {
          return true;
        }
        if (deal.group === 4) {
          foundStudentRefinanceDeal = true;
          if (deal.stage === 15) {
            studentRefinanceDealComplete = true;
          }
        } else if (deal.group === 9) {
          foundCcRefinanceDeal = true;
          if (deal.stage === 31) {
            ccRefinanceDealComplete = true;
          }
        } else if (deal.group === 13) {
          foundLifeDeal = true;
          if (deal.stage === 56) {
            lifeDealComplete = true;
          }
        } else if (deal.group === 14) {
          foundDisabilityDeal = true;
          if (deal.stage === 60) {
            disabilityDealComplete = true;
          }
        }
      });
    }
    const privateLoan = accounts.find(
      (account: Account) => account.variable === "priv_loan"
    );
    const fedLoan = accounts.find(
      (account: Account) => account.variable === "fed_loan"
    );
    if (!foundStudentRefinanceDeal) {
      if (privateLoan && privateLoan.rate > 4) {
        messages.push({ ...DASHBOARD_MESSAGES[10] });
      } else if (fedLoan) {
        if (
          profile.fed_repayment_plan === "std_plan" ||
          profile.fed_repayment_plan === "ext_fixed"
        ) {
          // messages.push({ ...DASHBOARD_MESSAGES[11] });
          if (fedLoan.rate > 4) {
            messages.push({ ...DASHBOARD_MESSAGES[12] });
          }
        } else {
          messages.push({ ...DASHBOARD_MESSAGES[13] });
        }
      }
    } else if (studentRefinanceDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[20] });
    }
    if (features.hasPlan && emergencyFundTarget && emergencyFundTarget.now) {
      if (
        emergencyFundTarget.now.cash_value <
        emergencyFundTarget.now.reserve_fund_amt_needed
      ) {
        const emergencyFundMessage = { ...DASHBOARD_MESSAGES[14] };
        emergencyFundMessage.message = emergencyFundMessage.message
          .replace(
            "TARGET_FUND",
            formatWholeDollars(emergencyFundTarget.now.reserve_fund_amt_needed)
          )
          .replace("TARGET_SCORE", "" + emergencyFundTarget.future.score);
        messages.push(emergencyFundMessage);
      } else {
        // messages.push({ ...DASHBOARD_MESSAGES[14], complete: true });
      }
    }
    if (!foundCcRefinanceDeal) {
      const creditCardAccount = accounts.find(
        (account: Account) =>
          account.variable === "credit_card" && account.balance_live
      );
      if (creditCardAccount) {
        messages.push({ ...DASHBOARD_MESSAGES[15] });
      }
    } else if (ccRefinanceDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[21] });
    }
    if (!foundDisabilityDeal) {
      const disabilityCashflow = cashflows.find(
        (cashflow: Cashflow) => cashflow.type === "disability_insurance"
      );
      if (!disabilityCashflow) {
        messages.push({ ...DASHBOARD_MESSAGES[16] });
      }
    } else if (disabilityDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[23] });
    }
    if (!foundLifeDeal) {
      const foundLifeInsurance = cashflows.filter(
        (cashflow: Cashflow) => cashflow.type === "life_insurance"
      );
      if (!foundLifeInsurance.length) {
        const lifeInsuranceMessage = { ...DASHBOARD_MESSAGES[17] };
        lifeInsuranceMessage.message = lifeInsuranceMessage.message.replace(
          "(additional)",
          ""
        );
        lifeInsuranceMessage.title = lifeInsuranceMessage.title.replace(
          "(additional)",
          ""
        );
        messages.push(lifeInsuranceMessage);
      } else if (lifeInsuranceLiabilities) {
        let tooLow = false;
        const target = Object.values(lifeInsuranceLiabilities).reduce(
          (result: number, item: { [field: string]: number }) =>
            result +
            Object.values(item).reduce(
              (subResult: number, subValue: number) => subResult + subValue,
              0
            ),
          0
        );
        foundLifeInsurance.forEach((insurance: Cashflow) => {
          if (
            !tooLow &&
            (!insurance.death_benefit || insurance.death_benefit < target)
          ) {
            tooLow = true;
            const lifeInsuranceMessage = { ...DASHBOARD_MESSAGES[17] };
            lifeInsuranceMessage.message = lifeInsuranceMessage.message.replace(
              "(additional)",
              "additional"
            );
            lifeInsuranceMessage.title = lifeInsuranceMessage.title.replace(
              "(additional)",
              "additional"
            );
            messages.push({ ...DASHBOARD_MESSAGES[17] });
          }
        });
      }
    } else if (lifeDealComplete) {
      messages.push({ ...DASHBOARD_MESSAGES[22] });
    }
    messages.push({ ...DASHBOARD_MESSAGES[18], ...DASHBOARD_MESSAGES[19] });
    return messages.filter((message) => hiddenIds.indexOf(message.id) < 0);
  }
);

export const getSubscribedStudentDashboardItems = createSelector(
  [
    getProfile,
    getSpouseProfile,
    featuresSelector,
    getPlans,
    getLiveProjection,
    getAccounts,
    getMarketingDeals,
    getEmergencyFundTarget,
    getMiscState,
    getUserInSchool,
    getSpouseInSchool,
  ],
  (
    userProfile,
    spouseProfile,
    features,
    plans,
    liveProjection,
    accounts,
    marketingDeals,
    emergencyFundTarget,
    miscState,
    userInSchool,
    spouseInSchool
  ) => {
    const messages: DashboardMessage[] = [];
    const hiddenIds = miscState?.completeActionItems || [];
    if (!plans.length) {
      messages.push({ ...PAID_DASHBOARD_MESSAGES[0] });
    } else if (!features.hasPlan) {
      messages.push({ ...PAID_STUDENT_DASHBOARD_MESSAGES[0] });
    }
    if (liveProjection?.start) {
      const daysSincePlanImplemented =
        ((new Date() as any) -
          (new Date(liveProjection.start.slice(0, 10)) as any)) /
        24 /
        60 /
        60 /
        1000;
      if (daysSincePlanImplemented < 30) {
        messages.push({ ...PAID_DASHBOARD_MESSAGES[1] });
      }
    }
    if (!features.married || (userInSchool && spouseInSchool)) {
      const privateLoan = accounts.find(
        (account: Account) => account.variable === "priv_loan"
      );
      if (privateLoan) {
        messages.push({ ...PAID_STUDENT_DASHBOARD_MESSAGES[1] });
      }
    } else {
      const personNotInSchool = spouseInSchool ? "applicant" : "spouse";
      let foundStudentRefinanceDeal: any = false;
      let studentRefinanceDealComplete = false;
      if (marketingDeals) {
        marketingDeals.forEach((deal: any) => {
          const now = new Date();
          now.setMonth(now.getMonth() - 6);
          const sixMonthsAgo = now.toISOString();
          const sixMonthsAgoNumber = +`${sixMonthsAgo.slice(
            0,
            4
          )}${sixMonthsAgo.slice(5, 7)}`;
          const dealNumber = +`${deal.mdate.slice(0, 4)}${deal.mdate.slice(
            5,
            7
          )}`;
          if (dealNumber < sixMonthsAgoNumber) {
            return true;
          }
          if (deal.group === 4) {
            foundStudentRefinanceDeal = true;
            if (deal.stage === 15) {
              studentRefinanceDealComplete = true;
            }
          }
        });
        const fedLoan = accounts.find(
          (account: Account) =>
            account.variable === "fed_loan" &&
            (account.who || account.whose) === personNotInSchool
        );
        const privateLoan = accounts.find(
          (account: Account) =>
            account.variable === "priv_loan" &&
            (account.who || account.whose) === personNotInSchool
        );
        if (!foundStudentRefinanceDeal) {
          if (privateLoan && privateLoan.rate > 4) {
            messages.push({ ...DASHBOARD_MESSAGES[10] });
          } else if (fedLoan) {
            const profile = spouseInSchool ? userProfile : spouseProfile;
            if (
              profile.fed_repayment_plan === "std_plan" ||
              profile.fed_repayment_plan === "ext_fixed"
            ) {
              // messages.push({ ...DASHBOARD_MESSAGES[11] });
              if (fedLoan.rate > 4) {
                messages.push({ ...DASHBOARD_MESSAGES[12] });
              }
            } else {
              messages.push({ ...DASHBOARD_MESSAGES[13] });
            }
          }
        } else if (studentRefinanceDealComplete) {
          messages.push({ ...DASHBOARD_MESSAGES[20] });
        }
      }

      if (features.hasPlan && emergencyFundTarget && emergencyFundTarget.now) {
        if (
          emergencyFundTarget.now.cash_value <
          emergencyFundTarget.now.reserve_fund_amt_needed
        ) {
          const emergencyFundMessage = { ...DASHBOARD_MESSAGES[14] };
          emergencyFundMessage.message = emergencyFundMessage.message
            .replace(
              "TARGET_FUND",
              formatWholeDollars(
                emergencyFundTarget.now.reserve_fund_amt_needed
              )
            )
            .replace("TARGET_SCORE", "" + emergencyFundTarget.future.score);
          messages.push(emergencyFundMessage);
        } else {
          // messages.push({ ...DASHBOARD_MESSAGES[14], complete: true });
        }
      }
    }

    messages.push({ ...DASHBOARD_MESSAGES[18], ...DASHBOARD_MESSAGES[19] });
    return messages.filter((message) => hiddenIds.indexOf(message.id) < 0);
  }
);

const calculateScoreBeforeAndAfterDate = (
  projection: PlanProjection,
  dateString: string
) => {
  const eventYear = dateString.slice(0, 4);
  const eventMonth = dateString.slice(5, 7);
  const monthsUntilEvent =
    +eventMonth - nowMonth + 12 * Math.max(0, +eventYear - nowYear);
  const scoreBeforeEvent = projection.score[monthsUntilEvent - 2];
  const scoreAfterEvent = projection.score[monthsUntilEvent + 2];
  return scoreAfterEvent - scoreBeforeEvent;
};

export const getPlanReviewMessages = createSelector(
  [getProfile, getPlanProjection, getCurrentPlan, getScore],
  (profile, projection, plan, currentScore) => {
    const messages: { retirement: any; now: any; overTime: any[] } = {
      retirement: null,
      now: null,
      overTime: [],
    };
    if (!projection) {
      return messages;
    }
    projection.goal.forEach((goal: Goal) => {
      if (goal.type === "house") {
        if (goal.target === goal.date) {
          messages.overTime.push(REVIEW_MESSAGES.BOUGHT_HOUSE_ON_TARGET);
        } else {
          const target = goal.target || "";
          const targetMonth =
            target[5] === "0" ? target.slice(6, 7) : target.slice(5, 7);
          const actualMonth =
            goal.date[5] === "0"
              ? goal.date.slice(6, 7)
              : goal.date.slice(5, 7);
          const targetString = `${targetMonth}/${target.slice(0, 4)}`;
          const actualString = `${actualMonth}/${goal.date.slice(0, 4)}`;
          messages.overTime.push(
            REVIEW_MESSAGES.MISSED_HOUSE_TARGET.replace(
              "ACTUAL",
              actualString
            ).replace("TARGET", targetString)
          );
        }
      } else if (goal.type === "marriage") {
        const marriedDate = goal.date;
        if (calculateScoreBeforeAndAfterDate(projection, marriedDate) > 0) {
          messages.overTime.push(REVIEW_MESSAGES.SCORE_INCREASES_FROM_MARRIAGE);
        } else {
          messages.overTime.push(REVIEW_MESSAGES.SCORE_DECREASES_FROM_MARRIAGE);
        }
      } else if (goal.type === "student_loan") {
        const payoffDate = goal.date;
        if (calculateScoreBeforeAndAfterDate(projection, payoffDate) > 0) {
          const payoffMonth =
            payoffDate[5] === "0"
              ? payoffDate.slice(6, 7)
              : payoffDate.slice(5, 7);
          const dateString = `${payoffMonth}/${payoffDate.slice(0, 4)}`;
          messages.overTime.push(
            REVIEW_MESSAGES.SCORE_INCREASES_FROM_SL_PAYOFF.replace(
              "DATE",
              dateString
            )
          );
        }
      }
    });
    if (plan.lifeevents.find((event) => event.eventtype === "child")) {
      messages.overTime.push(REVIEW_MESSAGES.HAVE_A_CHILD);
    }
    const finalScore = last(projection.score) || 0;
    const aDate = new Date();
    const retirementDate = new Date(
      aDate.setMonth(aDate.getMonth() + projection.score.length)
    );
    const retirementYear = retirementDate.getFullYear();
    const dob_year = profile.dob_year;
    const retirementAge = retirementYear - dob_year;
    const retirementProbability = (finalScore / 10).toFixed(0);
    if (finalScore >= 700) {
      messages.retirement = REVIEW_MESSAGES.RETIRE_HIGH.replace(
        "PERCENT",
        retirementProbability
      ).replace("AGE", "" + retirementAge);
    } else {
      messages.retirement = REVIEW_MESSAGES.RETIRE_LOW.replace(
        "PERCENT",
        retirementProbability
      ).replace("AGE", "" + retirementAge);
    }
    if (currentScore < 500 && finalScore >= 500) {
      const goesOver500MonthIndex = projection.score.findIndex(
        (score) => score >= 500
      );
      const goesOver500Date = new Date(
        new Date().setMonth(now.getMonth() + goesOver500MonthIndex)
      );
      const goesOver500DateString = `${
        goesOver500Date.getMonth() + 1
      }/${goesOver500Date.getFullYear()}`;
      messages.overTime.push(
        REVIEW_MESSAGES.SCORE_INCREASES_PAST_500.replace(
          "xx/xx",
          goesOver500DateString
        )
      );
    } else if (finalScore >= 700) {
      if (currentScore < 700) {
        messages.overTime.push(REVIEW_MESSAGES.SCORE_INCREASES_PAST_700);
      } else {
        messages.overTime.push(REVIEW_MESSAGES.SCORE_STAYS_HIGH);
      }
    }
    if (
      projection.score[0] > currentScore &&
      SPECIAL_REPAYMENTS.indexOf(plan.studentloan[0].repayplan as any) >= 0 &&
      profile.fed_repayment_plan !== plan.studentloan[0].repayplan
    ) {
      messages.now = REVIEW_MESSAGES.SCORE_INCREASED_FROM_IDR;
    }
    return messages;
  }
);
