import React from "react";
import { useSelector } from "react-redux";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Select,
} from "@material-ui/core";

import FbRadio from "src/components/Radio";
import {
  getSpouseStudentStatus,
  getStudentStatus,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField, PercentTextField } from "src/utils/inputMask";

import { FormComponentType } from "./interfaces";

const PrivateStudentLoanForm: FormComponentType = ({
  formState,
  updateField,
}) => {
  const isMarried = useSelector(getIsMarried);
  const isSpouse = formState.who === "spouse";
  const monthsSinceGraduation = useSelector(
    isSpouse ? getSpouseStudentStatus : getStudentStatus
  );
  const their = isSpouse ? "their" : "your";
  const they = isSpouse ? "they" : "you";
  return (
    <>
      {isMarried && (
        <Grid container spacing={3}>
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-4">
                Who is the borrower on the loan?
              </FormLabel>
              <Select
                variant="outlined"
                fullWidth
                name="who"
                style={{ maxWidth: 350, width: "100%" }}
                onChange={updateField}
                value={formState.who}
              >
                <MenuItem value="applicant">Me</MenuItem>
                <MenuItem value="spouse">My Spouse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            What is {their} total student loan balance to date?
          </FormLabel>
          <DollarTextField
            name="balance"
            onChange={updateField}
            style={{ maxWidth: 350, width: "100%" }}
            value={formState.balance || 0}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="mt-5">
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className="mb-3 h-4">
            What is {their} average interest rate?
          </FormLabel>
          <PercentTextField
            allowZero
            name="rate"
            style={{ maxWidth: 350, width: "100%" }}
            onChange={updateField}
            value={formState.rate || 0}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      {monthsSinceGraduation >= 6 && (
        <>
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" className="mb-3 h-4">
                What is {their} monthly payment?
              </FormLabel>
              <DollarTextField
                name="payment"
                style={{ maxWidth: 350, width: "100%" }}
                onChange={updateField}
                value={formState.payment || 0}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="mt-5">
            <FormControl component="fieldset">
              <FormLabel component="legend" className="mb-3">
                Do {they} make prepayments?
              </FormLabel>
              <RadioGroup
                aria-label="student_loan_prepayments"
                name="student_loan_prepayments"
                onChange={updateField}
                value={formState.student_loan_prepayments || "n"}
                className="flex flex-row"
              >
                <FormControlLabel value="n" control={<FbRadio />} label="No" />
                <FormControlLabel value="y" control={<FbRadio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {formState.student_loan_prepayments === "y" && (
            <Grid item xs={12} className="mt-5">
              <FormControl component="fieldset">
                <FormLabel component="legend" className="mb-3">
                  Do {they} make...
                </FormLabel>
                <RadioGroup
                  aria-label="student_loan_prepayments_type"
                  name="student_loan_prepayments_type"
                  onChange={updateField}
                  value={formState.student_loan_prepayments_type || "1"}
                  className="flex flex-row"
                >
                  <FormControlLabel
                    value="1"
                    control={<FbRadio />}
                    label="Scheduled prepayments"
                  />
                  <FormControlLabel
                    value="2"
                    control={<FbRadio />}
                    label={`Prepayments whenever ${they} can`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default PrivateStudentLoanForm;
