import React from "react";
import clsx from "clsx";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { progressBarStyles } from "../../theme/base";

const useStyles = makeStyles(progressBarStyles);

interface ILinearProgress {
  fbType?: "round";
}

const ProgressBar: React.FC<LinearProgressProps & ILinearProgress> = ({
  fbType,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return fbType === "round" ? (
    <LinearProgress className={clsx(classes.root, className)} {...rest} />
  ) : (
    <LinearProgress className={className} {...rest} />
  );
};

export default ProgressBar;
