import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forOwn } from "lodash";

import { Grid } from "@material-ui/core";

import { tabLabels } from "../data";
import Tabs from "src/components/Tabs";
import { MappedCashflow, INCOME_TYPES } from "src/interfaces";
import BudgetCard from "../Components/BudgetCard";
import Dialog from "../Components/Dialog";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import { getIncomeSummary } from "src/store/dashboard/selector";
import { getDashboardCashflows } from "src/store/cashflow/selector";
import {
  getIsCurrentStudent,
  getIsSubscribed,
} from "src/store/system/selector";

const IncomeCard = ({ openTransactionsDialog }: any) => {
  const dispatch = useDispatch();
  const subscribed = useSelector(getIsSubscribed);
  const incomeSummary = useSelector(getIncomeSummary);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const { income }: any = useSelector(getDashboardCashflows);

  const [currentTab, setCurrentTab] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const _handleTabs = (value: number) => setCurrentTab(value);

  const handleCardEditClick = (
    changes: { [stringId: string]: number },
    deletedIds: Set<number>,
    newValues: { [type: string]: number }
  ) => {
    if (isEditing) {
      forOwn(changes, (amount: number, stringId: string) => {
        const cashflow = income.find(
          (item: MappedCashflow) => item.id === +stringId
        );
        if (cashflow) {
          const newAmount = currentTab === 2 ? amount : amount * 12;
          const update: any = { id: +stringId, amount: newAmount };
          dispatch(editCashflow(update));
        }
      });
      Array.from(deletedIds).forEach((id: number) =>
        dispatch(removeCashflow(id))
      );
      forOwn(newValues, (rawAmount: number, type: string) => {
        if (rawAmount) {
          const amount = currentTab === 2 ? rawAmount : rawAmount * 12;
          const splitType = type.split("__");
          const who = splitType?.[1] === "spouse" ? "spouse" : "applicant";
          const cashflowType = splitType[0];
          dispatch(
            addCashflow({ cashflow: { amount, type: cashflowType, who } })
          );
        }
      });
    }
    setEditing(!isEditing);
  };

  const openAddDialog = () => setAddDialogVisible(true);
  const closeAddDialog = () => setAddDialogVisible(false);
  const addItem = (values: { type: string; annual: string; who?: string }) => {
    const newCashflow: any = {
      type: values.type,
      amount: +values.annual || 0,
      who: values.who || "applicant",
    };
    if (isCurrentStudent && INCOME_TYPES[values.type]) {
      newCashflow.inschool = true;
    }
    dispatch(addCashflow({ cashflow: newCashflow }));
    closeAddDialog();
  };

  const availableTypeList: Array<{ label: string; value: string }> = [];
  forOwn(INCOME_TYPES, (label, cashflowType) => {
    availableTypeList.push({
      label,
      value: cashflowType,
    });
  });

  const getActual = () => {
    if (currentTab === 0) {
      return incomeSummary.current;
    } else if (currentTab === 1) {
      return incomeSummary.monthly;
    }
    return incomeSummary.annual;
  };

  const timeframes = ["current", "monthly", "annual"];
  const timeframe = timeframes[currentTab];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs
          disabled={!subscribed}
          tooltip={
            !subscribed ? (
              <div style={{ fontSize: 14, lineHeight: "16px", padding: 2 }}>
                Build and implement a plan to see your cashflow history.
              </div>
            ) : (
              ""
            )
          }
          list={tabLabels}
          value={currentTab}
          _handleChange={_handleTabs}
        />
      </Grid>
      <Grid item xs={12}>
        <BudgetCard
          title="Income"
          budget={income}
          openTransactionsDialog={
            subscribed
              ? (key) => openTransactionsDialog("income", key, timeframe)
              : undefined
          }
          actual={subscribed ? getActual() : undefined}
          onSave={handleCardEditClick}
          onAdd={openAddDialog}
          editing={isEditing}
          annual={currentTab === 2}
          addOwner
        />
      </Grid>
      <Dialog
        title="Income"
        list={availableTypeList} //send dropdown list
        open={addDialogVisible}
        onAdd={addItem}
        onClose={closeAddDialog}
        showOwner
      />
    </Grid>
  );
};

export default IncomeCard;
