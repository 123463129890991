import React from "react";

import {
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
} from "@material-ui/core";

import Radio from "src/components/Radio";
import AutoLoanForm from "./AutoLoanForm";

import { FormComponentType } from "./interfaces";
import { DollarTextField } from "src/utils/inputMask";

const AutoValueForm: FormComponentType = ({ formState, updateField }) => (
  <>
    <Grid item xs={12}>
      <FormLabel component="legend" className="my-4 h-4">
        What is the value of the vehicle?
      </FormLabel>
      <DollarTextField
        name="auto_value"
        style={{ maxWidth: 350, width: "100%" }}
        onChange={updateField}
        variant="outlined"
        value={formState.auto_value || 0}
      />
    </Grid>
    <Grid item xs={12}>
      <FormLabel component="legend" className="mt-8 mb-2">
        Do you have a loan associated with this vehicle?
      </FormLabel>
      <RadioGroup
        aria-label="has_auto_loan"
        name="has_auto_loan"
        style={{ maxWidth: 350, width: "100%" }}
        onChange={updateField}
        value={formState.has_auto_loan}
        className="flex flex-row"
      >
        <FormControlLabel value="n" control={<Radio />} label="No" />
        <FormControlLabel value="y" control={<Radio />} label="Yes" />
      </RadioGroup>
    </Grid>
    {formState.has_auto_loan === "y" && (
      <AutoLoanForm
        formState={formState}
        updateField={updateField}
        hideValue={true}
      />
    )}
  </>
);

export default AutoValueForm;
