const data = {
  answers: {
    heading: "Upgrade to Premium and find out. Powered by FitBUX's A.I.",
    subscribedHeading: "Powered by FitBUX's A.I.",
    items: [
      {
        iconName: "fa-piggy-bank",
        target: "/emergency-fund-amount",
        label: "Emergency Fund Optimization",
      },
    ],
    showSubscribeButton: true,
  },
  calculators: {
    heading: "",
    items: [],
  },
  products: {
    heading: "Products that help you analyze and implement your plan.",
    items: [
      {
        iconName: "fa-graduation-cap",
        target: "/solutions?solution=moneyschool",
        label: "Money School",
      },
    ],
    showSubscribeButton: true,
  },
};

export default data;
