import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import Button from "src/components/Button";
import Card from "src/components/Card";
import HeaderText from "src/components/HeaderText";
import LockedLayer from "src/components/LockedLayer";
import AddTransactions from "./Blocks/AddTransactions";
import ConfirmedTransactions from "./Blocks/ConfirmedTransactions";
import ConfirmIncome from "./Blocks/ConfirmIncome";
import ConfirmIncomeDialog from "./Dialogs/ConfirmIncomeDialog";
import { getBreakoutBaseApi } from "src/apiService";
import { Transaction } from "src/interfaces";
import { getHasPlan, getIsSubscribed } from "src/store/system/selector";
import { AdapterLink } from "src/utils";

const useStyles = makeStyles({
  modal: {
    position: "absolute",
    top: 200,
    left: "50%",
    transform: "translateX(-50%);",
    minWidth: 388,
    padding: "0 24px",
    width: 388,
    textAlign: "center",
  },
  modalText: {
    fontSize: 18,
    lineHeight: 1.25,
    marginBottom: 24,
  },
});

const emptyTransaction: Transaction = {
  account: -999,
  amount: -999,
  date: "1999-01-01",
  description: "",
  id: -999,
  type: 7000,
};

export const Content = () => {
  const styles = useStyles();
  const hasPlan = useSelector(getHasPlan);
  const isSubscribed = useSelector(getIsSubscribed);
  const unlocked = isSubscribed && hasPlan;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [showEditExpenseDialog, setShowEditExpenseDialog] = useState(false);
  const [
    confirmingTransaction,
    setConfirmingTransaction,
  ] = useState<Transaction>(emptyTransaction);
  const [editing, setEditing] = useState(false);

  const openConfirmDialog = (transaction?: Transaction, editing?: boolean) => {
    setEditing(!!editing);
    let rootTransaction = transaction;
    let promise = Promise.resolve();
    if (transaction) {
      promise = getBreakoutBaseApi(transaction.id)
        .then((result: any) => {
          rootTransaction = result?.transactions?.[0] || transaction;
        })
        .catch(console.error);
    }
    return promise.then(() => {
      if (rootTransaction) {
        setConfirmingTransaction(rootTransaction);
      } else {
        setConfirmingTransaction(emptyTransaction);
      }
      setShowConfirmDialog(true);
    });
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setEditing(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <HeaderText
          className="mb-0"
          heading="Manage Your Transactions"
          content={
            <>
              <Typography paragraph className="text-sm">
                Making sure that your transaction-related information is
                up-to-date is key to your financial plan.
              </Typography>
              <Typography paragraph className="text-sm">
                In particular, it is important that you confirm your income
                transaction information and include deductions, if applicable
                (e.g., 401k contributions, etc.).
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LockedLayer isLocked={!unlocked}>
          <AddTransactions openConfirmTransactionsDialog={openConfirmDialog} />
        </LockedLayer>
      </Grid>
      <Grid item xs={12} md={6}>
        <LockedLayer isLocked={!unlocked}>
          <ConfirmIncome openConfirmDialog={openConfirmDialog} />
        </LockedLayer>
      </Grid>
      <Grid item xs={12} md={6}>
        <LockedLayer isLocked={!unlocked}>
          <ConfirmedTransactions
            openDialog={(transaction: Transaction) =>
              openConfirmDialog(transaction, true)
            }
          />
        </LockedLayer>
      </Grid>
      {!hasPlan && (
        <Card className={styles.modal}>
          <Typography className={styles.modalText} variant="h2" component="h2">
            Build a plan and implement to manage transactions.
          </Typography>
          <Button fbColor="primary" component={AdapterLink} to="/build-plan">
            Build a Plan
          </Button>
        </Card>
      )}
      <ConfirmIncomeDialog
        open={showConfirmDialog}
        onClose={closeConfirmDialog}
        transaction={confirmingTransaction}
        alreadyConfirmed={editing}
      />
      {/*<EditExpenseDialog
        open={showEditExpenseDialog}
        onClose={toggleExpenseDialog}
        transaction={confirmingTransaction}
      />*/}
    </Grid>
  );
};
